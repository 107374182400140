export default function scrollToTop(targetElement: typeof window | HTMLElement = window) {
  if ("scrollBehavior" in document.documentElement.style) {
    targetElement.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  } else {
    targetElement.scrollTo(0, 0);
  }
}
