import { MULTISTOP_IATA_LIST_SEPARATOR, PARAM_LIST_SEPARATOR } from "@flights/constants";
import { State as SearchCriteriaState } from "./searchCriteria/state";
import { State } from "./search/state";

export const getSearchCriteria = (query: URLSearchParams, searchBoxState: State): SearchCriteriaState => {
  const fromLocationNameSearchbox = getFromLocationFromSearchState(searchBoxState, "name");
  const toLocationNameSearchbox = getToLocationFromSearchState(searchBoxState, "name");
  const fromCountrySearchbox = getFromLocationFromSearchState(searchBoxState, "country");
  const toCountrySearchbox = getToLocationFromSearchState(searchBoxState, "country") || ["Anywhere"];

  return {
    fromCountry: fromCountrySearchbox,
    toCountry: toCountrySearchbox,
    fromLocationName: fromLocationNameSearchbox,
    toLocationName: toLocationNameSearchbox,
    adults: Number(query.get("adults") || 1),
    children: getQueryParamValueList("children", query)
  };
};

const getQueryParamValueList = (paramName: string, query: URLSearchParams): string[] => {
  const list = query
    .get(paramName)
    ?.split(PARAM_LIST_SEPARATOR)
    .filter((value) => value !== ""); // `get()` returns an empty string if no results exists, which we need to remove.

  return list || [];
};

const getFromLocationFromSearchState = (searchBoxState: State, key: string) => {
  switch (searchBoxState.type) {
    case "MULTISTOP":
      const fromLocationName = searchBoxState.searchSegments
        .map((segment) => {
          // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
          return segment.from[0][key];
        })
        .join(MULTISTOP_IATA_LIST_SEPARATOR);
      return [fromLocationName];
    default:
      return searchBoxState.searchSegments.map((segment) => {
        return segment.from
          .map((fromLocation) => {
            // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
            return fromLocation[key];
          })
          .join(PARAM_LIST_SEPARATOR);
      });
  }
};

const getToLocationFromSearchState = (searchBoxState: State, key: string) => {
  switch (searchBoxState.type) {
    case "MULTISTOP":
      const toLocationName = searchBoxState.searchSegments
        .map((segment) => {
          // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
          return segment.to[0][key];
        })
        .join(MULTISTOP_IATA_LIST_SEPARATOR);
      return [toLocationName];
    default:
      return searchBoxState.searchSegments.map((segment) => {
        return segment.to
          .map((toLocationName) => {
            // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
            return toLocationName[key];
          })
          .join(PARAM_LIST_SEPARATOR);
      });
  }
};
