import React, { useContext, forwardRef } from "react";
import { Button } from "@bookingcom/bui-react";
import { SearchBoxContext } from "../SearchBoxContext/index.js";
import { t } from "@bookingcom/lingojs-core";
import useSubmit from "./useSubmit.js";
import styles from "./Submit.module.css";
const SubmitWithRef = forwardRef(function Submit(props, ref) {
    const { className, onClick, ...rest } = props;
    const { i18n, trackV2 } = useSubmit();
    const { doSearch, isHeaderUpdateExp } = useContext(SearchBoxContext);
    return (React.createElement(Button, { ...rest, onClick: (e) => {
            trackV2("click_submit_search");
            doSearch();
            setTimeout(() => onClick?.(e), 0);
        }, text: i18n.trans(t("flights_search_button")), className: `${styles.cta} ${className} ${isHeaderUpdateExp ? styles.font : ""}`, attributes: { "data-ui-name": "button_search_submit" }, ref: ref }));
});
export default SubmitWithRef;
