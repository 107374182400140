// eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
import { __deprecated__createTrackingUtils } from "..";

const etName = "flights_web_flexible_date_search_desktop_v2";

const createCustomGoal = <T extends string>(name: T): `${typeof etName}_${T}` => `${etName}_${name}`;

// eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
const utils = __deprecated__createTrackingUtils({
  name: etName,
  stages: {
    search_results: 1, // All users on SR
    main: 2, // Flex in rendered for direct users
    confirm_best_price: 3,
    book_window_0_1: 4,
    book_window_2_4: 5,
    book_window_5_14: 6,
    book_window_15_28: 7,
    book_window_29_60: 8
  },
  goals: {
    flex_is_in_view: 1,
    click_on_item: 2
  },
  goalsWithValue: (
    [
      "sr_diff_percentage",
      "sr_diff_higher_count",
      "sr_diff_lower_count",
      "mismatch_sr_and_cache",
      "users_scrolls_carousel"
    ] as const
  ).map(createCustomGoal)
});

const { stages } = utils;

type Params = {
  searchResultsHaveBetterPrice: boolean;
  bookingWindow: number | undefined;
};
export const trackDesktopFlexStages = ({ searchResultsHaveBetterPrice, bookingWindow }: Params) => {
  stages.main();

  if (searchResultsHaveBetterPrice) stages.confirm_best_price();

  if (bookingWindow) {
    if (bookingWindow >= 0 && bookingWindow <= 1) {
      stages.book_window_0_1();
    }

    if (bookingWindow >= 2 && bookingWindow <= 4) {
      stages.book_window_2_4();
    }

    if (bookingWindow >= 5 && bookingWindow <= 14) {
      stages.book_window_5_14();
    }

    if (bookingWindow >= 15 && bookingWindow <= 28) {
      stages.book_window_15_28();
    }

    if (bookingWindow >= 29 && bookingWindow <= 60) {
      stages.book_window_29_60();
    }
  }
};

export default utils;
