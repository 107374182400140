/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React from "react";
import useSearchFiltersController from "../useSearchFiltersController";
import { InputRadio, Stack, Text } from "@bookingcom/bui-react";
import { SBSortingOrder } from "@bookingcom/flights-searchbox";
import useUserAgent from "hooks/useUserAgent";

type StopsControllerProps = NonNullable<ReturnType<typeof useSearchFiltersController>["sort"]>;

type StopsProps = {
  value: StopsControllerProps["value"];
  options: StopsControllerProps["options"];
  onChange: StopsControllerProps["set"];
};

export default function Sort(props: StopsProps) {
  const { options, value, onChange } = props;
  const { isMobile } = useUserAgent();
  return (
    <Stack gap={isMobile ? 4 : 2}>
      {options.map((option, i) => (
        <InputRadio
          key={option.value + i}
          value={String(option.value)}
          name="filters-stops-option"
          onChange={({ value }) => {
            value && onChange(value as SBSortingOrder);
          }}
          checked={option.value === value}
          attributes={{ "data-testid": `search_filter_stops_radio_${option.label}` }}
          label={
            <>
              <Text variant="body_2">{option.label}</Text>
              {!!option.description ? (
                <Text tagName="span" color="neutral_alt" variant="body_2">
                  {option.description}
                </Text>
              ) : null}
            </>
          }
        />
      ))}
    </Stack>
  );
}
