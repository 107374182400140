import React, { useContext } from "react";
import { OccupancyCabinClassModal } from "../OccupancyCabinClass/index.js";
import { SearchBoxContext, SearchboxController } from "../SearchBoxContext/index.js";
import { SearchTypeRadio } from "../SearchType/index.js";
import { SegmentVertical, AddSegment } from "../Segment/index.js";
import { Submit } from "../Submit/index.js";
import { DirectFlightSwitch } from "../DirectFlight/index.js";
import YellowBox from "./YellowBox.js";
import { ShellWrapper } from "../Shell/index.js";
import styles from "./SearchBoxVerticalDefault.module.css";
const SearchBoxVertical = () => {
    const { searchType, isHeaderFramed, hideShadow } = useContext(SearchBoxContext);
    const isMultiStop = searchType === "MULTISTOP";
    return (React.createElement("div", { className: styles.wrapper },
        !isHeaderFramed && (React.createElement("div", { className: styles.line },
            React.createElement(SearchTypeRadio, null))),
        React.createElement("div", { className: styles.line },
            React.createElement(YellowBox, { hideShadow: hideShadow },
                React.createElement("div", { className: styles.yellowInner },
                    isHeaderFramed && (React.createElement(ShellWrapper, null,
                        React.createElement(SearchTypeRadio, null))),
                    React.createElement(SegmentVertical, null),
                    isMultiStop ? (React.createElement(ShellWrapper, { fill: true },
                        React.createElement(AddSegment, { wide: true, variant: "tertiary" }))) : null,
                    React.createElement(OccupancyCabinClassModal, null),
                    !isMultiStop ? (React.createElement(ShellWrapper, { size: "large" },
                        React.createElement(DirectFlightSwitch, null))) : null,
                    React.createElement(Submit, { size: "large", wide: true })))),
        !isMultiStop ? React.createElement("div", { className: styles.line }) : null));
};
export default function SearchBoxVerticalDefault() {
    return (React.createElement(SearchboxController, null,
        React.createElement(SearchBoxVertical, null)));
}
