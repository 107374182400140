import React from "react";
import styles from "./SearchLoadingIllustration.module.css";

export default function SearchLoadingIllustration() {
  return (
    <div className={styles.root}>
      <svg xmlns="http://www.w3.org/2000/svg" className={styles.person} viewBox="0 0 207 246">
        <g fill="none" fillRule="evenodd">
          <path
            d="M119.627 11.934S109.173-1.153 92.01 4.437c0 0-22.755-13.915-36.54 8.132 0 0-24.428-5.73-29.534 23.212 0 0-20.04 3.735-15.548 27.579 0 0-16.539 10.103-6.786 30.093 0 0-11.754 17.443 7.197 32.115 0 0-6.353 19.916 11.532 30.872 0 0 3.734 17.695 23.464 14.049 0 0 2.711 14.62 18.153 14.23 0 0 14.884 19.188 34.783 6.781l29.284-7.725s16.547-.724 22.635-19.224c0 0 13.975-7.358 15.287-23.818 0 0 13.087-10.454 5.638-27.083 0 0 8.748-13.901.862-25.044 0 0 3.612-17.79-10.554-25.121 0 0 4.05-23.277-13.654-28.923 0 0-3.3-27.872-28.603-22.628"
            fill="#E15C5C"
          />
          <path
            d="M172.744 81.067c-1.266 5.249-3.71 9.053-3.71 9.053 7.733 17.125-6.084 27.995-6.084 27.995-1.476 17.003-16.208 24.682-16.208 24.682-6.508 19.136-23.906 19.98-23.906 19.98l-30.826 8.147c-20.989 12.926-36.523-6.799-36.523-6.799-16.234.493-19-14.586-19-14.586-20.76 3.88-24.58-14.368-24.58-14.368a31.402 31.402 0 0 1-2.2-1.456c-.02 6.767 2.12 16.29 12.625 22.726 0 0 3.734 17.694 23.464 14.047 0 0 2.712 14.62 18.153 14.231 0 0 14.884 19.188 34.783 6.782l29.283-7.726s16.548-.724 22.636-19.224c0 0 13.975-7.357 15.287-23.818 0 0 13.087-10.454 5.638-27.083 0 0 8.748-13.902.862-25.044 0 0 .655-3.25.306-7.54"
            fill="#D14E4E"
          />
          <g fill="#E2BB9F">
            <path d="M164.856 62.605s9.163-6.2 11.614-16.005c2.452-9.804-3.58-15.959-3.58-15.959l-1.675-.09s3.024 6.324 1.796 13.715l-1.076-.206s-.299-8.131-2.237-13.49l-2.395.12s2.194 7.036 1.627 13.02l-1.115-.406s-.985-8.999-1.895-12.623l-2.29.306s1.29 7.006 1.102 11.74l-1.143-.193-.838-11.445-2.267.784.435 12.216s-5.715 8.295-1.088 15.586l5.025 2.93z" />
            <path d="M121.347 157.203l49.886-17.652-5.276-79.337-8.922 2.015.384 66.578-37.51 11.992z" />
          </g>
          <path
            d="M106.957 142.238l16.98-3.166 4.317 23.024s-17.843 7.483-17.556 6.045c.288-1.44-3.741-25.903-3.741-25.903"
            fill="#0068B3"
          />
          <path d="M88.632 24.057s26.715-8.237 45.463 21.825l-.877 9.225s-12.844-19.972-44.586-31.05" fill="#F4D8BD" />
          <path d="M58.123 135.202h67.971V242.32h-67.97z" />
          <path
            fill="#2E83C0"
            d="M78.447 135.202l-14.255 11.692L58.123 246h67.971l-8.682-54.798-9.929-49.972-7.283-5.26z"
          />
          <path
            d="M79.422 133.641s-3.837 1.343-4.22 3.837c0 0 8.826 10.17 21.296 9.786 12.472-.384 10.985-6.035 10.985-6.035l-4.269-3.942-23.792-3.646z"
            fill="#0068B3"
          />
          <path
            d="M86.797 91.114s1.67 26.386-8.35 44.088c0 0 14.028 14.696 26.385 6.011 0 0-7.014-24.048-3.339-46.091l-14.696-4.008z"
            fill="#F4D8BD"
          />
          <path
            d="M102.077 128.311c-1.45-8.958-2.556-21.359-.584-33.19L89.98 91.983c-1.035 1.383-2.071 3.16-3.096 5.397-.026 1.586-.09 3.414-.215 5.422l15.408 25.51z"
            fill="#E2BB9F"
          />
          <path
            d="M76.405 89.425l10.62 18.551 59.235-5.636s2.717-24.184-22.002-62.886C99.538.752 51.246 35.836 54.304 61.795c3.058 25.96 22.101 27.63 22.101 27.63"
            fill="#F4D8BD"
          />
          <path fill="#F4D8BD" d="M130.69 52.706l16.126 15.4-4.55 9.531-11.226-.116z" />
          <path fill="#E2BB9F" d="M146.816 68.105l-12.754 7.3-2.365-4.803-.658 6.919 11.227.116z" />
          <path
            d="M81.995 84.622c-2.795-4.883-9.02-6.576-13.902-3.78-4.883 2.794-6.575 9.019-3.78 13.9 2.112 3.691 6.182 5.555 10.153 5.04 1.25 1.773 3.65 2.353 5.57 1.252a4.249 4.249 0 0 0 1.609-5.739c2.05-3.106 2.325-7.222.35-10.673"
            fill="#F4D8BD"
          />
          <path
            d="M73.38 87.051c-.249-.435-4.683-2.129-6.132-1.3-1.45.83-3.291 4 .048 8.824 3.338 4.824 7.665 1.962 7.665 1.962l2.838-.084s-5.429-2.089-6.155-4.367c-.727-2.278 1.736-5.035 1.736-5.035"
            fill="#E2BB9F"
          />
          <path
            d="M69.75 96.832c2.682 1.366 5.211-.295 5.211-.295l2.838-.085s-2.392-.923-4.213-2.21c-.635 1.165-2.086 2.15-3.836 2.59M67.248 85.751c-.656.375-1.387 1.234-1.688 2.505 1.486.169 4.099.622 6.122 1.807.46-1.62 1.697-3.012 1.697-3.012-.248-.434-4.682-2.13-6.131-1.3"
            fill="#CEA284"
          />
          <path
            fill="#E2BB9F"
            d="M133.257 47.774l-1.574 3.978 1.535 3.355.716-7.537zM124.378 52.214l5.156 4.794s-1.54 10.829-15.15 11.767l3.494-3.548s-11.466 5.458-23.372-2.981l8.633-8.48 21.239-1.552z"
          />
          <path
            fill="#D14E4E"
            d="M93.65 62.294l10.322-7.457 20.406-2.623.388-5.08-23.129 4.397zM130.215 46.226l1.467 5.527 3.091-3.566-.678-2.305z"
          />
          <path d="M107.798 62.485s6.613-3.896 12.112-.853l-1.894-3.116s-6.664-1.602-10.218 3.97" fill="#202020" />
          <path
            d="M64.886 83.96s3.06-5.858 10.577-1.693l5.257-23.534-8.19-19.686s14.418-5.898 42.167-7.46c.994-.057 4.03-2.821 4.03-2.821s-18.933-19.18-50.732 2.358c-31.8 21.539-9.74 48.422-3.109 52.836"
            fill="#E15C5C"
          />
          <path
            d="M133.08 42.155s-11.493 1.936-17.927-9.304l-5.246 3.002s4.86 6.173-1.062 10.89c0 0 .188 12.493-8.093 14.248-8.28 1.756-8.396-9.453-3.365-11.67 0 0-7.994 2.255-9.355-3.598 0 0 1.366 12.814-8.306 15.034C70.054 62.978 64.08 48.49 64.08 48.49l3.881-13.496L96.655 24.87l20.382-2.05s13.925 4.63 16.043 19.336"
            fill="#E15C5C"
          />
          <path
            d="M119.62 78.92l4.167 25.558 22.473-2.138s1.832-16.453-11.376-43.916l-26.488 6.97s1.727 11.8 11.224 13.527"
            fill="#E2BB9F"
          />
          <path
            d="M141.597 85.033s-10.922 2.935-24.68-.243l1.95-3.548-5.185 4.073 3.669 5.638-.681-3.7s11.462 3.609 24.927-2.22"
            fill="#CEA284"
          />
          <path
            d="M172.157 50.753c1.78 6.827-2.31 13.805-9.136 15.586-6.827 1.782-13.805-2.309-15.586-9.136-1.782-6.826 2.309-13.805 9.135-15.586 6.827-1.78 13.805 2.309 15.587 9.136"
            fill="#F2C55C"
          />
          <path fill="#F2C55C" d="M183.198 28.574L155.78 41.823l6.45 24.723 30.397-1.838z" />
          <path
            d="M205.98 41.927c2.603 9.978-3.375 20.178-13.353 22.78-9.978 2.605-20.178-3.373-22.782-13.351-2.604-9.978 3.375-20.177 13.353-22.782 9.978-2.603 20.177 3.375 22.781 13.353"
            fill="#F9E6B9"
          />
          <path
            d="M202.668 42.79c2.126 8.15-2.756 16.48-10.905 18.607-8.15 2.126-16.48-2.756-18.607-10.905-2.126-8.15 2.756-16.48 10.906-18.607 8.149-2.126 16.48 2.756 18.606 10.906"
            fill="#513583"
          />
          <path
            d="M180.99 33.058c-6.25 3.179-9.677 10.37-7.834 17.434 1.843 7.063 8.348 11.663 15.354 11.382 6.25-3.178 9.678-10.37 7.834-17.433-1.842-7.063-8.347-11.663-15.353-11.383"
            fill="#7E62B0"
          />
          <path d="M191.651 39.877a7.193 7.193 0 1 1-13.92 3.632 7.193 7.193 0 0 1 13.92-3.632" fill="#FFF" />
          <path
            d="M135.646 60.28c1.781 6.827-2.308 13.805-9.135 15.586-6.827 1.782-13.806-2.309-15.587-9.136-1.781-6.826 2.31-13.805 9.136-15.586 6.826-1.78 13.805 2.309 15.586 9.136"
            fill="#F2C55C"
          />
          <path fill="#F2C55C" d="M146.687 38.101L119.27 51.35l6.452 24.723 30.396-1.838z" />
          <path
            d="M169.469 51.454c2.604 9.978-3.374 20.178-13.353 22.78-9.977 2.605-20.177-3.373-22.78-13.351-2.605-9.978 3.373-20.177 13.352-22.782 9.977-2.603 20.177 3.375 22.78 13.353"
            fill="#F9E6B9"
          />
          <path
            d="M166.158 52.318c2.126 8.15-2.756 16.48-10.905 18.606-8.15 2.126-16.48-2.755-18.607-10.905-2.127-8.15 2.756-16.48 10.906-18.607 8.149-2.126 16.48 2.756 18.606 10.906"
            fill="#513583"
          />
          <path
            d="M144.48 42.585c-6.25 3.179-9.677 10.37-7.834 17.434 1.843 7.063 8.348 11.663 15.354 11.382 6.25-3.178 9.678-10.37 7.834-17.433-1.842-7.063-8.347-11.663-15.353-11.383"
            fill="#7E62B0"
          />
          <path d="M155.14 49.404a7.193 7.193 0 1 1-13.919 3.632 7.193 7.193 0 0 1 13.92-3.632" fill="#FFF" />
          <g fill="#F4D8BD">
            <path d="M96.308 169.003l58.712-17.555-24.463-76.269-7.195 3.454 14.966 61.59-43.459 12.376z" />
            <path d="M124.833 79.767s-9.162-6.2-11.614-16.004c-2.451-9.805 3.58-15.96 3.58-15.96l1.675-.089s-3.023 6.324-1.796 13.715l1.076-.207s.3-8.13 2.238-13.49l2.395.12s-2.195 7.037-1.628 13.02l1.115-.406s.986-8.998 1.896-12.622l2.29.305s-1.29 7.006-1.102 11.741l1.143-.193.838-11.446 2.266.785-.435 12.216s5.716 8.295 1.088 15.585l-5.025 2.93z" />
          </g>
          <path
            d="M13.972 63.906S8.851 45.81 28.996 38.298c0 0 6.487-26.291 28.34-21.852 0 0 12.633-20.487 33.803-8.195 0 0 14.682-5.805 26.632 7.17 0 0 22.536-4.78 26.633 19.804 0 0-9.902-17.755-29.023-13.658 0 0-8.536-12.292-25.95-7.17 0 0-13.999-11.268-29.705 6.829 0 0-18.438-1.366-25.609 19.804 0 0-18.096 7.17-20.145 22.876"
            fill="#ED7A7A"
          />
        </g>
      </svg>
      <svg viewBox="0 0 167 80" xmlns="http://www.w3.org/2000/svg" className={styles.plane}>
        <g fill="none" fillRule="evenodd">
          <path
            d="M149.122 32.919c-4.91-9.412-28.664-16.55-57.23-16.55H4.61v6.496S16.777 71.468 99.118 72.13c.566.005 1.135.008 1.704.008 36.188 0 65.525-10.479 65.525-23.406 0-6.097-6.53-11.65-17.225-15.814"
            fill="#FFF"
          />
          <path
            d="M99.118 72.13c.566.006 1.134.009 1.704.009 36.189 0 65.525-10.48 65.525-23.405 0-1.427-.362-2.824-1.048-4.182H18.207c12.73 13.052 36.709 27.223 80.911 27.579"
            fill="#EBEBEB"
          />
          <path
            d="M101.845 61.61c-.638 0-1.274-.002-1.908-.008-48.105-.345-74.817-13.743-89.366-26.478C20.12 49.569 43.76 71.685 99.118 72.13c.566.005 1.135.008 1.704.008 35.581 0 64.537-10.13 65.5-22.757-12.436 7.285-36.646 12.228-64.477 12.228"
            fill="#D8D8D8"
          />
          <path
            d="M22.488 48.584h143.855c-.026-1.375-.382-2.722-1.044-4.031H18.206a70.643 70.643 0 0 0 4.282 4.031M15.953 1.991C15.561.854 14.7.2 13.713.2h-7.31C5.416.2 4.61 1.006 4.61 1.99V16.37h17.805L15.953 1.991z"
            fill="#0068B3"
          />
          <path
            d="M149.122 32.919c-.986-1.89-2.732-3.687-5.123-5.353h-12.222v5.375h17.402a1.563 1.563 0 0 1-.057-.022M115.74 30.254a2.688 2.688 0 1 1-5.377 0 2.688 2.688 0 0 1 5.376 0M107.206 30.254a2.688 2.688 0 1 1-5.376 0 2.688 2.688 0 0 1 5.376 0M98.673 30.254a2.688 2.688 0 1 1-5.377 0 2.688 2.688 0 0 1 5.377 0M90.14 30.254a2.688 2.688 0 1 1-5.377 0 2.688 2.688 0 0 1 5.377 0M81.606 30.254a2.688 2.688 0 1 1-5.377 0 2.688 2.688 0 0 1 5.377 0M73.073 30.254a2.688 2.688 0 1 1-5.377 0 2.688 2.688 0 0 1 5.377 0M64.54 30.254a2.688 2.688 0 1 1-5.377 0 2.688 2.688 0 0 1 5.377 0"
            fill="#005693"
          />
          <path fill="#F7F7F7" d="M135.564 32.941h.896v-5.375h-.896z" />
          <path
            d="M72.254 50.297v.015a5.365 5.365 0 0 0-.356-.015c-4.827 0-8.74 6.464-8.74 14.438 0 7.975 3.913 14.44 8.74 14.44.12 0 .238-.004.356-.013v.012h22.95l.355-28.877H72.254z"
            fill="#5C9ECE"
          />
          <path
            d="M85.897 50.297v.015a5.416 5.416 0 0 0-.356-.015c-4.827 0-8.74 6.464-8.74 14.438 0 7.975 3.913 14.44 8.74 14.44.12 0 .238-.004.356-.013v.012h9.308l.355-28.877h-9.663z"
            fill="#0068B3"
          />
          <path
            d="M103.944 64.735c0 7.975-3.912 14.439-8.739 14.439-4.827 0-8.74-6.464-8.74-14.439 0-7.974 3.913-14.438 8.74-14.438s8.74 6.464 8.74 14.438"
            fill="#F7F7F7"
          />
          <path
            d="M101.003 64.735c0 5.292-2.596 9.58-5.798 9.58-3.203 0-5.8-4.288-5.8-9.58 0-5.291 2.597-9.58 5.8-9.58 3.202 0 5.798 4.289 5.798 9.58"
            fill="#C1C1C1"
          />
          <path
            d="M93.594 55.538c-2.418 1.153-4.188 4.83-4.188 9.197 0 4.367 1.77 8.044 4.188 9.198 2.418-1.154 4.188-4.831 4.188-9.198 0-4.366-1.77-8.044-4.188-9.197"
            fill="#8C8C8C"
          />
          <path
            d="M89.406 64.735c0 3.402 1.077 6.378 2.695 8.076 1.618-1.698 2.695-4.674 2.695-8.076s-1.077-6.378-2.695-8.076c-1.618 1.698-2.695 4.674-2.695 8.076"
            fill="#777"
          />
          <path
            d="M96.593 64.735c0 1.267-.621 2.295-1.388 2.295-.767 0-1.39-1.028-1.39-2.295s.623-2.294 1.39-2.294c.767 0 1.388 1.027 1.388 2.294"
            fill="#D7D7D7"
          />
          <path fill="#FFF" d="M119.047 46.345L67.62 61.147l-33.32-.26L55.28 46.345z" />
          <path fill="#DDD" d="M119.047 46.345v2.235l-51.503 12.6-1.773-.392z" />
          <path
            d="M67.755 60.947c0 .877-7.496 1.589-16.742 1.589-9.245 0-16.74-.712-16.74-1.59 0-.878 7.495-1.59 16.74-1.59 9.246 0 16.742.712 16.742 1.59"
            fill="#C1C1C1"
          />
          <path fill="#0068B3" d="M21.83 20.322L8.612 24.765.63 24.702l5.923-4.38z" />
          <path fill="#005693" d="M21.83 20.322v.536L8.594 24.773l-.452-.166z" />
          <path
            d="M8.644 24.716c0 .211-1.796.381-4.01.381-2.216 0-4.01-.17-4.01-.38s1.794-.381 4.01-.381c2.214 0 4.01.17 4.01.38"
            fill="#005693"
          />
        </g>
      </svg>
      <svg viewBox="0 0 146 99" xmlns="http://www.w3.org/2000/svg" className={styles.cloud3}>
        <g fill="none" fillRule="evenodd">
          <path
            d="M84.92 26.777c0 14.77-11.972 26.742-26.741 26.742-14.77 0-26.742-11.972-26.742-26.742 0-14.769 11.973-26.74 26.742-26.74S84.92 12.007 84.92 26.776"
            fill="#FFF"
          />
          <path
            d="M53.927 50.657c0 14.77-11.973 26.742-26.742 26.742-14.77 0-26.742-11.972-26.742-26.742 0-14.769 11.973-26.74 26.742-26.74s26.742 11.971 26.742 26.74"
            fill="#FFF"
          />
          <path
            d="M90.817 70.61c0 14.502-13.714 26.256-30.631 26.256S29.554 85.112 29.554 70.611c0-14.5 13.715-26.255 30.632-26.255S90.817 56.11 90.817 70.611M144.764 58.707c0 12.621-10.231 22.852-22.852 22.852-12.62 0-22.852-10.23-22.852-22.852 0-12.621 10.231-22.852 22.852-22.852 12.62 0 22.852 10.231 22.852 22.852"
            fill="#FFF"
          />
          <path
            d="M118.342 37.391c0 12.622-10.45 22.853-23.339 22.853S71.665 50.013 71.665 37.39c0-12.62 10.45-22.85 23.338-22.85 12.89 0 23.339 10.23 23.339 22.85"
            fill="#FFF"
          />
          <path
            d="M118.752 66.418c0 12.621-10.45 22.852-23.338 22.852-12.89 0-23.339-10.23-23.339-22.852 0-12.621 10.45-22.852 23.339-22.852s23.338 10.231 23.338 22.852"
            fill="#FFF"
          />
          <path
            d="M142.82 47.562c.152 1.077.258 2.169.258 3.287 0 12.918-10.503 23.39-23.459 23.39-2.62 0-5.132-.447-7.485-1.237-4.337 5.85-11.285 9.66-19.141 9.66a23.72 23.72 0 0 1-10.209-2.316C76.971 86.364 68.182 90.2 58.346 90.2c-14.536 0-26.777-8.378-30.512-19.798-.888.086-1.788.136-2.699.136-10.474 0-19.584-5.82-24.257-14.392 2.248 12.94 13.535 22.788 27.155 22.788.91 0 1.81-.05 2.7-.135 3.734 11.418 15.976 19.797 30.51 19.797 9.838 0 18.627-3.836 24.44-9.854A23.739 23.739 0 0 0 95.89 91.06c7.857 0 14.804-3.81 19.142-9.66a23.453 23.453 0 0 0 7.485 1.237c12.956 0 23.458-10.472 23.458-23.39 0-4.26-1.16-8.243-3.156-11.684"
            fill="#D7D7D7"
          />
        </g>
      </svg>
      <svg viewBox="0 0 146 99" xmlns="http://www.w3.org/2000/svg" className={styles.cloud2}>
        <g fill="none" fillRule="evenodd">
          <path
            d="M84.92 26.777c0 14.77-11.972 26.742-26.741 26.742-14.77 0-26.742-11.972-26.742-26.742 0-14.769 11.973-26.74 26.742-26.74S84.92 12.007 84.92 26.776"
            fill="#FFF"
          />
          <path
            d="M53.927 50.657c0 14.77-11.973 26.742-26.742 26.742-14.77 0-26.742-11.972-26.742-26.742 0-14.769 11.973-26.74 26.742-26.74s26.742 11.971 26.742 26.74"
            fill="#FFF"
          />
          <path
            d="M90.817 70.61c0 14.502-13.714 26.256-30.631 26.256S29.554 85.112 29.554 70.611c0-14.5 13.715-26.255 30.632-26.255S90.817 56.11 90.817 70.611M144.764 58.707c0 12.621-10.231 22.852-22.852 22.852-12.62 0-22.852-10.23-22.852-22.852 0-12.621 10.231-22.852 22.852-22.852 12.62 0 22.852 10.231 22.852 22.852"
            fill="#FFF"
          />
          <path
            d="M118.342 37.391c0 12.622-10.45 22.853-23.339 22.853S71.665 50.013 71.665 37.39c0-12.62 10.45-22.85 23.338-22.85 12.89 0 23.339 10.23 23.339 22.85"
            fill="#FFF"
          />
          <path
            d="M118.752 66.418c0 12.621-10.45 22.852-23.338 22.852-12.89 0-23.339-10.23-23.339-22.852 0-12.621 10.45-22.852 23.339-22.852s23.338 10.231 23.338 22.852"
            fill="#FFF"
          />
          <path
            d="M142.82 47.562c.152 1.077.258 2.169.258 3.287 0 12.918-10.503 23.39-23.459 23.39-2.62 0-5.132-.447-7.485-1.237-4.337 5.85-11.285 9.66-19.141 9.66a23.72 23.72 0 0 1-10.209-2.316C76.971 86.364 68.182 90.2 58.346 90.2c-14.536 0-26.777-8.378-30.512-19.798-.888.086-1.788.136-2.699.136-10.474 0-19.584-5.82-24.257-14.392 2.248 12.94 13.535 22.788 27.155 22.788.91 0 1.81-.05 2.7-.135 3.734 11.418 15.976 19.797 30.51 19.797 9.838 0 18.627-3.836 24.44-9.854A23.739 23.739 0 0 0 95.89 91.06c7.857 0 14.804-3.81 19.142-9.66a23.453 23.453 0 0 0 7.485 1.237c12.956 0 23.458-10.472 23.458-23.39 0-4.26-1.16-8.243-3.156-11.684"
            fill="#D7D7D7"
          />
        </g>
      </svg>
      <svg viewBox="0 0 146 99" xmlns="http://www.w3.org/2000/svg" className={styles.cloud1}>
        <g fill="none" fillRule="evenodd">
          <path
            d="M84.92 26.777c0 14.77-11.972 26.742-26.741 26.742-14.77 0-26.742-11.972-26.742-26.742 0-14.769 11.973-26.74 26.742-26.74S84.92 12.007 84.92 26.776"
            fill="#FFF"
          />
          <path
            d="M53.927 50.657c0 14.77-11.973 26.742-26.742 26.742-14.77 0-26.742-11.972-26.742-26.742 0-14.769 11.973-26.74 26.742-26.74s26.742 11.971 26.742 26.74"
            fill="#FFF"
          />
          <path
            d="M90.817 70.61c0 14.502-13.714 26.256-30.631 26.256S29.554 85.112 29.554 70.611c0-14.5 13.715-26.255 30.632-26.255S90.817 56.11 90.817 70.611M144.764 58.707c0 12.621-10.231 22.852-22.852 22.852-12.62 0-22.852-10.23-22.852-22.852 0-12.621 10.231-22.852 22.852-22.852 12.62 0 22.852 10.231 22.852 22.852"
            fill="#FFF"
          />
          <path
            d="M118.342 37.391c0 12.622-10.45 22.853-23.339 22.853S71.665 50.013 71.665 37.39c0-12.62 10.45-22.85 23.338-22.85 12.89 0 23.339 10.23 23.339 22.85"
            fill="#FFF"
          />
          <path
            d="M118.752 66.418c0 12.621-10.45 22.852-23.338 22.852-12.89 0-23.339-10.23-23.339-22.852 0-12.621 10.45-22.852 23.339-22.852s23.338 10.231 23.338 22.852"
            fill="#FFF"
          />
          <path
            d="M142.82 47.562c.152 1.077.258 2.169.258 3.287 0 12.918-10.503 23.39-23.459 23.39-2.62 0-5.132-.447-7.485-1.237-4.337 5.85-11.285 9.66-19.141 9.66a23.72 23.72 0 0 1-10.209-2.316C76.971 86.364 68.182 90.2 58.346 90.2c-14.536 0-26.777-8.378-30.512-19.798-.888.086-1.788.136-2.699.136-10.474 0-19.584-5.82-24.257-14.392 2.248 12.94 13.535 22.788 27.155 22.788.91 0 1.81-.05 2.7-.135 3.734 11.418 15.976 19.797 30.51 19.797 9.838 0 18.627-3.836 24.44-9.854A23.739 23.739 0 0 0 95.89 91.06c7.857 0 14.804-3.81 19.142-9.66a23.453 23.453 0 0 0 7.485 1.237c12.956 0 23.458-10.472 23.458-23.39 0-4.26-1.16-8.243-3.156-11.684"
            fill="#D7D7D7"
          />
        </g>
      </svg>
    </div>
  );
}
