/* istanbul ignore file */
/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { FC, useEffect, useState } from "react";
import { SkeletonLoader, Stack, Text } from "@bookingcom/bui-react";
import { t } from "@bookingcom/lingojs-core";

import { useI18n } from "@bookingcom/lingojs-react";
// eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
import { useStore } from "../../../store";

import NearbyCard from "./NearbyCard";
import { useNearbyListCall } from "cross-sell/hooks/useNearbyListCall";
import { MULTISTOP_IATA_LIST_SEPARATOR } from "@flights/constants";
import { searchUtils } from "@flights/web-api-utils-universal";
import { addDays, format } from "date-fns";
import styles from "./NearbyList.module.css";

const NearbyList: FC = () => {
  // eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
  const store = useStore();
  const i18n = useI18n();
  const { destinations, destinationsLoading } = useNearbyListCall("webList");

  interface UIdates {
    startDate: string;
    endDate: string;
  }

  const { order } = store.order;
  const [dates, setDates] = useState<UIdates>();

  useEffect(() => {
    const searchParams = searchUtils.getSearchParams(order);
    const params = searchParams?.params;
    if ((params?.type === "ONEWAY" || params?.type === "ROUNDTRIP") && params?.depart && params?.return) {
      setDates({
        startDate: format(new Date(params?.depart), "dd-LLL"),
        endDate: format(
          new Date(params.type === "ONEWAY" ? addDays(new Date(params?.return), 1) : params?.return),
          "dd-LLL"
        )
      });
    } else if (params?.type === "MULTISTOP") {
      const dates = params?.multiStopDates?.split(MULTISTOP_IATA_LIST_SEPARATOR);
      if (dates?.length) {
        setDates({ startDate: format(new Date(dates[0]), "dd-LLL"), endDate: format(new Date(dates[1]), "dd-LLL") });
      }
    }
  }, [setDates, order]);

  if (destinationsLoading) {
    return (
      <>
        <SkeletonLoader className={styles.skeleton} variant="box" aspectRatio="5:1" />
        <SkeletonLoader className={styles.skeleton} variant="box" aspectRatio="5:1" />
        <SkeletonLoader className={styles.skeleton} variant="box" aspectRatio="5:1" />
      </>
    );
  }

  return destinations?.length && dates ? (
    <>
      <Stack direction={"column"}>
        <Text variant="headline_3">{i18n.trans(t("flights_xsell_nearby_stays_headline"))}</Text>
        <Text variant="body_2" color="neutral_alt" className={styles.subheader}>
          {i18n.trans(
            t("flights_xsell_nearby_stays_subheader", {
              variables: {
                check_in: dates.startDate,
                check_out: dates.endDate
              }
            })
          )}
        </Text>
        {destinations?.map((destination, index) => {
          return <NearbyCard key={index} destination={destination} />;
        })}
      </Stack>
    </>
  ) : null;
};

export default NearbyList;
