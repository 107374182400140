import React from "react";
import { UISearchResultsBanner } from "@flights/types";
import { Banner, Icon, SheetContainer, Text } from "@bookingcom/bui-react";
import { InfoSignIcon } from "@bookingcom/bui-assets-react/streamline/index.js";
import useToggle from "hooks/useToggle";
import useUserAgent from "hooks/useUserAgent";
import Frame from "../Frame";
import { t, useI18n } from "@bookingcom/lingojs-react";

const SearchResultBanner = ({ banner }: { banner?: UISearchResultsBanner }) => {
  const { visible: showPopover, show: showModal, hide: hideModal } = useToggle();
  const { isMobile } = useUserAgent();
  const i18n = useI18n();

  if (!banner) {
    return null;
  }
  const { dismissible, type, title, media, message, actionLabel, details } = banner;

  const mediaType = { infoSign: InfoSignIcon };

  const bannerType = {
    warning: "hint",
    neutral: "neutral",
    info: "callout"
  } as const;

  return (
    <>
      <Frame pb={4}>
        <Banner
          dismissible={dismissible}
          title={title ? <Text variant="strong_1">{title}</Text> : undefined}
          text={message ? <Text variant="body_2">{message}</Text> : undefined}
          startIcon={media ? <Icon size="medium" svg={mediaType[media]} /> : undefined}
          closeAriaLabel={i18n.trans(t("close"))}
          variant={bannerType[type]}
          actions={
            actionLabel
              ? [
                  {
                    variant: "primary",
                    text: actionLabel,
                    onClick: showModal
                  }
                ]
              : undefined
          }
        />
      </Frame>
      {details && (
        <SheetContainer
          active={showPopover}
          position={isMobile ? "bottom" : "center"}
          zIndex={300}
          title={details.title}
          onCloseTrigger={() => {
            hideModal();
          }}
          closeAriaLabel={i18n.trans(t("close"))}
        >
          {details.message}
        </SheetContainer>
      )}
    </>
  );
};

export default SearchResultBanner;
