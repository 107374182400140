import { useCallback, useMemo } from "react";
import C360ReactTracker from "@bookingcom/c360-tracker-react";

import useUserAgent from "./useUserAgent";
import useGlobalContext from "./useGlobalContext";
import { DEFAULT_MARKETING_TRACKING_VARIABLES } from "../constants";
import { flightsSessionStore } from "utils/session-store";
import useEvents from "./useEvents";
import { UIXc360Data } from "cross-sell/types";

const useC360Tracker = () => {
  const { isMobile } = useUserAgent();
  const { currencyCode, env, locale = "en-gb", sessionId } = useGlobalContext();
  const { jsError, grumble } = useEvents();

  const initC360 = useCallback(() => {
    C360ReactTracker.init({
      aid: flightsSessionStore.get("aid") || DEFAULT_MARKETING_TRACKING_VARIABLES.aid,
      currency: currencyCode,
      isDev: env !== "prod",
      label: flightsSessionStore.get("label") || DEFAULT_MARKETING_TRACKING_VARIABLES.label,
      lang: locale,
      onErrorCallback: (error) => jsError(error),
      platform: isMobile ? "mdot" : "ddot",
      sessionId
    });
  }, [currencyCode, env, isMobile, jsError, locale, sessionId]);

  const c360SendEvent = useCallback(
    async (data: UIXc360Data) => {
      if (!C360ReactTracker.c360Config) {
        grumble("c360SendEvent: C360 ReactTracker is not initialised. Event was not send.", { ...data });
        return;
      }

      await C360ReactTracker.sendEvent(data);
    },
    [grumble]
  );

  return useMemo(() => ({ initC360, c360SendEvent }), [initC360, c360SendEvent]);
};

export default useC360Tracker;
