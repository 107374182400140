/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React from "react";
import FlightTimes from "../Input/FlightTimes";
import useSearchFiltersController from "../useSearchFiltersController";
import { Link, Stack, Text } from "@bookingcom/bui-react";
import WithSuffix from "../WithSuffix";
import { useI18n, t } from "@bookingcom/lingojs-react";

export default function FlightTimesFilter() {
  const i18n = useI18n();
  const { flightTimes } = useSearchFiltersController();

  if (flightTimes.options.length === 0) return null;

  return (
    <Stack attributes={{ "data-testid": "flight_times_filter" }}>
      <WithSuffix
        suffix={
          flightTimes.touched ? (
            <Link attributes={{ "data-testid": "search_filter_flight_times_reset" }} onClick={flightTimes.reset}>
              {i18n.trans(t("flights_filter_reset"))}
            </Link>
          ) : null
        }
      >
        <Text variant="emphasized_1">{i18n.trans(t("flights_filters_times_title"))}</Text>
      </WithSuffix>
      <FlightTimes
        options={flightTimes.options}
        value={flightTimes.value}
        onChange={flightTimes.set}
        getTitle={flightTimes.getTitle}
        getLabel={flightTimes.getLabel}
      />
    </Stack>
  );
}
