import { ActionsUnion, createAction } from "@bookingcom/flights-core";
import { UIClientFetchError, UIRebookEligibilityInfo } from "@flights/types";

export enum ActionTypes {
  fetch = "rebookPolicy/fetch",
  fetchSuccess = "rebookPolicy/fetchSuccess",
  fetchError = "rebookPolicy/fetchError",
  reset = "rebookPolicy/reset"
}

export const actions = {
  fetch: createAction(() => ({
    type: ActionTypes.fetch,
    payload: {}
  })),
  fetchSuccess: createAction((rebookPolicy: UIRebookEligibilityInfo) => ({
    type: ActionTypes.fetchSuccess,
    payload: { rebookPolicy }
  })),
  fetchError: createAction((error: UIClientFetchError) => ({
    type: ActionTypes.fetchError,
    payload: { error }
  })),
  reset: createAction(() => ({
    type: ActionTypes.reset,
    payload: {}
  }))
};

export type Actions = ActionsUnion<typeof actions>;
