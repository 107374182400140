import { UIPointOfSale, UIPrice } from "@flights/types";
import useGlobalContext from "./useGlobalContext";
import { useCallback } from "react";
import { t } from "@bookingcom/lingojs-core";
import { I18nChildContext, useI18n } from "@bookingcom/lingojs-react";
import useTrackFlightsWebUseOfferPos from "./useTrackFlightsWebUseOfferPosExp";
import usePointOfSale from "./usePointOfSale";
import { pricingUtils, FormatOptions } from "@flights/web-api-utils-universal";
import { isFeatureRunningClientSide } from "utils/features";
import flights_web_price_format_user_locale, {
  isPriceFormatTrackingRouteCondition,
  useTrackPriceFormatUserLocaleStages
} from "utils/experiments/funnel/flights_web_price_format_user_locale";

const useFormatPrice = (locale?: string) => {
  const { ipCountry, locale: contextLocale } = useGlobalContext();
  const i18n = useI18n() as I18nChildContext;
  const flightsWebUseOfferPosExp = useTrackFlightsWebUseOfferPos();
  const useNativeFormatting = isFeatureRunningClientSide("FLIGHTS_WEB_USE_NATIVE_CURRENCY_FORMATTING");
  let localeCountry = ipCountry;

  const pointOfSale = usePointOfSale();
  const trackFormatPriceLocaleStages = useTrackPriceFormatUserLocaleStages();

  if (flightsWebUseOfferPosExp.isVariant && pointOfSale) {
    localeCountry = pointOfSale.toLowerCase() as UIPointOfSale;
  }

  const formatPrice = useCallback(
    (price: UIPrice, options?: FormatOptions) => {
      const baseLocaleValue = localeCountry || locale;
      const variantLocaleValue = locale || contextLocale || "en-gb";

      let localeValue = baseLocaleValue;

      if (baseLocaleValue !== variantLocaleValue && isPriceFormatTrackingRouteCondition()) {
        if (!!flights_web_price_format_user_locale.trackWithDefaultStage()) {
          localeValue = variantLocaleValue;
        }
        trackFormatPriceLocaleStages();
      }

      return pricingUtils.formatPrice(price, localeValue, { ...options, useNativeFormatting });
    },
    [contextLocale, locale, localeCountry, trackFormatPriceLocaleStages, useNativeFormatting]
  );

  const priceOrFree = (price: UIPrice) => {
    return pricingUtils.priceIsZero(price) ? i18n.trans(t("flights_free_price")) : formatPrice(price);
  };

  const startingPrice = (price: UIPrice) => {
    return pricingUtils.priceIsZero(price)
      ? i18n.trans(t("flights_free_price"))
      : i18n.trans(t("flights_starting_price", { variables: { lowest_price: formatPrice(price) } }));
  };

  const plusPrice = (price: UIPrice) => {
    return pricingUtils.priceIsZero(price)
      ? i18n.trans(t("flights_free_price"))
      : i18n.trans(t("flights_ancillary_price_total", { variables: { total_price: formatPrice(price) } }));
  };

  return {
    priceToValue: pricingUtils.priceToValue,
    priceIsZero: pricingUtils.priceIsZero,
    formatPrice,
    priceOrFree,
    startingPrice,
    plusPrice,
    formatAbbreviatedPrice: pricingUtils.formatAbbreviatedPrice
  };
};

export default useFormatPrice;
