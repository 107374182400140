import { checkForAppVersionMismatch } from "./check-client-version";
import { checkForNewClientsidePayload, updateClientSideContext } from "./check-for-new-clientside-payload";
import { getMarketingTrackingVariable } from "../utils/marketing-url-params";
import type { RequestDevOverride } from "@flights/turbine";
import {
  X_BOOKING_FLIGHTS_DEV_SERVICE_OVERRIDE,
  X_BOOKING_FLIGHTS_CLIENT_HINTS,
  FLIGHTS_CLIENT_HINTS_FEATURES
} from "@flights/constants";
import { FE_DEPENDECIES_SERVICES } from "../constants/internal";
import * as devOverridesService from "utils/internal/devOverrides";

export class ClientFetchError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "ClientFetchError";
  }
}
export class ClientFetchNotAuthorizedError extends ClientFetchError {
  constructor(message: string) {
    super(message);
    this.name = "ClientFetchNotAuthorizedError";
  }
}

const overrideParamsRegexList = [
  /^b_feature_/,
  /my_ip/,
  /i_am_from/,
  /debug/,
  /show_tags/,
  /mock-scenario-enabled/,
  /mock-scenario-name/
];

function appendOverrideParams(url: string = "", isInternal: boolean = false): string {
  if (!isInternal) return url;
  const [urlPath, urlSearch = ""] = url.split("?");
  const urlSearchParam = new URLSearchParams(urlSearch);
  new URLSearchParams(window?.location?.search || "").forEach((value, param) => {
    if (overrideParamsRegexList.some((rx) => rx.test(param))) {
      urlSearchParam.set(param, value);
    }
  });
  return `${urlPath}?${urlSearchParam.toString()}`;
}

function clientFetch<T>(...args: Parameters<typeof fetch>): Promise<T> {
  const {
    debugAllExtraProducts,
    marketingTrackingVariables: trackingVariables,
    emkToken,
    isInternal,
    env,
    soylentEmail,
    tracingHeaders
  } = window.__GLOBAL_CONTEXT__;
  const [url, options = {}] = args;
  let { headers = {} } = options;
  headers = { ...headers, ...tracingHeaders };

  // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
  headers["X-Requested-From"] = "clientFetch";

  const aid = getMarketingTrackingVariable("aid") || trackingVariables?.aid;
  const label = getMarketingTrackingVariable("label") || trackingVariables?.label;
  const etgTestUrl = sessionStorage && sessionStorage.getItem("debugEtgUrl");
  const debugAllTravelerDataRequirements = sessionStorage && sessionStorage.getItem("debugAllTravelerDataRequirements");
  if (aid) {
    // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
    headers["X-Booking-Affiliate-Id"] = aid;
  }
  if (label) {
    // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
    headers["X-Booking-Label"] = label;
  }
  if (emkToken) {
    // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
    headers["X-Booking-EMK-Token"] = emkToken;
  }
  if (debugAllExtraProducts) {
    // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
    headers["X-Flights-Debug-All-Products"] = "1";
  }
  if (soylentEmail) {
    // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
    headers["X-Soylent-Email"] = soylentEmail;
  }
  if (etgTestUrl) {
    // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
    headers["X-Flights-Debug-Etg-Test-Url"] = etgTestUrl;
  }
  if (debugAllTravelerDataRequirements) {
    // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
    headers["X-Flights-Debug-All-Traveler-Data-Requirements"] = "1";
  }

  // Set the supported Flights Client Hints by the Web app.
  const hints: typeof FLIGHTS_CLIENT_HINTS_FEATURES[number][] = ["price_change_v2"];
  // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
  headers[X_BOOKING_FLIGHTS_CLIENT_HINTS] = hints.join(",");

  if (env !== "prod") {
    const devOverrideHeader: RequestDevOverride = {};
    const devOverrides = devOverridesService.getAll();
    for (const service of FE_DEPENDECIES_SERVICES) {
      const overrideUrl = devOverrides[service];
      if (overrideUrl) {
        // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
        devOverrideHeader[service] = overrideUrl;
      }
    }
    if (Object.keys(devOverrideHeader).length) {
      // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
      headers[X_BOOKING_FLIGHTS_DEV_SERVICE_OVERRIDE] = JSON.stringify(devOverrideHeader);
    }
  }
  if (!options.credentials) {
    options.credentials = "same-origin";
  }

  let apiPrefix = "";
  if (String(url).startsWith("/")) {
    apiPrefix = window.__GLOBAL_CONTEXT__?.apiPrefix;
  }
  const fetchUrl = appendOverrideParams(apiPrefix + url, isInternal);
  return fetch(fetchUrl, { ...options, headers })
    .then((res) => {
      updateClientSideContext(res.headers.get("X-Booking-Experiment-Soylent-Payload"));
      checkForAppVersionMismatch(res.headers.get("X-Booking-App-Version"));
      if (res.ok) {
        if (res.headers.get("Content-Type") === "application/pdf") {
          return res.blob();
        }

        return res.json();
      } else {
        if (res.status === 403) {
          throw new ClientFetchNotAuthorizedError("Fetch: Not Authorized");
        } else {
          throw new ClientFetchError("Fetch: Non-OK response");
        }
      }
    })
    .then((data: any) => {
      checkForNewClientsidePayload(data?._meta?._clientsidePayload, fetchUrl);
      if (data.error) {
        // UIError
        // http status was ok, but response data has error. Throw!
        throw data.error;
      }
      return data as T;
    });
}

export default clientFetch;
