import React, { CSSProperties, ComponentProps, RefObject } from "react";
import Frame, { PaddingDirection } from "../Frame";
import styles from "./Container.module.css";

type Props = {
  children: React.ReactNode;
  justify?: ComponentProps<typeof Frame>["justifyContent"];
  align?: ComponentProps<typeof Frame>["alignItems"];
  position?: CSSProperties["position"];
  style?: CSSProperties;
  id?: string;
  innerRef?: RefObject<HTMLDivElement>;
  className?: string;
} & PaddingDirection;

const Container: React.FunctionComponent<Props> = (props: Props) => {
  const { children, justify = "center", align = "start", position = "relative", id, innerRef, className } = props;
  return (
    <Frame
      justifyContent={justify}
      alignItems={align}
      direction="row"
      p={props.p}
      pt={props.pt}
      pr={props.pr || 4}
      pb={props.pb}
      pl={props.pl || 4}
      attributes={{ style: { ...props.style, width: "100%" } }}
      className={className}
    >
      <div ref={innerRef} id={id} style={{ position }} className={styles.container}>
        {children}
      </div>
    </Frame>
  );
};
export { Container as default };
