/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React from "react";

interface Props {
  quiet?: boolean;
  children?: React.ReactNode;
}

interface State {
  hasError: boolean;
}

// No Hooks? http://reactjs.org/docs/hooks-faq.html#do-hooks-cover-all-use-cases-for-classes

export default class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    console.error(error); // eslint-disable-line
  }

  render() {
    if (this.state.hasError) {
      if (this.props.quiet) {
        return null;
      } else {
        return <div>Something went wrong.</div>;
      }
    }
    return <>{this.props.children}</>;
  }
}
