/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { ReactNode, useState } from "react";

import { I18nChildContext, t, useI18n } from "@bookingcom/lingojs-react";
import { Popover, SheetContainer, Text } from "@bookingcom/bui-react";
import useUserAgent from "hooks/useUserAgent";

type Props = {
  hover?: {
    title: string;
    text: string;
  };
  children: ReactNode;
};

export const FlightCardBadgeInteractiveWrapper = ({ hover, children }: Props) => {
  const { isMobile } = useUserAgent();

  if (!hover) return <>{children}</>;

  if (isMobile) {
    return <FlightCardBadgeInteractiveWrapperMobile hover={hover}>{children}</FlightCardBadgeInteractiveWrapperMobile>;
  }

  return <FlightCardBadgeInteractiveWrapperDesktop hover={hover}>{children}</FlightCardBadgeInteractiveWrapperDesktop>;
};

const FlightCardBadgeInteractiveWrapperMobile = ({ hover, children }: Required<Props>) => {
  const i18n = useI18n() as I18nChildContext;
  const [isBottomSheetActive, setBottomSheetActive] = useState<boolean>(false);

  return (
    <span
      role="presentation"
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
      }}
    >
      <div role="button" tabIndex={0} onClick={() => setBottomSheetActive(true)}>
        {children}
      </div>
      <SheetContainer
        active={isBottomSheetActive}
        onCloseTrigger={() => setBottomSheetActive(false)}
        closeAriaLabel={i18n.trans(t("close"))}
        position="bottom"
        title={hover.title}
      >
        <Text>{hover.text}</Text>
      </SheetContainer>
    </span>
  );
};

const FlightCardBadgeInteractiveWrapperDesktop = ({ hover, children }: Required<Props>) => (
  <Popover triggerType="hover" position="end">
    <Popover.Trigger>{(attributes) => <span {...attributes}>{children}</span>}</Popover.Trigger>
    <Popover.Content>
      <Text>{hover.text}</Text>
    </Popover.Content>
  </Popover>
);
