/**
 * for an Offer, Cart and/or Order
 * this hook will help set/get custom data related to a flight offer, cart
 * or order based on the token and keep tracking it cross pages (SR -> FD -> offer -> cart -> order)
 *
 * for example:
 * you can set for an offer token (in search result) a flag like "isAfterFilters"
 * and in (check and pay) you can retrieve that flag using the order token.
 */

import { useCallback } from "react";
import localstory from "localstory";

type DataType = { [key: string]: string | boolean | undefined };

export default function useOfferCartOrderData() {
  const keyName = "offer_cart_order_data";
  const storage = localstory(window.localStorage, "flights", { ttl: "30m" });

  const save = useCallback(
    (token: string, data: DataType) => {
      const allItems: DataType[] = storage.get(keyName) || [];
      const newData = allItems.filter(
        ({ offer, cart, order }) =>
          (!offer || offer !== data.offer) &&
          (!cart || cart !== data.cart) &&
          (!order || order !== data.order) &&
          ![offer, cart, order].filter(Boolean).includes(token)
      );
      newData.push(data);
      storage.set(keyName, newData);
    },
    [storage]
  );

  const get = useCallback(
    (token?: string) => {
      if (!token) return {};
      const allItems: DataType[] = storage.get(keyName) || [];
      const result = allItems.find((i) => [i.offer, i.cart, i.order].filter(Boolean).includes(token)) || {};
      return result;
    },
    [storage]
  );

  const set = useCallback(
    (token?: string, data?: DataType) => {
      if (!token) return;
      const currentData = get(token);
      const mergedData = { ...currentData, ...data };
      const { offer, cart, order } = mergedData;
      if (![offer, cart, order].filter(Boolean).length) return;
      save(token, mergedData);
    },
    [get, save]
  );

  return { set, get };
}
