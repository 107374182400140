import React, { useCallback, useMemo, useRef, useState } from "react";
import { Text, ActionBar } from "@bookingcom/bui-react";
import { PersonHalfIcon, AccountUsersIcon } from "@bookingcom/bui-assets-react/streamline/index.js";
import { ShellButton, Modal } from "../Shell/index.js";
import { t } from "@bookingcom/lingojs-core";
import OccupancyCabinClass from "./OccupancyCabinClass.js";
import useOccupancyCabinClass from "./useOccupancyCabinClass.js";
const OccupancyCabinClassModal = () => {
    const { cabinClass, occupancy, setCabinClass, setOccupancy, cabinClassName, errorMessage, i18n, isChildrenAgesValid, trackV2, isOccupancySelected, travellers } = useOccupancyCabinClass();
    const [state, setState] = useState({ cabinClass, occupancy });
    const buttonref = useRef(null);
    const [showModal, setShowModal] = useState(false);
    const text = useMemo(() => {
        return i18n.trans(t("flights_search_traveller_criteria", {
            variables: {
                passenger_count: i18n.trans(t("flights_search_passenger_count_mix", {
                    num_exception: travellers,
                    variables: { num_travellers: travellers }
                })),
                cabin_class: cabinClassName
            }
        }));
    }, [i18n, travellers, cabinClassName]);
    const icon = useMemo(() => {
        return travellers === 1 ? PersonHalfIcon : AccountUsersIcon;
    }, [travellers]);
    const trackEvent = useCallback(() => {
        if (!isOccupancySelected)
            return;
        if (occupancy.adults !== state.occupancy.adults ||
            occupancy.children.length !== state.occupancy.children.length ||
            occupancy.children.some((a, i) => state.occupancy.children[i] !== a)) {
            trackV2("select_travelers", {
                number_adults: state.occupancy.adults,
                number_children: state.occupancy.children.length,
                children_ages: state.occupancy.children
            });
        }
    }, [state, occupancy, trackV2, isOccupancySelected]);
    const closeModal = useCallback(() => {
        setShowModal(false);
        buttonref.current?.focus();
    }, []);
    const handleOnClose = useCallback(() => {
        closeModal();
        setCabinClass(state.cabinClass);
        setOccupancy(state.occupancy);
        trackEvent();
    }, [closeModal, setCabinClass, state.cabinClass, state.occupancy, setOccupancy, trackEvent]);
    const handleOnClick = useCallback(() => {
        closeModal();
        trackV2("click_passenger_done");
        const newState = { occupancy, cabinClass };
        setState(newState);
    }, [closeModal, trackV2, occupancy, cabinClass]);
    const errorId = "occupancy_cabin_class_modal_error";
    return (React.createElement(React.Fragment, null,
        React.createElement(ShellButton, { icon: icon, errorMessage: errorMessage, errorId: errorId, errorVariant: "inline", title: i18n.trans(t("a11y_flights_search_box_travellers_cabin_class_edit_hint")), "aria-label": i18n.trans(t("a11y_flights_search_box_travellers_cabin_class_edit_hint")), "aria-describedby": errorId, "aria-invalid": "true", "aria-live": "polite", ref: buttonref, "data-ui-error": Boolean(errorMessage), size: "large", "data-ui-name": "button_occupancy", onClick: (e) => {
                e.stopPropagation();
                setShowModal(true);
                trackV2("click_passenger");
            } },
            React.createElement(Text, { tagName: "span", variant: "body_2" }, text)),
        React.createElement(Modal, { active: showModal, onCloseTrigger: handleOnClose, position: "fullScreen", title: i18n.trans(t("flights_search_traveller_criteria_name")), footer: React.createElement(ActionBar, { topContent: text, button: {
                    wide: true,
                    text: i18n.trans(t("flights_action_done")),
                    disabled: !isChildrenAgesValid,
                    onClick: handleOnClick,
                    size: "large",
                    attributes: {
                        "data-ui-name": "button_occupancy_action_bar_done"
                    }
                } }) },
            React.createElement(OccupancyCabinClass, null))));
};
export default OccupancyCabinClassModal;
