import { getOrchestrator } from "@bookingcom/web-page-orchestrator";
import { createContext, useCallback, useEffect, useState } from "react";

const initialState = {
  GET_MANDATORY_DATA_CONSENT_KR: { checked: false, required: false },
  GET_MANDATORY_DATA_TRANSFER_CONSENT_KR: { checked: false, required: false },
  GET_MANDATORY_CONSENT: { checked: false, required: false }
};

type ConsentData = { checked: boolean; required: boolean };

export const useOrchestratorContext = () => {
  const orchestrator = getOrchestrator();

  const [consentData, setConsentData] = useState(initialState);

  const validateAllConsents = useCallback(async () => {
    const consents = await Promise.allSettled<ConsentData>(
      Object.keys(consentData).map((c) => {
        if (orchestrator) {
          return orchestrator.runQuery(c);
        }

        return Promise.resolve({ checked: false, required: false });
      })
    );

    for (const c of consents) {
      if (c.status === "fulfilled" && c.value.required && !c.value.checked) {
        return false;
      }
    }

    return true;
  }, [orchestrator, consentData]);

  const setMandatoryConsent = useCallback(
    async (payload: ConsentData) => {
      setConsentData({ ...consentData, GET_MANDATORY_CONSENT: { ...consentData.GET_MANDATORY_CONSENT, ...payload } });
    },
    [consentData]
  );
  const setMandatoryDataConsentKr = useCallback(
    async (payload: ConsentData) => {
      setConsentData({
        ...consentData,
        GET_MANDATORY_DATA_CONSENT_KR: { ...consentData.GET_MANDATORY_DATA_CONSENT_KR, ...payload }
      });
    },
    [consentData]
  );
  const setMandatoryDataTransferConsentKr = useCallback(
    async (payload: ConsentData) => {
      setConsentData({
        ...consentData,
        GET_MANDATORY_DATA_TRANSFER_CONSENT_KR: { ...consentData.GET_MANDATORY_DATA_TRANSFER_CONSENT_KR, ...payload }
      });
    },
    [consentData]
  );

  const getMandatoryConsent = useCallback(async () => {
    return consentData.GET_MANDATORY_CONSENT;
  }, [consentData]);
  const getMandatoryDataConsentKr = useCallback(async () => {
    return consentData.GET_MANDATORY_DATA_CONSENT_KR;
  }, [consentData]);
  const getMandatoryDataTransferConsentKr = useCallback(async () => {
    return consentData.GET_MANDATORY_DATA_TRANSFER_CONSENT_KR;
  }, [consentData]);

  useEffect(() => {
    if (!orchestrator) return;
    orchestrator.addCommands({
      SET_MANDATORY_CONSENT: setMandatoryConsent,
      SET_MANDATORY_DATA_CONSENT_KR: setMandatoryDataConsentKr,
      SET_MANDATORY_DATA_TRANSFER_CONSENT_KR: setMandatoryDataTransferConsentKr
    });
    orchestrator.addQueries({
      GET_MANDATORY_CONSENT: getMandatoryConsent,
      GET_MANDATORY_DATA_CONSENT_KR: getMandatoryDataConsentKr,
      GET_MANDATORY_DATA_TRANSFER_CONSENT_KR: getMandatoryDataTransferConsentKr
    });
  }, [
    orchestrator,
    setMandatoryConsent,
    getMandatoryConsent,
    setMandatoryDataConsentKr,
    setMandatoryDataTransferConsentKr,
    getMandatoryDataConsentKr,
    getMandatoryDataTransferConsentKr
  ]);

  return { orchestrator, validateAllConsents };
};

export const OrchestratorContext = createContext<ReturnType<typeof useOrchestratorContext>>(undefined as any);
