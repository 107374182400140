import { useEffect } from "react";
// eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
import { __deprecated__createTrackingUtils } from "..";
import useProfiler from "hooks/useProfiler";

// eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
const utils = __deprecated__createTrackingUtils({
  name: "flights_web_swap_price_baggage_sr_desktop_2",
  stages: {
    main: 1, // Search Results
    direct: 2,
    meta: 3,
    solo: 4,
    non_solo: 5,
    one_way: 6,
    round_trip: 7
  },
  goals: {
    baggage_info_hover: 1
  },
  goalsWithValue: []
});

const { stages, goals } = utils;

export const useTrackSwapPriceBaggageDesktop = () => {
  const { isMeta, isSolo, isOneWay, isRountrip } = useProfiler();

  useEffect(() => {
    stages.main();
    isMeta() ? stages.meta() : stages.direct();
    isSolo() ? stages.solo() : stages.non_solo();
    isOneWay() && stages.one_way();
    isRountrip() && stages.round_trip();
  }, [isMeta, isOneWay, isRountrip, isSolo]);
};

export const trackSwapPriceBaggageDesktopHoverGoal = () => goals.baggage_info_hover();
