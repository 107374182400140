import { BagHoldIcon, CabinTrolleyIcon } from "@bookingcom/bui-assets-react/streamline/index.js";
import BagPersonalItemIcon from "@bookingcom/bui-assets-react/streamline/BagPersonalItemIcon";
import { Actionable, Icon, Popover, Stack, Text } from "@bookingcom/bui-react";
import { t, useI18n } from "@bookingcom/lingojs-react";
import useGlobalContext from "hooks/useGlobalContext";
import React, { FC, useMemo } from "react";
import { getLeastAvailableLuggagePerTrip } from "store/selectors/searchResultsAvailableLuggage";
import { UIFlightData } from "@flights/types";
import { trackSwapPriceBaggageDesktopHoverGoal } from "utils/experiments/funnel/flights_web_swap_price_baggage_sr_desktop_2";

type Props = {
  flight: UIFlightData;
};

const FlightCardLuggagePopover = ({ flight }: Props) => {
  const i18n = useI18n();
  const { supportsChildrenTickets } = useGlobalContext();
  const { segments } = flight;

  const luggageData = useMemo(() => {
    const data: { icon: FC; label: string }[] = [];

    const { cabinLuggage, personalItem, checkedInLuggage } = getLeastAvailableLuggagePerTrip(
      segments || [],
      supportsChildrenTickets
    );

    if (personalItem) {
      data.push({
        icon: BagPersonalItemIcon,
        label: i18n.trans(t("flights_sr_baggage_personal_item"))
      });
    }

    if (cabinLuggage) {
      data.push({
        icon: CabinTrolleyIcon,
        label: i18n.trans(t("flights_sr_baggage_cabin_bag"))
      });
    }

    if (checkedInLuggage) {
      data.push({
        icon: BagHoldIcon,
        label: i18n.trans(t("flights_sr_baggage_checked_bag"))
      });
    }

    return data;
  }, [i18n, segments, supportsChildrenTickets]);

  if (!luggageData.length) {
    return null;
  }

  return (
    <Stack gap={1} direction="row" justifyContent="end">
      <Text variant="small_1">{i18n.trans(t("flights_sr_baggage_includes"))}</Text>

      <Popover size="small" triggerType="hover" position="top-end" onOpen={trackSwapPriceBaggageDesktopHoverGoal}>
        <Popover.Trigger>
          {(attributes) => (
            <Actionable
              attributes={{ ...attributes, "aria-label": i18n.trans(t("a11y_flights_sr_included_baggage_hover")) }}
            >
              {luggageData.map(({ icon }, i) => (
                <Icon key={i} svg={icon} size="small" />
              ))}
            </Actionable>
          )}
        </Popover.Trigger>
        <Popover.Content>
          <Stack>
            {luggageData.map(({ icon, label }, i) => (
              <Stack key={i} gap={3} direction="row">
                <Icon svg={icon} size="medium" />
                <Text variant="body_2">{label}</Text>
              </Stack>
            ))}
          </Stack>
        </Popover.Content>
      </Popover>
    </Stack>
  );
};

export default FlightCardLuggagePopover;
