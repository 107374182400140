// eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
import { __deprecated__createTrackingUtils } from "..";

// eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
const utils = __deprecated__createTrackingUtils({
  name: "flights_web_aa_fd_not_available_error",
  stages: {
    search_results: 1,
    flight_details: 2,
    desktop: 3,
    mdot: 4,
    meta: 5,
    direct: 6
  },
  goals: {
    not_available: 1
  },
  goalsWithValue: []
});

export default utils;
