/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React from "react";
import { merge } from "lodash";
import { formatISO } from "date-fns";
import { useLocation } from "react-router-dom";
import { t } from "@bookingcom/lingojs-core";
import { useI18n } from "@bookingcom/lingojs-react";

// eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
import { useStore } from "../../../store";
import useLocaleContext from "../../../hooks/useLocaleContext";
import useGlobalContext from "../../../hooks/useGlobalContext";
import { UIFetchStatus } from "@flights/types";
import clientFetch from "../../../utils/client-fetch";
import useCreateUrl from "../../../hooks/useCreateUrl";
import useClientMetrics from "../../../hooks/useClientMetrics";
import styles from "./LiveChatWidget.module.css";

import "./LiveChatWidget.css";

interface WidgetI18nConfig {
  widgetLanguagePackageUrl: string;
  widgetLocaleCode: string;
}
// List of copy tags provided by the widget. If user's language is not in this list - fall back to english.
// En English, DA Danish, DE German, ES Spanish, FI Finnish, FR French, IT Italian, JA Japanese, KO Korean, NL Dutch,
// NO Norwegian, PL Polish, SV Swedish, TH Thai, TR Turkish, XT Chinese (Traditional), ZH Chinese (edited), pt-BR Brazilian Portuguese
const getWidgetI18nConfigByLocaleCode = (localeCode: string | null | undefined): WidgetI18nConfig => {
  switch (localeCode) {
    case "ro":
    case "pt":
    case "es":
    case "es-MX":
      return {
        widgetLocaleCode: localeCode,
        widgetLanguagePackageUrl: `/live-chat-language-packages/${localeCode}.json`
      };
    case "no":
    case "nb":
    case "nn":
      return {
        widgetLocaleCode: "no",
        widgetLanguagePackageUrl: "https://apps.mypurecloud.com/widgets/9.0/i18n/widgets-no.i18n.json"
      };
    case "da":
    case "nl":
    case "fi":
    case "fr":
    case "de":
    case "it":
    case "ja":
    case "ko":
    case "pl":
    case "sv":
    case "th":
    case "tr":
    case "pt-BR":
    case "zh-CN":
    case "zh-TW":
      return {
        widgetLocaleCode: localeCode,
        widgetLanguagePackageUrl: `https://apps.mypurecloud.com/widgets/9.0/i18n/widgets-${localeCode}.i18n.json`
      };
    case "en-US":
    case "en-GB":
    default:
      return {
        widgetLocaleCode: "en",
        widgetLanguagePackageUrl: "https://apps.mypurecloud.com/widgets/9.0/i18n/widgets-en.i18n.json"
      };
  }
};

declare global {
  interface Window {
    CXBus?: {
      configure(...args: any[]): void;
      loadPlugin(plugin: string): void;
      registerPlugin(plugin: string): any;
    };
    _genesys: Object;
  }
}

export const LiveChatLoader: React.FC = () => {
  const i18n = useI18n();
  const { locale } = useLocaleContext();
  // If user's language is not in this list - fall back to english
  const { widgetLanguagePackageUrl, widgetLocaleCode } = getWidgetI18nConfigByLocaleCode(locale.code);
  const [widgetI18n, setWidgetI18n] = React.useState<null | Object>(null);
  const [widgetI18nStatus, setWidgetI18nStatus] = React.useState<UIFetchStatus>("initial");

  // fetching translations provided by widget and merging them with our custom copy tags into one i18n object
  React.useEffect(() => {
    setWidgetI18nStatus("loading");
    fetch(widgetLanguagePackageUrl)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        setWidgetI18n(
          merge(json, {
            [widgetLocaleCode]: {
              webchat: {
                ChatTitle: i18n.trans(t("flights_hc_chat_home_navigation")),
                ChatFormSubmit: i18n.trans(t("flights_hc_chat_home_cta_start")),
                AriaChatFormSubmit: i18n.trans(t("flights_hc_chat_home_cta_start")),
                ConfirmCloseWindow: i18n.trans(t("flights_hc_chat_confirmation_end_description")),
                ConfirmCloseConfirm: i18n.trans(t("flights_hc_chat_confirmation_cta_end")),
                AriaConfirmCloseConfirm: i18n.trans(t("flights_hc_chat_confirmation_cta_end")),
                ChatFormCancel: i18n.trans(t("flights_hc_chat_home_cta_cancel")),
                AriaChatFormCancel: i18n.trans(t("flights_hc_chat_home_cta_cancel")),
                ChatEndCancel: i18n.trans(t("flights_hc_chat_confirmation_cta_cancel")),
                AriaChatEndCancel: i18n.trans(t("flights_hc_chat_confirmation_cta_cancel")),
                todayLabel: i18n.trans(t("flights_hc_chat_window_today")),
                ChatStarted: i18n.trans(t("flights_hc_chat_window_chat_started")),
                AgentNameDefault: i18n.trans(t("flights_hc_chat_window_agent")),
                AgentConnected: i18n.trans(t("flights_hc_chat_window_agent_connected")),
                ChatInputPlaceholder: i18n.trans(t("flights_hc_chat_window_type_prompt")),
                ChatEnded: i18n.trans(t("flights_hc_chat_window_chat_ended")),
                RestoreTimeoutTitle: i18n.trans(t("flights_hc_chat_window_chat_ended")),
                AgentDisconnected: i18n.trans(t("flights_hc_chat_window_agent_disconnected"))
              }
            }
          })
        );
        setWidgetI18nStatus("success");
      })
      .catch(() => {
        setWidgetI18nStatus("failed");
      });
    /* eslint-disable-next-line */
  }, []);

  if (widgetI18nStatus === "failed") {
    throw new Error("Unable to fetch and merge translations");
  }

  if (widgetI18nStatus === "success" && widgetI18n) {
    return <LiveChatWidget widgetLocaleCode={widgetLocaleCode} widgetI18n={widgetI18n} />;
  }

  return <div className={styles.loading}>{i18n.trans(t("flights_select_in_progress"))}</div>;
};

interface LiveChatWidgetWrapperProps {
  widgetI18n: Object;
  widgetLocaleCode: string;
}

export const LiveChatWidget: React.FC<LiveChatWidgetWrapperProps> = ({ widgetI18n, widgetLocaleCode }) => {
  if (!window?.CXBus) {
    throw new Error("CXBus has not been loaded");
  }

  // eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
  const store = useStore();
  const i18n = useI18n();
  const { createUrl } = useCreateUrl();
  const { locale } = useLocaleContext();
  const { env = "" } = useGlobalContext();
  const [language] = locale.code.split("-");
  const { order } = store.order;
  const searchParams = useLocation().search;
  const query = new URLSearchParams(searchParams);
  const { trackClientMetric } = useClientMetrics();

  if (!order) {
    throw new Error("Order is not provided");
  }

  if (!query.get("country_code")) {
    throw new Error("country_code is not provided");
  }

  const passenger = order.passengers.find((passenger) => passenger.email === order.booker.email);
  if (!passenger) {
    throw new Error("Passenger was not found");
  }

  const customConfig = {
    form: {
      autoSubmit: false,
      firstname: passenger.firstName,
      lastname: passenger.lastName,
      email: order.booker.email
    },
    formJSON: {
      wrapper: "<table></table>",
      inputs: [
        {
          id: "cx_webchat_welcome",
          name: "welcome",
          type: "checkbox",
          label: i18n.trans(t("flights_hc_chat_home_header")),
          value: ""
        },
        {
          id: "cx_webchat_form_firstname",
          name: "firstname",
          maxlength: "100",
          label: i18n.trans(t("flights_hc_chat_home_field_first_name")),
          readonly: true,
          disabled: true
        },
        {
          id: "cx_webchat_form_lastname",
          name: "lastname",
          maxlength: "100",
          label: i18n.trans(t("flights_hc_chat_home_field_last_name")),
          readonly: true,
          disabled: true
        },
        {
          id: "cx_webchat_form_email",
          name: "email",
          maxlength: "100",
          label: i18n.trans(t("flights_hc_chat_home_field_contact_email")),
          readonly: true,
          disabled: true
        },
        {
          id: "cx_webchat_form_order_id",
          name: "orderId",
          maxlength: "100",
          label: i18n.trans(t("flights_hc_chat_home_field_cust_reference")),
          value: order.publicReference.publicReference,
          required: true,
          readonly: true,
          disabled: true
        },
        {
          id: "cx_webchat_form_consent",
          name: "consent",
          type: "checkbox",
          label: i18n.trans(
            t("flights_hc_chat_home_consent", {
              variables: {
                supplier_legal_entity: order.orderTerms.supplier.name
              }
            })
          ),
          value: "",
          validate: (_event: any, _form: any, inputs: HTMLInputElement[] | undefined) => {
            const submitButton = document.querySelector("button.cx-submit");
            if (inputs !== undefined && inputs.length > 0 && inputs[0].checked === true) {
              if (submitButton) {
                submitButton.setAttribute("data-form-valid", "true");
              }

              return true;
            }
            if (submitButton) {
              submitButton.removeAttribute("data-form-valid");
            }

            return false;
          },
          wrapper: `<tr><td colspan="2">{input}{label} <a id="consent-privacy-policy-link" target="_blank" href="${
            order.orderTerms.supplier.privacyPolicyUrl
          }">${i18n.trans(t("flights_hc_chat_home_consent_read_more"))}</a></td></tr>`
        }
      ]
    }
  };

  window._genesys = {
    widgets: {
      main: {
        lang: widgetLocaleCode,
        i18n: widgetI18n,
        downloadGoogleFont: false,
        themes: {
          booking: "cx-theme-booking"
        },
        theme: "booking",
        mobileMode: true
      },
      webchat: {
        chatButton: {
          enabled: false
        },
        transport: {
          type: "purecloud-v2-sockets",
          dataURL: "https://api.mypurecloud.de",
          deploymentKey:
            env === "prod" ? "6a7bd5e3-d6c5-45d8-b646-49770d4a9117" : "d4052495-5544-4089-aa7d-0df67832563d",
          orgGuid: "778992df-72eb-434e-bb5b-b32f65dd50b5",
          interactionData: {
            routing: {
              targetType: "QUEUE",
              targetAddress: "ETG Booking.com English",
              priority: 2
            }
          }
        },
        userData: {
          site: `booking_${query.get("country_code") || language}`,
          language,
          contactReason: query.get("selected_topic") || "",
          orderNumber: order.publicReference.publicReference,
          rebookingTrip: query.get("rebooking_trip") || ""
        }
      }
    }
  };

  React.useEffect(() => {
    if (window?.CXBus) {
      window.CXBus.configure({ debug: false, pluginsPath: "https://apps.mypurecloud.de/widgets/9.0/plugins/" });
      window.CXBus.loadPlugin("widgets-core");
      const customPlugin = window.CXBus.registerPlugin("Custom");
      customPlugin.subscribe("CoBrowse.ready", () => {
        customPlugin.command("WebChat.open", customConfig);
      });

      customPlugin.before("WebChatService.startChat", function (oData: any) {
        // oData == the options passed into the command call
        // e.g. if this command is called: oMyPlugin.command("WebChat.open", {form: {firstname: "Mike"}});
        // then oData will == {form: {firstname: "Mike"}}

        // You must return oData back, or an empty object {} for execution to continue.
        // If you return false|undefined|null, execution of the command will be stopped

        // Here we need to store user's consent before starting the chat,
        // but .before does not support async function as a callback,
        // so here is a workaround to make sure the consent is being saved.
        // We are intercepting and stopping "WebChatService.startChat" event by returning false
        // and manually triggering this event again in "then" section after successful api call
        if (!oData?.consentSaved) {
          void clientFetch(`/api/order/${order.orderToken}/consent`, {
            method: "post",
            credentials: "same-origin",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              timestamp: formatISO(new Date()),
              countryCode: query.get("country_code"),
              topic: query.get("selected_topic") || "",
              rebookingTrip: query.get("rebooking_trip") || "",
              rebookingPrice: query.get("rebook_price") || ""
            })
          }).then(() => {
            customPlugin.command("WebChatService.startChat", { ...oData, consentSaved: true });
          });

          return false;
        }

        return oData;
      });

      // The WebChat widget has been removed from the screen
      customPlugin.subscribe("WebChat.closed", () => {
        window.location.href = createUrl("/live-chat/ended");
        trackClientMetric("live_chat_closed");
      });

      // The WebChat has successfully started
      customPlugin.subscribe("WebChat.started", () => {
        trackClientMetric("live_chat_started");
      });

      // The Chat session ended after agent is successfully connected to WebChat
      customPlugin.subscribe("WebChat.completed", () => {
        trackClientMetric("live_chat_completed");
      });

      // The Chat session ended before agent is connected to WebChat
      customPlugin.subscribe("WebChat.cancelled", () => {
        trackClientMetric("live_chat_cancelled");
      });
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return <></>;
};
