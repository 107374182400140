import localstory from "localstory";

import { SESSION_STORAGE_KEY_LAST_SUCCESSFUL_SEARCH } from "../constants";
import useCreateUrl from "./useCreateUrl";
import { validationUtils } from "@flights/web-api-utils-universal";
import { generatePath } from "react-router-dom";

const isClient = process.env.BUILD_TARGET === "client";
const sessionStorage = isClient && localstory(window.sessionStorage, "flights");

export default function useClientCachedURL(redirectSearchParams?: string) {
  const { createUrl } = useCreateUrl();
  const cacheLastSuccessfulSearchUrl = (airports: string) => {
    if (!sessionStorage) {
      return;
    }
    const urlParams = new URLSearchParams(redirectSearchParams || location.search);
    const searchResultsUrl = generatePath("/flights/:airports/", {
      airports
    });
    sessionStorage.set(SESSION_STORAGE_KEY_LAST_SUCCESSFUL_SEARCH, `${searchResultsUrl}?${urlParams.toString()}`);
  };

  const getLastSuccessfulSearchUrl = (): string => {
    if (!sessionStorage) {
      return createUrl("/");
    }

    const savedSuccessfulSearchUrl = sessionStorage.get(SESSION_STORAGE_KEY_LAST_SUCCESSFUL_SEARCH) || undefined;
    if (savedSuccessfulSearchUrl && validationUtils.isValidSearchURL(savedSuccessfulSearchUrl, true)) {
      return createUrl(savedSuccessfulSearchUrl);
    }
    return createUrl("/");
  };

  return { cacheLastSuccessfulSearchUrl, getLastSuccessfulSearchUrl };
}
