import React, { useCallback, useEffect, useState } from "react";
import useSearchFiltersController from "../useSearchFiltersController";
import { InputSlider } from "@bookingcom/bui-react";
import { useI18n, t } from "@bookingcom/lingojs-react";

type DurationControllerProps = NonNullable<ReturnType<typeof useSearchFiltersController>["duration"]>;

type DurationProps = {
  min: DurationControllerProps["options"]["min"];
  max: DurationControllerProps["options"]["max"];
  value: DurationControllerProps["value"];
  onChange: DurationControllerProps["set"];
};

export default function Duration(props: DurationProps) {
  const { value, min, max, onChange } = props;
  const i18n = useI18n();
  const [valueState, setValueState] = useState(value);
  const handleOnChange = useCallback((duration: number) => setValueState(duration), []);
  const handleOnChangeCommit = useCallback((duration: number) => onChange(duration), [onChange]);
  useEffect(() => setValueState(value), [value]);
  return (
    //https://jira.booking.com/jira/browse/DESY-5093
    <div style={{ padding: "var(--bui_spacing_4x)", overflow: "hidden", margin: "calc(var(--bui_spacing_4x) * -1)" }}>
      <InputSlider
        min={min}
        max={max}
        value={valueState}
        name="filters-duration"
        attributes={{ "data-testid": "search_filter_stops_duration_slider" }}
        ariaLabel={i18n.trans(t("flights_filters_duration_travel_time_title"))}
        label={i18n.trans(t("flights_filters_duration_travel_time_title"))}
        onChange={({ value }) => handleOnChange(value)}
        onChangeCommit={({ value }) => handleOnChangeCommit(value)}
        renderTooltipValue={(val) =>
          i18n.trans(
            t("flights_filters_duration_hours", { variables: { num_exception: val, num_hours: val.toString() } })
          )
        }
        renderValue={(val) => (
          <span data-testid="search_filter_stops_duration_travel_time_label">
            {i18n.trans(
              t("flights_filters_duration_hours", {
                variables: { num_exception: val, num_hours: val.toString() }
              })
            )}
          </span>
        )}
      />
    </div>
  );
}
