import { useCallback, useContext } from "react";
import { SearchBoxContext } from "../SearchBoxContext/index.js";
const useDirectFlight = () => {
    const { i18n, setIsDirect, isDirect, trackV2, isHeaderUpdateExp, showNonStopExp } = useContext(SearchBoxContext);
    const toggleIsDirect = useCallback((checked) => {
        setIsDirect(checked);
    }, [setIsDirect]);
    return {
        i18n,
        toggleIsDirect,
        isDirect,
        trackV2,
        isHeaderUpdateExp,
        showNonStopExp
    };
};
export default useDirectFlight;
