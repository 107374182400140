import { Actions, ActionTypes } from "./actions";
import { getInitialState, State } from "./state";

const initialState = getInitialState();

const reducer = (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case ActionTypes.fetch:
      return {
        ...state,
        fetchStatus: "loading",
        rebookPolicy: undefined,
        error: undefined
      };
    case ActionTypes.fetchSuccess:
      return {
        ...state,
        fetchStatus: "success",
        rebookPolicy: action.payload.rebookPolicy,
        error: undefined
      };
    case ActionTypes.fetchError:
      return {
        ...state,
        fetchStatus: "failed",
        rebookPolicy: undefined,
        error: action.payload.error
      };
    case ActionTypes.reset:
      return getInitialState();
    default:
      return state;
  }
};

export { reducer, getInitialState };
