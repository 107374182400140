import { useCallback } from "react";
import { useHistory } from "react-router-dom";

import { TranslationMessage } from "@bookingcom/lingojs-core/build/t";
import { t } from "@bookingcom/lingojs-core";

import { RouteName } from "./routes";

// eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
import { StoreState, useStore } from "../store";
import useGlobalContext from "../hooks/useGlobalContext";

import { assert } from "../utils/assert";
import { PARAM_LIST_SEPARATOR } from "@flights/constants";
import useCreateUrl from "../hooks/useCreateUrl";
import {
  PRE_OPTED_ANCILLARIES_QUERY_PARAMS,
  BRANDED_FARES_QUERY_PARAMS,
  PRE_FETCHED_FARES_QUERY_PARAMS
} from "../constants";
import useRouteName from "hooks/useRouteName";
import { trackExperiment } from "utils/et";
import flights_ace_web_prevent_double_cart_creation_for_bf from "utils/experiments/ace/flights_ace_web_prevent_double_cart_creation_for_bf";

/* start - flights_web_update_checkout_title_desktop */
const globalContext = window.__GLOBAL_CONTEXT__;
const isCheckoutTitleETActive =
  globalContext && !globalContext.userAgent.isMobile && trackExperiment("flights_web_update_checkout_title_desktop");
/* end - flights_web_update_checkout_title_desktop */

const isMobile = !!globalContext?.userAgent?.isMobile;
const isPaxWithBaggages =
  (!isMobile && !!trackExperiment("flights_web_ddot_pax_with_baggages")) ||
  (isMobile && !!trackExperiment("flights_web_mdot_pax_with_baggages"));

type NavigationScreen = {
  title: TranslationMessage;
  /* start - flights_web_update_checkout_title_desktop */
  pageTitle: (store: StoreState) => TranslationMessage | undefined;
  /* end - flights_web_update_checkout_title_desktop */
  url: (store: StoreState) => string;
};

type NavigationScreens = {
  [key in RouteName]?: NavigationScreen;
};

const screens: NavigationScreens = {
  home: {
    title: t("flights_index_header_compare_book_ease"),
    pageTitle: () => undefined,
    url: function () {
      return "/";
    }
  },

  /*
     First user-facing checkout step is dynamic:
       - it's branded fare if available for a flight
       - otherwise it's ticket-type if available for a flight
       - otherwise it's pax

     Moreover we have no idea about flight available extras (incl. flex ticket) until we fetch flight details.
     However user might start checkout (pushing "Select" button on flight details screen) before we fetch them.

     To avoid slowing down UI funnel by disabling that button and to avoid putting unrelated responsibility
     on flight details screen `checkout-start` step was introduced.
     It's a service step which is a guaranteed checkout entry point.
     It makes sure flight details are fetched and redirects to a proper next step right after.
   */
  "checkout-start": {
    title: t("flights_page_title_bp_generic"),
    pageTitle: () => undefined,
    url: function () {
      throw Error("Unable to build `checkout-start` url. Flight id is not yet known in store.");
    }
  },

  "checkout-ticket-type": {
    title: isCheckoutTitleETActive ? t("flights_checkout_stepper_ticket_type") : t("flights_progress_ticket_type"),
    pageTitle: () => t("flights_checkout_ticket_type_title"),
    url: (store) => {
      const flightToken = store.flightDetails.flight?.token;
      const cartToken = store.cartDetails.cartDetails?.cart.cartToken;

      assert(flightToken, "Missing flight details for checkout nav");
      assert(cartToken, "Missing cart token");

      return `/checkout/ticket-type/${flightToken}/${cartToken}?${getQueryParamsToPreserve().toString()}`;
    }
  },

  "checkout-fare": {
    title: isCheckoutTitleETActive ? t("flights_checkout_stepper_fare") : t("flight_fare_choose"),
    pageTitle: () => t("flights_checkout_fare_title"),
    url: (store) => {
      const flightToken = store.flightDetails.flight?.token;
      const cartToken = store.cartDetails.cartDetails?.cart.cartToken;
      const isBrandedFare = !!store.flightDetails.flight?.brandedFareOffers?.length;

      assert(flightToken, "Missing flight details for checkout nav");

      if (isBrandedFare && flights_ace_web_prevent_double_cart_creation_for_bf.trackWithDefaultStage()) {
        return `/checkout/fare/${flightToken}?${getQueryParamsToPreserve().toString()}`;
      }

      assert(cartToken, "Missing cart token");

      return `/checkout/fare/${flightToken}/${cartToken}?${getQueryParamsToPreserve().toString()}`;
    }
  },

  checkout: {
    title:
      isCheckoutTitleETActive || isPaxWithBaggages
        ? t("flights_checkout_stepper_your_details")
        : t("flights_checkout_passenger_details_stepper"),
    pageTitle: () => (isPaxWithBaggages ? t("flights_pax_fill_in_details") : t("flights_checkout_pax_details_title")),
    url: (store) => {
      const flightToken = store.flightDetails.flight?.token;
      const cartToken = store.cartDetails.cartDetails?.cart.cartToken;

      assert(flightToken, "Missing flight details for checkout nav");
      assert(cartToken, "Missing cart token");

      return `/checkout/pax/${flightToken}/${cartToken}?${getQueryParamsToPreserve().toString()}`;
    }
  },

  checkout2: {
    title:
      isCheckoutTitleETActive || isPaxWithBaggages
        ? t("flights_checkout_stepper_extras")
        : t("flights_stepper_baggage"),
    pageTitle: () => t("flights_checkout_customise_title"),
    url: (store) => {
      const flightToken = store.flightDetails.flight?.token;
      const cartToken = store.cartDetails.cartDetails?.cart.cartToken;

      assert(flightToken, "Missing flight details for checkout nav");
      assert(cartToken, "Missing cart token");

      return `/checkout/extras/${flightToken}/${cartToken}?${getQueryParamsToPreserve().toString()}`;
    }
  },

  "checkout-seat-selection": {
    title: isCheckoutTitleETActive
      ? t("flights_checkout_stepper_seats")
      : t("flights_seatmap_dt_header_select", { num_exception: 1 }),
    pageTitle: ({ flightDetails: { flight } }) => {
      const travellerCount = flight?.travellers?.length || 1;
      const isSingular = travellerCount === 1 && flight?.tripType === "ONEWAY" && flight.segments.length === 1;

      return t("flights_checkout_seats_title_multi", {
        variables: {
          num_exception: isSingular ? 1 : 2
        }
      });
    },
    url: (store) => {
      const flightToken = store.flightDetails.flight?.token;
      const cartToken = store.cartDetails.cartDetails?.cart.cartToken;

      assert(flightToken, "Missing flight details for checkout nav");
      assert(cartToken, "Missing cart token");

      return `/checkout/seats/${flightToken}/${cartToken}`;
    }
  },

  "checkout-sirf": {
    // SIRF = Spanish islands resident fare
    title: isCheckoutTitleETActive ? t("flights_checkout_stepper_check_pay") : t("flights_checkout_review_stepper"),
    pageTitle: () => t("flights_checkout_check_pay_title"),
    url: (store) => {
      const flightToken = store.flightDetails.flight?.token;
      const cartToken = store.cartDetails.cartDetails?.cart.cartToken;

      assert(flightToken, "Missing flight details for checkout nav");
      assert(cartToken, "Missing cart token");

      return `/checkout/sirf/${flightToken}/${cartToken}`;
    }
  },

  checkout3: {
    title: isCheckoutTitleETActive ? t("flights_checkout_stepper_check_pay") : t("flights_checkout_review_stepper"),
    pageTitle: () => t("flights_checkout_check_pay_title"),
    url: (store) => {
      const flightToken = store.flightDetails.flight?.token;
      const orderToken = store.order.orderCreated?.orderToken;

      assert(flightToken, "Missing flight details for checkout nav");
      assert(orderToken, "Missing orderToken");

      return `/checkout/payment/${flightToken}/${orderToken}`;
    }
  },

  // FLIGHTS_WEB_PAYMENT_COLLECTION_NEW_PAGE
  "checkout-collection": {
    title: isCheckoutTitleETActive ? t("flights_checkout_stepper_check_pay") : t("flights_checkout_review_stepper"),
    pageTitle: () => t("flights_checkout_check_pay_title"),
    url: (store) => {
      const flightToken = store.flightDetails.flight?.token;
      const orderToken = store.order.orderCreated?.orderToken;

      assert(flightToken, "Missing flight details for checkout nav");
      assert(orderToken, "Missing orderToken");

      return `/checkout/payment/${flightToken}/${orderToken}`;
    }
  }
  // FLIGHTS_WEB_PAYMENT_COLLECTION_NEW_PAGE
};

export const getQueryParamsToPreserve = (): URLSearchParams => {
  const paramsToPreserve = pickParamsFromCurrentUrl(
    Object.values({
      ...PRE_OPTED_ANCILLARIES_QUERY_PARAMS,
      ...BRANDED_FARES_QUERY_PARAMS,
      ...PRE_FETCHED_FARES_QUERY_PARAMS
    })
  );
  return new URLSearchParams(paramsToPreserve);
};

const pickParamsFromCurrentUrl = (params: string[]): Record<string, string> => {
  const currentParams = new URLSearchParams(location.search);
  const picked = {};

  params.forEach((param) => {
    if (currentParams.has(param)) {
      // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
      picked[param] = currentParams.get(param);
    }
  });

  return picked;
};

/**
 * Overrides for deep-linking back to native app screens.
 */
const DEEPLINKS_PROTOCOL = "booking:";

/*eslint-disable-next-line @bookingcom/flights/no-unassigned-todo-comments*/
/* TODO(aashrivastav) start: Remove deep links support once apps go entirely native for all screens.
 */
function searchParamsFromState(search: StoreState["search"]) {
  const params = new URLSearchParams();

  const { searchSegments } = search;
  const { from, to, departureDate, returnDate } = searchSegments[0];
  params.set("type", search.type);
  params.set("adults", `${search.adults}`);
  params.set("cabinClass", search.cabinClass);
  params.set("children", search.children.join(PARAM_LIST_SEPARATOR));
  params.set("depart", departureDate || "");
  params.set("sort", search.sort);
  params.set("from", from.map((l) => l.code).join(PARAM_LIST_SEPARATOR));
  params.set("to", to.map((l) => l.code).join(PARAM_LIST_SEPARATOR));
  if (search.type == "ROUNDTRIP") {
    params.set("return", `${returnDate}`);
  }
  return params;
}

const nativeAppScreens: NavigationScreens = {
  home: {
    title: t("flights_index_header_compare_book_ease"),
    pageTitle: () => undefined,
    url: function (store) {
      const params = searchParamsFromState(store.search);
      return `${DEEPLINKS_PROTOCOL}//flights?${params}`;
    }
  }
};
/*eslint-disable-next-line @bookingcom/flights/no-unassigned-todo-comments*/
/* TODO(aashrivastav) end
 */

export const getScreen = (routeName: RouteName): NavigationScreen => {
  const screen = screens[routeName];
  if (!screen) {
    throw Error(`Invalid screen: ${routeName}`);
  }

  return screen;
};

export const getNativeScreen = (routeName: RouteName): NavigationScreen => {
  const screen = nativeAppScreens[routeName];
  if (!screen) {
    throw Error(`Invalid native screen: ${routeName}`);
  }

  return screen;
};

/*eslint-disable-next-line @bookingcom/flights/no-unassigned-todo-comments*/
/* TODO(asamilyak): Search results went native on apps recently.
      <NoResultsFound> was the last (and only) usage of deep link navigation in webview.
      So currently we don't use deep links and `isDeepLinkUrlInWebview` param anywhere in the code base.
      We keep it only to support a possible future need to navigate from webview non-native checkout/postbooking screens
      to already native screens (which are all others).
      Remove deep links support once apps go entirely native for all screens.
*/
export const useScreenUrl = (routeName: RouteName, isDeepLinkUrlInWebview?: boolean) => {
  // eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
  const store = useStore();
  const globalContext = useGlobalContext();
  const { userAgent, features } = globalContext;
  const { createUrl } = useCreateUrl();

  const isNativeScreen = userAgent.isWebview && isDeepLinkUrlInWebview && features.FLIGHTS_ENABLE_APP_DEEPLINKS;
  const screen = isNativeScreen ? getNativeScreen(routeName) : getScreen(routeName);

  // screen url construction is depending on store state in some cases, so deferring it with a function
  // to make sure consuming component will receive the correct url based on the _latest_ store state
  const getScreenUrl = useCallback(() => {
    const url = screen.url(store);
    return createUrl(url);
  }, [createUrl, screen, store]);

  return { getScreenUrl };
};

/*eslint-disable-next-line @bookingcom/flights/no-unassigned-todo-comments*/
/* TODO(asamilyak): Remove deep links support once apps go entirely native for all screens.
      See a TODO for `useScreenUrl()` method above for more details.
*/
export const useScreenNavigation = (routeName: RouteName, isDeepLinkNavigationInWebview?: boolean): (() => void) => {
  const { userAgent, features } = useGlobalContext();
  const isDeepLinkUrl = userAgent.isWebview && isDeepLinkNavigationInWebview && features.FLIGHTS_ENABLE_APP_DEEPLINKS;
  const { getScreenUrl } = useScreenUrl(routeName, isDeepLinkUrl);
  const history = useHistory();

  return useCallback(() => {
    if (isDeepLinkUrl) {
      window.location.href = getScreenUrl();
    } else {
      history.push(getScreenUrl());
    }
  }, [history, getScreenUrl, isDeepLinkUrl]);
};

export const useCheckoutPageTitleTag = () => {
  // eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
  const store = useStore();
  const screen = getScreen(useRouteName());

  return screen.pageTitle(store);
};
