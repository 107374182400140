import { Stack } from "@bookingcom/bui-react";
import React, { ComponentProps, ReactNode, useMemo } from "react";
import { StackProps } from "@bookingcom/bui-react";
import styles from "./Frame.module.css";
import { mcn } from "utils/mergeClassnames";

type Directions = "t" | "r" | "b" | "l" | "";
export type MarginDirection = Partial<Record<`m${Directions}`, StackProps["gap"]>>;
export type PaddingDirection = Partial<Record<`p${Directions}`, StackProps["gap"]>>;

/*
  This component will soon be completely deprecated or it will be completely built around BUI's base components.

  <Stack /> can't be used yet because how it can be buggy with its children, but as soon as the BUI team can use
  "gap" this won't be an issue.

  The BUI team will be aslo working on an utilities component, this component won't need to create its own margin
  and padding utilities.
*/

type FlexProps = {
  justifyContent?:
    | "flex-start"
    | "flex-end"
    | "center"
    | "space-around"
    | "space-between"
    | "start"
    | "end"
    | "left"
    | "right";
  alignItems?:
    | "flex-start"
    | "flex-end"
    | "center"
    | "stretch"
    | "baseline"
    | "start"
    | "end"
    | "top"
    | "bottom"
    | "left"
    | "right";
  direction?: "column" | "column-reverse" | "row" | "row-reverse";
  grow?: 0 | 1 | 2 | 3 | 4 | 5;
  wrap?: "wrap" | "nowrap" | "wrap-reverse";
};

type Props = {
  children: ReactNode;
  tagName?: string;
  className?: string;
  attributes?: ComponentProps<typeof Stack>["attributes"];
  elementRef?: React.Ref<HTMLDivElement>;
} & FlexProps &
  MarginDirection &
  PaddingDirection;

const Frame = (props: Props) => {
  const {
    className,
    tagName,
    mt,
    mr,
    mb,
    ml,
    m,
    pt,
    pr,
    pb,
    pl,
    p,
    children,
    attributes,
    justifyContent,
    alignItems,
    wrap,
    grow,
    direction,
    elementRef,
    ...rest
  } = props;

  // eslint-disable-next-line @typescript-eslint/naming-convention -- this line was auto generated, hence fix the issue timely
  const TagName: any = tagName || "div";

  const classes = useMemo(
    () =>
      mcn(
        applyFlexClasses({ justifyContent, alignItems, wrap, direction, grow }),
        applyGapClasses({ m, mt, mr, mb, ml, p, pt, pr, pb, pl })
      ),
    [alignItems, direction, grow, justifyContent, m, mb, ml, mr, mt, p, pb, pl, pr, pt, wrap]
  );

  return (
    <TagName
      {...rest} // This is basically to catch any non standard attributes
      {...attributes}
      className={mcn(classes, className)}
      ref={elementRef}
    >
      {children}
    </TagName>
  );
};

const applyFlexClasses = (props: FlexProps) => {
  const classMap = {
    justifyContent: "justify-content",
    alignItems: "align-items",
    direction: "flex-direction",
    wrap: "flex-wrap",
    grow: "flex-grow"
  };

  const defaultFlexDirection = "column";

  const classList = Object.entries(props)
    .filter(([, value]) => value != undefined)
    // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
    .map(([prop, value]) => styles[`${classMap[prop]}_${value}`]);

  if (classList.length > 0 && !props.direction) {
    classList.push(styles[`${classMap.direction}_${defaultFlexDirection}`]);
  }

  return mcn(...classList);
};

const applyGapClasses = (props: MarginDirection & PaddingDirection) => {
  const propMap: Record<keyof MarginDirection | keyof PaddingDirection, string> = {
    m: "margin",
    mt: "margin-top",
    mr: "margin-right",
    mb: "margin-bottom",
    ml: "margin-left",
    p: "padding",
    pt: "padding-top",
    pr: "padding-right",
    pb: "padding-bottom",
    pl: "padding-left"
  };

  return mcn(
    ...Object.entries(props)
      .filter(([, value]) => value != undefined)
      // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
      .map(([key, value]) => styles[`${propMap[key]}_${String(value).replace(".", "")}`])
  );
};

export type FrameProps = Props;
export default Frame;
