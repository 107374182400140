/* istanbul ignore file */
/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import { useState } from "react";
// eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
import { useStore } from "../../../store";
import { useXsellActionsCall } from "../../hooks/useXsellActionsCall";
import { useEventsTracking } from "../../hooks/useEventsTracking";
import useIsInViewport from "../../../hooks/useIsInViewport";
import useLoader from "../../hooks/useLoader";

const DesktopCardsList = () => {
  // eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
  const store = useStore();
  const loader = useLoader("desktopCards");

  const { crossSell: carouselData, isLoading } = store.crossSell;
  const [currentElement] = useState<null>(null);
  const isCardsListInViewport = useIsInViewport(currentElement, [carouselData?.accommodations], { threshold: 0.33 });
  const NUMBER_OF_RESULTS = 3;

  useXsellActionsCall("webList", NUMBER_OF_RESULTS);
  useEventsTracking(isCardsListInViewport, "webList");

  if (isLoading) {
    return loader;
  }

  return null;
};

export default DesktopCardsList;
