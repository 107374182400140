import React, { useCallback } from "react";
import { Button } from "@bookingcom/bui-react";
import useSegment from "./useSegment.js";
import { t } from "@bookingcom/lingojs-core";
import { ADD_DATA_UI_NAME, LOCATION_DATA_UI_NAME, MULTISTOP_MAX_SEGMENT_LENGTH } from "../../utils/constants.js";
const AddSegment = (props) => {
    const { addSegment, segments, i18n } = useSegment();
    const disabled = segments.length === MULTISTOP_MAX_SEGMENT_LENGTH;
    const handleOnClick = useCallback(() => {
        addSegment();
        setTimeout(() => {
            const inputList = document.querySelectorAll(`[data-ui-name^='${LOCATION_DATA_UI_NAME.from}']`);
            const input = inputList[inputList.length - 1];
            if (!input)
                return;
            input.focus();
        }, 1);
    }, [addSegment]);
    const buttonPtops = {
        ...props,
        disabled,
        text: i18n.trans(t("flights_add_flight_action")),
        onClick: handleOnClick,
        size: "large",
        attributes: {
            "data-ui-name": ADD_DATA_UI_NAME
        }
    };
    return React.createElement(Button, { ...buttonPtops });
};
export default AddSegment;
