/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React from "react";
import { Sidebar } from "../../Container";
import SearchTabs from "../../SearchTabs";
import { SkeletonFlightCard } from "../SkeletonFlightCard";
import { SkeletonListKeyValue } from "../SkeletonListKeyValue";
import { SearchLoadingMessage } from "components/elements/SearchLoadingMessage";
import { trackExperiment } from "utils/et";
import Frame from "components/elements/Frame";

interface Props {
  text?: string;
}

export const SkeletonSearchContent = ({ count = 4 }: { count?: number }) => {
  return (
    <React.Fragment>
      <SearchLoadingMessage />
      {Array.from({ length: count }).map((_, i) => (
        <Frame key={i} mt={3} mb={3}>
          <SkeletonFlightCard />
        </Frame>
      ))}
    </React.Fragment>
  );
};

const SkeletonSearchDesktop: React.FunctionComponent<Props> = () => {
  return (
    <Frame direction="row">
      {/* START: Left Column */}
      {!trackExperiment("flights_web_ddot_sr_wide_page_layout") ? (
        <Sidebar pt={4}>
          <Frame p={4}>
            <SkeletonListKeyValue amount={12} />
          </Frame>
        </Sidebar>
      ) : null}
      {/* END: Left Column */}
      {/* START: Right Column */}
      <Frame grow={1} pt={4} pb={0} pl={6}>
        {!trackExperiment("flights_web_ddot_sr_wide_page_layout") ? (
          <>
            {!trackExperiment("flights_web_ddot_side_sorting") &&
            !trackExperiment("flights_web_ddot_side_sorting_2") ? (
              <SearchTabs showBestRankingInfo fill />
            ) : null}
          </>
        ) : null}
        <SkeletonSearchContent />
      </Frame>
      {/* END: Right Column */}
    </Frame>
  );
};

export default SkeletonSearchDesktop;
