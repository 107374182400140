/**
 * =============================================================================
 * crosssell_solutions_connected_navigation_poc_web ============================
 * =============================================================================
 */
import { useCallback } from "react";
import { flightsSessionStore } from "../utils/session-store";
import useRouteName from "./useRouteName";
import { trackGoalWithValue, trackCustomGoal } from "../utils/et";
import { SBState } from "@bookingcom/flights-searchbox";

export default function useTrackExpConnectedNavigation() {
  const routeName = useRouteName();
  const sessionKeyName = "flights_exp_connected_navigation";

  const formatData = useCallback((state: SBState) => {
    const destination = state.searchSegments.map((s) => s.to.map((_) => _.code + _.type).toString()).toString();
    const dates = state.searchSegments.map((_) => (_.departureDate || "") + (_.returnDate || "")).toString();
    return { destination, dates };
  }, []);

  const setInitState = useCallback(
    (searchState?: SBState) => {
      if (!searchState || routeName !== "home" || !flightsSessionStore) return;
      const timestamp = new Date().getTime();
      flightsSessionStore.set(sessionKeyName, { ...formatData(searchState), timestamp });
    },
    [routeName, formatData]
  );

  const getInitState = useCallback(() => {
    if (routeName !== "home" || !flightsSessionStore) return undefined;
    return flightsSessionStore.get(sessionKeyName) || undefined;
  }, [routeName]);

  const trackOnSearch = useCallback(
    (searchState: SBState) => {
      if (!searchState || routeName !== "home") return;
      const initData = getInitState();
      if (!initData) return;
      const timeDiff = Math.ceil((new Date().getTime() - initData.timestamp) / 1e3);
      const searchData = formatData(searchState);
      if (searchData.destination !== initData.destination && !!initData.destination) {
        trackCustomGoal("crosssell_solutions_connected_navigation_poc_web", 1); // distenation change
      }
      if (searchData.dates !== initData.dates && !!initData.dates) {
        trackCustomGoal("crosssell_solutions_connected_navigation_poc_web", 2); // dates change
      }
      trackCustomGoal("crosssell_solutions_connected_navigation_poc_web", 4); // search click index page
      if (!!initData.timestamp) trackGoalWithValue("flights_landing_to_search_click_time", timeDiff); // time to click search from landing
    },
    [routeName, formatData, getInitState]
  );

  return { setInitState, trackOnSearch };
}
