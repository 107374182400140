import { Fragment, useEffect, useState } from "react";
import { ComponentDefinition } from "@bookingcom/remote-component";
import { RemoteComponentReactOptions } from "@bookingcom/remote-component/src/react";

export default function useFakeRemoteComponent(
  componentDefinitionArgument: ComponentDefinition, // eslint-disable-line  @typescript-eslint/no-unused-vars
  options?: RemoteComponentReactOptions // eslint-disable-line  @typescript-eslint/no-unused-vars
) {
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 0);
  }, []);
  return { Component: Fragment, isLoading, error: false };
}
