import React, { forwardRef } from "react";
import { Icon, Text } from "@bookingcom/bui-react";
import useOverview from "./useOverview.js";
import { MagnifyingGlassFillIcon } from "@bookingcom/bui-assets-react/streamline/index.js";
import styles from "./OverviewVertical.module.css";
import { ShellButton } from "../Shell/index.js";
const OverviewVerticalWithRef = forwardRef(function OverviewVertical(props, ref) {
    const { lineOne, lineTwo, ariaLabel } = useOverview();
    return (React.createElement(ShellButton, { ...props, ref: ref, alignIcon: "center", icon: React.createElement(Icon, { size: "medium", svg: MagnifyingGlassFillIcon, ariaLabel: ariaLabel }) },
        React.createElement(Text, { variant: "strong_2", className: styles.row },
            React.createElement("span", { className: styles.ellipsis }, lineOne)),
        React.createElement(Text, { variant: "small_1", className: styles.row },
            React.createElement("span", { className: styles.ellipsis }, lineTwo))));
});
export default OverviewVerticalWithRef;
