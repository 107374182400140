import { useCallback } from "react";
import useTrackerContext from "./useTrackerContext";

//sends a route change event configured in google tag manager
export default function useGTMRouteChangeEvent() {
  const { trackExternal } = useTrackerContext();
  return useCallback(() => {
    trackExternal({
      type: "gtm-route-change",
      args: [
        {
          event: "route-change"
        }
      ]
    });
  }, [trackExternal]);
}
