import React, { HTMLAttributes, useMemo } from "react";
import styles from "./FloatingPanel.module.css";
import { mcn } from "utils/mergeClassnames";

type Props = {
  align?: "top" | "bottom";
  className?: string;
  children?: React.ReactNode;
  fixed?: boolean;
  sticky?: boolean;
  style?: React.CSSProperties;
  attributes?: HTMLAttributes<HTMLDivElement>;
};

let n = 0;

const FloatingPanel = (props: Props) => {
  const zIndex = useMemo(() => {
    if (n === 199) {
      n = 0;
    }
    return 200 + n++;
  }, []);

  const position = props.align === "top" ? { top: "0" } : { bottom: "0" };
  if (props.fixed) {
    const ELASTIC_SCROLL_GAP = 100;
    Object.assign(position, {
      position: "fixed",
      paddingBottom: ELASTIC_SCROLL_GAP,
      marginBottom: -ELASTIC_SCROLL_GAP,
      zIndex
    });
  }
  return (
    <div
      {...props.attributes}
      className={mcn(styles.floatingPanel, props.sticky && styles.sticky, props.className, "floating-panel")}
      style={{ ...position, ...props.style }}
    >
      {props.children}
    </div>
  );
};
export default FloatingPanel;
