import { Actions, ActionTypes } from "./actions";
import { State, getInitialState } from "./state";

const initialState = getInitialState();

const reducer = (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case ActionTypes.set:
      return {
        ...state,
        sanctions: action.payload.sanctions
      };

    case ActionTypes.reset:
      return getInitialState();

    default:
      return state;
  }
};

export { reducer, getInitialState };
