import { StoreState } from "store/index";

export const getSearchSegments = (state: StoreState) => state.search.searchSegments;

export const getSearchType = (state: StoreState) => state.search.type;

export const getNoOfAdults = (state: StoreState) => state.search.adults;

export const getNoOfChildren = (state: StoreState) => state.search.children.length;

export const getArrivalCountryName = (state: StoreState) =>
  state.search.searchSegments[0].to[state.search.searchSegments[0].to.length - 1].countryName;

export const getArrivalPlace = (state: StoreState) => {
  const arrivalPlace = state.search.searchSegments[0].to[state.search.searchSegments[0].to.length - 1];
  return { name: arrivalPlace.name, type: arrivalPlace.type };
};

export const getLastReturnDate = (state: StoreState) =>
  state.search.searchSegments[state.search.searchSegments.length - 1].returnDate;

export const getType = (state: StoreState) => state.search.type;
