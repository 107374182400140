import React from "react";
import useModalDialog from "hooks/useModalDialog";
import { Card, Stack, Text, Timeline } from "@bookingcom/bui-react";
import { PlaneTripIcon, TicketIcon, TravelCreditIcon } from "@bookingcom/bui-assets-react/streamline/index.js";
import { Divider } from "@bookingcom/bui-react";
import { UICampaignBanner } from "@flights/types";
import { ItemProps } from "@bookingcom/bui-react/components/Timeline/Timeline.types";
import { I18nChildContext, t, useI18n } from "@bookingcom/lingojs-react";
import styles from "./GeniusTCModal.module.css";

interface GeniusTCModalProps {
  isActive: boolean;
  campaignBanner: UICampaignBanner;
  onModalClose?: VoidFunction;
}

const GeniusTCModal = (props: GeniusTCModalProps) => {
  const { isActive, onModalClose, campaignBanner } = props;
  const i18n = useI18n() as I18nChildContext;
  const Modal = useModalDialog();

  return (
    <Modal
      active={isActive}
      onClose={onModalClose}
      closeAriaLabel={i18n.trans(t("a11y_flights_deal_banner_detail_close"))}
    >
      <GeniusTC isActive={isActive} campaignBanner={campaignBanner} />
    </Modal>
  );
};

const GeniusTC = (props: GeniusTCModalProps) => {
  const { campaignBanner } = props;

  const timeLineItems: ItemProps[] = [
    {
      children: (
        <Text tagName="div" variant="body_2">
          {campaignBanner.action.timeline?.firstStepText}
        </Text>
      ),
      marker: TicketIcon,
      markerColor: "brand_primary",
      lineVariant: "dashed"
    },
    {
      children: (
        <Text tagName="div" variant="body_2">
          {campaignBanner.action.timeline?.secondStepText}
        </Text>
      ),
      marker: TravelCreditIcon,
      markerColor: "brand_primary",
      lineVariant: "dashed"
    },
    {
      children: (
        <Text tagName="div" variant="body_2">
          {campaignBanner.action.timeline?.thirdStepText}
        </Text>
      ),
      marker: PlaneTripIcon,
      markerColor: "brand_primary",
      lineVariant: "dashed"
    }
  ];

  return (
    <Card className={styles.modalWrapper}>
      <Stack direction="column" justifyContent="start">
        <Text tagName="div" variant="strong_1" className={styles.title} data-testid="tc-banner-header">
          {campaignBanner.action.title}
        </Text>
        {campaignBanner.action.subtitle && (
          <Text tagName="div" variant="small_1" className={styles.description} data-testid="tc-banner-subheader">
            {campaignBanner.action.subtitle}
          </Text>
        )}
        {campaignBanner.action.timeline && (
          <Timeline>
            {timeLineItems.map((timeLineItem, index) => {
              return <Timeline.Item {...timeLineItem} key={index} />;
            })}
          </Timeline>
        )}
        <Divider />
        <Text tagName="div" variant="strong_1" className={styles.title} data-testid="tc-banner-header">
          {campaignBanner.action.contentTitle}
        </Text>
        <ul className={styles.terms}>
          {campaignBanner.action.content.map((contentItem) => {
            return contentItem.itemType === "BULLET" ? (
              <li className={styles.termsList}>{contentItem.text}</li>
            ) : (
              <Text
                tagName="p"
                variant="body_1"
                className={styles.contentDescription}
                data-testid="tc-banner-subheader"
              >
                {contentItem.text}
              </Text>
            );
          })}
        </ul>
      </Stack>
    </Card>
  );
};

export default GeniusTCModal;
