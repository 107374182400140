import React, { useContext } from "react";
import { Badge, Icon, ListItem, Stack, Text } from "@bookingcom/bui-react";
import { ArrowNavRightIcon, PlaneTripInternationalIcon } from "@bookingcom/bui-assets-react/streamline/index.js";
import { t } from "@bookingcom/lingojs-core";
import styles from "./Anywhere.module.css";
import { SearchBoxContext } from "../SearchBoxContext/index.js";
import { ANYWHERE_LOCATION } from "../../utils/constants.js";
const Anywhere = ({ add }) => {
    const { searchType, i18n } = useContext(SearchBoxContext);
    if (searchType === "MULTISTOP") {
        return null;
    }
    const handleSelection = () => {
        add({ ...ANYWHERE_LOCATION, code: i18n.trans(t("flights_search_destination_list_item_anywhere")) });
    };
    return (React.createElement(Stack, { direction: "column", className: styles.wrapper, gap: 0 },
        React.createElement(Stack.Item, null,
            React.createElement(Text, { variant: "emphasized_2", color: "neutral_alt" }, i18n.trans(t("flights_search_destination_list_header")))),
        React.createElement(Stack.Item, null,
            React.createElement(ListItem, { icon: React.createElement(Icon, { svg: PlaneTripInternationalIcon, size: "small" }), endSlot: React.createElement(Icon, { svg: ArrowNavRightIcon, size: "medium" }), onClick: handleSelection, className: styles.selection },
                React.createElement(Stack, { direction: "row", alignItems: "center" },
                    React.createElement(Text, { variant: "strong_2" }, i18n.trans(t("flights_search_destination_list_item_anywhere"))),
                    React.createElement(Badge, { text: i18n.trans(t("flights_searchbox_fly_anywhere_badge_new")), variant: "constructive" }))))));
};
export default Anywhere;
