import React, { useEffect, useMemo } from "react";
import { useRemoteComponent } from "@bookingcom/remote-component";
import useFakeRemoteComponent from "./useFakeRemoteComponent";
import { trackCustomGoal, trackExperiment, trackExperimentStage, trackGoalWithValue } from "utils/et";
import useUserAgent from "hooks/useUserAgent";
import useEvents from "hooks/useEvents";

const AppleCardSR = () => {
  const startTime = useMemo(() => performance.now(), []);

  const { isMobile } = useUserAgent();
  const { grumble } = useEvents();

  const isAppleCardExp = !!trackExperiment("flights_web_cat_apple_rc_tech");
  const useRC = isAppleCardExp ? useRemoteComponent : useFakeRemoteComponent;

  useEffect(() => {
    trackExperimentStage("flights_web_cat_apple_rc_tech", 1);
    trackExperimentStage("flights_web_cat_apple_rc_tech", isMobile ? 3 : 2);
  }, [isMobile]);

  const { Component, isLoading, error } = useRC({
    project: "web-shell",
    service: "header-mfe",
    path: "/PromotionalBanner/Flights/SearchResults",
    type: "capla"
  });

  useEffect(() => {
    if (!isLoading && !error) {
      const endTime = performance.now();
      trackGoalWithValue("flights_web_cat_apple_rc_tech_load_time", Math.round(endTime - startTime));
    }
  }, [startTime, isLoading, error]);

  useEffect(() => {
    if (error) {
      trackCustomGoal("flights_web_cat_apple_rc_tech", 1);
      grumble("Remote component error: Apple card on SR page", { error });
    }
  }, [error, grumble]);

  return <Component />;
};

export default AppleCardSR;
