export function assert<T>(condition: T, msg: string = "Assertion failed"): asserts condition {
  if (!condition) {
    throw new Error(msg);
  }
}

export function invariant<T>(input: T, msg?: string): asserts input {
  // eslint-disable-next-line no-restricted-syntax -- this line was auto generated, hence fix the issue timely
  assert(input !== undefined && input !== null, msg);
}
