import { createContext } from "react";
import { StoreState } from "../../store";

export type StaticContextType = {
  initialState?: Function;
  statusCode?: number;
  getInitialState?: Promise<StoreState>;
  set?: (props: StaticContextType) => void;
};

const StaticContext = createContext<StaticContextType>({});

export default StaticContext;
