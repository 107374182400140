/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React from "react";
import { Text } from "@bookingcom/bui-react";
import { t } from "@bookingcom/lingojs-core";
import { I18nChildContext, useI18n } from "@bookingcom/lingojs-react";
import { useTheme } from "@bookingcom/bui-react";
import useGlobalContext from "../../../hooks/useGlobalContext";
import ErrorAlert from "../ErrorAlert";

type GenericErrorReportProps = {
  error?: any;
  text?: string;
};

const GenericErrorReport: React.FunctionComponent<GenericErrorReportProps> = (props) => {
  const { env } = useGlobalContext();
  const i18n = useI18n() as I18nChildContext;

  const theme = useTheme();

  return (
    <ErrorAlert
      title={i18n.trans(t("flights_error_unknown_header"))}
      buttonLabel={i18n.trans(t("flights_search_again_action"))}
      onClick={() => (location.href = "/")}
    >
      <Text variant="body_2">{i18n.trans(t("flights_error_unknown_subheader"))}</Text>
      {env !== "prod" && !!props.error && (
        <>
          <pre style={{ padding: theme.units.spacing_2x, color: theme.colors.color_foreground_alt }}>
            {JSON.stringify(props.error, null, 2)}
          </pre>
          <Text variant="small_1">{"This error block is not visible on prod"}</Text>
        </>
      )}
    </ErrorAlert>
  );
};

export default GenericErrorReport;
