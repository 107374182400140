import { OCCUPANCY_INFANT_AGE, OCCUPANCY_MAX_ALLOWED_PASSENGERS } from "./constants.js";
export const validateSegment = (segment, idx, type) => {
    // make sure segments data is valid
    const isDiffOriginDestination = segment.from.every((from) => {
        return segment.to.every((to) => to.code !== from.code);
    });
    const isValidFrom = !!segment.from.length;
    const isValidTo = !!segment.to.length;
    const isValidDates = type === "ROUNDTRIP" ? !!segment.departureDate && !!segment.returnDate : !!segment.departureDate;
    if (idx !== 0 && !isValidFrom && !isValidTo && !isValidDates) {
        return true; // skip empty segments
    }
    else {
        return isDiffOriginDestination && isValidFrom && isValidTo && isValidDates;
    }
};
export const validateOccupancy = (occupancy) => {
    // make sure occupancy data is valid
    const { adults, children } = occupancy;
    if (!adults || adults < 1)
        return false;
    if (children.some((a) => a === undefined || a === null))
        return false;
    const infants = children.filter((a) => a < OCCUPANCY_INFANT_AGE).length;
    if (infants > adults)
        return false;
    if (adults + children.length > OCCUPANCY_MAX_ALLOWED_PASSENGERS)
        return false;
    return true;
};
