import { useClientFetch } from "hooks/useClientFetch";
import usePointOfSale from "hooks/usePointOfSale";
import { useDispatch, useSelector } from "react-redux";
import { getFetchStatus } from "store/esim/selectors";
import { getSearchSegments, getType } from "store/search/selectors";
import { getGeniusLevel, getUserEmail } from "store/user/selectors";
import flights_ace_web_esim_banner from "utils/experiments/ace/flights_ace_web_esim_banner";
import { actions as esimActions } from "../../../store/esim/actions";
import { getFlightSegments, getOrderId, getConfirmedAt } from "store/order/selectors";
import { UITripType } from "@flights/types";
import useContextName from "hooks/useContextName";

export const useEsimCheck = () => {
  const dispatch = useDispatch();
  const userEmail = useSelector(getUserEmail);
  const esimFetchStatus = useSelector(getFetchStatus);
  const geniusLevel = useSelector(getGeniusLevel)?.toString();
  const searchSegments = useSelector(getSearchSegments);
  const tripType = useSelector(getType);
  const pointOfSale = usePointOfSale();
  const arrivalCountryName = searchSegments[0]?.to[searchSegments[0]?.to.length - 1]?.countryName;
  const arrivalPlace = searchSegments[0]?.to[searchSegments[0]?.to.length - 1];
  const lastDepartureDate =
    tripType === "ROUNDTRIP" ? searchSegments[searchSegments.length - 1].returnDate : searchSegments[0].departureDate;
  const params = new URLSearchParams({});
  const contextName = useContextName();

  useClientFetch(`/api/esimEligibility/check/?${params.toString()}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Flights-Context-Name": contextName
    },
    body: JSON.stringify({
      pointOfSale,
      arrivalCountryName,
      geniusLevel,
      lastDepartureDate,
      tripType,
      arrivalPlaceType: arrivalPlace?.type,
      arrivalPlaceName: arrivalPlace?.name
    }),
    condition: () =>
      !!userEmail && esimFetchStatus === "initial" && !!flights_ace_web_esim_banner.trackWithDefaultStage(),
    fetch: () => dispatch(esimActions.fetch()),
    success: (data) => dispatch(esimActions.fetchSuccess(data)),
    error: (data) => dispatch(esimActions.fetchError(data))
  });
};

export const useEsimGenerateLink = () => {
  const dispatch = useDispatch();
  const userEmail = useSelector(getUserEmail);
  const esimFetchStatus = useSelector(getFetchStatus);
  const geniusLevel = useSelector(getGeniusLevel);
  const flightSegments = useSelector(getFlightSegments);
  const pointOfSale = usePointOfSale();
  const orderId = useSelector(getOrderId);
  const orderConfirmedAt = useSelector(getConfirmedAt);
  const contextName = useContextName();

  const arrivalCountryName = flightSegments?.[0]?.arrivalAirport.countryName;
  const arrivalPlace = flightSegments?.[0]?.arrivalAirport;
  const tripType: UITripType =
    flightSegments && flightSegments.length > 0 && flightSegments.length === 1
      ? "ONEWAY"
      : flightSegments?.length === 2
      ? "ROUNDTRIP"
      : "MULTISTOP";
  const lastDepartureDate =
    tripType === "ROUNDTRIP"
      ? flightSegments?.[flightSegments.length - 1].arrivalTime
      : flightSegments?.[0].departureTime;
  const params = new URLSearchParams({});

  useClientFetch(`/api/esimEligibility/generateLink/?${params.toString()}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Flights-Context-Name": contextName
    },
    body: JSON.stringify({
      orderId,
      orderConfirmedAt,
      pointOfSale,
      arrivalCountryName,
      arrivalPlace,
      tripType,
      geniusLevel,
      arrivalPlaceType: arrivalPlace?.type,
      arrivalPlaceName: arrivalPlace?.name,
      lastDepartureDate
    }),
    condition: () =>
      !!userEmail && esimFetchStatus === "initial" && !!flights_ace_web_esim_banner.trackWithDefaultStage(),
    fetch: () => dispatch(esimActions.fetch()),
    success: (data) => dispatch(esimActions.fetchSuccess(data)),
    error: (data) => dispatch(esimActions.fetchError(data))
  });
};

export const useEsimGetLink = () => {
  const dispatch = useDispatch();
  const userEmail = useSelector(getUserEmail);
  const esimFetchStatus = useSelector(getFetchStatus);
  const geniusLevel = useSelector(getGeniusLevel);
  const orderId = useSelector(getOrderId);
  const params = new URLSearchParams({});
  const contextName = useContextName();

  useClientFetch(`/api/esimEligibility/getLink/?${params.toString()}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Flights-Context-Name": contextName
    },
    body: JSON.stringify({
      orderId,
      geniusLevel
    }),
    condition: () =>
      !!userEmail && esimFetchStatus === "initial" && !!flights_ace_web_esim_banner.trackWithDefaultStage(),
    fetch: () => dispatch(esimActions.fetch()),
    success: (data) => dispatch(esimActions.fetchSuccess(data)),
    error: (data) => dispatch(esimActions.fetchError(data))
  });
};
