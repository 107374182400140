import React from "react";
import ChangeFlightSegmentHorizontal from "../Segment/ChangeFlightSegmentHorizontal.js";
import { SearchBoxContext, SearchboxController } from "../SearchBoxContext/index.js";
import YellowBox from "./YellowBox.js";
import { Submit } from "../Submit/index.js";
import styles from "./SearchBoxHorizontalDefault.module.css";
const SearchBoxChangeFlightHorizontal = () => {
    const { hideShadow } = React.useContext(SearchBoxContext);
    return (React.createElement("div", { className: styles.wrapper },
        React.createElement("div", { className: styles.line },
            React.createElement(YellowBox, { hideShadow: hideShadow },
                React.createElement("div", { className: styles.yellowInner },
                    React.createElement(ChangeFlightSegmentHorizontal, null),
                    " ",
                    React.createElement(Submit, null))))));
};
export default function SearchBoxChangeFlightHorizontalDefault() {
    return (React.createElement(SearchboxController, null,
        React.createElement(SearchBoxChangeFlightHorizontal, null)));
}
