import { ETTrackingRequest, ETTrackingType } from "@flights/types";
import { flightsSessionStore } from "./session-store";
import * as debugTrackingNames from "./experiments/debugTrackingNames";

const isInternal = process.env.BUILD_TARGET === "client" && !!window?.__GLOBAL_CONTEXT__?.isInternal;
const watching = new Set<string>();

const colors: Record<ETTrackingType, string> = {
  stage: "green",
  customGoal: "orange",
  goal: "yellow",
  goalWithValue: "red"
};

const loggedStages = new Set();

const init = () => {
  const initial = (flightsSessionStore.get("watchEt") || "").split(",");

  for (const name of initial) {
    if (!name) continue;
    add(name);
  }
};

export const remove = (name: string) => {
  if (!isInternal) return;

  watching.delete(name);
  save();
};

export const add = (name: string) => {
  if (!isInternal) return;

  watching.add(name);
  save();
};

export const toggle = (name: string) => {
  if (watching.has(name)) {
    remove(name);
  } else {
    add(name);
  }
};

export const getAll = () => Array.from(watching.values());

export const log = (events: ETTrackingRequest[]) => {
  if (!isInternal) return;

  for (const event of events) {
    if (!watching.has(event.name)) continue;

    // Only log stages once
    if (event.type === "stage") {
      const key = event.name + event.value;
      if (loggedStages.has(key)) continue;
      loggedStages.add(key);
    }

    const name = debugTrackingNames.get(event.name, event.type, event.value);

    // eslint-disable-next-line no-console
    console.log(
      `%c[${event.type}]%c ${event.name}${event.value ? ": %c" + event.value : ""}${name ? ` (${name})` : ""}`,
      `font-weight: bold; color: ${colors[event.type]}`,
      "font-weight: normal;",
      "font-weight: bold"
    );
  }
};

const save = () => {
  flightsSessionStore.set("watchEt", getAll().join(","));
};

export const dispatchName = (tag: string) => {
  if (!isInternal) return;
  const e = new CustomEvent("exp-tag-name-tracked", { detail: tag });
  document.dispatchEvent(e);
};

export const clearCache = () => {
  loggedStages.clear();
};

if (isInternal) {
  init();
}
