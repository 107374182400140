import { type Request } from "express";
import { type CopyExperimentParams } from "@bookingcom/lingojs-experiments";
import { type UIPointOfSale } from "@flights/types";

export const getCopyExperimentParamsServer = (req: Request): CopyExperimentParams => {
  const countries = !!req.geoIP?.countryCode ? [req.geoIP?.countryCode] : undefined;
  const siteTypesArr: CopyExperimentParams["siteTypes"] = [];
  if (!!req.useragent?.isDesktop) siteTypesArr.push("www");
  if (!!req.useragent?.isMobile) siteTypesArr.push("mdot");
  if (!!req.useragent?.isTablet) siteTypesArr.push("tdot");
  if (!!req.isAndroidApp) siteTypesArr.push("android");
  if (!!req.isIOSApp) siteTypesArr.push("iphone");
  const siteTypes = siteTypesArr.length ? siteTypesArr : undefined;
  return { countries, siteTypes };
};

export const getCopyExperimentParamsClinet = (ipCountry?: UIPointOfSale, isMobile?: boolean): CopyExperimentParams => {
  const countries = !!ipCountry ? [String(ipCountry)] : undefined;
  const siteTypesArr: CopyExperimentParams["siteTypes"] = [];
  if (!isMobile) siteTypesArr.push("www");
  if (!!isMobile) siteTypesArr.push("mdot");
  const siteTypes = siteTypesArr.length ? siteTypesArr : undefined;
  return { countries, siteTypes };
};
