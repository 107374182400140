import { useCallback } from "react";
import { useSelector } from "react-redux";
import { trackExperiment, trackExperimentStage } from "../utils/et";
import useRouteName from "./useRouteName";
import usePointOfSale from "./usePointOfSale";
import { getCartSupplyType } from "store/cartDetails/selectors";
import { getOrderSupplierType } from "store/order/selectors";

const useTrackMPFlightsTraffic = () => {
  const pointOfSale = usePointOfSale();
  const routeName = useRouteName();
  const expName = "mp_flights_insurance_traffic_aa";
  const isUK = pointOfSale === "gb";

  const isCheckoutAndPayPage = routeName === "checkout3";
  const isPaxPage = routeName === "checkout";
  const isExtrasPage = routeName === "checkout2";
  const isConfirmationPage = routeName === "confirmation";
  const cartSupplyType = useSelector(getCartSupplyType);
  const orderSupplierType = useSelector(getOrderSupplierType);

  const supplyType = isConfirmationPage ? orderSupplierType : cartSupplyType;
  const isMerchantTraffic = supplyType === "MERCHANT";

  const trackStages = useCallback(() => {
    trackExperiment(expName);

    if (isUK && isMerchantTraffic) {
      if (isPaxPage) {
        trackExperimentStage(expName, 1);
      } else if (isExtrasPage) {
        trackExperimentStage(expName, 2);
      } else if (isCheckoutAndPayPage) {
        trackExperimentStage(expName, 3);
      } else if (isConfirmationPage) {
        trackExperimentStage(expName, 4);
      }
    }
  }, [isCheckoutAndPayPage, isConfirmationPage, isExtrasPage, isPaxPage, isMerchantTraffic, isUK]);

  return {
    trackStages
  };
};

export default useTrackMPFlightsTraffic;
