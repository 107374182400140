export const SEARCH_DATE_FORMAT = "yyyy-MM-dd";
export const initialSearchSegment = {
    from: [],
    to: [],
    departureDate: undefined,
    returnDate: undefined,
    /* This is purely to help tracking, remove if fullon */
    fareRange: undefined
    /* ------------------------------------------------- */
};
export const initialFareCalendar = {
    fetchStatus: "initial",
    dateFares: [],
    error: undefined
};
export const getInitialState = () => ({
    type: "ROUNDTRIP",
    searchSegments: [initialSearchSegment],
    adults: 1,
    children: [],
    cabinClass: "ECONOMY",
    isDirect: false,
    fareCalendar: initialFareCalendar,
    initial: true
});
