/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React from "react";
import { Actionable, Badge, Card, Icon, Stack, Text, Title } from "@bookingcom/bui-react";
import GeniusBadge from "@bookingcom/bui-assets-react/genius/GeniusBadge";
import { useI18n } from "@bookingcom/lingojs-react";
import { t } from "@bookingcom/lingojs-core";
import { CheckmarkIcon, GeoPinIcon } from "@bookingcom/bui-assets-react/streamline/index.js";
import { UIXCardProps } from "../../../types";
import { useStarsRating } from "../../../hooks/useStarsRating";
import styles from "./MobileAccommodationCard.module.css";

const MobileAccommodationCard = (props: UIXCardProps) => {
  const i18n = useI18n();

  const { accommodation, searchParams } = props;
  const stars = useStarsRating(accommodation);
  const PAY_WITH_WALLET_TEXT = i18n.trans(t("a11y_flights_xsell_pb_modal_pay_with_wallet_label"));
  const photoUrl = accommodation.photo.replace("square200", "120x238");
  const hasSamePrice = accommodation.formattedInitialPrice
    ? accommodation.formattedInitialPrice === accommodation.formattedDisplayPrice
    : true;

  const handleClick = () => {
    if (props?.onCardClick) {
      props?.onCardClick();
    }
  };

  return (
    <Actionable href={accommodation.propertyTarget.target} onClick={handleClick} className={styles.actionable}>
      <Card fill className={styles.card}>
        <Stack direction="row" gap={0} alignItems="stretch" className={styles.wrapper}>
          <Stack.Item
            attributes={{
              style: {
                backgroundImage: `url(${photoUrl})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                width: 120
              }
            }}
          />
          <Stack.Item className={styles.details}>
            <Stack direction="column" className={styles.wrap}>
              <Title title={accommodation.name} variant="strong_2" />

              <Stack direction="row" className={styles.infoRow}>
                {accommodation.accommodationType ? (
                  <Stack.Item>
                    <Text variant="small_1" color="neutral">
                      {accommodation.accommodationType}
                    </Text>
                  </Stack.Item>
                ) : null}
                {stars ? (
                  <Stack.Item
                    className={styles.starsWrapper}
                    attributes={{
                      "aria-label": accommodation.starRatingData?.value
                        ? i18n.trans(
                            t("flights_pb_accessibility_star_rating_label", {
                              variables: {
                                num_star: accommodation.starRatingData.value,
                                num_exception: accommodation.starRatingData.value
                              }
                            })
                          )
                        : ""
                    }}
                  >
                    {stars}
                  </Stack.Item>
                ) : null}
                {accommodation.persuasionBlock && accommodation.persuasionBlock.isAd && (
                  <Stack.Item
                    className={styles.adBadge}
                    attributes={{
                      "data-testid": "accommodation-stars"
                    }}
                  >
                    <Badge text={i18n.trans(t("flights_xsell_pb_carousel_sponsored_ad_badge"))} variant="outline" />
                  </Stack.Item>
                )}
                {accommodation.geniusProperty ? (
                  <Stack.Item
                    className={styles.geniusTag}
                    attributes={{
                      "aria-label": i18n.trans(t("flights_pb_accessibility_genius_badge_label"))
                    }}
                  >
                    <GeniusBadge />
                  </Stack.Item>
                ) : null}
                {accommodation.acceptsWalletCredits && (
                  <Stack.Item
                    className={styles.payWallet}
                    attributes={{
                      "data-testid": "accommodation-pay-with-wallet"
                    }}
                  >
                    <Badge className={styles.payWalletBadge} text={PAY_WITH_WALLET_TEXT} variant="brand-primary" />
                  </Stack.Item>
                )}
              </Stack>

              {accommodation.totalScore?.ratingScore && accommodation.totalScore?.numberReviews ? (
                <Stack direction="row" gap={0.5} className={styles.wrap}>
                  <Stack.Item>
                    <Text variant="small_1" color="neutral">
                      {accommodation.totalScore.ratingScore} {accommodation.totalScore.scoreDescription}
                    </Text>
                  </Stack.Item>
                  <Stack.Item className={styles.middot}>&middot;</Stack.Item>
                  <Stack.Item>
                    <Text variant="small_1" color="neutral_alt">
                      {i18n.trans(
                        t("flights_confirmation_genius_reviews", {
                          num_exception: accommodation.totalScore.numberReviews,
                          variables: { num_reviews: accommodation.totalScore.numberReviews }
                        })
                      )}
                    </Text>
                  </Stack.Item>
                </Stack>
              ) : null}

              {accommodation.distanceFromCentre ? (
                <Stack className={styles.distance} justifyContent="start" alignItems="center" direction="row">
                  <Icon className={styles.icon} color="neutral" size="smaller" svg={GeoPinIcon} />
                  <Text className={styles.distanceText} color="neutral_alt" variant="small_1">
                    {i18n.trans(
                      t("flights_xsell_pb_carousel_distance", {
                        variables: {
                          distance: accommodation.distanceFromCentre?.toString()
                        }
                      })
                    )}
                  </Text>
                </Stack>
              ) : null}

              <Stack direction="row" alignItems="center" gap={0.5}>
                {!hasSamePrice && accommodation.formattedInitialPrice && (
                  <Text variant="small_1" color="destructive" decoration="line-through">
                    {accommodation.formattedInitialPrice}
                  </Text>
                )}
                <Text variant="strong_1" color="neutral">
                  {accommodation.formattedDisplayPrice}
                </Text>
              </Stack>

              {accommodation.mealplanDescription ? (
                <Badge
                  text={accommodation.mealplanDescription}
                  ariaLabel={accommodation.mealplanDescription}
                  variant="constructive"
                  alternative={true}
                  className={styles.badge}
                />
              ) : null}

              {searchParams.numberGuests && searchParams.numberNights ? (
                <Text variant="small_1" color="neutral_alt">
                  {i18n.trans(
                    t("flights_pb_accessibility_guests_nights_label", {
                      num_exception: searchParams.numberGuests,
                      variables: { num_guests: searchParams.numberGuests }
                    })
                  )}{" "}
                  {i18n.trans(
                    t("flights_pb_accessibility_guests_nights_label_2", {
                      num_exception: searchParams.numberNights,
                      variables: { num_nights: searchParams.numberNights }
                    })
                  )}
                </Text>
              ) : null}

              {accommodation.hasFreeCancellation ? (
                <Stack justifyContent="start" alignItems="center" direction="row">
                  <Icon size="smaller" color="constructive" svg={CheckmarkIcon} />
                  <Stack.Item>
                    <Text color="constructive" variant="small_1">
                      {i18n.trans(t("flights_xsell_pb_carousel_free_cancellation"))}
                    </Text>
                  </Stack.Item>
                </Stack>
              ) : null}
            </Stack>
          </Stack.Item>
        </Stack>
      </Card>
    </Actionable>
  );
};

export default MobileAccommodationCard;
