import { useEffect, useState, useRef, RefObject } from "react";

type ObserverOptions = {
  root?: Element | Document | null;
  rootMargin?: string;
  threshold?: number | number[];
};

/**
 *  ? You can pass the Ref or the Ref.current
 * @example
 *  const bannerRef = useRef<HTMLDivElement>(null);
 *  const isBannerInViewport = useIsInViewport(bannerRef);
 *  <Banner ref={bannerRef}></Banner>
 */
export default function useIsInViewport(
  elem: HTMLElement | RefObject<HTMLElement> | null,
  triggers: Array<any> = [],
  options?: ObserverOptions
) {
  const [isInViewport, setIsInViewport] = useState(false);
  const observerRef = useRef<IntersectionObserver>();

  useEffect(() => {
    observerRef.current = new IntersectionObserver(([entry]) => setIsInViewport(entry.isIntersecting), options);
  }, [options]);

  useEffect(() => {
    if (!elem) return;
    if (!observerRef.current) return;

    const targetElem = elem instanceof HTMLElement ? elem : elem.current;

    if (!targetElem) return;
    observerRef.current.observe(targetElem);
    return () => {
      observerRef.current?.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elem, ...triggers]);

  return isInViewport;
}
