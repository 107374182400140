import React, { useEffect } from "react";
import useGlobalContext from "hooks/useGlobalContext";
import { FLOATING_PANEL_MARGIN, STICKY_FOOTER_SPACER_ID } from "../../../constants";
import { RemoteFooter } from "components/remote";
import scrollToTop from "utils/scrollToTop";
import Frame from "../Frame";
import styles from "./AppShell.module.css";

type Props = {
  header?: React.ReactNode;
  noPadding?: boolean;
  noInnerPadding?: boolean;
  minHeight?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  noMainWrapper?: boolean;
};

const AppShell: React.FunctionComponent<Props> = (props) => {
  const { noPadding = false, header, children, noMainWrapper } = props;
  const { ipCountry } = useGlobalContext();
  const isUK = ipCountry?.toLowerCase() === "gb";

  useEffect(scrollToTop, []);

  const content = <Frame className={styles.appShell}>{children}</Frame>;

  return (
    <div
      id="app-shell"
      className={styles.root}
      /*
        for the UK legal footer we have to add padding on all floating panel pages to show the message
        The logic is every uk page without a footer should show it even if we passed noPadding to it.
        So noPadding is actually not relevent to uk pages thus the check for !isUKs
      */
      style={{ paddingBottom: noPadding && !isUK ? undefined : FLOATING_PANEL_MARGIN }}
    >
      {!!header && <div id="app-header">{header}</div>}
      {noMainWrapper ? (
        content
      ) : (
        <main id="basiclayout" role="main">
          {content}
        </main>
      )}
      {/* This spacer will create space for a sticky footer, it receives its height from the useStickyFooterSpacerHeight hook */}
      <div id={STICKY_FOOTER_SPACER_ID} aria-hidden />
      <RemoteFooter />
    </div>
  );
};
export default AppShell;
