import { useCallback } from "react";
import { trackExperimentStage } from "utils/et";
import { isOfMetaOrigin } from "utils/marketing-url-params";
import useContextName from "./useContextName";
import useUserAgent from "./useUserAgent";

const useTrackExpRankerV3 = () => {
  const contextName = useContextName();
  const { isMobile } = useUserAgent();
  const expName = "flights_best_flights_score_bugfix";

  const trackStages = useCallback(() => {
    if (contextName !== "search_results") return;
    trackExperimentStage(expName, 3); // SR - main
    trackExperimentStage(expName, isMobile ? 5 : 4); // ddot & mdot
    if (isOfMetaOrigin()) trackExperimentStage(expName, isMobile ? 7 : 6); // meta - ddot & mdot
  }, [contextName, isMobile, expName]);

  return { trackStages };
};

export default useTrackExpRankerV3;
