import { t } from "@bookingcom/lingojs-core";
import { TranslationMessage, ArgumentsInterface } from "@bookingcom/lingojs-core/build/t";
import { UIOrderFuckedUpProductType } from "@flights/types";

export const ancillaryNames = (
  productType: UIOrderFuckedUpProductType,
  args?: ArgumentsInterface
): TranslationMessage => {
  switch (productType) {
    case "checkedInBaggage":
      return t("flights_ancillary_baggage_checked");

    case "cabinBaggage":
      return t("flights_ancillary_baggage_cabin");

    case "cabinBaggagePerTraveller":
      return t("flights_ancillary_baggage_cabin");

    case "flexibleTicket":
      return t("flights_ancillary_flexticket_name");

    case "mealChoice":
      return t("flights_ancillary_mealplan_name");

    case "seatingBesides":
      return t("flights_ancillary_seatstogether_name");

    case "travelInsurance":
      return t("flights_ancillary_travelinsurance_name");

    case "mobileTravelPlan":
      return t("flights_ancillary_mobileplan_name");

    case "seatingPreference":
      return t("flights_ancillary_seatlocation_name");

    case "cancelForAnyReason":
      // TODO:: Use specific copy tag for this name - https://jira.booking.com/jira/browse/APEX-262
      return t("flights_apex_cfar_heading");

    case "seatMapSelection":
      return t("flights_seatmap_dt_price_main", args);

    case "fastTrack":
      return t("flights_ace_fast_track_name");

    default:
      //Oops something's not right
      return t("flights_error_unknown_header");
  }
};
