import React, { FC, useCallback } from "react";
import { Button, Text } from "@bookingcom/bui-react";
import { I18nChildContext, useI18n } from "@bookingcom/lingojs-react";
import { t } from "@bookingcom/lingojs-core";
import useUserAgent from "hooks/useUserAgent";
import Frame from "components/elements/Frame";
import styles from "./AncillaryItem.module.css";

type Props = {
  icon: React.ReactElement;
  title: string | React.ReactElement;
  subtitle: string | React.ReactElement;
  cta?: () => void;
  ctaLabel?: string;
  ctaAriaLabel?: string;
  dataTestId?: string;
  boldTitle?: boolean;
  status?: React.ReactElement | null;
};

const AncillaryItem: FC<Props> = ({
  icon,
  title,
  subtitle,
  cta,
  ctaAriaLabel,
  ctaLabel,
  dataTestId,
  boldTitle,
  status
}) => {
  const i18n = useI18n() as I18nChildContext;
  const { isMobile } = useUserAgent();

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      cta?.();
    },
    [cta]
  );

  return (
    <>
      <Frame direction="row" alignItems="top" attributes={{ "data-testid": dataTestId }}>
        <Frame attributes={{ style: { flex: 1 } }} mt={2} mb={2}>
          {icon}
        </Frame>
        <Frame attributes={{ style: { flex: isMobile ? 10 : 15 } }}>
          <Text variant={boldTitle ? "strong_2" : "body_2"} attributes={{ "data-testid": "title" }}>
            {title}
          </Text>
          <Frame mt={1}>
            <Text variant="body_2" attributes={{ "data-testid": "subtitle" }}>
              {subtitle}
            </Text>
          </Frame>
          {cta && (
            <div className={styles.viewDetailsLink}>
              <Button.Aligner alignment="start">
                <Button
                  variant="tertiary"
                  text={ctaLabel || i18n.trans(t("flights_view_details_action"))}
                  onClick={handleClick}
                  attributes={{ "data-testid": "button_view_details", "aria-label": ctaAriaLabel }}
                />
              </Button.Aligner>
            </div>
          )}
          {status ?? null}
        </Frame>
      </Frame>
    </>
  );
};
export default AncillaryItem;
