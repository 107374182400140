// https://www.unicode.org/cldr/charts/42/supplemental/territory_information.html
const countryCodeStartDayMap = {
    af: 6,
    ax: 1,
    al: 1,
    dz: 6,
    as: 0,
    ad: 1,
    ao: 1,
    ai: 1,
    aq: 1,
    ag: 0,
    ar: 1,
    am: 1,
    aw: 1,
    ac: 1,
    au: 1,
    at: 1,
    az: 1,
    bs: 0,
    bh: 6,
    bd: 0,
    bb: 1,
    by: 1,
    be: 1,
    bz: 0,
    bj: 1,
    bm: 1,
    bt: 0,
    bo: 1,
    ba: 1,
    bw: 0,
    bv: 1,
    br: 0,
    io: 1,
    vg: 1,
    bn: 1,
    bg: 1,
    bf: 1,
    bi: 1,
    kh: 0,
    cm: 1,
    ca: 0,
    ic: 1,
    cv: 1,
    bq: 1,
    ky: 1,
    cf: 1,
    ea: 1,
    td: 1,
    cl: 1,
    cn: 1,
    cx: 1,
    cp: 1,
    cc: 1,
    co: 0,
    km: 1,
    cg: 1,
    cd: 1,
    ck: 1,
    cr: 1,
    ci: 1,
    hr: 1,
    cu: 1,
    cw: 1,
    cy: 1,
    cz: 1,
    dk: 1,
    dg: 1,
    dj: 6,
    dm: 0,
    do: 0,
    ec: 1,
    eg: 6,
    sv: 0,
    gq: 1,
    er: 1,
    ee: 1,
    sz: 1,
    et: 0,
    fk: 1,
    fo: 1,
    fj: 1,
    fi: 1,
    fr: 1,
    gf: 1,
    pf: 1,
    tf: 1,
    ga: 1,
    gm: 1,
    ge: 1,
    de: 1,
    gh: 1,
    gi: 1,
    gr: 1,
    gl: 1,
    gd: 1,
    gp: 1,
    gu: 0,
    gt: 0,
    gg: 1,
    gn: 1,
    gw: 1,
    gy: 1,
    ht: 1,
    hm: 1,
    hn: 0,
    hk: 0,
    hu: 1,
    is: 1,
    in: 0,
    id: 0,
    ir: 6,
    iq: 6,
    ie: 1,
    im: 1,
    il: 0,
    it: 1,
    jm: 0,
    jp: 0,
    je: 1,
    jo: 6,
    kz: 1,
    ke: 0,
    ki: 1,
    xk: 1,
    kw: 6,
    kg: 1,
    la: 0,
    lv: 1,
    lb: 1,
    ls: 1,
    lr: 1,
    ly: 6,
    li: 1,
    lt: 1,
    lu: 1,
    mo: 0,
    mg: 1,
    mw: 1,
    my: 1,
    mv: 5,
    ml: 1,
    mt: 0,
    mh: 0,
    mq: 1,
    mr: 1,
    mu: 1,
    yt: 1,
    mx: 0,
    fm: 1,
    md: 1,
    mc: 1,
    mn: 1,
    me: 1,
    ms: 1,
    ma: 1,
    mz: 0,
    mm: 0,
    na: 1,
    nr: 1,
    np: 0,
    nl: 1,
    nc: 1,
    nz: 1,
    ni: 0,
    ne: 1,
    ng: 1,
    nu: 1,
    nf: 1,
    kp: 1,
    mk: 1,
    mp: 1,
    no: 1,
    om: 6,
    pk: 0,
    pw: 1,
    ps: 1,
    pa: 0,
    pg: 1,
    py: 0,
    pe: 0,
    ph: 0,
    pn: 1,
    pl: 1,
    pt: 0,
    pr: 0,
    qa: 6,
    re: 1,
    ro: 1,
    ru: 1,
    rw: 1,
    ws: 0,
    sm: 1,
    st: 1,
    sa: 0,
    sn: 1,
    rs: 1,
    sc: 1,
    sl: 1,
    sg: 0,
    sx: 1,
    sk: 1,
    si: 1,
    sb: 1,
    so: 1,
    za: 0,
    gs: 1,
    kr: 0,
    ss: 1,
    es: 1,
    lk: 1,
    bl: 1,
    sh: 1,
    kn: 1,
    lc: 1,
    mf: 1,
    pm: 1,
    vc: 1,
    sd: 6,
    sr: 1,
    sj: 1,
    se: 1,
    ch: 1,
    sy: 6,
    tw: 0,
    tj: 1,
    tz: 1,
    th: 0,
    tl: 1,
    tg: 1,
    tk: 1,
    to: 1,
    tt: 0,
    ta: 1,
    tn: 1,
    tr: 1,
    tm: 1,
    tc: 1,
    tv: 1,
    um: 0,
    vi: 0,
    ug: 1,
    ua: 1,
    ae: 6,
    gb: 1,
    us: 0,
    uy: 1,
    uz: 1,
    vu: 1,
    va: 1,
    ve: 0,
    vn: 1,
    wf: 1,
    eh: 1,
    ye: 0,
    zm: 1,
    zw: 0
};
export default function getFirstWeekDay(code) {
    const country = (code || "nl").toLocaleLowerCase();
    // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
    return countryCodeStartDayMap[country];
}
