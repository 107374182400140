import { ActionsUnion, createAction } from "@bookingcom/flights-core";
import { UIClientFetchError, UIEsim } from "@flights/types";

export enum ActionTypes {
  fetch = "esim/fetch",
  fetchSuccess = "esim/fetchSuccess",
  fetchError = "esim/fetchError"
}

export const actions = {
  fetch: createAction(() => ({
    type: ActionTypes.fetch,
    payload: {}
  })),

  fetchSuccess: createAction((esim: UIEsim) => ({
    type: ActionTypes.fetchSuccess,
    payload: { esim }
  })),

  fetchError: createAction((error: UIClientFetchError) => ({
    type: ActionTypes.fetchError,
    payload: { error }
  }))
};

export type Actions = ActionsUnion<typeof actions>;
