import { useState, useEffect, useCallback } from "react";
import useRouteName from "./useRouteName";
import { RouteName } from "../app/routes";
import { AvroSchemaMapping } from "@flights/internal-events-types";

export default function useContextName() {
  const routeName = useRouteName();
  const mapRouteNameToContextName = useCallback((name: RouteName): keyof AvroSchemaMapping => {
    switch (name) {
      case "checkout-start":
        return "checkout_start";
      case "checkout-fare":
        return "checkout_fare";
      case "checkout-ticket-type":
        return "checkout_ticket_type";
      case "checkout":
        return "checkout1";
      case "checkout2":
        return "checkout2";
      case "checkout-seat-selection":
        return "checkout_seat_selection";
      case "checkout-sirf":
        return "checkout_sirf";
      case "checkout3":
        return "checkout3";
      case "pricechange":
        return "checkout_price_change";
      case "confirmation":
        return "confirmation";
      case "flightdetails":
        return "flight_details";
      case "home":
        return "index";
      case "pb-add-baggage":
        return "pb_add_baggage";
      case "pb-checkout-confirmation":
        return "pb_checkout_confirmation";
      case "pb-checkout-payment":
        return "pb_checkout_payment";
      case "pb-flight-details":
        return "pb_flight_details";
      case "pb-order-details":
        return "pb_order_details";
      case "pb-cancel-order":
        return "pb_cancel_order";
      case "searchresults":
        return "search_results";
      case "additional-flights-terms":
        return "additional_flights_terms";
      case "landing":
        return "route";
      default:
        return "flight_events";
    }
  }, []);
  const [contextName, setContextName] = useState(mapRouteNameToContextName(routeName));
  useEffect(() => {
    setContextName(mapRouteNameToContextName(routeName));
  }, [routeName, setContextName, mapRouteNameToContextName]);
  return contextName;
}
