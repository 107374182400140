/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { useCallback, useEffect, useState } from "react";
import { Button, Chip, SheetContainer, SheetContainerProps, Stack } from "@bookingcom/bui-react";
import FlightTimes from "../Input/FlightTimes";
import useSearchFiltersController from "../useSearchFiltersController";
import { useI18n, t } from "@bookingcom/lingojs-react";
import Frame from "components/elements/Frame";

export type FlightTimesModalProps = {
  position: SheetContainerProps["position"];
};

const FlightTimesModalComp = (props: FlightTimesModalProps) => {
  const { flightTimes } = useSearchFiltersController();
  const i18n = useI18n();
  const [active, setActive] = useState(false);
  const [shouldApply, setShouldApply] = useState(false);
  const [draftValue, setDraftValue] = useState(flightTimes.value);

  const handleOnClose = useCallback(() => {
    setActive(false);
    setDraftValue(flightTimes.value);
  }, [flightTimes.value]);

  const handleOnChange = useCallback((value: typeof draftValue) => {
    setDraftValue(value);
  }, []);

  const handleOnApply = useCallback(() => {
    flightTimes.set(draftValue);
    setActive(false);
    setShouldApply(true);
  }, [flightTimes, draftValue]);

  const handleOnReset = useCallback(() => {
    flightTimes.reset();
    setActive(false);
    setShouldApply(true);
  }, [flightTimes]);

  useEffect(() => {
    if (shouldApply) {
      setShouldApply(false);
      setDraftValue(flightTimes.value);
    }
  }, [shouldApply, flightTimes]);

  return (
    <>
      <Chip
        variant="action"
        checked={flightTimes.touched}
        onClick={() => setActive(true)}
        bubble={
          flightTimes.touched
            ? {
                text: flightTimes.count,
                ariaLabel: i18n.trans(
                  t("flights_mdot_filters_times_applied", {
                    variables: { num_selected: flightTimes.count, num_exception: flightTimes.count }
                  })
                )
              }
            : undefined
        }
        label={i18n.trans(t("flights_filters_times_title"))}
        attributes={{ "aria-label": i18n.trans(t("a11y_flights_filters_times")) }}
      />
      <SheetContainer
        active={active}
        onClose={handleOnClose}
        onAfterClose={handleOnClose}
        onCloseTrigger={handleOnClose}
        closeAriaLabel={i18n.trans(t("close"))}
        title={i18n.trans(t("flights_filters_times_title"))}
        keepMounted={false}
        position={props.position}
        footer={
          <Frame mr={4} mb={4} ml={4}>
            <Stack direction="row">
              <Stack.Item grow>
                <Button wide onClick={handleOnReset} disabled={!flightTimes.touched} variant="secondary" size="large">
                  {i18n.trans(t("flights_filter_reset"))}
                </Button>
              </Stack.Item>
              <Stack.Item grow>
                <Button wide onClick={handleOnApply} size="large">
                  {i18n.trans(t("flights_mdot_filters_apply_cta"))}
                </Button>
              </Stack.Item>
            </Stack>
          </Frame>
        }
      >
        <FlightTimes
          options={flightTimes.options}
          value={draftValue || flightTimes.value}
          onChange={handleOnChange}
          getLabel={flightTimes.getLabel}
          getTitle={flightTimes.getTitle}
        />
      </SheetContainer>
    </>
  );
};

export default function FlightTimesModal({ position }: FlightTimesModalProps) {
  const { flightTimes } = useSearchFiltersController();
  if (!flightTimes.options.length) return null;
  return <FlightTimesModalComp position={position} />;
}
