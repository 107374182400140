/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { FC } from "react";
import { UIFlightData } from "@flights/types";
import styles from "./BaseFareName.module.css";

type Props = {
  flight: UIFlightData;
};

const BaseFareName: FC<Props> = ({ flight }) => {
  const { brandedFareInfo } = flight;

  return brandedFareInfo?.fareAttributes?.includes("DISPLAY_FARE_NAME") ? (
    <div className={styles.root}>{brandedFareInfo.fareName.toLowerCase()}</div>
  ) : null;
};
export default BaseFareName;
