import { ActionsUnion, createAction } from "@bookingcom/flights-core";
import { UIClientFetchError } from "@flights/types";
import { UIFlyAnywhere } from "@flights/types";

export enum ActionTypes {
  fetch = "flyAnywhere/fetch",
  fetchSuccess = "flyAnywhere/fetchSuccess",
  fetchError = "flyAnywhere/fetchError",
  reset = "flyAnywhere/reset"
}

export const actions = {
  fetch: createAction(() => ({
    type: ActionTypes.fetch,
    payload: {}
  })),

  fetchSuccess: createAction((data: UIFlyAnywhere) => ({
    type: ActionTypes.fetchSuccess,
    payload: { ...data }
  })),

  fetchError: createAction((error: UIClientFetchError) => ({
    type: ActionTypes.fetchError,
    payload: { error }
  })),

  reset: createAction(() => ({
    type: ActionTypes.reset,
    payload: {}
  }))
};

export type Actions = ActionsUnion<typeof actions>;
