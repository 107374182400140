/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import { Link, Text } from "@bookingcom/bui-react";
import React, { FC, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { t } from "@bookingcom/lingojs-core";
import { useI18n } from "@bookingcom/lingojs-react";

import LoadingScreen from "../LoadingScreen";

import SearchLoadingIllustration from "./SearchLoadingIllustration";
import useUserAgent from "../../../../hooks/useUserAgent";
import useGlobalContext from "hooks/useGlobalContext";
import useEventTracking from "hooks/useEventTracking";
import useIsBR from "hooks/useIsBR";
import Frame from "components/elements/Frame";
import { useSelector } from "react-redux";
import { getSearchType, getSearchSegments } from "store/search/selectors";
import { UIAirportLocation, UICityLocation } from "@flights/types";

const SearchLoadingScreen: FC = () => {
  const i18n = useI18n();
  const history = useHistory();
  const { isWebview } = useUserAgent();
  const { ipCountry, requestId } = useGlobalContext();
  const isBR = useIsBR();
  const trackV2 = useEventTracking("search_results", requestId);
  const { isMobile } = useUserAgent();
  const searchType = useSelector(getSearchType);
  const searchSegments = useSelector(getSearchSegments);

  const webviewOffset = useMemo(() => {
    return window.outerHeight - window.innerHeight + 24;
  }, []);

  let title;

  if (isMobile) {
    const destinationLocations = searchSegments?.[0]?.to?.map(
      (item) => (item as UIAirportLocation)?.cityName || (item as UICityLocation)?.name
    );
    const destinationsList = destinationLocations?.join(i18n.trans(t("flights_sr_airline_separator")));
    const suffix = i18n.trans(t("flights_ellipsis_animation_localised_1")).repeat(3);

    if (isBR) {
      title = i18n.trans(t("flights_search_loading_instalment"));
    } else if (searchType === "ONEWAY") {
      title = i18n.trans(t("flights_sr_loading_one_way", { variables: { destination: destinationsList } })) + suffix;
    } else if (searchType === "ROUNDTRIP") {
      title = i18n.trans(t("flights_sr_loading_rt", { variables: { destination: destinationsList } })) + suffix;
    } else {
      title = i18n.trans(t("flights_sr_loading_multi")) + suffix;
    }
  } else {
    title =
      ipCountry === "us"
        ? i18n.trans(t("flights_search_results_loading"))
        : isBR
        ? i18n.trans(t("flights_search_loading_instalment"))
        : i18n.trans(t("flights_loading_flex_message"));
  }

  return (
    <LoadingScreen illustration={SearchLoadingIllustration} data-testid="search_loading_overlay" title={title}>
      <div style={{ paddingBottom: isWebview ? webviewOffset : 0 }}>
        <Frame alignItems="center" m={0}>
          <Frame mb={4} alignItems="center" justifyContent="center" attributes={{ style: { textAlign: "center" } }}>
            <Text variant="headline_2">{title}</Text>
          </Frame>
          <Link
            text={
              isMobile
                ? i18n.trans(t("flights_sr_loading_cancel_search"))
                : i18n.trans(t("flights_search_change_search_button"))
            }
            onClick={() => {
              trackV2("click_change_search");
              history.goBack();
            }}
          />
        </Frame>
      </div>
    </LoadingScreen>
  );
};

export default SearchLoadingScreen;
