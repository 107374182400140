import React, { useCallback, useRef, useState, Fragment } from "react";
import useLocations from "./useLocations.js";
import { ShellButton, Modal } from "../Shell/index.js";
import { t } from "@bookingcom/lingojs-core";
import { Text, ActionBar } from "@bookingcom/bui-react";
import { AutoComplete } from "../AutoComplete/index.js";
import { TransportAirplaneDepartIcon, TransportAirplaneArriveIcon } from "@bookingcom/bui-assets-react/streamline/index.js";
const LocationsModal = (props) => {
    const { type, segmentIndex, size } = props;
    const { errorMessage, i18n, placeholderText, selectedLocationsText, trackV2, selectedLocations, setLocations, multiSelectVariant, 
    // START - flights_web_ios_mdotvalue_searchbox_segment_focus
    onTrackIosSegmentFocusStages, isFixingIosSegmentFocus
    // END - flights_web_ios_mdotvalue_searchbox_segment_focus
     } = useLocations({ type, segmentIndex });
    const [showModal, setShowModal] = useState(false);
    const inputRef = useRef(null);
    const buttonRef = useRef(null);
    const [initLocations, setInitLocations] = useState(selectedLocations);
    const autoFocusInput = multiSelectVariant !== 2 && multiSelectVariant !== 3;
    // START - flights_web_ios_mdotvalue_searchbox_segment_focus
    /*
    When we focus the destination input A, the overlay must be opened and the destination input B in the overlay
    must be focused insted. SheetContainer has a catch focus logic that stores the focused element before
    Overlay gets opened, and then focuses it back when it gets closed. The logic is pretty fair and valid,
    but in our case the focusing itself causes the overlay to open, so when it closes it focuses the same thing back
    and the overlay opens again.
  
    The only way to work around this is to focus something else when A gets focused so that the overlay wouldn't focus A
    when it gets closed. The right thing to do here probably would be to focus the next field in the form (dates), so that when
    users dismiss the overlay they can continue with the form. But unfortunately Mobile Safari wouldn't show the keyboard
    in this case. Mobile Safari is very picky when it comes to showing the keyboard on focus and would only
    do that if the focusing was caused by some user action, and would only do that in that action's callback. So we can't, say,
    focus the dates field instead and then in the overlay call `overlayInputRef.current?.focus();` -- Safari would just ignore that
    since it wouldn't be anymore a result of user action (Overlay rendering doesn't happen immediately but later).
  
    Having considered all that, I'm adding a fake input to focus instead. It should be more or less the same for a11y
    which is anyway not great here.
    */
    const removeFakeInput = () => {
        const existingFakeInput = document.getElementById("searchbox_overlay_fake_input");
        if (existingFakeInput) {
            existingFakeInput.remove();
        }
    };
    const focusFakeInput = () => {
        removeFakeInput();
        const fakeInput = document.createElement("input");
        fakeInput.id = "searchbox_overlay_fake_input";
        fakeInput.setAttribute("type", "text");
        fakeInput.style.position = "absolute";
        fakeInput.style.height = "0";
        fakeInput.style.width = "0";
        fakeInput.style.opacity = "0";
        document.body.prepend(fakeInput);
        fakeInput.focus();
    };
    // END - flights_web_ios_mdotvalue_searchbox_segment_focus
    const closeModal = useCallback(() => {
        setShowModal(false);
        buttonRef.current?.focus();
    }, []);
    const handleOnClick = useCallback((e) => {
        e.stopPropagation();
        setShowModal(true);
        trackV2(type === "from" ? "click_origin" : "click_destination");
        setInitLocations(selectedLocations);
        onTrackIosSegmentFocusStages(type);
    }, [selectedLocations, trackV2, type]);
    const handleModalOnClose = useCallback(() => {
        if (document.activeElement === inputRef?.current)
            return;
        closeModal();
        setLocations(initLocations);
    }, [closeModal, initLocations, setLocations]);
    const handleOnFinish = useCallback(() => {
        closeModal();
    }, [closeModal]);
    const handleCTAOnClick = useCallback(() => {
        closeModal();
        if (selectedLocations.length === 0) {
            setLocations(initLocations);
        }
    }, [closeModal, selectedLocations.length, setLocations, initLocations]);
    const errorId = `location_input_${type}_${segmentIndex}_error`;
    return (React.createElement(React.Fragment, null,
        React.createElement(ShellButton, { icon: type === "from" ? TransportAirplaneDepartIcon : TransportAirplaneArriveIcon, errorMessage: errorMessage, errorVariant: "inline", tabIndex: 0, ref: buttonRef, "data-ui-name": `input_location_${type}_segment_${segmentIndex}`, "data-ui-error": Boolean(errorMessage), onClick: handleOnClick, size: size, errorId: errorId }, selectedLocationsText && selectedLocationsText.length > 0 ? (React.createElement(Text, { tagName: "span", variant: "body_2" }, selectedLocationsText.map((tx, idx) => (React.createElement(Fragment, { key: tx.code + idx },
            tx.code ? React.createElement("b", null,
                tx.code,
                " ") : null,
            tx.name,
            idx < selectedLocationsText.length - 1 ? (React.createElement(Text, { tagName: "span", variant: "body_2" }, " · ")) : null))))) : (React.createElement(Text, { tagName: "span", color: "neutral_alt", variant: "body_2" }, placeholderText))),
        React.createElement(Modal, { active: showModal, onCloseTrigger: handleModalOnClose, arrowNavigation: true, position: "fullScreen", title: type === "from"
                ? i18n.trans(t("flights_search_origin_prompt"))
                : i18n.trans(t("flights_search_destination_prompt")), footer: multiSelectVariant === 1 ? undefined : (React.createElement(ActionBar, { button: {
                    wide: true,
                    text: i18n.trans(t("flights_multi_select_apply")),
                    size: "large",
                    onClick: handleCTAOnClick,
                    attributes: {
                        "data-ui-name": "button_location_action_bar_done"
                    }
                } })), 
            /* START - flights_web_ios_mdotvalue_searchbox_segment_focus */
            onOpen: () => {
                if (autoFocusInput && isFixingIosSegmentFocus) {
                    focusFakeInput();
                }
            }, onAfterOpen: () => {
                if (autoFocusInput && isFixingIosSegmentFocus) {
                    removeFakeInput();
                    inputRef.current?.focus();
                }
            } },
            React.createElement(AutoComplete, { type: type, segmentIndex: segmentIndex, onFinish: handleOnFinish, autoFocusInput: autoFocusInput, largeInputFontSize: true, carouselSelected: multiSelectVariant === 2, 
                /* START - flights_web_ios_mdotvalue_searchbox_segment_focus */
                overlayInputRef: isFixingIosSegmentFocus ? inputRef : undefined }))));
};
export default LocationsModal;
