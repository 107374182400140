export type State = {
  fromCountry: string[];
  toCountry: string[];
  fromLocationName: string[];
  toLocationName: string[];
  adults: number;
  children: string[];
};

export const getInitialState = (): State => ({
  fromCountry: [],
  toCountry: [],
  fromLocationName: [],
  toLocationName: [],
  adults: 0,
  children: []
});
