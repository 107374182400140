import React, { useCallback, useEffect, useRef } from "react";
import FlexibleDateBestPriceInner from "./FlexibleDateBestPriceInner";
import flights_web_flexible_date_search_desktop_v2, {
  trackDesktopFlexStages
} from "utils/experiments/funnel/flights_web_flexible_date_search_desktop_v2";
// eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
import { useStore } from "../../../store";
import useIsInViewport from "hooks/useIsInViewport";
import { useGetFlexDatesData } from "./useGetFlexDatesData";
import useBookWindowFromSearch from "hooks/useBookingWindowFromSearch";

const FlexibleDateBestPriceContentDesktop = ({ shouldRender }: { shouldRender: boolean }) => {
  const data = useGetFlexDatesData();
  const {
    searchCriteria: { adults, children },
    flexibleDateMinPrice: { flexibleDateMinPrice = [] }
    // eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
  } = useStore();
  const bookingWindow = useBookWindowFromSearch();
  const hiddenElementRef = useRef(null);
  const isInViewport = useIsInViewport(hiddenElementRef.current);
  const goalsTrackedRef = useRef(false);
  const inViewportTrackedRef = useRef(false);
  const scrollRef = useRef(false);

  useEffect(() => {
    if (data) {
      trackDesktopFlexStages({
        searchResultsHaveBetterPrice: data.cheaperCount === 0,
        bookingWindow
      });

      if (!inViewportTrackedRef.current && isInViewport) {
        flights_web_flexible_date_search_desktop_v2.goals.flex_is_in_view();
        inViewportTrackedRef.current = true;
      }

      if (!goalsTrackedRef.current) {
        const originalPrice = flexibleDateMinPrice[3];

        if (originalPrice?.priceRounded?.units !== data.currentBestPrice.units) {
          flights_web_flexible_date_search_desktop_v2.goalsWithValue.flights_web_flexible_date_search_desktop_v2_mismatch_sr_and_cache(
            1
          );
        }

        goalsTrackedRef.current = true;
      }
    }
  }, [adults, bookingWindow, children.length, data, flexibleDateMinPrice, isInViewport]);

  const onCarouselScroll = useCallback(() => {
    if (!scrollRef.current) {
      flights_web_flexible_date_search_desktop_v2.goalsWithValue.flights_web_flexible_date_search_desktop_v2_users_scrolls_carousel(
        1
      );
      scrollRef.current = true;
    }
  }, []);

  const onItemClick = useCallback(() => {
    flights_web_flexible_date_search_desktop_v2.goals.click_on_item();
  }, []);

  if (!shouldRender || !data) return null;

  const { dates, currentBestPrice, cheaperCount, isOneWay, departureDate, returnDate } = data;

  return (
    <>
      <div ref={hiddenElementRef} aria-hidden></div>

      <FlexibleDateBestPriceInner
        carouselSize="medium"
        onCarouselScroll={onCarouselScroll}
        onItemClick={onItemClick}
        dates={dates}
        currentBestPrice={currentBestPrice}
        cheaperCount={cheaperCount}
        isOneWay={isOneWay}
        departureDate={departureDate}
        returnDate={returnDate}
      />
    </>
  );
};

export default FlexibleDateBestPriceContentDesktop;
