// eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
import { useStore } from "../store";

const useInsuranceServerSide = (isOrder: boolean = false) => {
  // eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
  const store = useStore();
  const travelInsuranceContent = isOrder
    ? store?.order?.order?.ancillaries?.travelInsurance?.content
    : store?.extraProducts?.extraProducts?.travelInsurance?.content;

  return { travelInsuranceContent };
};

export default useInsuranceServerSide;
