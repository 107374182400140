import React from "react";

import useUserAgent from "../../../hooks/useUserAgent";
import { useViewport, SkeletonLoader, Stack, Card } from "@bookingcom/bui-react";
import styles from "./LoadingAndErrorCSXPHandling.module.css";

const CsxpLoadingAndErrorHandling: React.FC<{
  isCsxpInitialized: boolean;
  skeletonItems: number;
  hasBorder?: boolean;
}> = ({ isCsxpInitialized, skeletonItems, hasBorder = false }) => {
  const { isSmall } = useViewport();

  if (hasBorder) {
    return (
      <>
        {!isCsxpInitialized && (
          <div data-small={isSmall ? "true" : "false"} className={styles.wrapper} role="presentation">
            <Card fill className={styles.root}>
              <Skeleton skeletonItems={skeletonItems} />
            </Card>
          </div>
        )}
      </>
    );
  }
  return <>{!isCsxpInitialized && <Skeleton skeletonItems={skeletonItems} />}</>;
};

interface SkeletonProps {
  skeletonItems?: number;
}

const Skeleton: React.FC<SkeletonProps> = (skeletonItems) => {
  const { isMobile } = useUserAgent();
  const numberOfElements = skeletonItems.skeletonItems;

  return (
    <Stack gap={6}>
      <SkeletonLoader variant="title" />
      {!isMobile &&
        [...Array(numberOfElements)].map((_, i) => (
          <Stack key={`skeleton-card-${i}`} direction="row" gap={4}>
            <SkeletonLoader variant="box" width="150px" aspectRatio="4:3" />
            <Stack.Item grow>
              <Stack gap={2}>
                <SkeletonLoader variant="title" />
                <SkeletonLoader variant="three-lines" />
              </Stack>
            </Stack.Item>
          </Stack>
        ))}
      {isMobile &&
        [...Array(numberOfElements)].map((_, i) => (
          <Stack key={`skeleton-card-${i}`} direction="row" gap={4}>
            <SkeletonLoader variant="box" width="110px" aspectRatio="2:3" />
            <Stack.Item grow>
              <Stack gap={2}>
                <SkeletonLoader variant="title" />
                <SkeletonLoader variant="three-lines" />
              </Stack>
            </Stack.Item>
          </Stack>
        ))}
    </Stack>
  );
};

export default CsxpLoadingAndErrorHandling;
