import { useCallback, useMemo, useState } from "react";
import * as dateFn from "date-fns";
import useCalendar from "./useCalendar.js";
export default function (props) {
    const { segmentIndex } = props;
    const { endDate, i18n, isRoundTrip, maxDate, minDate, monthHashFull, sortedDays, sortedMonth, startDate, trackV2, weekDaysHashFull } = useCalendar({ segmentIndex });
    const getDateYearMonthDay = useCallback((d) => {
        const day = String(d ? d.getDate() : "");
        const month = String(d ? d.getMonth() : "");
        const year = String(d ? d.getFullYear() : "");
        const yearMonth = year !== "" && month !== "" ? `${year}-${month}` : "";
        return { day, yearMonth };
    }, []);
    const _start = useMemo(() => getDateYearMonthDay(startDate), [getDateYearMonthDay, startDate]);
    const _end = useMemo(() => getDateYearMonthDay(endDate), [getDateYearMonthDay, endDate]);
    const [fromDay, setFromDay] = useState(_start.day);
    const [fromYearMonth, setFromYearMonth] = useState(_start.yearMonth);
    const [toDay, setToDay] = useState(_end.day);
    const [toYearMonth, setToYearMonth] = useState(_end.yearMonth);
    const fromYear = useMemo(() => fromYearMonth.split("-")[0] || "", [fromYearMonth]);
    const fromMonth = useMemo(() => fromYearMonth.split("-")[1] || "", [fromYearMonth]);
    const toYear = useMemo(() => toYearMonth.split("-")[0] || "", [toYearMonth]);
    const toMonth = useMemo(() => toYearMonth.split("-")[1] || "", [toYearMonth]);
    const onFromDayChange = useCallback((day) => {
        setToDay("");
        setToYearMonth("");
        setFromDay(day);
    }, [setFromDay]);
    const onFromYearMonthChange = useCallback((yearMonth) => {
        setFromDay("");
        setToDay("");
        setToYearMonth("");
        setFromYearMonth(yearMonth);
    }, [setFromDay, setFromYearMonth]);
    const onToDayChange = useCallback((day) => {
        setToDay(day);
    }, [setToDay]);
    const onToYearMonthChange = useCallback((yearMonth) => {
        setToDay("");
        setToYearMonth(yearMonth);
    }, [setToDay, setToYearMonth]);
    const getMonthYearList = useCallback((starting) => {
        const start = new Date(starting || minDate);
        start.setDate(1);
        const end = new Date(maxDate);
        let current = new Date(start);
        const list = [];
        do {
            list.push({
                // @ts-ignore: Element implicitly has an 'any' type. Fix the issue timely.
                text: `${monthHashFull[sortedMonth[current.getMonth()]]} - ${current.getFullYear()}`,
                value: `${current.getFullYear()}-${current.getMonth()}`
            });
            current = dateFn.addMonths(current, 1);
        } while (dateFn.isBefore(current, end));
        return list;
    }, [minDate, maxDate, monthHashFull, sortedMonth]);
    const fromYearMonthList = useMemo(() => {
        return getMonthYearList();
    }, [getMonthYearList]);
    const getDaysList = useCallback((monthYear, startingDate) => {
        const [_year, _month] = monthYear.split("-");
        if (!_year || !_month)
            return [];
        const year = Number(_year);
        const month = Number(_month);
        const start = new Date(startingDate || minDate);
        const end = new Date(maxDate);
        const currentMonth = new Date(year, month);
        let currentDay = new Date(year, month, 1);
        const list = [];
        do {
            const i = dateFn.getDay(currentDay);
            list.push({
                // @ts-ignore: Element implicitly has an 'any' type. Fix the issue timely.
                text: `${dateFn.getDate(currentDay)} - ${weekDaysHashFull[sortedDays[i]]}`,
                value: String(list.length + 1),
                disabled: (dateFn.isBefore(currentDay, start) && !dateFn.isSameDay(currentDay, start)) ||
                    (dateFn.isAfter(currentDay, end) && !dateFn.isSameDay(currentDay, end))
            });
            currentDay = dateFn.addDays(currentDay, 1);
        } while (dateFn.isSameMonth(currentDay, currentMonth));
        return list;
    }, [maxDate, minDate, sortedDays, weekDaysHashFull]);
    const dateValues = useMemo(() => {
        const d = new Date();
        d.setHours(0, 0, 0, 0);
        let from = undefined;
        let to = undefined;
        if (fromDay && fromMonth && fromYear) {
            from = dateFn.set(d, {
                date: Number(fromDay),
                month: Number(fromMonth),
                year: Number(fromYear)
            });
        }
        if (from && toDay && toMonth && toYear) {
            to = dateFn.set(d, {
                date: Number(toDay),
                month: Number(toMonth),
                year: Number(toYear)
            });
        }
        return { from, to };
    }, [fromDay, fromMonth, fromYear, toDay, toMonth, toYear]);
    const toYearMonthList = useMemo(() => {
        return getMonthYearList(dateValues.from || startDate);
    }, [getMonthYearList, startDate, dateValues]);
    return {
        endDate,
        fromDay,
        fromYearMonth,
        fromYearMonthList,
        getDaysList,
        i18n,
        isRoundTrip,
        onFromDayChange,
        onFromYearMonthChange,
        onToDayChange,
        onToYearMonthChange,
        dateValues,
        startDate,
        toDay,
        toYearMonth,
        toYearMonthList,
        trackV2
    };
}
