export const getUniqueCarriers = (segments) => {
    const carriers = new Map();
    for (const segment of segments) {
        for (const leg of segment.legs) {
            for (const carrier of leg.carriersData) {
                carriers.set(carrier.code, carrier);
            }
        }
    }
    return Array.from(carriers.values());
};
export const uniqueCarriersFromSegments = (segments) => {
    const carriers = [];
    segments.forEach((segment) => {
        segment.legs.forEach((leg) => {
            carriers.push(...leg.carriersData.map((carrier) => carrier.code));
        });
    });
    return [...new Set(carriers)];
};
export const getUniqueMarketingCarriers = (segments) => {
    const carriers = new Map();
    for (const segment of segments) {
        for (const leg of segment.legs) {
            const marketingCarrier = leg.flightInfo?.carrierInfo?.marketingCarrier;
            if (!marketingCarrier || carriers.has(marketingCarrier))
                continue;
            for (const carrier of leg.carriersData) {
                if (carrier.code === marketingCarrier)
                    carriers.set(carrier.code, carrier);
            }
        }
    }
    return Array.from(carriers.values());
};
export const getUniqueCarrierCombinations = (segments) => {
    const carrierCombinations = new Map();
    for (const segment of segments) {
        for (const leg of segment.legs) {
            const marketingCarrierCode = leg.flightInfo?.carrierInfo?.marketingCarrier;
            const operatingCarrierCode = leg.flightInfo?.carrierInfo?.operatingCarrier;
            if (!marketingCarrierCode || !operatingCarrierCode)
                continue;
            const key = marketingCarrierCode + "-" + operatingCarrierCode;
            if (carrierCombinations.has(key))
                continue;
            let marketingCarrier;
            let operatingCarrier;
            for (const carrier of leg.carriersData) {
                if (carrier.code === marketingCarrierCode)
                    marketingCarrier = carrier;
                if (carrier.code === operatingCarrierCode)
                    operatingCarrier = carrier;
            }
            if (marketingCarrier && operatingCarrier) {
                carrierCombinations.set(key, { marketingCarrier, operatingCarrier });
            }
        }
    }
    return Array.from(carrierCombinations.values());
};
export function hasCarrier(segments, airlines) {
    const carriers = getUniqueCarriers(segments);
    return carriers.some((carrier) => airlines.includes(carrier.code));
}
export function getMarketingCarriers(segments) {
    const carriers = new Set();
    for (const segment of segments) {
        for (const leg of segment.legs) {
            if (leg.flightInfo?.carrierInfo?.marketingCarrier) {
                carriers.add(leg.flightInfo.carrierInfo.marketingCarrier);
            }
        }
    }
    return Array.from(carriers);
}
