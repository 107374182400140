import { Actions, ActionTypes } from "./actions";
import { State, getInitialState } from "./state";

const initialState = getInitialState();

const reducer = (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case ActionTypes.setErrorCode:
      return {
        ...state,
        code: action.payload.code
      };

    case ActionTypes.resetErrorCode:
      return {
        ...state,
        code: undefined
      };

    case ActionTypes.setHadPhoneNumberError:
      return {
        ...state,
        hadPhoneNumberError: true
      };

    case ActionTypes.setHadEmailError:
      return {
        ...state,
        hadEmailError: true
      };

    case ActionTypes.setPhoneNumberValidationTracked:
      return {
        ...state,
        phoneNumberValidationTracked: true
      };

    case ActionTypes.setEmailValidationTracked:
      return {
        ...state,
        emailValidationTracked: true
      };

    default:
      return state;
  }
};

export { reducer, getInitialState };
