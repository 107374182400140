import React from "react";
import useSegment from "./useSegment.js";
import { DateDropDown } from "../Date/index.js";
import styles from "./SegmentHorizontal.module.css";
const ChangeFlightSegmentHorizontal = () => {
    const { segments } = useSegment();
    return (React.createElement("div", { className: styles.wrapper }, segments.map((_, idx) => (React.createElement("div", { key: idx, className: styles.line, "data-ui-name": "segments_list_item" },
        React.createElement("div", { className: styles.date },
            React.createElement(DateDropDown, { segmentIndex: idx })))))));
};
export default ChangeFlightSegmentHorizontal;
