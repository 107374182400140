import usePointOfSale from "hooks/usePointOfSale";

// Flex dates uses "Aggregate Cache" which in turn is not integrated with
// "Pricing service", so we need to skip countries where we do internal pricing.
export const flexDatesRestrictedCountries = ["id", "qa", "ph"];

export const useFlexDatesRectrictedPOS = () => {
  const pos = usePointOfSale();

  return flexDatesRestrictedCountries.some((c) => pos === c);
};
