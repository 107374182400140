import React from "react";

interface DefaultProps {
  color: string;
  size: number | string;
}

type Props = Partial<DefaultProps>;

const defaultProps: DefaultProps = {
  color: "#BDBDBD",
  size: 16
};

export const Toilet = (props: Props) => {
  const { color, size } = { ...defaultProps, ...props };
  return (
    <svg width={size} height={size} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.375 4.50299C2.375 6.57099 4.057 8.25299 6.125 8.25299C8.193 8.25299 9.875 6.57099 9.875 4.50299C9.875 2.43499 8.193 0.752991 6.125 0.752991C4.057 0.752991 2.375 2.43499 2.375 4.50299ZM3.875 4.50299C3.875 3.26199 4.884 2.25299 6.125 2.25299C7.366 2.25299 8.375 3.26199 8.375 4.50299C8.375 5.74399 7.366 6.75299 6.125 6.75299C4.884 6.75299 3.875 5.74399 3.875 4.50299Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.375 4.50299C14.375 6.57099 16.057 8.25299 18.125 8.25299C20.193 8.25299 21.875 6.57099 21.875 4.50299C21.875 2.43499 20.193 0.752991 18.125 0.752991C16.057 0.752991 14.375 2.43499 14.375 4.50299ZM15.875 4.50299C15.875 3.26199 16.884 2.25299 18.125 2.25299C19.366 2.25299 20.375 3.26199 20.375 4.50299C20.375 5.74399 19.366 6.75299 18.125 6.75299C16.884 6.75299 15.875 5.74399 15.875 4.50299Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.664 22.567C3.699 22.956 4.02 23.25 4.411 23.25H7.839C8.23 23.25 8.551 22.956 8.586 22.567L9.061 17.25H10.625C11.039 17.25 11.375 16.914 11.375 16.5V13.5C11.375 10.605 9.02 8.25 6.125 8.25C3.23 8.25 0.875 10.605 0.875 13.5V16.5C0.875 16.914 1.211 17.25 1.625 17.25H3.189L3.664 22.567ZM7.628 16.433L7.153 21.75H5.097L4.622 16.433C4.587 16.044 4.266 15.75 3.875 15.75H2.375V13.5C2.375 11.432 4.057 9.75 6.125 9.75C8.193 9.75 9.875 11.432 9.875 13.5V15.75H8.375C7.984 15.75 7.663 16.044 7.628 16.433Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.885 22.623C15.946 22.986 16.257 23.25 16.625 23.25H19.624C19.993 23.25 20.304 22.986 20.364 22.623L21.01 18.75H23.376C23.619 18.75 23.848 18.632 23.989 18.432C24.13 18.232 24.164 17.976 24.082 17.747L21.937 11.763L21.9368 11.7624C21.3339 9.95562 20.7647 8.25 18.125 8.25C15.485 8.25 14.917 9.952 14.319 11.747L12.169 17.747C12.087 17.976 12.122 18.232 12.262 18.431C12.402 18.631 12.631 18.75 12.875 18.75H15.24L15.885 22.623ZM19.636 17.877L18.99 21.75H17.261L16.615 17.877C16.554 17.514 16.243 17.25 15.875 17.25H13.94L15.737 12.237C16.41 10.221 16.711 9.75 18.126 9.75C19.541 9.75 19.843 10.223 20.52 12.253L22.311 17.25H20.376C20.007 17.25 19.696 17.514 19.636 17.877Z"
        fill={color}
      />
    </svg>
  );
};

export const Galley = (props: Props) => {
  const { color, size } = { ...defaultProps, ...props };
  return (
    <svg width={size} height={size} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.74997 5.25C8.54997 5.25 8.36097 5.172 8.21997 5.03C8.07797 4.889 7.99997 4.7 7.99997 4.5C7.99997 4.3 8.07797 4.111 8.21997 3.97C8.51297 3.677 8.52497 3.64 8.12597 3.041C7.77197 2.51 7.03497 1.405 8.21997 0.22C8.36097 0.078 8.54997 0 8.74997 0C8.94997 0 9.13897 0.078 9.27997 0.22C9.42197 0.361 9.49997 0.55 9.49997 0.75C9.49997 0.95 9.42197 1.139 9.27997 1.28C8.98697 1.573 8.97497 1.61 9.37397 2.209C9.72797 2.74 10.465 3.845 9.27997 5.03C9.13897 5.172 8.94997 5.25 8.74997 5.25Z"
        fill={color}
      />
      <path
        d="M12.5 5.25C12.3 5.25 12.111 5.172 11.97 5.03C11.828 4.889 11.75 4.7 11.75 4.5C11.75 4.3 11.828 4.111 11.97 3.97C12.263 3.677 12.276 3.641 11.876 3.041C11.522 2.51 10.785 1.405 11.97 0.22C12.111 0.078 12.3 0 12.5 0C12.7 0 12.889 0.078 13.03 0.22C13.172 0.361 13.25 0.55 13.25 0.75C13.25 0.95 13.172 1.139 13.03 1.28C12.737 1.573 12.724 1.609 13.124 2.209C13.478 2.74 14.215 3.846 13.03 5.03C12.889 5.172 12.7 5.25 12.5 5.25Z"
        fill={color}
      />
      <path
        d="M16.25 5.25C16.05 5.25 15.861 5.172 15.72 5.03C15.578 4.889 15.5 4.7 15.5 4.5C15.5 4.3 15.578 4.111 15.72 3.97C16.013 3.677 16.026 3.641 15.626 3.041C15.272 2.51 14.535 1.404 15.72 0.22C15.861 0.078 16.05 0 16.25 0C16.45 0 16.639 0.078 16.78 0.22C16.922 0.361 17 0.55 17 0.75C17 0.95 16.922 1.139 16.78 1.28C16.487 1.573 16.474 1.609 16.874 2.209C17.228 2.74 17.965 3.846 16.78 5.03C16.639 5.172 16.45 5.25 16.25 5.25Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 24C6.086 24 1.25 22.065 1.25 19.5C1.25 18.482 1.949 17.066 5.217 16.01C4.781 14.392 4.709 12.516 5.003 10.428C5.075 8.33 8.29 6.75 12.5 6.75C16.708 6.75 19.924 8.329 19.997 10.427C20.292 12.515 20.22 14.391 19.783 16.01C22.345 16.841 23.75 18.073 23.75 19.5C23.75 22.065 18.914 24 12.5 24ZM5.725 17.423C3.673 18.085 2.75 18.932 2.75 19.5C2.75 21.042 7.488 22.5 12.5 22.5C17.512 22.5 22.25 21.042 22.25 19.5C22.25 18.929 21.327 18.081 19.275 17.422C19.036 17.933 18.814 18.255 18.702 18.401C18.126 19.503 15.682 21 12.5 21C9.318 21 6.874 19.503 6.297 18.401C6.187 18.255 5.964 17.934 5.725 17.423ZM7.512 17.518C7.499 17.502 6.303 15.99 6.33 12.683C6.573 12.858 6.847 13.021 7.149 13.172L7.16215 13.1786C7.17951 13.1874 7.19662 13.196 7.215 13.204C8.603 13.879 10.479 14.25 12.5 14.25C14.521 14.25 16.397 13.879 17.786 13.204C17.808 13.194 17.829 13.183 17.85 13.172C18.153 13.021 18.427 12.858 18.67 12.683C18.697 15.989 17.503 17.5 17.495 17.51C17.444 17.57 17.402 17.637 17.371 17.711C17.218 18.078 15.345 19.5 12.5 19.5C9.655 19.5 7.782 18.078 7.629 17.71C7.599 17.64 7.558 17.572 7.512 17.518ZM8.896 12.263C9.933 12.579 11.187 12.75 12.5 12.75C13.813 12.75 15.067 12.579 16.104 12.263C15.139 11.602 13.99 11.242 12.818 11.242C12.724 11.242 12.63 11.244 12.536 11.249L12.518 11.2495L12.5 11.25L12.467 11.249C12.372 11.244 12.279 11.242 12.186 11.242C11.011 11.243 9.861 11.602 8.896 12.263ZM6.5 10.5C6.5 9.56 8.783 8.25 12.5 8.25C16.217 8.25 18.5 9.56 18.5 10.5C18.5 10.838 18.184 11.232 17.644 11.584L17.533 11.473C17.516 11.455 17.498 11.438 17.479 11.423C16.17 10.339 14.515 9.743 12.819 9.743C12.713 9.743 12.607 9.745 12.501 9.75C12.396 9.745 12.291 9.743 12.186 9.743C10.488 9.743 8.832 10.339 7.523 11.422C7.504 11.438 7.485 11.454 7.47 11.47L7.357 11.582C6.816 11.232 6.5 10.838 6.5 10.5Z"
        fill={color}
      />
    </svg>
  );
};

export function ExitLeft(props: Props) {
  const { color, size } = { ...defaultProps, ...props };
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.689 24C11.489 24 11.3 23.922 11.159 23.78L0.44 13.06C0.156 12.778 -5e-08 12.401 -5e-08 12.001C-5e-08 11.601 0.155 11.224 0.438 10.941L11.159 0.22C11.3 0.078 11.488 0 11.689 0C11.89 0 12.078 0.078 12.22 0.22C12.362 0.361 12.44 0.55 12.44 0.75C12.44 0.95 12.362 1.139 12.22 1.28L1.5 12L12.219 22.72C12.511 23.012 12.511 23.488 12.219 23.781C12.078 23.922 11.889 24 11.689 24Z"
        fill={color}
      />
      <path
        d="M22.939 24C22.739 24 22.55 23.922 22.409 23.78L11.69 13.06C11.106 12.476 11.105 11.525 11.689 10.941L22.409 0.22C22.55 0.078 22.739 0 22.939 0C23.139 0 23.328 0.078 23.469 0.22C23.611 0.361 23.689 0.55 23.689 0.75C23.689 0.95 23.611 1.139 23.469 1.28L12.75 12L23.469 22.72C23.761 23.012 23.761 23.488 23.469 23.781C23.327 23.922 23.139 24 22.939 24Z"
        fill={color}
      />
    </svg>
  );
}

export function ExitRight(props: Props) {
  const { color, size } = { ...defaultProps, ...props };
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.31 24C12.11 24 11.921 23.922 11.78 23.78C11.488 23.488 11.488 23.012 11.78 22.719L22.499 12L11.78 1.28C11.638 1.138 11.56 0.95 11.56 0.75C11.56 0.55 11.638 0.361 11.78 0.22C11.921 0.078 12.11 0 12.31 0C12.51 0 12.699 0.078 12.84 0.22L23.559 10.94C24.143 11.524 24.144 12.475 23.56 13.059L12.84 23.78C12.699 23.922 12.51 24 12.31 24Z"
        fill={color}
      />
      <path
        d="M1.06 24C0.859998 24 0.670998 23.922 0.529998 23.78C0.237998 23.488 0.237998 23.012 0.529998 22.719L11.249 12L0.529998 1.28C0.387998 1.139 0.309998 0.95 0.309998 0.75C0.309998 0.55 0.387998 0.361 0.529998 0.22C0.670998 0.078 0.859998 0 1.06 0C1.26 0 1.449 0.078 1.59 0.22L12.309 10.94C12.592 11.223 12.748 11.599 12.749 11.999C12.75 12.399 12.594 12.776 12.311 13.059L1.59 23.78C1.449 23.922 1.261 24 1.06 24Z"
        fill={color}
      />
    </svg>
  );
}

export function UnavailableIcon(props: Props) {
  const { color, size } = { ...defaultProps, ...props };
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.72549 8.04552L12.8988 3.87218C12.9538 3.77922 12.9763 3.67059 12.9628 3.56341C12.9493 3.45624 12.9005 3.35661 12.8241 3.28023C12.7477 3.20385 12.6481 3.15505 12.5409 3.14152C12.4337 3.12799 12.3251 3.15051 12.2322 3.20552L8.05882 7.37885L3.88549 3.18552C3.8477 3.12165 3.7958 3.06727 3.73377 3.02654C3.67174 2.98581 3.60121 2.9598 3.52759 2.95051C3.45396 2.94122 3.37919 2.94888 3.30898 2.97293C3.23877 2.99697 3.175 3.03675 3.12252 3.08922C3.07005 3.14169 3.03027 3.20547 3.00623 3.27568C2.98219 3.34589 2.97452 3.42066 2.98381 3.49429C2.9931 3.56791 3.01911 3.63844 3.05984 3.70047C3.10057 3.7625 3.15495 3.81439 3.21882 3.85218L7.39215 8.04552L3.19882 12.2189C3.13201 12.2873 3.08684 12.3739 3.06895 12.4678C3.05105 12.5617 3.06123 12.6589 3.09822 12.7471C3.1352 12.8352 3.19734 12.9106 3.27689 12.9637C3.35644 13.0167 3.44986 13.0452 3.54549 13.0455C3.67277 13.0451 3.79482 12.9948 3.88549 12.9055L8.05882 8.71218L12.2322 12.8855C12.3231 12.9689 12.4421 13.0152 12.5655 13.0152C12.6889 13.0152 12.8078 12.9689 12.8988 12.8855C12.9822 12.7945 13.0285 12.6756 13.0285 12.5522C13.0285 12.4288 12.9822 12.3098 12.8988 12.2189L8.72549 8.04552Z"
        fill={color}
      />
    </svg>
  );
}

export function CloseIcon(props: Props) {
  const { color, size } = { ...defaultProps, ...props };
  return (
    <svg width={Number(size) + 1} height={size} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="M1 15.4993L16 0.499268"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16 15.4993L1 0.499268"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
