import { trackExperiment } from "utils/et";

export const getLocationParams = (searchType: "ONEWAY" | "ROUNDTRIP" | "MULTISTOP", params: string) => {
  const locationParams = new URLSearchParams(
    searchType === "ROUNDTRIP" && trackExperiment("flights_web_inbound_outbound_sr") ? window.location.search : params
  );

  // Prevent fetching branded fares from previously selected flight
  locationParams.delete("baseOfferToken");

  return locationParams;
};
