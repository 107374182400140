import { t } from "@bookingcom/lingojs-core";
import React from "react";
import { Carousel, Chip } from "@bookingcom/bui-react";
import styles from "./Tags.module.css";
const Item = (props) => {
    const { loc, idx, onClick, i18n } = props;
    return (React.createElement(Chip, { dismissible: true, attributes: { "data-autocomplete-chip-idx": String(idx) }, onChange: ({ event: e }) => {
            e.stopPropagation();
            e.preventDefault();
            onClick(idx);
        }, label: React.createElement("span", { className: styles.text }, loc.type === "CITY" ? (React.createElement(React.Fragment, null,
            React.createElement("b", null, loc.name),
            ` ${i18n.trans(t("flights_multi_select_all_airports"))}`)) : (React.createElement(React.Fragment, null,
            React.createElement("b", null, loc.code),
            ` ${loc.cityName || loc.name}`))) }));
};
export default function Chips(props) {
    const { selectedLocations, i18n, onClick, carousel } = props;
    if (!selectedLocations.length)
        return React.createElement(React.Fragment, null);
    return carousel ? (React.createElement(Carousel, { nextButtonAriaLabel: "", previousButtonAriaLabel: "" }, selectedLocations.map((loc, idx) => (React.createElement(Item, { key: loc.code + loc.type, onClick, i18n, idx, loc }))))) : (React.createElement("div", { style: {
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
            minWidth: "100%",
            maxWidth: "100%",
            gap: "var(--bui_spacing_2x)"
        } }, selectedLocations.map((loc, idx) => (React.createElement(Item, { key: loc.code + loc.type, onClick, i18n, idx, loc })))));
}
