import { trackCustomGoal, trackExperimentStage } from "utils/et";
import {
  isOfMetaOrigin,
  isOfGoogleFlightsOrigin,
  isOfGoogleSearchWidgetOrigin,
  isOfKayakOrigin,
  isOfSkyScannerOrigin
} from "utils/marketing-url-params";

type PlatformType = "desktop" | "mdot";

const FLIGHTS_META_LANDINGS_V2_DESKTOP_AA_NAME = "flights_meta_landings_v2_desktop_aa";
const FLIGHTS_META_LANDINGS_V2_MOBILE_AA_NAME = "flights_meta_landings_v2_mdot_aa";

const trackMetaLandingsV2 = (stageNumber: number, type: PlatformType) => {
  // always have to track meta users
  const isMeta = isOfMetaOrigin();
  const expTypeToTrack =
    type === "desktop" ? FLIGHTS_META_LANDINGS_V2_DESKTOP_AA_NAME : FLIGHTS_META_LANDINGS_V2_MOBILE_AA_NAME;
  isMeta &&
    // main meta landing stage 1
    trackExperimentStage(expTypeToTrack, 1) &&
    // could be any of these:
    // Stage number : stage type
    // 2 : All Kayak Meta traffic
    // 3 : All GFS Meta traffic
    // 4 : All Skyscanner User traffic
    // 5: Google Search Widget
    trackExperimentStage(expTypeToTrack, stageNumber);
};

export const trackMetaChannelStagesV2 = (type: PlatformType) => {
  if (isOfGoogleFlightsOrigin()) {
    trackMetaLandingsV2(3, type); // track GFS landing stage 3
  } else if (isOfKayakOrigin()) {
    trackMetaLandingsV2(2, type); // track as meta landing from Kayak
  } else if (isOfSkyScannerOrigin()) {
    trackMetaLandingsV2(4, type); // track as meta landing from Skyscanner
  } else if (isOfGoogleSearchWidgetOrigin()) {
    trackMetaLandingsV2(5, type); // track as meta landing from Google Search Widget
  }
};

export const trackMetaLandingsCustomGoalsV2 = (goalNumber: number, type: PlatformType) => {
  const metaOrigin = isOfMetaOrigin();
  const expTypeToTrack =
    type === "desktop" ? FLIGHTS_META_LANDINGS_V2_DESKTOP_AA_NAME : FLIGHTS_META_LANDINGS_V2_MOBILE_AA_NAME;
  metaOrigin && trackCustomGoal(expTypeToTrack, goalNumber);
};
