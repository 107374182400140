import { CarouselDataResponse } from "cross-sell/types";
import { UIClientFetchError } from "@flights/types";

export type State = {
  isLoading: boolean;
  crossSell?: CarouselDataResponse | undefined;
  error?: UIClientFetchError;
  trackedAd: boolean;
  trackedAdLoading: boolean;
};

export const getInitialState = (): State => ({
  isLoading: false,
  crossSell: undefined,
  error: undefined,
  trackedAd: false,
  trackedAdLoading: false
});
