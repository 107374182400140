import { useMemo } from "react";
// eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
import { useStore } from "../../../store";
import { UISearchMinPriceInfo } from "@flights/types";

type UISearchMinPriceInfoWithPrice = Omit<UISearchMinPriceInfo, "price"> &
  Required<Pick<UISearchMinPriceInfo, "priceRounded">> & {
    isExpensive: boolean;
  };

// Used in case Search Results prices are rounded
type Params = { isReferencePriceRounded: boolean };

export const useGetFlexDatesData = (params?: Params) => {
  const {
    search: { type, searchSegments },
    searchResults: { flightDeals = [] },
    flexibleDateMinPrice: { flexibleDateMinPrice = [] }
    // eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
  } = useStore();

  const currentBestPrice = useMemo(
    () =>
      flightDeals
        .find(({ key }) => key === "BEST")
        ?.travellerPrices.find(({ travellerType }) => travellerType === "ADULT")?.travellerPriceBreakdown[
        params?.isReferencePriceRounded ? "totalRounded" : "total"
      ],
    [flightDeals, params?.isReferencePriceRounded]
  );

  const dates = useMemo(() => {
    if (!currentBestPrice || flexibleDateMinPrice.length === 0) return [];

    const { lowestPrice, hightestPrice } = flexibleDateMinPrice.reduce(
      (obj, date) => {
        if (!date.priceRounded) return obj;

        obj.lowestPrice = Math.min(date.priceRounded.units, obj.lowestPrice);
        obj.hightestPrice = Math.max(date.priceRounded.units, obj.hightestPrice);

        return obj;
      },
      {
        lowestPrice: Number.POSITIVE_INFINITY,
        hightestPrice: Number.NEGATIVE_INFINITY
      }
    );

    return flexibleDateMinPrice
      .filter((date) => date.offsetDays !== 0 && date.priceRounded)
      .map((date) => {
        return {
          ...date,
          isCheapest: lowestPrice === date.priceRounded?.units,
          isExpensive: hightestPrice === date.priceRounded?.units
        };
      }) as UISearchMinPriceInfoWithPrice[];
  }, [currentBestPrice, flexibleDateMinPrice]);

  const { cheaperCount, isOneWay, departureDate, returnDate } = useMemo(() => {
    let cheaperCount = 0;

    dates.forEach((date) => {
      if (date.priceRounded.units < (currentBestPrice?.units || 0)) {
        cheaperCount++;
      }
    });

    const firstSegment = searchSegments[0];
    const isOneWay = type === "ONEWAY";
    const departureDate = (firstSegment && firstSegment.departureDate) || "";
    const returnDate =
      type === "ROUNDTRIP"
        ? firstSegment.returnDate
        : type === "MULTISTOP"
        ? searchSegments[searchSegments.length - 1].departureDate
        : undefined;

    return { cheaperCount, isOneWay, departureDate, returnDate };
  }, [currentBestPrice?.units, dates, searchSegments, type]);

  if (!currentBestPrice || dates.length === 0) return undefined;

  return {
    currentBestPrice,
    dates,
    cheaperCount,
    isOneWay,
    departureDate,
    returnDate
  };
};
