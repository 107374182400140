import React, { useCallback } from "react";
import { InputSwitch } from "@bookingcom/bui-react";
import useDirectFlight from "./useDirectFlight.js";
import { t } from "@bookingcom/lingojs-core";
import styles from "./DirectFlightSwitch.module.css";
const DirectFlightSwitch = () => {
    const { i18n, toggleIsDirect, isDirect, trackV2, showNonStopExp } = useDirectFlight();
    const handleOnChange = useCallback((checked) => {
        if (checked)
            trackV2("select_direct_flights_only");
        toggleIsDirect(checked);
    }, [toggleIsDirect, trackV2]);
    return (React.createElement(InputSwitch, { name: "sr_direct_flights", label: showNonStopExp ? i18n.trans(t("flights_search_nonstop_us")) : i18n.trans(t("flights_search_direct")), onChange: ({ value }) => {
            handleOnChange(value);
        }, value: isDirect, className: styles.input, id: "direct_flights_input_checkbox", attributes: { "data-ui-name": "direct_flights" }, inputAttributes: { "data-ui-name": "direct_flights_input" } }));
};
export default DirectFlightSwitch;
