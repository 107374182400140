/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React from "react";
import Stops from "../Input/Stops";
import useSearchFiltersController from "../useSearchFiltersController";
import { Link, Stack, Text } from "@bookingcom/bui-react";
import WithSuffix from "../WithSuffix";
import { useI18n, t } from "@bookingcom/lingojs-react";

export default function StopsFilter() {
  const i18n = useI18n();
  const { stops } = useSearchFiltersController();

  if (stops.options.length === 0) return null;

  return (
    <Stack attributes={{ "data-testid": "stops_filter" }}>
      <WithSuffix
        suffix={
          stops.touched ? (
            <Link attributes={{ "data-testid": "search_filter_stops_reset" }} onClick={stops.reset}>
              {i18n.trans(t("flights_filter_reset"))}
            </Link>
          ) : null
        }
      >
        <Text variant="emphasized_1">{i18n.trans(t("flights_filters_stops_title"))}</Text>
      </WithSuffix>

      <Stops value={stops.value} options={stops.options} onChange={stops.set} />
    </Stack>
  );
}
