import { UIFetchStatus } from "@flights/types";
import { Actions, ActionTypes } from "./actions";
import { State, getInitialState, BrandedFare } from "./state";

const initialState = getInitialState();

const reducer = (state: State = initialState, action: Actions): State => {
  if (action.type === ActionTypes.fetch) {
    const fareOffer: BrandedFare = {
      baseOfferToken: action.payload.baseOfferToken,
      fetchStatus: "loading",
      error: undefined,
      brandedFareOffers: undefined
    };
    return {
      ...state,
      fareOffersWithDetails: {
        ...state.fareOffersWithDetails,
        [action.payload.baseOfferToken]: {
          ...fareOffer
        }
      }
    };
  }

  if (action.type === ActionTypes.fetchSuccess) {
    // prepare map of branded fares
    const baseOfferToken = action.payload.baseOfferToken;
    const brandedFaresFetched = action.payload.brandedFaresOffers;
    const existingFares = state.fareOffersWithDetails;

    const currentFare = {
      ...existingFares[baseOfferToken],
      brandedFareOffers: brandedFaresFetched,
      fetchStatus: "success" as UIFetchStatus
    };

    return {
      ...state,
      fareOffersWithDetails: {
        ...existingFares,
        [baseOfferToken]: currentFare
      }
    };
  }

  if (action.type === ActionTypes.fetchError) {
    const existingFares = state.fareOffersWithDetails;
    return {
      ...state,
      fareOffersWithDetails: {
        ...existingFares,
        [action.payload.baseOfferToken]: {
          fetchStatus: "failed",
          error: action.payload.error
        }
      }
    };
  }

  if (action.type === ActionTypes.reset) {
    return getInitialState();
  }

  return state;
};

export { reducer, getInitialState };
