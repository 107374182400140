import React, { forwardRef, useCallback, useRef, useState } from "react";
import { ShellWrapper, Select } from "../Shell/index.js";
import useSearchType from "./useSearchType.js";
import { ArrowsLeftRightIcon } from "@bookingcom/bui-assets-react/streamline/index.js";
import { Icon } from "@bookingcom/bui-react";
import styles from "./SearchTypeSelect.module.css";
const SearchTYpeSelectWithRef = forwardRef(function SearchTYpeSelect(props, ref) {
    const { searchTypeExtendedOptions, onSearchTypeChange, searchType } = useSearchType();
    const [isFocus, setIsFocus] = useState(false);
    const selectRef = useRef(null);
    const onFocus = useCallback(() => {
        setIsFocus(true);
    }, []);
    const onBlur = useCallback(() => {
        setIsFocus(false);
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(ShellWrapper, { clickThrough: selectRef, isFocus: isFocus, className: styles.wrapper },
            React.createElement(Select, { wide: true, onFocus: onFocus, onBlur: onBlur, onChange: ({ currentTarget }) => {
                    onSearchTypeChange(currentTarget.value);
                }, value: searchType, options: searchTypeExtendedOptions, icon: React.createElement(Icon, { size: "medium", svg: ArrowsLeftRightIcon }), ref: ref }))));
});
export default SearchTYpeSelectWithRef;
