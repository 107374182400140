/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { Pagination } from "@bookingcom/bui-react";
import { useI18n, I18nChildContext } from "@bookingcom/lingojs-react";
import { t } from "@bookingcom/lingojs-core";

import { SEARCH_PAGE_SIZE as SEARCH_PAGE_SIZE_BASE } from "../../../constants";
// eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
import { useStore, useActions } from "../../../store";
import { actions as searchActions } from "../../../store/search/actions";
import useCreateUrl from "../../../hooks/useCreateUrl";
import { trackCustomGoal } from "utils/et";
import scrollToTop from "utils/scrollToTop";
import styles from "./SearchPagination.module.css";

interface Props {
  text?: string;
}

const SearchPagination: React.FunctionComponent<Props> = () => {
  const i18n = useI18n() as I18nChildContext;
  const history = useHistory();
  const { search, pathname } = useLocation();
  const { enhanceURLSearchParams } = useCreateUrl();
  // eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
  const store = useStore();
  // eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
  const actions = useActions(searchActions);
  const qs = useMemo(() => new URLSearchParams(search), [search]);
  const currentPage = Number(qs.get("page") || 1);

  const {
    search: { type },
    searchResults: {
      availableFilters: { totalCount, filteredTotalCount }
    }
  } = store;
  let totalPages = totalCount ? Math.ceil(totalCount / SEARCH_PAGE_SIZE_BASE) : 0;
  totalPages = filteredTotalCount ? Math.ceil(filteredTotalCount / SEARCH_PAGE_SIZE_BASE) : totalPages;

  const navigateToPage = useCallback(
    (p: number) => {
      actions.setPage(p);
      scrollToTop();

      qs.set("page", `${p}`);
      enhanceURLSearchParams(qs);

      if (type === "ONEWAY" && p !== 1) {
        trackCustomGoal("flights_web_ddot_condensed_one_way_search_results", 4);
      }

      history.push({ pathname: pathname, search: qs.toString() }, { hideLoader: true });
    },
    [actions, qs, enhanceURLSearchParams, type, history, pathname]
  );

  if (totalPages === 1) {
    return null;
  }

  return (
    <Pagination
      className={styles.pagination}
      totalPages={totalPages}
      currentPage={currentPage}
      ariaPreviousLabel={i18n.trans(t("flights_action_back"))}
      ariaNextLabel={i18n.trans(t("flights_action_next"))}
      ariaPageLabelPrefix=""
      onPageChange={({ page }) => {
        navigateToPage(page);
      }}
    />
  );
};
export default SearchPagination;
