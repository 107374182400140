import { useCallback, useContext } from "react";
import { SearchBoxContext } from "../SearchBoxContext/index.js";
const useSegment = () => {
    const { searchType, segments, removeSegment, addSegment, i18n, fixInputSpacing } = useContext(SearchBoxContext);
    const isMultiStop = searchType === "MULTISTOP";
    const isRoundTrip = searchType === "ROUNDTRIP";
    const removeByIndex = useCallback((index) => {
        removeSegment(index);
    }, [removeSegment]);
    const add = useCallback(() => {
        addSegment();
    }, [addSegment]);
    return {
        isMultiStop,
        isRoundTrip,
        segments,
        removeSegmentByIndex: removeByIndex,
        addSegment: add,
        i18n,
        fixInputSpacing
    };
};
export default useSegment;
