// eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
import { useStore } from "../store";
import { useCallback } from "react";
import {
  getAddedAncillariesExtraProduct,
  preOptedToAddedAncillaries
} from "../store/selectors/getAddedAncillariesExtraProduct";
import { UIPriceBreakdown } from "@flights/types";
import { UIFlightData } from "@flights/types";
import usePaxWithBaggages from "./usePaxWithBaggages";
import { objectUtils, pricingUtils } from "@flights/web-api-utils-universal";

const usePriceBreakDownForPreOptedAncillaries = (flightFromProp?: UIFlightData) => {
  // eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
  const store = useStore();
  const { isPaxWithBaggages } = usePaxWithBaggages();

  const flightFromStore = store?.flightDetails?.flight || store?.metaFlightDetails?.flight;

  const flight = flightFromProp || flightFromStore;

  const getAncillaries = useCallback(() => {
    if (isPaxWithBaggages) {
      return {
        ...store.cartDetails.cartDetails?.cart.extras,
        ...getAddedAncillariesExtraProduct(store.extraProducts.extraProducts, store.extraProducts.selected),
        ...(flight ? preOptedToAddedAncillaries(store.extraProducts.preOptedAncillaries, flight) : {})
      };
    }
    return flight ? preOptedToAddedAncillaries(store.extraProducts.preOptedAncillaries, flight) : {};
  }, [flight, store.extraProducts, store.cartDetails, isPaxWithBaggages]);

  const getTotalPrice = useCallback(
    (basePrice?: UIPriceBreakdown): UIPriceBreakdown => {
      // return only flight base price where we don't have to add ancillaries - search results
      let total = basePrice || pricingUtils.getEmptyBreakdown();

      /**
       * This total would be basePrice for flight which comes in searchResponse/flightDetails response or
       * units:0, nanos: 0 and currencyCode:"".
       * This is here just to avoid type checking errors in case flight details are not availble and this hook is called i.e. search results
       */

      if (!flight) return total;

      const flightBasePrice = flight.priceBreakdown;
      const selectedAncillaries = getAncillaries();
      const availableAncillaries = flight.ancillaries;

      if (selectedAncillaries && availableAncillaries) {
        const ancillaryPrices: UIPriceBreakdown[] = Object.keys(selectedAncillaries)
          .map((item) => {
            // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
            const price = selectedAncillaries[item].price;
            // For totals, we don't show price strike through for ancillaries
            if (price) {
              return { ...price, showPriceStrikethrough: false };
            }

            return price;
          })
          .filter(Boolean);

        total = pricingUtils.sumPriceBreakdown([flightBasePrice, ...ancillaryPrices]);
      } else {
        total = flightBasePrice;
      }

      return total;
    },
    [flight, getAncillaries]
  );

  const getTotalPriceFromCart = useCallback((): UIPriceBreakdown => {
    let total = pricingUtils.getEmptyBreakdown();

    if (!flight || !store.cartDetails?.cartDetails?.cart.price) return total;

    const cartBasePrice = store.cartDetails.cartDetails.cart.price;
    const selectedAncillaries = getAncillaries();
    const availableAncillaries = flight.ancillaries;

    if (selectedAncillaries && availableAncillaries) {
      const ancillaryPrices: UIPriceBreakdown[] = objectUtils
        .getObjectKeys(selectedAncillaries)
        .flatMap((ancillaryKey) => selectedAncillaries[ancillaryKey])
        .map((selectedAncillary) => {
          // For totals, we don't show price strike through for ancillaries
          if (selectedAncillary?.price) {
            return { ...selectedAncillary.price, showPriceStrikethrough: false };
          }

          return selectedAncillary?.price;
        })
        .filter((selectedAncillaryPrice): selectedAncillaryPrice is UIPriceBreakdown => !!selectedAncillaryPrice);

      total = pricingUtils.sumPriceBreakdown([cartBasePrice, ...ancillaryPrices]);
    } else {
      total = cartBasePrice;
    }

    return total;
  }, [flight, getAncillaries, store.cartDetails]);

  return {
    getAncillaries,
    getTotalPrice,
    getTotalPriceFromCart
  };
};

export default usePriceBreakDownForPreOptedAncillaries;
