import React from "react";
import { Divider } from "@bookingcom/bui-react";
import { LocationsModal } from "../Locations/index.js";
import ButtonFlip from "./ButtonFlip.js";
import useOriginDestination from "./useOriginDestination.js";
import ErrorMessage from "../Shell/ErrorMessage.js";
import { t } from "@bookingcom/lingojs-core";
import styles from "./OriginDestinationVertical.module.css";
const OriginDestinationVertical = (props) => {
    const { segmentIndex } = props;
    const { isMultiStop, isSubmitted, isValidSegment, i18n } = useOriginDestination({ segmentIndex });
    return (React.createElement("div", { className: styles.wrapper },
        React.createElement("div", { className: styles.inputWrapper },
            React.createElement(LocationsModal, { size: "large", segmentIndex: segmentIndex, type: "from" }),
            React.createElement(Divider, null),
            React.createElement(LocationsModal, { size: "large", segmentIndex: segmentIndex, type: "to" })),
        !isMultiStop ? (React.createElement("div", { className: styles.flipWrapper },
            React.createElement("div", { className: styles.flipBtnWrapper },
                React.createElement(ButtonFlip, { className: styles.flipBtn, segmentIndex: segmentIndex })))) : null,
        isSubmitted && !isValidSegment ? (React.createElement("div", { className: styles.errorWrapper },
            React.createElement(ErrorMessage, { variant: "inline", text: i18n.trans(t("flights_search_error_airport_duplicate")) }))) : null));
};
export default OriginDestinationVertical;
