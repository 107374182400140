import React, { useCallback } from "react";
import { InputCheckbox } from "@bookingcom/bui-react";
import useDirectFlight from "./useDirectFlight.js";
import { t } from "@bookingcom/lingojs-core";
import styles from "./DirectFlightCheckbox.module.css";
const DirectFlightCheckbox = () => {
    const { i18n, toggleIsDirect, isDirect, trackV2, showNonStopExp } = useDirectFlight();
    const handleOnChange = useCallback((checked) => {
        if (checked)
            trackV2("select_direct_flights_only");
        toggleIsDirect(checked);
    }, [toggleIsDirect, trackV2]);
    return (React.createElement("div", { className: styles.wrapper },
        React.createElement(InputCheckbox, { name: "sr_direct_flights", label: showNonStopExp ? i18n.trans(t("flights_search_nonstop_us")) : i18n.trans(t("flights_search_direct")), onChange: ({ checked }) => {
                handleOnChange(checked);
            }, checked: isDirect, id: "direct_flights_input_checkbox", className: styles.input, attributes: { "data-ui-name": "direct_flights" }, inputAttributes: { "data-ui-name": "direct_flights_input" } })));
};
export default DirectFlightCheckbox;
