/* istanbul ignore file */
/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React from "react";
import { Card, Icon, Image, Link, Stack, Text } from "@bookingcom/bui-react";
import { UIXNearbyDestination } from "cross-sell/types";
import { ArrowNavRightIcon } from "@bookingcom/bui-assets-react/streamline/index.js";
import { t } from "@bookingcom/lingojs-core";

import { useI18n } from "@bookingcom/lingojs-react";
import { useClickHandlers } from "cross-sell/hooks/useClickHandlers";
import styles from "./NearbyCard.module.css";

interface Props {
  destination: UIXNearbyDestination;
}

const NearbyCard = (props: Props) => {
  const i18n = useI18n();
  const { handleButtonClick } = useClickHandlers("NearbyDestinations");
  const destination = props.destination;

  const themeMapper = {
    16: i18n.trans(t("flights_xsell_nearby_stays_travel_theme_history")),
    888: i18n.trans(t("flights_xsell_nearby_stays_travel_theme_relaxation")),
    23: i18n.trans(t("flights_xsell_nearby_stays_travel_theme_romantic")),
    11: i18n.trans(t("flights_xsell_nearby_stays_travel_theme_beaches")),
    88: i18n.trans(t("flights_xsell_nearby_stays_travel_theme_nature")),
    73: i18n.trans(t("flights_xsell_nearby_stays_travel_theme_family")),
    19: i18n.trans(t("flights_xsell_nearby_stays_travel_theme_sightseeing")),
    29: i18n.trans(t("flights_xsell_nearby_stays_travel_theme_nightlife")),
    21: i18n.trans(t("flights_xsell_nearby_stays_travel_theme_foodie")),
    28: i18n.trans(t("flights_xsell_nearby_stays_travel_theme_shopping")),
    30: i18n.trans(t("flights_xsell_nearby_stays_travel_theme_budget")),
    1: i18n.trans(t("flights_xsell_nearby_stays_travel_theme_sailing")),
    14: i18n.trans(t("flights_xsell_nearby_stays_travel_theme_history")),
    82: i18n.trans(t("flights_xsell_nearby_stays_travel_theme_forests")),
    79: i18n.trans(t("flights_xsell_nearby_stays_travel_theme_wellness")),
    62: i18n.trans(t("flights_xsell_nearby_stays_travel_theme_wine")),
    69: i18n.trans(t("flights_xsell_nearby_stays_travel_theme_skiing")),
    18: i18n.trans(t("flights_xsell_nearby_stays_travel_theme_castles")),
    87: i18n.trans(t("flights_xsell_nearby_stays_travel_theme_lakes")),
    4: i18n.trans(t("flights_xsell_nearby_stays_travel_theme_diving")),
    92: i18n.trans(t("flights_xsell_nearby_stays_travel_theme_parks")),
    10: i18n.trans(t("flights_xsell_nearby_stays_travel_theme_surfing")),
    102: i18n.trans(t("flights_xsell_nearby_stays_travel_theme_desert")),
    118: i18n.trans(t("flights_xsell_nearby_stays_travel_theme_casinos")),
    81: i18n.trans(t("flights_xsell_nearby_stays_travel_theme_volcanoes")),
    101: i18n.trans(t("flights_xsell_nearby_stays_travel_theme_route_66"))
  };

  return (
    <Link
      className={styles.link}
      variant="secondary"
      attributes={{ target: "__blank" }}
      onClick={() => {
        handleButtonClick(destination.link, destination.name);
      }}
      href={destination.link}
    >
      <Card variant="neutral" fill={false} bleed={false}>
        <Stack direction={"row"} gap={4} alignItems={"center"}>
          <Image height="64px" width="64px" src={destination.photo} borderRadius={200} />
          <div className={styles.propertiesWrapper}>
            <div className={styles.infoWrapper}>
              <div className={styles.nameWrapper}>
                <Text variant="strong_1">{destination.name}</Text>
                <Text className={styles.themeName} variant="body_2">
                  {/* @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely. */}
                  {themeMapper[destination.theme.themeId]}
                </Text>
              </div>
            </div>
            <Text variant="body_2" color="neutral_alt" className={styles.properties}>
              {i18n.trans(
                t("flights_xsell_nearby_stays_property_number", {
                  num_exception: destination.properties,
                  variables: {
                    num_properties: destination.properties
                  }
                })
              )}
            </Text>
          </div>
          <Icon svg={ArrowNavRightIcon} />
        </Stack>
      </Card>
    </Link>
  );
};

export default NearbyCard;
