import React, { useMemo, forwardRef } from "react";
import { Icon } from "@bookingcom/bui-react";
import { ArrowNavDownIcon, WarningIcon } from "@bookingcom/bui-assets-react/streamline/index.js";
import ShellErrorMessage from "./ErrorMessage.js";
import styles from "./ButtonDropDown.module.css";
const ButtonDropDown = (props, ref) => {
    const { className, children, errorMessage, errorVariant, errorPosition, isHeaderUpdateExp, ...rest } = props;
    const classNames = useMemo(() => {
        return [styles.wrapper, errorMessage && styles.error, className].filter(Boolean).join(" ");
    }, [className, errorMessage]);
    return (React.createElement("button", { ...rest, type: "button", className: classNames, ref: ref },
        React.createElement("span", null, children),
        React.createElement(Icon, { svg: ArrowNavDownIcon, size: "medium" }),
        errorMessage ? React.createElement(Icon, { svg: WarningIcon, size: "medium", color: "destructive" }) : null,
        errorMessage ? (React.createElement(ShellErrorMessage, { variant: errorVariant, position: errorPosition, text: errorMessage, isHeaderUpdateExp: isHeaderUpdateExp })) : null));
};
export default forwardRef(ButtonDropDown);
