/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { FC, useState, useEffect } from "react";

interface Props {
  onSSR?: React.ReactNode;
  children: React.ReactNode;
}

const NoSSR: FC<Props> = (props) => {
  const { children, onSSR = null } = props;
  const [canRender, setCanRender] = useState(false);
  useEffect(() => {
    setCanRender(true);
  }, [setCanRender]);
  return <>{canRender ? children : onSSR}</>;
};

export default NoSSR;
