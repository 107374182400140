/* https://docs.google.com/document/d/16V_tuWP8CBpd0nkrak_iSug15OCDPXvWqJIJyc2iTYc/edit */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { useCallback, useMemo } from "react";
import { isOfMetaOrigin } from "utils/marketing-url-params";
import useUserAgent from "./useUserAgent";
// eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
import { useStore } from "../store";
import { EUROPEAN_COUNTRIES, INFANT_MAX_AGE } from "../constants";
import useGlobalContext from "./useGlobalContext";
import useRouteName from "./useRouteName";
import { differenceInCalendarDays } from "date-fns";

const parseDate = (s: string) => new Date(s);

const dateDiffInDays = (s: string, e: string) => {
  try {
    const start = parseDate(s);
    const end = parseDate(e);
    return differenceInCalendarDays(end, start);
  } catch (e) {
    return undefined;
  }
};

export default function useProfiler() {
  // eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
  const store = useStore();
  const routeName = useRouteName();
  const { isMobile: _isMobile } = useUserAgent();
  const { ipCountry } = useGlobalContext();
  const { order, flightDetails, searchCriteria, user, search } = store;

  const ip = useMemo(() => {
    if (["checkout3", "confirmation"].includes(routeName) || routeName.startsWith("pb-")) {
      return order?.order?.salesInfo?.ipCountry?.toLowerCase() || "";
    } else {
      return ipCountry?.toLowerCase() || "";
    }
  }, [routeName, order, ipCountry]);

  const tripType = useMemo(() => {
    const flightTripType = flightDetails?.flight?.tripType;
    const searchTripType = search?.type;
    return flightTripType || searchTripType || "";
  }, [flightDetails, search]);

  const passengers = useMemo(
    () =>
      order?.order?.passengers?.length
        ? order.order.passengers
        : flightDetails?.flight?.travellers?.length
        ? flightDetails.flight.travellers
        : [],
    [order, flightDetails]
  );

  const segments = useMemo(() => {
    return order?.order?.airOrder?.flightSegments?.length
      ? order.order.airOrder.flightSegments
      : flightDetails?.flight?.segments?.length
      ? flightDetails.flight.segments
      : [];
  }, [order, flightDetails]);

  const firstDepartureDay = useMemo(() => {
    const t = segments?.[0]?.departureTimeTz;
    return !t ? undefined : t;
  }, [segments]);

  const bookingDay = useMemo(() => {
    return order?.order?.airOrder?.bookedAt || new Date().toISOString();
  }, [order]);

  const noOfAdults = useMemo(() => {
    if (passengers?.length) return passengers.filter((_) => _.type === "ADULT").length;
    return searchCriteria?.adults || 1;
  }, [searchCriteria, passengers]);

  const noOfKids = useMemo(() => {
    if (passengers?.length)
      return passengers.filter(
        (_) => _.type === "KID" && ((_.age !== undefined && _.age > INFANT_MAX_AGE) || _.age === undefined)
      ).length;
    return searchCriteria?.children?.filter((_) => Number(_) > INFANT_MAX_AGE)?.length || 0;
  }, [searchCriteria, passengers]);

  const noOfInfants = useMemo(() => {
    if (passengers?.length)
      return passengers.filter((_) => _.type === "KID" && _.age !== undefined && _.age <= INFANT_MAX_AGE).length;
    return searchCriteria?.children?.filter((_) => Number(_) <= INFANT_MAX_AGE)?.length || 0;
  }, [searchCriteria, passengers]);

  // Booker ====================================================================
  const isMeta = useCallback(() => !!isOfMetaOrigin(), []);
  const isDirect = useCallback(() => !isOfMetaOrigin(), []);
  const isMobile = useCallback(() => !!_isMobile, [_isMobile]);
  const isDesktop = useCallback(() => !_isMobile, [_isMobile]);

  // Traveller =================================================================
  const isSolo = useCallback(
    () => noOfAdults === 1 && noOfKids === 0 && noOfInfants === 0,
    [noOfAdults, noOfKids, noOfInfants]
  );

  const isCouple = useCallback(
    () => noOfAdults === 2 && noOfKids === 0 && noOfInfants === 0,
    [noOfAdults, noOfKids, noOfInfants]
  );

  const isGroup = useCallback(
    () => noOfAdults > 2 && (noOfKids >= 0 || noOfInfants >= 0),
    [noOfAdults, noOfKids, noOfInfants]
  );

  const isFamily = useCallback(
    () => noOfAdults <= 2 && (noOfKids > 0 || noOfInfants > 0),
    [noOfAdults, noOfKids, noOfInfants]
  );
  const isWithInfants = useCallback(() => !!noOfInfants, [noOfInfants]);
  const isWithKids = useCallback(() => !!noOfKids, [noOfKids]);

  // Customer ==================================================================
  const isLoggedIn = useCallback(() => !!user?.userInfo?.email, [user]);
  const isLoggedOut = useCallback(() => !user?.userInfo?.email, [user]);
  const isGenius = useCallback(() => !!user?.userInfo?.geniusLevel && user?.userInfo?.geniusLevel > 0, [user]);
  const isHVC = useCallback(() => false, []); // Definition TBD

  // Trip ======================================================================
  const isInternational = useCallback(() => {
    if (segments.length) return segments.some((_) => _.departureAirport.country !== _.arrivalAirport.country);
    return !!searchCriteria?.toCountry?.some((_) => searchCriteria.fromCountry[0] !== _);
  }, [segments, searchCriteria]);

  const isDomestic = useCallback(() => {
    if (segments.length) return segments.every((_) => _.departureAirport.country === _.arrivalAirport.country);
    return !!searchCriteria?.toCountry?.every((_) => searchCriteria.fromCountry?.[0] === _);
  }, [segments, searchCriteria]);

  const isRountrip = useCallback(() => tripType === "ROUNDTRIP", [tripType]);

  const isMultiStop = useCallback(() => tripType === "MULTISTOP", [tripType]);

  const isOneWay = useCallback(() => tripType === "ONEWAY", [tripType]);

  const isShortHaul = useCallback(() => false, []); // Definition TBD

  const isMediumHaul = useCallback(() => false, []); // Definition TBD

  const isLongHaul = useCallback(() => false, []); // Definition TBD

  const isWeekendStay = useCallback(() => {
    if (segments.length < 2) return false;
    const start = segments?.[0]?.departureTime;
    const end = segments?.[1]?.departureTime;
    const diff = dateDiffInDays(start, end);
    if (diff === undefined || diff < 2 || diff > 4) return false;
    let includesSaturday = false;
    let includesMonday = false;
    for (let day = parseDate(start); day <= parseDate(end); day.setDate(day.getDate() + 1)) {
      const dayOfWeek = Number(day.getDay());
      if (dayOfWeek === 6) includesSaturday = true;
      if (dayOfWeek === 1) includesMonday = true;
    }
    return includesSaturday && includesMonday;
  }, [segments]);

  const isLongStay = useCallback(() => {
    if (segments.length < 2) return false;
    const start = segments?.[0]?.departureTime;
    const end = segments?.[1]?.departureTime;
    const diff = dateDiffInDays(start, end);
    if (diff === undefined) return false;
    return diff !== undefined && diff >= 7;
  }, [segments]);

  const isOneDay = useCallback(() => {
    if (segments.length < 2) return false;
    const start = segments?.[0]?.departureTime;
    const end = segments?.[1]?.departureTime;
    const diff = dateDiffInDays(start, end);
    return diff !== undefined && diff <= 1;
  }, [segments]);

  // Book window ===============================================================
  const isEarlyBird = useCallback(() => {
    if (!firstDepartureDay) return false;
    const diff = dateDiffInDays(bookingDay, firstDepartureDay);
    if (diff == undefined) return false;
    return diff > 31;
  }, [firstDepartureDay, bookingDay]);

  const isLateBird = useCallback(() => {
    if (!firstDepartureDay) return false;
    const diff = dateDiffInDays(bookingDay, firstDepartureDay);
    if (diff == undefined) return false;
    return diff >= 7 && diff <= 31;
  }, [firstDepartureDay, bookingDay]);

  const isLastMinute = useCallback(() => {
    if (!firstDepartureDay) return false;
    const diff = dateDiffInDays(bookingDay, firstDepartureDay);
    if (diff == undefined) return false;
    return diff < 7;
  }, [firstDepartureDay, bookingDay]);

  // Flexibility ===============================================================
  const isSensitivePrice = useCallback(() => false, []); // Definition TBD
  const isSensitiveDate = useCallback(() => false, []); // Definition TBD
  const isSensitiveTime = useCallback(() => false, []); // Definition TBD

  // Region ====================================================================
  const isAmerican = useCallback(() => ip === "us", [ip]);

  const isEuropean = useCallback(() => EUROPEAN_COUNTRIES.includes(ip), [ip]);

  const isGlobal = useCallback(() => !!ip && !isEuropean() && !isAmerican(), [isEuropean, isAmerican, ip]);

  const isPointOfSale = useCallback((code: string) => ip === code.toLowerCase(), [ip]);

  return {
    isAmerican,
    isCouple,
    isDesktop,
    isDirect,
    isDomestic,
    isEarlyBird,
    isEuropean,
    isFamily,
    isGenius,
    isGlobal,
    isGroup,
    isInternational,
    isLastMinute,
    isLateBird,
    isLoggedIn,
    isLoggedOut,
    isLongStay,
    isMeta,
    isMobile,
    isMultiStop,
    isOneDay,
    isOneWay,
    isPointOfSale,
    isRountrip,
    isSolo,
    isWeekendStay,
    isWithInfants,
    isWithKids
  };
}
