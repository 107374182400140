import React, { useRef } from "react";
import { useRemoteComponent } from "@bookingcom/remote-component";
import useRouteName from "hooks/useRouteName";
import { RouteName } from "app/routes";
import { TravellerFooter } from "@bookingcom/web-shell";
import { useI18n, t } from "@bookingcom/lingojs-react";
import useUserAgent from "hooks/useUserAgent";
import { useTrackLandingPageViewport } from "hooks/c360/useLandingPageEvents";

const NO_FOOTER_PAGE_LIST: RouteName[] = [
  "checkout-fare",
  "checkout-seat-selection",
  "checkout-sirf",
  "checkout-start",
  "checkout-ticket-type",
  "checkout",
  "checkout2",
  "checkout3",
  "confirmation",
  "pb-checkout-payment",
  "alternative-fares"
];

function FooterFallback() {
  const i18n = useI18n();
  return (
    <TravellerFooter
      variant="minimal"
      footerItems={[]}
      copyrightText={
        <div style={{ textAlign: "center" }}>
          <div>{i18n.trans(t("footer_booking_holdings", { variables: { b_companyname: "Booking.com" } }))}</div>
          <div>
            {i18n.trans(
              t("flights_footer_copyright", {
                variables: { copyright_symbol: "©", b_companyname: "Booking.com", year: new Date().getFullYear() }
              })
            )}
          </div>
        </div>
      }
    />
  );
}

export default function Footer() {
  const routeName = useRouteName();
  const { isMobile } = useUserAgent();
  const { isWebview } = useUserAgent();
  const remoteFooter = useRemoteComponent(
    {
      project: "web-shell",
      service: "header-mfe",
      path: "/TravellerFooter/Flights",
      type: "capla"
    },
    {
      fallbackComponent: FooterFallback
    }
  );
  const scrollRef = useRef<HTMLDivElement>(null);

  useTrackLandingPageViewport(scrollRef, "whole_page");

  if (
    routeName === "not-available" ||
    (isMobile && routeName === "flightdetails") ||
    (isMobile && isWebview && routeName === "searchresults") ||
    (isMobile && isWebview && routeName === "landing") ||
    (isMobile && isWebview && routeName === "home") ||
    NO_FOOTER_PAGE_LIST.includes(routeName)
  ) {
    return null;
  }

  const { Component } = remoteFooter;
  return (
    <div ref={scrollRef}>
      <Component />
    </div>
  );
}
