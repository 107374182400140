/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import WorldwideFlightsItem from "./WorldwideFlightsItem";
import { Carousel, Tab, Title } from "@bookingcom/bui-react";
import { t } from "@bookingcom/lingojs-core";
import { useI18n } from "@bookingcom/lingojs-react";
import { COMPANY_NAME } from "../../../constants";
import { UIWorldwideFlightInfo } from "@flights/types";
import { Panel } from "@bookingcom/bui-react/components/Tab/Tab.types";
import Frame from "../Frame";
import styles from "./WorldwideFlights.module.css";
import { useTrackLandingPageViewport } from "hooks/c360/useLandingPageEvents";
import { getWorldwideFlights } from "store/worldwideFlights/selector";

const groupContinentFlightsIntoColumns = (flights: UIWorldwideFlightInfo[]): Map<string, UIWorldwideFlightInfo[][]> => {
  const flightsMap = new Map<string, UIWorldwideFlightInfo[]>();

  flights.forEach((flight) => {
    flightsMap.set(flight.continentId, flightsMap.get(flight.continentId) || []);
    flightsMap.get(flight.continentId)?.push(flight);
  });

  const flightsMapGrouped = new Map<string, UIWorldwideFlightInfo[][]>();
  flightsMap.forEach((continentFlights, continentId) => {
    const grouped: UIWorldwideFlightInfo[][] = [];
    const chunkSize = 3;
    for (let start = 0; start < continentFlights.length; start += chunkSize) {
      grouped.push(continentFlights.slice(start, start + chunkSize));
    }
    flightsMapGrouped.set(continentId, grouped);
  });

  return flightsMapGrouped;
};

const WorldwideFlightsMobile: React.FunctionComponent = () => {
  const i18n = useI18n();
  const worldwideFlights = useSelector(getWorldwideFlights);
  const scrollRef = useRef<HTMLDivElement>(null);
  const flightsMapGrouped = useMemo(() => groupContinentFlightsIntoColumns(worldwideFlights || []), [worldwideFlights]);

  const continents = useMemo(() => {
    const continentIds: string[] = [];
    flightsMapGrouped.forEach((_, continentId: string) => {
      continentIds.push(continentId);
    });
    continentIds.sort();
    return continentIds;
  }, [flightsMapGrouped]);

  const continentNameTranslations: string[][] = continents.map((continentId) => {
    // This tricks the lingo static analyser to extract tags and fetch translations for all available continents.
    return [continentId, i18n.trans(t("continent", continentId, "name"))];
  });

  const renderColumn = (flights: UIWorldwideFlightInfo[], index: number) => {
    return (
      <div key={`con-${index}`} className={styles.mobileColumn}>
        {flights.map((flight: UIWorldwideFlightInfo, index: number) => (
          <WorldwideFlightsItem flight={flight} key={index} />
        ))}
      </div>
    );
  };

  const renderCarousel = (columns: UIWorldwideFlightInfo[][], key: number) => {
    return (
      <Carousel
        nextButtonAriaLabel={i18n.trans(t("a11y_flights_route_carousel_next_button_aria_label"))}
        previousButtonAriaLabel={i18n.trans(t("a11y_flights_route_carousel_previous_button_aria_label"))}
        key={key}
      >
        {columns.map((item, index) => renderColumn(item, index))}
      </Carousel>
    );
  };

  const triggerList: { text: string; id: string }[] = [];
  const panelList: Panel[] = [];

  continentNameTranslations.map((translation: string[]) => {
    triggerList.push({
      text: translation[1],
      id: translation[0]
    });
  });

  continents.map((continentId, index) => {
    panelList.push({
      id: continentId,
      children: renderCarousel(flightsMapGrouped.get(continentId) || [], index)
    });
  });

  useTrackLandingPageViewport(scrollRef, "worldwide_flights");

  return continents.length > 0 ? (
    <Frame attributes={{ style: { width: "100%" } }} grow={1} p={4} elementRef={scrollRef}>
      <Title
        className={styles.title}
        title={i18n.trans(
          t("seo_flights_new_index_lp_linking_block_header", { variables: { b_companyname: COMPANY_NAME } })
        )}
        subtitle={i18n.trans(t("seo_flights_new_index_lp_linking_block_subheader"))}
        variant="headline_2"
        titleTagName="h2"
      />
      <Tab className={styles.tabs}>
        <Tab.TriggerList>
          {triggerList.map((trigger) => (
            <Tab.Trigger key={trigger.id} {...trigger} />
          ))}
        </Tab.TriggerList>
        {panelList.map((panel) => (
          <Tab.Panel key={panel.id} id={panel.id}>
            <div className={styles.tabPanelContent}>{panel.children}</div>
          </Tab.Panel>
        ))}
      </Tab>
    </Frame>
  ) : null;
};
export default WorldwideFlightsMobile;
