import React, { Profiler } from "react";
import AppShell from "components/elements/AppShell";
import NotFoundInner from "components/elements/NotFound";
import Frame from "components/elements/Frame";
import SearchLoadingScreen from "components/elements/LoadingScreen/components/SearchLoadingScreen";
import { PageHeader } from "./Search";
import { useStore } from "store";
import useClientMetrics from "hooks/useClientMetrics";

const NotFound = function () {
  const store = useStore();
  const { trackRenderTime } = useClientMetrics();

  const { searchSegments } = store.search;

  return (
    <Profiler id="screen" onRender={trackRenderTime}>
      <PageHeader segments={searchSegments} />
      <AppShell>
        <Frame mr={4} mb={4} ml={4}>
          <NotFoundInner />
        </Frame>
        <SearchLoadingScreen />
      </AppShell>
    </Profiler>
  );
};

export default NotFound;
