import React, { useCallback, useEffect } from "react";
import useOccupancy from "./useOccupancy.js";
import { InputSelect, Text, Alert } from "@bookingcom/bui-react";
import { t } from "@bookingcom/lingojs-core";
import { Trans } from "@bookingcom/lingojs-react";
import styles from "./Ages.module.css";
const Ages = (props) => {
    const { occupancy, onChange } = props;
    const { children } = occupancy;
    const { i18n, isSubmitted, isValidInfants, lang } = useOccupancy();
    const defaultOptions = [
        { text: i18n.trans(t("flights_search_box_child_age_field")), value: "" },
        { text: "0", value: "0" },
        { text: "1", value: "1" },
        { text: "2", value: "2" },
        { text: "3", value: "3" },
        { text: "4", value: "4" },
        { text: "5", value: "5" },
        { text: "6", value: "6" },
        { text: "7", value: "7" },
        { text: "8", value: "8" },
        { text: "9", value: "9" },
        { text: "10", value: "10" },
        { text: "11", value: "11" },
        { text: "12", value: "12" },
        { text: "13", value: "13" },
        { text: "14", value: "14" },
        { text: "15", value: "15" },
        { text: "16", value: "16" },
        { text: "17", value: "17" }
    ];
    const handleOnChange = useCallback((age, idx) => {
        const result = [];
        for (let i = 0; i < children.length; i++) {
            result[i] = children[i];
        }
        result[idx] = age;
        onChange(result);
    }, [children, onChange]);
    useEffect(() => {
        // hack - so the tag get extracted
        // TODO - investigate why <Trans /> is not recognized
        i18n.trans(t("flights_search_error_infant_exceed", {
            variables: { start_link: 0, end_link: 0 }
        }));
    }, []); //eslint-disable-line
    return (React.createElement(React.Fragment, null,
        !isValidInfants && isSubmitted ? (React.createElement(Alert, { variant: "error", text: React.createElement(Trans, { tag: "flights_search_error_infant_exceed", variables: { start_link: "<0>", end_link: "</0>" }, components: [
                    React.createElement("a", { href: `https://secure.booking.com/help/faq/40/RmxpZ2h0cyAtIEZBUXMgLSBCb29rIGJhYnkgKE1heSAyMDIzKSAxMDc2Mjg?category=flights_faq_topic_passenger&lang=${lang}`, target: "_blank", rel: "noopener noreferrer", key: 0 })
                ] }) })) : null,
        React.createElement("div", { className: styles.wrapper }, occupancy.children.map((age, idx) => {
            const options = [...defaultOptions];
            if (typeof age === "number")
                options.shift();
            const childNumOrdinal = i18n.trans(t("ordinal_numbers_non_fancy", String(idx + 1), "name"));
            return (React.createElement(InputSelect, { key: `${idx} - ${occupancy.children.length}`, name: `sr_occupancy_children_age_${idx}`, value: age !== undefined ? String(age) : null, onChange: ({ value }) => {
                    handleOnChange(Number(value), idx);
                }, options: options, attributes: { "data-ui-name": `occupancy_children_age_${idx}` }, inputAttributes: {
                    "data-ui-name": `select_occupancy_children_age_${idx}`
                }, label: i18n.trans(t("flights_child_age_header", {
                    variables: { child_num_ordinal: childNumOrdinal }
                })), helper: React.createElement(Text, { variant: "small_1" }, i18n.trans(t("flights_search_child_age_helper"))), error: isSubmitted && (age === undefined || age === null) ? (React.createElement(Text, { variant: "small_1" }, i18n.trans(t("flights_search_child_age_helper")))) : undefined }));
        }))));
};
export default Ages;
