import { useEffect } from "react";
import useRouteName from "./useRouteName";
import useEvents from "./useEvents";

/**
 * This hook allows to track web-vitals through web shell header.
 *
 * For client side routing we can only measure performance for the
 * first page, or else we get mixed results between pages. So we
 * need to tell the header tracker when a navigation happens,
 * this is done by calling `__webShellUpdateRoute` method that's
 * exposed in the header.
 *
 */
const useHeaderRouteChange = () => {
  const routeName = useRouteName();
  const { squeak, jsError } = useEvents();

  useEffect(() => {
    try {
      if (window.__webShellUpdateRoute) {
        window.__webShellUpdateRoute(routeName);
      } else {
        squeak("web-shell: route update interface not found");
      }
    } catch (e) {
      jsError(e);
    }
  }, [routeName, squeak, jsError]);
};

declare global {
  interface Window {
    __webShellUpdateRoute?: (routeName: string) => void;
  }
}

export default useHeaderRouteChange;
