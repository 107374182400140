import { useSelector } from "react-redux";
import { getMarketingTrackingVariable } from "../utils/marketing-url-params";
import useGlobalContext from "./useGlobalContext";
import { getFlightPointOfSale } from "store/flightDetails/selectors";
import { getOrderCountry } from "store/order/selectors";
import { getSearchPointOfSale } from "store/searchResults/selectors";

/*
Returns pointOfSale to be used in different features like currency localisation, offer attribution etc
https://docs.google.com/spreadsheets/d/18Ik2u1kLjRccVRFT56LQMf_FvPhbCZMjlwlpqLjaj9E/edit#gid=392204482
PointOfSale to be used primarily from flight offer, order or from search results where its already engraved in.

salesCountry param used is the meta channel override for manually setting a POS
See https://docs.google.com/document/d/1iKcj9S8qNcIcytWui0_yI7I7KXXrMxrgyBD0wGfSkHM/edit#heading=h.2x8r7gx4p9on

Defaults to ipCountry when none of the above is available, for eg usages in home page
*/

export default function usePointOfSale(): string | undefined {
  const flightPointOfSale = useSelector(getFlightPointOfSale);
  const orderCountry = useSelector(getOrderCountry);
  const searchPointOfSale = useSelector(getSearchPointOfSale);
  const { ipCountry } = useGlobalContext();

  const pointOfSale =
    flightPointOfSale ||
    orderCountry ||
    searchPointOfSale ||
    getMarketingTrackingVariable("salesCountry") ||
    ipCountry ||
    undefined;

  return pointOfSale && pointOfSale.toLowerCase();
}
