import { flightsLocalStore } from "utils/local-store";

type DevOverrides = Record<string, string>;

const STORAGE_KEY = "flights-dev-overrides";
const storage = flightsLocalStore || undefined;

export const getAll = (): DevOverrides => {
  return storage?.get(STORAGE_KEY) || {};
};

export const setAll = (data: DevOverrides) => {
  return storage?.set(STORAGE_KEY, data);
};
