/* This is purely to help tracking, remove if fullon */
import { useCallback, useContext, useMemo } from "react";
import { SearchBoxContext } from "../../components/SearchBoxContext/index.js";
const useProfiler = () => {
    const { occupancy: { adults, children }, infantMaxAge } = useContext(SearchBoxContext);
    const noOfAdults = adults;
    const noOfKids = useMemo(() => children?.filter((_) => Number(_) > infantMaxAge)?.length || 0, [children, infantMaxAge]);
    const noOfInfants = useMemo(() => children?.filter((_) => Number(_) <= infantMaxAge)?.length || 0, [children, infantMaxAge]);
    const isSolo = useCallback(() => noOfAdults === 1 && noOfKids === 0 && noOfInfants === 0, [noOfAdults, noOfKids, noOfInfants]);
    const isCouple = useCallback(() => noOfAdults === 2 && noOfKids === 0 && noOfInfants === 0, [noOfAdults, noOfKids, noOfInfants]);
    const isFamily = useCallback(() => noOfAdults <= 2 && (noOfKids > 0 || noOfInfants > 0), [noOfAdults, noOfKids, noOfInfants]);
    return {
        isSolo,
        isCouple,
        isFamily
    };
};
export default useProfiler;
/* ------------------------------------------------- */
