/**
 * UI component
 * this file should hold only the UI and UI state
 * move any logic to the mini controller
 */
import React from "react";
import { LocationsDropDown } from "../Locations/index.js";
import ButtonFlip from "./ButtonFlip.js";
import ErrorMessage from "../Shell/ErrorMessage.js";
import useOriginDestination from "./useOriginDestination.js";
import { t } from "@bookingcom/lingojs-core";
import styles from "./OriginDestinationHorizontal.module.css";
const OriginDestinationHorizontal = (props) => {
    const { segmentIndex } = props;
    const { isValidSegment, isSubmitted, i18n } = useOriginDestination({
        segmentIndex
    });
    return (React.createElement("div", { className: styles.wrapper, "data-ui-name": `segment_origin_destination_${segmentIndex}` },
        React.createElement(LocationsDropDown, { type: "from", size: "medium", segmentIndex: segmentIndex }),
        React.createElement(ButtonFlip, { segmentIndex: segmentIndex }),
        React.createElement(LocationsDropDown, { type: "to", size: "medium", segmentIndex: segmentIndex }),
        isSubmitted && !isValidSegment ? (React.createElement(ErrorMessage, { variant: "tooltip", text: i18n.trans(t("flights_search_error_airport_duplicate")) })) : null));
};
export default OriginDestinationHorizontal;
