import { useSelector } from "react-redux";
import { getBrandedFareOffers } from "store/flightDetails/selectors";
import { getFareOffersWithDetails } from "store/brandedFareOffers/selectors";
import useGetUrlParams from "hooks/useGetUrlParams";

const useAvailableBrandedFares = () => {
  const { getUrlParam } = useGetUrlParams();
  const baseOfferToken = process.env.BUILD_TARGET === "client" && getUrlParam("baseOfferToken");
  const brandedFareOffers = useSelector(getBrandedFareOffers) || [];
  const fareOffersWithDetails = useSelector(getFareOffersWithDetails);

  if (!baseOfferToken) {
    return brandedFareOffers;
  }

  return fareOffersWithDetails[baseOfferToken]?.brandedFareOffers || brandedFareOffers;
};

export default useAvailableBrandedFares;
