import { UIClientFetchError, UIEsim, UIFetchStatus } from "@flights/types";

interface EsimState {
  fetchStatus: UIFetchStatus;
  error?: UIClientFetchError;
  esim?: UIEsim;
}

export const getInitialState = (): EsimState => ({
  fetchStatus: "initial",
  esim: {},
  error: undefined
});

export type State = EsimState;
