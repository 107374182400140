import { Actions, ActionTypes } from "./actions";
import { State, getInitialState } from "./state";

const initialState = getInitialState();

const reducer = (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case ActionTypes.authenticate:
      return {
        ...state
      };
    case ActionTypes.logOut:
      return {};

    default:
      return state;
  }
};

export { reducer, getInitialState };
