import { isOfMetaOrigin } from "utils/marketing-url-params";
// eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
import { __deprecated__createTrackingUtils } from "..";

// eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
const utils = __deprecated__createTrackingUtils({
  name: "flights_web_ddot_side_sorting_2",
  stages: {
    search_results: 1,
    search_results_direct: 2, // Main,
    search_results_meta: 3,
    traveller_solo: 4,
    traveller_2plus: 5,
    traveller_with_children: 6
  },
  goals: {},
  goalsWithValue: []
});

const { stages } = utils;

type Params = {
  travellersCount: number;
  hasChildren: boolean;
};

export const trackSortingToSide = ({ travellersCount, hasChildren }: Params) => {
  stages.search_results();

  isOfMetaOrigin() ? stages.search_results_meta() : stages.search_results_direct();

  if (travellersCount === 1) {
    stages.traveller_solo();
  }

  if (travellersCount >= 2) {
    stages.traveller_2plus();
  }

  if (hasChildren) {
    stages.traveller_with_children();
  }
};
