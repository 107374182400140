import { ActionsUnion, createAction } from "@bookingcom/flights-core";
import { UITravelPurpose } from "@flights/types";

export enum ActionTypes {
  setTravelPurpose = "travelPurpose/setTravelPurpose"
}

export const actions = {
  setTravelPurpose: createAction((data: UITravelPurpose) => ({
    type: ActionTypes.setTravelPurpose,
    payload: data
  }))
};

export type Actions = ActionsUnion<typeof actions>;
