import { FlightsErrorReporter } from "../utils/report-error";
import { useCallback, useEffect } from "react";
import useContextName from "./useContextName";
import useGlobalContext from "./useGlobalContext";
import { grumble, squeak, warning } from "utils/flog";

export default function useEvents() {
  const contextName = useContextName();
  const { requestId, etStateBlob } = useGlobalContext();

  useEffect(() => {
    FlightsErrorReporter.getInstance().resetErrorReporter(contextName, requestId, etStateBlob);
  }, [contextName, requestId, etStateBlob]);

  // Get singleton instance of error reporter
  const jsError = useCallback((error: Error) => {
    FlightsErrorReporter.getInstance().sendError(error);
  }, []);

  return { grumble, squeak, warning, jsError };
}
