import { SkeletonLoader, Stack } from "@bookingcom/bui-react";
import * as React from "react";

type Props = {
  amount?: number;
};

export function SkeletonListKeyValue({ amount = 3 }: Props) {
  const items: Array<React.ReactElement> = [];

  for (let i = 0; i < amount; i++) {
    items.push(
      <Stack key={i} direction="row" justifyContent="space-between" gap={8}>
        <SkeletonLoader width="calc(80% - 72px)" />
        <SkeletonLoader width="48px" />
      </Stack>
    );
  }

  return <Stack gap={4}>{items}</Stack>;
}
