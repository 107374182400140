import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import useGlobalContext from "./useGlobalContext";

export default function useUserAgent() {
  const { userAgent } = useGlobalContext();
  const location = useLocation();
  const search = location.search || "";

  return useMemo(() => {
    const qs = new URLSearchParams(search);
    const { isMobile = false, isTablet = false, isWebview = false } = userAgent;

    if (qs.has("mobile")) {
      return { isMobile: true, isTablet: false, isWebview: false };
    }

    if (qs.has("desktop")) {
      return { isMobile: false, isTablet: false, isWebview: false };
    }

    return {
      isMobile,
      isTablet,
      isWebview
    };
  }, [userAgent, search]);
}
