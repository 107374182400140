export const getSearchBoxTrackingObj = (inputValue, searchInputs, segment) => {
    return searchInputs.map((searchInput) => {
        return {
            input: inputValue,
            type: searchInput.type,
            code: searchInput.code,
            order: searchInput.autoCompleteResultIndex,
            segment,
            query: inputValue,
            iata: searchInput.code,
            position: searchInput.autoCompleteResultIndex
        };
    });
};
export const getSearchBoxGAData = (searchInputs) => {
    return searchInputs
        .map((searchInput) => {
        return `${searchInput.type}_${searchInput.code}`;
    })
        .join("|");
};
