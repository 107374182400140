import { HiddenVisually } from "@bookingcom/bui-react";
import { useFormatDateTime } from "@bookingcom/flights-core";
import { formatPriceForFareCalendar } from "../../hooks/useFareCalendar/utils.js";
import { t, useI18n } from "@bookingcom/lingojs-react";
import React, { useMemo } from "react";
const FareCalendarA11yAnnouncer = (props) => {
    const i18n = useI18n();
    const { formats } = useFormatDateTime(i18n);
    const lowerPriceCopy = useMemo(() => {
        if (!props?.lowestPricesData)
            return;
        if (props.lowestPricesData.dates.length === 0)
            return;
        return props.lowestPricesData.dates.reduce((acc, d, index) => {
            // For some reason TypeScript don't know "props.lowestPricesData" exists
            if (props.lowestPricesData?.price) {
                if (index === 0) {
                    acc += i18n.trans(t("a11y_flights_fare_calendar_cheapest", {
                        variables: {
                            price: formatPriceForFareCalendar(props.lowestPricesData.price, { hideSymbol: false }),
                            date: formats.flightDateWeekday(d)
                        }
                    }));
                }
                else {
                    acc += i18n.trans(t("a11y_flights_fare_calendar_cheapest_multi_comma", {
                        variables: {
                            next_date: formats.flightDateWeekday(d)
                        }
                    }));
                }
            }
            return acc;
        }, "");
    }, [formats, i18n, props?.lowestPricesData]);
    const content = useMemo(() => {
        if (!props)
            return "";
        if (props.status === "initial")
            return "";
        let result = "";
        result +=
            props.status === "loading"
                ? i18n.trans(t("a11y_flights_fare_calendar_loading"))
                : i18n.trans(t("a11y_flights_fare_calendar_loaded"));
        if (lowerPriceCopy) {
            result += i18n.trans(t("a11y_flights_fare_calendar_cheapest_multi_comma", {
                variables: {
                    next_date: lowerPriceCopy
                }
            }));
        }
        return result;
    }, [i18n, lowerPriceCopy, props]);
    return (React.createElement(HiddenVisually, null,
        React.createElement("div", { "aria-live": "polite" }, content)));
};
export default FareCalendarA11yAnnouncer;
