import { ORDER_TOKEN_VALIDITY, MANDATORY_SEARCH_PARAMS_ONEWAY } from "@flights/constants";
// Name contains Latin 1 characters and an optional hyphen. Allow multiple space-separated names.
// see https://en.wikipedia.org/wiki/ISO/IEC_8859-1#Code_page_layout
export const isValidLatinName = (name) => /^[a-z A-Z\u00C0-\u00ff]+([-'][a-z A-Z\u00C0-\u00ff]+)?$/g.test(name);
export function isTokenExpired(isodatetime) {
    const tokenCreationTime = Date.parse(isodatetime);
    const currentTime = Date.now();
    return (currentTime - tokenCreationTime) / 1000 > ORDER_TOKEN_VALIDITY;
}
/**  Verify we are not doing open redirection. @see https://jira.booking.com/jira/browse/FLIN-302 */
export function isValidRelativeURL(url) {
    const baseUrl = "https://flights.booking.com/";
    // Remove leading slash if any (as we set redirectURL from location.path)
    const relativePath = url.replace(/^\/+/, "");
    // This works because new URL() will ignore the $baseUrl if $url is an absolute url,
    // hence they won't match later.
    const testURL = new URL(url, baseUrl);
    if (testURL.href == baseUrl + relativePath) {
        return true;
    }
    return false;
}
//basic mandatory param check for search results page url
export const isValidSearchURL = (paramString, withPath) => {
    if (withPath) {
        paramString = paramString.split("?")[1];
    }
    const searchParams = new URLSearchParams(paramString);
    const invalidParam = MANDATORY_SEARCH_PARAMS_ONEWAY.find((mandatoryParam) => {
        return !searchParams.get(mandatoryParam);
    });
    if (invalidParam) {
        return false;
    }
    if (searchParams.get("type") === "ONEWAY") {
        return true;
    }
    else {
        // eslint-disable-next-line no-restricted-syntax -- this line was auto generated, hence fix the issue timely
        return searchParams.get("return") !== null;
    }
};
