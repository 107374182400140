import { ClassicPerformanceMetricName } from "@flights/types";

// Types.
type Metric = {
  name: ClassicPerformanceMetricName;
  value: number;
};

type ReportHandler = {
  (metric: Metric): void;
};

const debug = require("debug")("utils:client-performance-metrics");

// Globals.
let resourcesTransferSize = 0;
let totalLookupDuration = 0;

const isNumber = (x: any) => {
  return !isNaN(x) && typeof x === "number";
};

const reportNavEvents = (
  navigationEvents: PerformanceNavigationTiming | PerformanceTiming,
  onReport: ReportHandler
) => {
  const connectStart = navigationEvents.connectStart;

  const windowLoadTime = navigationEvents.loadEventStart - navigationEvents.connectStart;
  onReport({ name: "nav_window_load", value: windowLoadTime });
  const windowLoadTimeEnd = navigationEvents.loadEventEnd - navigationEvents.connectStart;
  onReport({ name: "nav_window_load_end", value: windowLoadTimeEnd });

  const domComplete = navigationEvents.domComplete - connectStart;
  onReport({ name: "nav_dom_complete", value: domComplete });

  const domContentLoaded = navigationEvents.domContentLoadedEventStart - connectStart;
  onReport({ name: "nav_dom_content_loaded", value: domContentLoaded });
  const domContentLoadedEnd = navigationEvents.domContentLoadedEventEnd - connectStart;
  onReport({ name: "nav_dom_content_loaded_end", value: domContentLoadedEnd });

  const domInteractive = navigationEvents.domInteractive - connectStart;
  onReport({ name: "nav_dom_interactive", value: domInteractive });

  const ttfb = navigationEvents.responseStart - connectStart;
  onReport({ name: "nav_ttfb", value: ttfb });
  const ttlb = navigationEvents.responseEnd - connectStart;
  onReport({ name: "nav_ttlb", value: ttlb });

  const connectEnd = navigationEvents.connectEnd - connectStart;
  onReport({ name: "nav_connect", value: connectEnd });

  const dnsLookup = navigationEvents.domainLookupEnd - navigationEvents.domainLookupStart;
  onReport({ name: "nav_dns_lookup", value: dnsLookup });

  const redirect = navigationEvents.redirectEnd - navigationEvents.redirectStart;
  onReport({ name: "nav_redirect", value: redirect });
};

const processPerformance = (performance: Performance, onReport: ReportHandler) => {
  debug("Processing performance object - deprecated", performance);

  const timing = performance.timing;

  reportNavEvents(timing, onReport);

  const redirects = performance.navigation.redirectCount;
  onReport({ name: "redirects", value: redirects });
};

const processNavigationEntry = (entry: PerformanceNavigationTiming, onReport: ReportHandler) => {
  debug("Processing navigation entry", entry);

  reportNavEvents(entry, onReport);

  const redirects = entry.redirectCount;
  onReport({ name: "redirects", value: redirects });
};

const processResourceEntry = (
  entry: PerformanceResourceTiming | undefined,
  onReport: ReportHandler,
  flush: boolean
) => {
  if (entry && !flush) {
    debug("Processing resource entry", entry);
    const lookupDuration = entry.domainLookupEnd - entry.domainLookupStart;

    resourcesTransferSize += entry.transferSize;
    totalLookupDuration += lookupDuration;
    debug("Incremeting resourcesTransferSize to", resourcesTransferSize);
    debug("Incremeting totalLookupDuration to", totalLookupDuration);
    return;
  }

  debug("Reporting assets_transfer_size", resourcesTransferSize);
  isNumber(resourcesTransferSize) && onReport({ name: "assets_transfer_size", value: resourcesTransferSize });
};

const processPaintEntry = (entry: PerformanceEntry, onReport: ReportHandler) => {
  const startTime = entry.startTime;
  debug("Processing paint entry", entry);

  switch (entry.name) {
    case "first-paint":
      debug("Reporting first_paint", startTime);
      onReport({ name: "first_paint", value: startTime });
      break;

    case "first-contentful-paint":
      debug("Reporting first_contentful_paint", startTime);
      onReport({ name: "first_contentful_paint", value: startTime });
      break;
  }
};

const processEntries = (entries: PerformanceEntry[], type: string, onReport: ReportHandler) => {
  const entryProcessors = {
    navigation: processNavigationEntry,
    resource: processResourceEntry,
    paint: processPaintEntry
  };

  entries.forEach((entry, index) => {
    const flush = entries.length === index + 1;
    // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
    entryProcessors[entry.entryType](entry.toJSON(), onReport, flush);
  });
};

const report = (onReport: ReportHandler) => {
  const SUPPORTED_TYPES: string[] = ["paint", "resource", "navigation"];

  SUPPORTED_TYPES.forEach((type) => {
    const entries = performance.getEntriesByType(type);
    // Handle fallback in case of no navigation performance entry
    // Safari doesn't support navigation performance API
    if (type === "navigation" && entries.length === 0) {
      processPerformance(performance, onReport);
      return;
    }
    processEntries(entries, type, onReport);
  });
};

const loadEventEndExists = () => {
  const navigationEntries = performance.getEntriesByType("navigation");
  if (navigationEntries.length > 0) {
    const navigationEntry = navigationEntries[0].toJSON();
    return !!navigationEntry.loadEventEnd;
  }
  return !!performance.timing.loadEventEnd;
};

const initializePerformanceObserver = (onReport: ReportHandler) => {
  const entries = ["navigation", "resource", "paint"];

  try {
    /**
     * I am using performance observer here as a mere polling mechanism to check if page is fully loaded,
     * and the corresponding performance values are populated.
     *
     * Some caveats that led to me to go this route:
     * 1. Performance observer doesn't observe paint entries.
     * 2. Just adding an onload event listener is not a guarantee that loadEventEnd value is populated,
     * which I need in the window load metrics
     */
    const observer = new PerformanceObserver(() => {
      if (loadEventEndExists()) {
        report(onReport);
        observer.disconnect();
      }
    });

    observer.observe({ entryTypes: entries });
  } catch (e) {
    debug("An error occurred in creating the performanceObserver.", e);
  }
};

export { initializePerformanceObserver, Metric, ReportHandler };
