import React, { PropsWithChildren, createContext } from "react";
import { useOrchestratorContext, OrchestratorContext } from "./OrchestratorContext";

export type OrchestratorContext = ReturnType<typeof useOrchestratorContext>;
export const CheckoutPaxContext = createContext<OrchestratorContext>(undefined as any);

export default function OrchestratorProvider(props: PropsWithChildren<{}>) {
  const orchestrator = useOrchestratorContext();
  return <OrchestratorContext.Provider value={orchestrator}>{props.children}</OrchestratorContext.Provider>;
}
