import React, { useCallback } from "react";
import { GeoPinIcon, PlaneTripIcon } from "@bookingcom/bui-assets-react/streamline/index.js";
import { HiddenVisually, Icon, InputCheckbox, Text } from "@bookingcom/bui-react";
import { t } from "@bookingcom/lingojs-core";
import styles from "./List.module.css";
const List = (props) => {
    const { list, selected = [], i18n, enableMultiSelect, add, addMulti, remove, disableIndent } = props;
    const isNested = useCallback((idx) => {
        const current = list[idx];
        const prev = list[idx - 1];
        if (!current.parent)
            return false;
        return current.parent === prev?.parent || current.parent === prev?.code;
    }, [list]);
    const isChecked = useCallback((idx) => {
        const current = list[idx];
        return selected.some((loc) => {
            return loc?.code === current?.code && loc.type === current.type;
        });
    }, [list, selected]);
    const isParentSelected = useCallback((idx) => {
        const current = list[idx];
        return selected.some((loc) => {
            return loc.code === current.parent && loc.type === "CITY" && current.type === "AIRPORT";
        });
    }, [list, selected]);
    const formatDisatnce = useCallback((d) => {
        if (!d?.value)
            return "";
        const distanceText = `${Math.round(d.value)} ${d.unit}`;
        return i18n.trans(t("flights_search_distance", { variables: { distance: distanceText } }));
    }, [i18n]);
    const handleCheckBoxOnChange = useCallback((checked, loc) => {
        checked ? addMulti(loc) : remove(loc);
    }, [remove, addMulti]);
    const handleLiOnKeyDownCapture = useCallback((e, loc, checked) => {
        if (e.key === "Space" || e.keyCode == 32) {
            e.stopPropagation();
            e.preventDefault();
            if (enableMultiSelect) {
                checked ? remove(loc) : addMulti(loc);
            }
            else {
                add(loc);
            }
        }
        else if (e.key === "Enter" || e.keyCode == 13) {
            e.stopPropagation();
            e.preventDefault();
            add(loc);
        }
    }, [add, addMulti, remove, enableMultiSelect]);
    const handleLiOnClick = useCallback((loc) => {
        add(loc);
    }, [add]);
    return list.length ? (React.createElement(React.Fragment, null,
        React.createElement(HiddenVisually, null,
            React.createElement("span", { role: "status", "aria-live": "polite", "aria-label": i18n.trans(t("a11y_flights_aria_label_search_box_departure_suggestions")) })),
        React.createElement("ul", { id: "flights-searchbox_suggestions", className: styles.result, "aria-label": enableMultiSelect
                ? i18n.trans(t("a11y_flights_search_box_add_aiports_aria_label"))
                : i18n.trans(t("a11y_flights_search_box_multi-city_add_airport_aria_label")) }, list.map((loc, idx) => {
            const { code, name, type, countryName, countryNameShort, regionName, distanceToCity } = loc;
            const cityName = loc["cityName"];
            // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
            const regionCode = loc["regionCode"];
            const nested = isNested(idx);
            const checked = isChecked(idx);
            const parentSelected = isParentSelected(idx);
            const isCity = type === "CITY";
            const country = countryName || countryNameShort;
            const region = regionName || regionCode;
            return (React.createElement("li", { className: styles.location, tabIndex: parentSelected ? -1 : 0, key: type + code + idx, "data-ui-name": "locations_list_item", onKeyDownCapture: (e) => {
                    handleLiOnKeyDownCapture(e, loc, checked);
                }, onClick: () => {
                    handleLiOnClick(loc);
                } },
                nested && !disableIndent ? React.createElement("span", { className: styles.spacer }) : null,
                React.createElement("span", { className: styles.icon },
                    React.createElement(Icon, { svg: isCity ? GeoPinIcon : PlaneTripIcon })),
                React.createElement("span", { className: styles.content },
                    React.createElement("span", { className: styles.title },
                        React.createElement("b", null, code),
                        " ",
                        name,
                        " ",
                        isCity ? i18n.trans(t("flights_multi_select_all_airports")) : ""),
                    React.createElement(Text, { color: "neutral_alt", className: styles.subTitle, variant: "body_2" },
                        isCity ? [region, country].filter(Boolean).join(", ") : "",
                        " ",
                        !isCity && !nested ? [cityName, region, country].filter(Boolean).join(", ") : "",
                        " ",
                        nested && distanceToCity ? formatDisatnce(distanceToCity) : "")),
                enableMultiSelect ? (React.createElement("span", { className: styles.checkBox },
                    React.createElement(InputCheckbox, { name: loc.type + loc.code, inputAttributes: {
                            tabIndex: -1,
                            onClick: (e) => {
                                e.stopPropagation();
                            }
                        }, disabled: parentSelected, checked: checked || parentSelected, attributes: {
                            tabIndex: -1,
                            onClick: (e) => {
                                e.stopPropagation();
                            }
                        }, onChange: ({ checked }) => {
                            handleCheckBoxOnChange(checked, loc);
                        } }))) : null));
        })))) : null;
};
export default List;
