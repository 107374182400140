import React, { FC } from "react";

import { useFormatDateTime } from "@bookingcom/flights-core";
import { I18nChildContext, useI18n } from "@bookingcom/lingojs-react";
import { Badge, HiddenVisually, Stack, Text } from "@bookingcom/bui-react";

import { UIFlightSegment } from "@flights/types";
import useLocaleContext from "../../../../hooks/useLocaleContext";
import getTechnicalStops from "utils/get-technical-stops";
import { trackExperiment } from "utils/et";
import Frame from "components/elements/Frame";
import styles from "./FlightCardSegment.desktop.module.css";
import { t } from "@bookingcom/lingojs-core";
import { SPACER_PADDED } from "@flights/constants";
import { formattingUtils } from "@flights/web-api-utils-universal";
const FlightCardSegmentDesktop: FC<{ segment: UIFlightSegment; idx: number }> = ({ segment, idx }) => {
  const { isRTL } = useLocaleContext();
  if (
    !segment.departureTime ||
    !segment.departureAirport ||
    !segment.arrivalTime ||
    !segment.arrivalAirport ||
    !segment.totalTime
  ) {
    throw new Error("Invalid flight data in FlightCard");
  }
  return (
    <Frame>
      <Stack direction="row" gap={2}>
        <TimeAndDateBlock
          airport={segment.departureAirport.code}
          timestamp={segment.departureTime}
          alignment={isRTL ? "right" : "left"}
          showWarning={segment.showWarningOriginAirport}
          testAttributes={{
            time: `flight_card_segment_departure_time_${idx}`,
            date: `flight_card_segment_departure_date_${idx}`,
            airport: `flight_card_segment_departure_airport_${idx}`
          }}
        />
        <Stops
          segment={segment}
          dataTestId={{
            duration: `flight_card_segment_duration_${idx}`,
            stops: `flight_card_segment_stops_${idx}`
          }}
        />

        <TimeAndDateBlock
          airport={segment.arrivalAirport.code}
          timestamp={segment.arrivalTime}
          alignment={isRTL ? "left" : "right"}
          showWarning={segment.showWarningDestinationAirport}
          testAttributes={{
            time: `flight_card_segment_destination_time_${idx}`,
            date: `flight_card_segment_destination_date_${idx}`,
            airport: `flight_card_segment_destination_airport_${idx}`
          }}
        />
      </Stack>
    </Frame>
  );
};

const TimeAndDateBlock: FC<{
  timestamp: string;
  airport: string;
  alignment: "left" | "right";
  showWarning?: boolean;
  testAttributes?: {
    time: string;
    airport: string;
    date: string;
  };
}> = ({ timestamp, airport, alignment, showWarning, testAttributes }) => {
  const i18n = useI18n();
  const { formats } = useFormatDateTime(i18n);
  const timeFormatted = formats.flightTime(timestamp);
  const usTimeMatch = timeFormatted.match(/(.+)(AM|PM)$/);
  return (
    <Frame
      direction="column"
      attributes={{
        style: {
          textAlign: alignment,
          flexShrink: trackExperiment("flights_web_sr_full_luggage_data") ? 0 : undefined
        }
      }}
      className={styles.timeDateBlock}
    >
      <Text variant="strong_1" attributes={{ "data-testid": `${testAttributes?.time}` }}>
        {usTimeMatch?.length === 3 ? (
          <>
            {usTimeMatch[1]}
            <Text tagName="span" variant="small_1">
              {usTimeMatch[2]}
            </Text>
          </>
        ) : (
          timeFormatted
        )}
      </Text>

      <Frame
        direction="row"
        justifyContent={alignment === "left" ? "flex-start" : "flex-end"}
        className={styles.iataAndDate}
      >
        <Text
          variant="small_1"
          className={showWarning ? styles.iataWarning : ""}
          attributes={{ "data-testid": `${testAttributes?.airport}` }}
        >
          {airport}
        </Text>
        <Text variant="small_1" className={styles.iataDateSeparator}>
          {SPACER_PADDED}
        </Text>
        <Text variant="small_1" attributes={{ "data-testid": `${testAttributes?.date}` }}>
          {formats.flightDate(timestamp)}
        </Text>
      </Frame>
    </Frame>
  );
};

const Stops: FC<{
  segment: UIFlightSegment;
  dataTestId?: {
    duration: string;
    stops: string;
  };
}> = ({ segment, dataTestId }) => {
  const { legs, totalTime } = segment;
  const i18n = useI18n() as I18nChildContext;
  const timeInMinutes = totalTime / 60;
  const stopsCount = legs.length - 1;
  const technicalStopsCount = getTechnicalStops.fromSegment(segment).length;

  return (
    <Frame className={styles.stopsRoot} alignItems="center">
      <div className={styles.stopsWrap}>
        <div className={styles.stopsConnector}></div>

        <Badge
          text={
            stopsCount === 0
              ? i18n.trans(t("flights_filter_stops_none"))
              : i18n.trans(
                  t("flights_route_num_stops", {
                    num_exception: stopsCount,
                    variables: { num_stops: stopsCount }
                  })
                )
          }
          variant={stopsCount === 0 ? "constructive" : "outline"}
          className={styles.stopsBadge}
          attributes={{ "data-testid": dataTestId?.stops }}
        />
      </div>

      <Text
        variant="small_1"
        className={styles.stopsTextStyle}
        attributes={{ "aria-hidden": "true", "data-testid": dataTestId?.duration }}
      >
        {formattingUtils.flightDuration(timeInMinutes, i18n)}
      </Text>
      <HiddenVisually>{formattingUtils.flightDurationAria(timeInMinutes, i18n)}</HiddenVisually>
      {Boolean(technicalStopsCount) && (
        <Text variant="small_1">
          {i18n.trans(
            t("flights_sr_technical_stop", {
              variables: {
                num_stop: technicalStopsCount,
                num_exception: technicalStopsCount
              }
            })
          )}
        </Text>
      )}
    </Frame>
  );
};

export default FlightCardSegmentDesktop;
