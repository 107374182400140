export function groupBy(arr, fn) {
    return arr.reduce(function (obj, item) {
        // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
        const key = typeof fn === "function" ? fn(item) : item[fn];
        if (!obj.hasOwnProperty(key)) {
            // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
            obj[key] = [];
        }
        // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
        obj[key].push(item);
        return obj;
    }, {});
}
export const percentageDiff = (startingValue, finalValue) => {
    const percentageDifference = ((finalValue - startingValue) / startingValue) * 100;
    return Math.round(percentageDifference * 100) / 100;
};
export function isJsonString(input) {
    try {
        JSON.parse(input);
    }
    catch (e) {
        return false;
    }
    return true;
}
