// eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
import { __deprecated__createTrackingUtils } from "../index";
import { RouteName } from "../../../app/routes";

// eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
const utils = __deprecated__createTrackingUtils({
  name: "flights_apex_web_checkout_flow_improvements",
  stages: {
    checkout_start: 1,
    desktop: 2,
    mobile: 3,
    back_in_checkout: 4, // back from (ancillary, checkout, seat, pax)
    back_from_checkout: 5,
    back_from_seat: 6,
    back_from_ancillary: 7,
    back_from_pax: 8,
    with_ancillary_selected: 9
  },
  goals: {
    next_from_any_checkout_step: 1,
    user_select_any_ancillary: 2
  },
  goalsWithValue: [
    "amount_of_going_back_on_checkout_steps",
    "amount_of_going_forward_on_checkout_steps",
    "checkout_page_loading_time"
  ]
});

export function isInCheckoutFlowImprovements(pointOfSale?: string) {
  return utils.variant() && isEligable(pointOfSale);
}

export function trackCheckoutFlowStages(isMobile: boolean, pointOfSale?: string) {
  if (!isEligable(pointOfSale)) return;

  utils.stages.checkout_start();

  if (isMobile) {
    utils.stages.mobile();
  } else {
    utils.stages.desktop();
  }
}

export function trackCheckoutFlowBackStages(
  ancillarySelected: boolean,
  currentStepName: RouteName,
  pointOfSale?: string
) {
  if (!isEligable(pointOfSale)) return;

  utils.stages.back_in_checkout();

  if (ancillarySelected) {
    utils.stages.with_ancillary_selected();
  }

  switch (currentStepName) {
    case "checkout3":
      utils.stages.back_from_checkout();
      break;
    case "checkout-seat-selection":
      utils.stages.back_from_seat();
      break;
    case "checkout2":
      utils.stages.back_from_ancillary();
      break;
    case "checkout":
      utils.stages.back_from_pax();
      break;
    default:
      break;
  }
}

function isEligable(pointOfSale?: string) {
  return pointOfSale !== "gb";
}

export default utils;
