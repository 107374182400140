import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { t } from "@bookingcom/lingojs-core";
import { I18nChildContext, useI18n } from "@bookingcom/lingojs-react";

import ErrorAlert from "../../ErrorAlert";
import RefreshResultsIllustration from "../../../../assets/illustrations/refresh-results.svg";

import useUserAgent from "../../../../hooks/useUserAgent";
import localstory from "localstory";
import useGlobalContext from "../../../../hooks/useGlobalContext";
import useEventTracking from "../../../../hooks/useEventTracking";
import { UIFlightData } from "@flights/types";
import useFlightSearchResultRefreshPopup from "hooks/useFlightSearchResultRefreshPopup";
import { getFlightOfferString } from "utils/flight-offers";
import { trackCustomGoal } from "utils/et";

const debug = require("debug")("RefreshResults");
const sessionStorage = localstory(window.sessionStorage, "flights", { vacuum: false });

const REFRESH_RESULTS_TIMEOUT = 1000 * 60 * 5;
const MAX_FLIGHT_ENDPOINT_LATENCY = 1000 * 30;
interface Props {
  text?: string;
  flights: UIFlightData[];
}

const RefreshResults: React.FunctionComponent<Props> = (props) => {
  const location = useLocation();
  const i18n = useI18n() as I18nChildContext;
  const [showRefreshAlert, toggle] = useState(false);
  const initialPath = useRef(location.pathname);
  const { requestId } = useGlobalContext();
  const trackV2 = useEventTracking("search_results", requestId);
  const { isWebview, isMobile } = useUserAgent();

  const offerIds = props.flights.map((flight) => getFlightOfferString(flight));

  const refreshPopupTimer = useFlightSearchResultRefreshPopup(
    REFRESH_RESULTS_TIMEOUT - MAX_FLIGHT_ENDPOINT_LATENCY,
    offerIds,
    (shouldShowSearchRefeshPopup: boolean) => {
      showRefreshModal(shouldShowSearchRefeshPopup);
    }
  );

  const showRefreshModal = (value: boolean) => {
    if (!value) {
      debug("No refresh required!");
    }
    toggle(value);
  };

  // If the location changes, clear the popup timer.
  useEffect(() => {
    if (location.pathname !== initialPath.current) {
      debug("Timer cleared because of path change");
      refreshPopupTimer && clearInterval(refreshPopupTimer);
    }
  }, [location.pathname, refreshPopupTimer]);

  useEffect(() => {
    if (showRefreshAlert) {
      // Trace user see refresh page

      if (isMobile) {
        trackCustomGoal("flight_web_sr_traffic_aa_mdot", 3);
      }
    }
  }, [showRefreshAlert, isMobile]);

  return showRefreshAlert ? (
    <ErrorAlert
      illustration={<RefreshResultsIllustration />}
      title={i18n.trans(t("flights_error_refresh"))}
      body={i18n.trans(t("flights_error_refresh_body"))}
      buttonLabel={i18n.trans(t("flights_refresh_action"))}
      onClick={() => {
        setTimeout(() => {
          if (isWebview) {
            const aid = sessionStorage.get("aid");
            if (aid) history.replaceState({}, "", window.location.href + "&aid=" + aid);
          }
          window.location.reload();
        });
        trackV2("click_refresh_search");
      }}
    />
  ) : (
    <React.Fragment />
  );
};

export default RefreshResults;
