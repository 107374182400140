import { Actions, ActionTypes } from "./actions";
import { State, getInitialState } from "./state";

const initialState = getInitialState();

const reducer = (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case ActionTypes.fetch:
      return {
        ...state,
        flight: undefined,
        fetchStatus: "loading",
        error: undefined
      };
    case ActionTypes.fetchSuccess:
      return {
        ...state,
        flight: action.payload.flight,
        fetchStatus: "success",
        error: undefined
      };

    case ActionTypes.fetchError:
      return {
        ...state,
        fetchStatus: "failed",
        error: action.payload.error,
        flight: undefined
      };

    default:
      return state;
  }
};

export { reducer, getInitialState };
