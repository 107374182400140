import React, { PropsWithChildren } from "react";
import GlobalContext from "./GlobalContext";
import type { GlobalContext as GlobalContextType } from "@flights/types";
import { useLocation } from "react-router-dom";

const GlobalProvider = (props: PropsWithChildren<GlobalContextType>) => {
  const { children, ...otherProps } = props;
  const { search } = useLocation();
  const showCopyTags = search?.includes("show_tags=1") && props.isInternal;
  return <GlobalContext.Provider value={{ ...otherProps, showCopyTags }}>{children}</GlobalContext.Provider>;
};

export default GlobalProvider;
