/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React from "react";
import { Card, SkeletonLoader, Stack, useTheme, useViewport } from "@bookingcom/bui-react";
import { UIXLoaderType } from "../types";
import { Theme } from "@bookingcom/bui-react/types/theme";

const useLoader = (type?: UIXLoaderType) => {
  const theme = useTheme();
  const { isSmall } = useViewport();

  switch (type) {
    case "desktopCards":
      return desktopCardsLoader;
    case "indicative":
      return indicativeLoader(theme, isSmall);
    case "mobileCards":
      return mobileCardsLoader;
    case "genius":
      return geniusLoader;
    default:
      return null;
  }
};

const geniusLoader = (
  <Card>
    <SkeletonLoader variant="box" aspectRatio="5:0.66" />
  </Card>
);

const desktopCardsLoader = (
  <Stack direction="column" gap={6}>
    {[1, 2, 3].map((item) => (
      <Card key={item}>
        <div style={{ display: "grid", gridTemplateColumns: "1fr 3fr", gridColumnGap: 10 }}>
          <SkeletonLoader variant="box" width="180px" aspectRatio="1:1" />
          <div>
            <br />
            <SkeletonLoader variant="title" />
            <br />
            <SkeletonLoader variant="three-lines" />
          </div>
        </div>
      </Card>
    ))}
  </Stack>
);

const indicativeLoader = (theme: Theme, isSmall?: boolean) => (
  <div
    style={{
      padding: `${theme.units.spacing_8x} ${isSmall ? theme.units.spacing_4x : theme.units.spacing_16x}`,
      backgroundColor: theme.colors.color_background_alt
    }}
  >
    <Card fill attributes={{ style: { maxWidth: isSmall ? 343 : undefined, margin: "auto" } }}>
      <Stack direction={isSmall ? "column" : "row"} gap={3}>
        <SkeletonLoader variant="box" width={isSmall ? "100%" : "212px"} aspectRatio={isSmall ? "4:1.93" : "4:2.5"} />
        <Stack.Item grow>
          <Stack gap={2}>
            <div style={{ padding: 16 }}>
              <SkeletonLoader variant="title" />
              <br />
              <SkeletonLoader variant="three-lines" />
            </div>
          </Stack>
        </Stack.Item>
      </Stack>
    </Card>
  </div>
);

const mobileCardsLoader = (
  <Stack direction="column" gap={6}>
    {[1, 2, 3].map((item) => (
      <Card key={item} fill>
        <div style={{ display: "grid", gridTemplateColumns: "1fr 3fr", gridColumnGap: 10, paddingRight: 10 }}>
          <SkeletonLoader variant="box" width="120px" aspectRatio="1:1.68" />
          <div>
            <br />
            <SkeletonLoader variant="title" />
            <br />
            <SkeletonLoader variant="three-lines" />
          </div>
        </div>
      </Card>
    ))}
  </Stack>
);

export default useLoader;
