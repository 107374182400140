import { BAGGAGE_FEATURE_MAP } from "./constants.js";
import { getProductsIncludedInAllSegments } from "./includedProducts.js";
import { getFeatureConfigsByName } from "@flights/branded-fares";
import { formattingUtils } from "@flights/web-api-utils-universal";
export const getBaggageFeaturesToRender = (i18n, includedProducts, ancillaries, options) => {
    const baggageFeaturesToRender = getBaggageFeatures(i18n, includedProducts, ancillaries, {
        includeIncludedBaggage: true,
        includeNonIncludedBaggage: options?.includeNonIncludedBaggage
    });
    const includedBaggageFeatures = baggageFeaturesToClientFeatures(baggageFeaturesToRender.includedAndSellableBaggageFeatures);
    const excludedBaggageFeatures = baggageFeaturesToClientFeatures(baggageFeaturesToRender.excludedBaggageFeatures);
    return { includedBaggageFeatures, excludedBaggageFeatures };
};
/**
 * This function is temporarily neccesary during the migration of baggage features to node layer.
 * The mapping for the new server side baggage features will be be handled by getFeaturesToRender().
 */
const baggageFeaturesToClientFeatures = (features) => {
    const clientFeatures = [];
    const featureConfigs = getFeatureConfigsByName();
    for (const feature of features) {
        const featureConfig = featureConfigs[feature.featureName];
        if (!feature.label || !featureConfig)
            continue;
        clientFeatures.push({
            name: feature.featureName,
            label: feature.label,
            availability: feature.availability,
            icon: featureConfig.icon,
            group: featureConfig.group
        });
    }
    return clientFeatures;
};
// server
export const getBaggageFeatures = (i18n, includedProducts, ancillaries, options = {}) => {
    const includedBaggageFeatures = [];
    const includedAndSellableBaggageFeatures = [];
    const sellableBaggageFeatures = [];
    const excludedBaggageFeatures = [];
    const luggageItems = getLuggageItems(includedProducts);
    const result = {
        includedBaggageFeatures,
        includedAndSellableBaggageFeatures,
        sellableBaggageFeatures,
        excludedBaggageFeatures
    };
    if (!i18n || !luggageItems)
        return result;
    for (const [type, baseFeature] of Object.entries(BAGGAGE_FEATURE_MAP)) {
        const includedBaggage = luggageItems.filter((item) => item.luggageType === type);
        if (options.includeIncludedBaggage) {
            for (const item of includedBaggage) {
                const feature = {
                    ...baseFeature,
                    label: formattingUtils.piecesOfLuggageWithWeight(item, i18n),
                    availability: "INCLUDED"
                };
                includedBaggageFeatures.push(feature);
                includedAndSellableBaggageFeatures.push(feature);
            }
        }
        if (includedBaggage.length > 0)
            continue;
        const isSellable = isLuggageTypeSellable(type, ancillaries);
        if (isSellable && options.includeNonIncludedBaggage) {
            const feature = {
                ...baseFeature,
                label: formattingUtils.sellableLuggage(type, i18n),
                availability: "SELLABLE"
            };
            sellableBaggageFeatures.push(feature);
            includedAndSellableBaggageFeatures.push(feature);
        }
        if (!isSellable && options.includeNonIncludedBaggage) {
            excludedBaggageFeatures.push({
                ...baseFeature,
                label: formattingUtils.excludedLuggage(type, i18n),
                availability: "NOT_INCLUDED"
            });
        }
    }
    return result;
};
const getLuggageItems = (includedProducts, onlyShowIncludedInAllSegments) => {
    if (!includedProducts)
        return undefined;
    return onlyShowIncludedInAllSegments
        ? getProductsIncludedInAllSegments(includedProducts)
        : includedProducts.segments[0];
};
export const isLuggageTypeSellable = (type, ancillaries) => {
    switch (type) {
        case "HAND":
            return !!ancillaries?.cabinBaggage || !!ancillaries?.cabinBaggagePerTraveller;
        case "CHECKED_IN":
            return !!ancillaries?.checkedInBaggage?.options[0]?.luggageAllowance || !!ancillaries?.specialBags;
        default:
            return false;
    }
};
