import { useCallback, useContext } from "react";
import { SearchBoxContext } from "../SearchBoxContext/index.js";
const useOriginDestination = (props) => {
    const { segmentIndex } = props;
    const { i18n, isSubmitted, removeSegment, searchType, swapSegment, trackV2, segments } = useContext(SearchBoxContext);
    const isMultiStop = searchType === "MULTISTOP";
    const swap = useCallback(() => {
        swapSegment(segmentIndex);
    }, [swapSegment, segmentIndex]);
    const remove = useCallback(() => {
        removeSegment(segmentIndex);
    }, [removeSegment, segmentIndex]);
    const segment = segments[segmentIndex];
    const isValidSegment = segment.from.every((from) => {
        return segment.to.every((to) => to.code !== from.code);
    });
    return {
        i18n,
        isMultiStop,
        isSubmitted,
        isValidSegment,
        removeSegment: remove,
        swapSegment: swap,
        trackV2
    };
};
export default useOriginDestination;
