import React from "react";

import { Text, useTheme } from "@bookingcom/bui-react";
import Frame from "components/elements/Frame";
import styles from "./FlightCardHighlight.module.css";

type Props = {
  style?: FlightCardHighlightStyle;
  text: string;
  textEmphasized?: boolean;
};

export const FlightCardHighlightStyle = {
  PRIMARY: "PRIMARY",
  CONSTRUCTIVE: "CONSTRUCTIVE",
  GENIUS: "GENIUS"
} as const;

export type FlightCardHighlightStyle = typeof FlightCardHighlightStyle[keyof typeof FlightCardHighlightStyle];

export const FlightCardHighlight = ({
  style = FlightCardHighlightStyle.PRIMARY,
  text,
  textEmphasized = false
}: Props) => {
  const { colors } = useTheme();

  const getBackgroundColor = (style: FlightCardHighlightStyle) => {
    return style === FlightCardHighlightStyle.CONSTRUCTIVE
      ? colors.color_constructive_background_alt
      : style === FlightCardHighlightStyle.GENIUS
      ? colors.color_brand_genius_primary_background
      : colors.color_action_background_alt;
  };

  const getForegroundColor = (style: FlightCardHighlightStyle) => {
    return style === FlightCardHighlightStyle.CONSTRUCTIVE
      ? colors.color_constructive_foreground
      : style === FlightCardHighlightStyle.GENIUS
      ? colors.color_foreground_inverted
      : colors.color_brand_primary_foreground;
  };

  return (
    <Frame alignItems="center" direction="row" m={0} attributes={{ style: { background: getBackgroundColor(style) } }}>
      <Text
        className={styles.highlightText}
        variant={textEmphasized ? "emphasized_2" : "body_2"}
        attributes={{
          style: {
            color: getForegroundColor(style),
            background: getBackgroundColor(style)
          }
        }}
      >
        {text}
      </Text>
    </Frame>
  );
};
