import { Actions, ActionTypes } from "./actions";
import { State, getInitialState } from "./state";

const initialState = getInitialState();

const reducer = (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case ActionTypes.fetch:
      return {
        ...state,
        order: undefined,
        fetchStatus: "loading",
        error: undefined
      };

    case ActionTypes.fetchSuccess:
      return {
        ...state,
        order: action.payload.order,
        orderCreated: undefined,
        fetchStatus: "success",
        error: undefined
      };

    case ActionTypes.fetchError:
      return {
        ...state,
        fetchStatus: "failed",
        error: action.payload.error,
        order: undefined
      };

    case ActionTypes.reset:
      return getInitialState();

    case ActionTypes.setOrderCreated:
      return {
        ...state,
        orderCreated: action.payload.orderCreated,
        orderCreatedByPriceChange: action.payload.orderCreatedByPriceChange
      };

    case ActionTypes.setAddOnOrder:
      return {
        ...state,
        addOnOrder: action.payload.addOnOrder
      };

    case ActionTypes.updateInstalmentsPrice:
      if (state.order?.totalPrice) {
        state.order.totalPrice = {
          ...state.order.totalPrice,
          instalmentsFees: action.payload.instalmentsFees,
          totalWithInstalments: action.payload.totalWithInstalments
        };
      }
      return { ...state };

    default:
      return state;
  }
};

export { reducer, getInitialState };
