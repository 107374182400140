/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { FC, Fragment, useCallback, useEffect, useMemo } from "react";
import { Button, Icon, List, Text, useTheme } from "@bookingcom/bui-react";
import { useHistory, useLocation } from "react-router-dom";
import { I18nChildContext, Trans, useI18n } from "@bookingcom/lingojs-react";
import { t } from "@bookingcom/lingojs-core";
import { UIClientFetchError, UIFlightData } from "@flights/types";
import FlightCard from "../FlightCard/FlightCard";
import SearchPagination from "../SearchPagination";
import { SearchRemoveIcon } from "@bookingcom/bui-assets-react/streamline/index.js";
import { SkeletonFlightCard } from "../Skeleton/SkeletonFlightCard";
import { TooManyFilters } from "./components/TooManyFilters";
import { NoResultsFound } from "./components/NoResultsFound";
// eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
import { useStore } from "../../../store";
import { useSelectorAppliedFilters } from "../../../store/selectors/appliedFilters";
import { getExpandedSearchAirports } from "../../../store/selectors/expandedSearch";
import { FILTERS } from "../../../constants";
import { RemoveDirectFlights } from "./components/RemoveDirectFlights";
import useCreateUrl from "../../../hooks/useCreateUrl";
import SearchBaggagePolicies from "../SearchBaggagePolicies";
import { trackCustomGoal, trackExperiment, trackExperimentStage } from "utils/et";
import useClientCachedURL from "hooks/useClientCachedURL";
import RefreshResults from "./components/RefreshResults";
import NoDirectFlightsBanner from "./components/NoDirectFlightsBanner";
import useTrackExpRankerV3 from "hooks/useTrackExpRankerV3";
import { XSell } from "cross-sell";
import { isOfMetaOrigin } from "utils/marketing-url-params";
import useGlobalContext from "hooks/useGlobalContext";
import useEventTracking from "hooks/useEventTracking";
import FlightCardToggle from "../FlightCard/FlightCardToggle";
import { SearchLoadingMessage } from "components/elements/SearchLoadingMessage";
import { getLocationParams } from "./utils";
import useFlexTrackPriceDiff from "hooks/useFlexTrackPriceDiff";
import GeniusTCBanner from "cross-sell/components/GeniusTCBanner";
import Frame from "../Frame";
import { isFeatureRunningClientSide } from "utils/features";
import { FlightCampaignTCBanner } from "../FlightCampaignTCBanner";
import { flightsSessionStore } from "utils/session-store";
import { trackIndexCampaign } from "utils/experiments/customer-aquisition/flights_web_cat_banner_campaign_index";
import { FlexibleDateBestPrice } from "../FlexibleDateBestPrice";
import flights_web_aa_fd_not_available_error from "utils/experiments/funnel/flights_web_aa_fd_not_available_error";
import styles from "./SearchResults.module.css";
import { useFlexDatesRectrictedPOS } from "../FlexibleDateBestPrice/useFlexDatesRectrictedPOS";
import useProfiler from "../../../hooks/useProfiler";
import { RemoteAppleCardSR } from "components/remote";
import flights_apex_web_ancillaries_micro_conversion_aa from "../../../utils/experiments/apex/flights_apex_web_ancillaries_micro_conversion_aa";
import useOfferCartOrderData from "hooks/useOfferCartOrderData";
import useAlternativeFaresParams from "hooks/useAlternativeFaresParams";
import useTrackBaggageToServerStages from "./hooks/useTrackBaggageToServerStages";
import flights_apex_web_q3_2024_blockout from "utils/experiments/apex/flights_apex_web_q3_2024_blockout";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { getEsim } from "store/esim/selectors";
import flights_ace_web_esim_banner from "utils/experiments/ace/flights_ace_web_esim_banner";
import EsimBanner from "ancillaries/components/Esim/EsimBanner";

type SearchResultsListProps = {
  flights: UIFlightData[];
  onSelectFlight: (flight: any) => void;
  dataTestId?: string;
};

function _SearchResultsList({ flights, onSelectFlight, dataTestId }: SearchResultsListProps) {
  // eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
  const store = useStore();
  const {
    search,
    searchCriteria,
    searchResults: { campaignBanner, remoteBanners, displayOptions }
  } = store;
  const isInboundOutboundActive = trackExperiment("flights_web_inbound_outbound_sr") && search.type === "ROUNDTRIP";
  const appliedFilters = useSelectorAppliedFilters(store);
  const { indexPageCampaign } = useGlobalContext();
  const isRestricedFlexDatePOS = useFlexDatesRectrictedPOS();
  const { isLoggedOut } = useProfiler();
  const params = new URLSearchParams(location.search);
  const currentSearchResultsPageNumber = params.get("page");

  //There isn't a page param for the first search
  const isFirstSearchResultsPage = currentSearchResultsPageNumber ? Number(currentSearchResultsPageNumber) === 1 : true;

  useEffect(() => {
    trackExperimentStage("flights_web_inbound_outbound_sr", 1);
    flights_apex_web_ancillaries_micro_conversion_aa.trackSearchResultsStages(flights);
  }, [flights]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (search.type === "ROUNDTRIP") {
      trackExperimentStage("flights_web_inbound_outbound_sr", 2);
      trackExperimentStage("flights_web_inbound_outbound_sr", 4);
      trackExperimentStage("flights_web_inbound_outbound_sr", isOfMetaOrigin() ? 5 : 6);
    }
  }, [search.type]);

  useEffect(() => {
    if (appliedFilters.length > 0) {
      trackExperimentStage("flights_web_inbound_outbound_sr", 7);
    }
  }, [appliedFilters.length]);

  useEffect(() => {
    const { adults, children } = searchCriteria;
    const travellersCount = adults + children.length;

    trackExperimentStage("flights_web_sr_full_luggage_data", 1);
    trackExperimentStage("flights_web_sr_full_luggage_data", isOfMetaOrigin() ? 3 : 2);
    trackExperimentStage("flights_web_sr_full_luggage_data", 5);
    trackExperimentStage("flights_web_sr_full_luggage_data", travellersCount > 1 ? 7 : 6);

    flights_web_aa_fd_not_available_error.stages.search_results();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    trackExperimentStage("flight_web_sr_traffic_aa_mdot", 1);
    trackExperimentStage("flight_web_sr_traffic_aa_mdot", isOfMetaOrigin() ? 6 : 5);

    if (appliedFilters.length > 0) {
      trackExperimentStage("flight_web_sr_traffic_aa_mdot", 2);
    }
    if (store.user.userInfo && flightsSessionStore.get("genius_banner_seen")) {
      trackCustomGoal("flight_web_sr_traffic_aa_mdot", 5);
    }
  }, [appliedFilters, store.user.userInfo]);

  useFlexTrackPriceDiff({
    diffPercentageTag: "flights_web_flexible_date_search_mdot_sr_diff_percentage",
    diffHigherTag: "flights_web_flexible_date_search_mdot_sr_diff_higher_count",
    diffLowerTag: "flights_web_flexible_date_search_mdot_sr_diff_lower_count"
  });

  flights_apex_web_q3_2024_blockout.stages.main();
  flights_apex_web_q3_2024_blockout.stages.mdot();
  flights_apex_web_q3_2024_blockout.stages.sr_or_fd_with_flex();

  useEffect(() => {
    // Bucket of users who were exposed both to index and SR January deal campaigns
    if (
      Array.isArray(flights) &&
      isFeatureRunningClientSide("FLIGHTS_PRICING_ENABLE_WHITE_LABEL_TC_BANNER_IN_FE") &&
      campaignBanner &&
      indexPageCampaign
    ) {
      trackIndexCampaign.goals.exposed_to_sr_campaign();
    }
  }, [isFirstSearchResultsPage, flights, campaignBanner, indexPageCampaign]);

  const FlightCardComponent = isInboundOutboundActive ? FlightCardToggle : FlightCard;

  const shouldShowFlexDates =
    !displayOptions?.hideFlexiblePricesBanner &&
    flights.length >= 5 &&
    store.search.cabinClass === "ECONOMY" &&
    isFirstSearchResultsPage &&
    !isRestricedFlexDatePOS &&
    trackExperiment("flights_web_flexible_date_search_mdot") === 2;

  const MARGIN_BOTTOM = !!trackExperiment("flights_web_mdot_sr_card_single_cta") ? 2 : undefined;
  const MARGIN_TOP = !!trackExperiment("flights_web_mdot_sr_card_single_cta") ? 0 : undefined;

  /** start - flights_ace_web_esim_banner */
  const esimAvailable = !isEmpty(useSelector(getEsim));
  const showEsimBanner = esimAvailable && !!flights_ace_web_esim_banner.trackWithDefaultStage();
  const ESIM_BANNER_SEARCH_RESULTS_INDEX = 6;
  /** end - flights_ace_web_esim_banner */

  return (
    <div className={styles.searchResultsList} data-testid={dataTestId}>
      <List attributes={{ style: { padding: 0 } }}>
        {flights.map((flight: any, index: number) => {
          return (
            <Fragment key={`${index}_${flight.token}`}>
              {isFeatureRunningClientSide("FLIGHTS_PRICING_ENABLE_WHITE_LABEL_TC_BANNER_IN_FE") &&
              campaignBanner &&
              index === campaignBanner.position ? (
                <Frame mt={MARGIN_TOP} mb={MARGIN_BOTTOM}>
                  <FlightCampaignTCBanner campaignBanner={campaignBanner} />
                </Frame>
              ) : null}
              <div id={`flightcard-${index}`} data-testid="searchresults_card">
                <FlightCardComponent
                  flight={flight}
                  index={index}
                  onClick={onSelectFlight}
                  pricePerPerson
                  mt={MARGIN_TOP}
                  mb={MARGIN_BOTTOM}
                  brandedFaresShownByDefault={displayOptions?.brandedFaresShownByDefault}
                />
              </div>
              <Frame mb={MARGIN_BOTTOM}>
                <FlexibleDateBestPrice shouldRender={shouldShowFlexDates && index === 0} />
              </Frame>
              {/* Always display CrossSell banner after second item if Genius campaign is disabled */}
              {/* hide crossSell when exp: flights_web_credit_campaign is in variant */}
              {!campaignBanner && index === 1 ? (
                <>
                  {isLoggedOut() ? null : (
                    <Frame mt={MARGIN_TOP} mb={MARGIN_BOTTOM}>
                      <XSell component="GeniusBanner" />
                      <SearchTrafficAAGeniusTrackerMdot />
                    </Frame>
                  )}
                </>
              ) : null}
              {/* If GeniusCampaign is Enabled, show the Genius TC campaign on 3rd position */}
              {!isFeatureRunningClientSide("FLIGHTS_PRICING_ENABLE_WHITE_LABEL_TC_BANNER_IN_FE") &&
              index === 1 &&
              campaignBanner ? (
                <Frame mt={MARGIN_TOP} mb={MARGIN_BOTTOM}>
                  <GeniusTCBanner campaignBanner={campaignBanner} />
                  <SearchTrafficAAGeniusTrackerMdot />
                </Frame>
              ) : null}
              {/**
               * If the position is larger than the number of cards displayed in the SR page,
               * then the T&C banner is shown after the last card.
               * More info: https://jira.booking.com/jira/browse/FPRICING-809
               */}
              {isFeatureRunningClientSide("FLIGHTS_PRICING_ENABLE_WHITE_LABEL_TC_BANNER_IN_FE") &&
              campaignBanner &&
              campaignBanner.position >= flights.length && // campaign banner position is larger than the number of cards
              index >= flights.length - 1 ? ( // after the last flight card
                <Frame mt={MARGIN_TOP} mb={MARGIN_BOTTOM}>
                  <FlightCampaignTCBanner campaignBanner={campaignBanner} />
                </Frame>
              ) : null}
              {/* If GeniusCampaign is Enabled, show the ABU campaign on 6th position */}
              {campaignBanner && index === 4 ? (
                <>
                  {isLoggedOut() ? null : (
                    <Frame mt={MARGIN_TOP} mb={MARGIN_BOTTOM}>
                      <XSell component="GeniusBanner" />
                      <SearchTrafficAAGeniusTrackerMdot />
                    </Frame>
                  )}
                </>
              ) : null}

              {/* start - flights_ace_web_esim_banner */}
              {showEsimBanner && index === ESIM_BANNER_SEARCH_RESULTS_INDEX && <EsimBanner isPostBooking={false} />}
              {/* end - flights_ace_web_esim_banner */}

              {Array.isArray(remoteBanners) && remoteBanners.includes("APPLE_CARD_BANNER_SR") && index === 4 ? (
                <RemoteAppleCardSR />
              ) : undefined}
            </Fragment>
          );
        })}
      </List>
    </div>
  );
}

const SearchResultsList = React.memo(_SearchResultsList);

interface SearchResultsProps {
  airports: string;
  params: string;
  fetchStatus: string;
  fetchError?: UIClientFetchError;
  flights: UIFlightData[];
  onSelectFlight: () => void;
}

const SearchResults = ({ airports, params, flights = [], fetchStatus, onSelectFlight }: SearchResultsProps) => {
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const { createUrl } = useCreateUrl();
  // eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
  const store = useStore();
  const {
    search,
    searchResults,
    flexibleDateMinPrice: { fetchStatus: flexibleDatePriceFetchStatus }
  } = store;
  const { error } = searchResults;
  const appliedFilters = useSelectorAppliedFilters(store);
  const i18n = useI18n() as I18nChildContext;
  const { cacheLastSuccessfulSearchUrl } = useClientCachedURL();
  const trackExpRankerV3 = useTrackExpRankerV3();
  const { requestId } = useGlobalContext();
  const trackV2 = useEventTracking("search_results", requestId);
  const isRestricedFlexDatePOS = useFlexDatesRectrictedPOS();
  const offerCartOrderData = useOfferCartOrderData();
  const { setAlternativeFaresParams } = useAlternativeFaresParams();

  useEffect(() => {
    if (["loading", "initial"].includes(fetchStatus)) return;
    if (flights.length === 0) trackV2("show_empty_search_results");
  }, [flights, fetchStatus, trackV2]);

  useTrackBaggageToServerStages(flights);

  const showFlightDetails = useCallback(
    async (flight: any) => {
      trackCustomGoal("flights_meta_land_on_cheapest_flights", 4); //select flight

      onSelectFlight();

      offerCartOrderData.set(flight.token, {
        offer: flight.token,
        cabinClass: searchResults.searchCriteria?.cabinClass
      });
      history.push(createUrl(`/flights/${airports}/${flight.token}/?${getLocationParams(search.type, params)}`), {
        fromSearch: true
      });
    },
    [
      onSelectFlight,
      offerCartOrderData,
      searchResults.searchCriteria?.cabinClass,
      history,
      createUrl,
      airports,
      search.type,
      params
    ]
  );

  const changeSearch = useCallback(() => {
    history.push(createUrl("/"));
  }, [history, createUrl]);

  const expandedSearchAirports = useMemo(() => {
    const { searchSegments } = search;
    /*eslint-disable-next-line @bookingcom/flights/no-unassigned-todo-comments*/
    /**
     * TODO: Update ExpandedSeach for multistop cases?
     */
    const { from, to } = searchSegments[0];
    return getExpandedSearchAirports(from, to);
  }, [search]);

  const expandToCitySearch = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      if (expandedSearchAirports) {
        const { fromCities, toCities, fromLocationName, toLocationName } = expandedSearchAirports;
        const params = new URLSearchParams(location.search);
        params.set("from", fromCities);
        params.set("to", toCities);
        params.set("fromLocationName", fromLocationName);
        params.set("toLocationName", toLocationName);

        history.replace(createUrl(`/flights/${fromCities}-${toCities}/?${params}`));
        window.location.reload();
      }
    },
    [history, location, expandedSearchAirports, createUrl]
  );

  // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
  const noFlightsFound = error && error["code"] === "SEARCH_SEARCHFLIGHTS_NO_FLIGHTS_FOUND";
  const canExtendSearch = appliedFilters.length === 0 && noFlightsFound && expandedSearchAirports;
  const canRemoveDirectFlightsFilter = appliedFilters.includes(FILTERS.stops) && flights.length === 0;

  const flexibleLoadingCondition =
    store.search.cabinClass === "ECONOMY" &&
    !isRestricedFlexDatePOS &&
    !!trackExperiment("flights_web_flexible_date_search_mdot")
      ? flexibleDatePriceFetchStatus === "initial" || flexibleDatePriceFetchStatus === "loading"
      : false;

  if (fetchStatus === "initial" || fetchStatus === "loading" || flexibleLoadingCondition) {
    return (
      <>
        <SearchLoadingMessage />
        <div className={styles.loading}>
          <SkeletonFlightCard />
          <SkeletonFlightCard />
          <SkeletonFlightCard />
          <SkeletonFlightCard />
        </div>
      </>
    );
  }

  if (fetchStatus === "success") {
    if (flights.length > 0) {
      cacheLastSuccessfulSearchUrl(airports);
      searchResults.fetchedAt && setAlternativeFaresParams("searchedAt", searchResults.fetchedAt);
      trackExpRankerV3.trackStages();
      return (
        <div className={styles.searchResults}>
          <NoDirectFlightsBanner />
          <SearchBaggagePolicies />
          <SearchResultsList flights={flights} onSelectFlight={showFlightDetails} dataTestId="searchresults_list" />
          <SearchPagination />
          <RefreshResults flights={flights} />
        </div>
      );
    } else if (canRemoveDirectFlightsFilter) {
      return <RemoveDirectFlights />;
    }
  }

  if (fetchStatus === "failed") {
    if (canExtendSearch) {
      return (
        <Frame mt={6} mb={6} attributes={{ style: { minHeight: "40vh" } }}>
          <Frame m={3} alignItems="center" attributes={{ style: { textAlign: "center" } }}>
            <Icon svg={SearchRemoveIcon} size="small" className={styles.searchRemoveIcon} />
            <Text variant="headline_2">{i18n.trans(t("flights_search_no_results_header"))} </Text>
          </Frame>
          {search.type !== "MULTISTOP" && (
            <Text variant="body_2">
              <Trans
                tag="flights_search_no_results_copy_mobile"
                variables={{ start_link: "<0>", end_link: "</0>" }}
                components={[
                  <a
                    key={0}
                    href="##"
                    style={{ color: theme.colors.color_action_foreground }}
                    onClick={expandToCitySearch}
                  />
                ]}
              />
            </Text>
          )}
          <Frame alignItems="center" m={6}>
            <Button onClick={changeSearch}>{i18n.trans(t("flights_search_no_results_change_search_cta"))}</Button>
          </Frame>
        </Frame>
      );
    }
  }

  if (appliedFilters.length > 0) {
    return <TooManyFilters />;
  } else {
    return <NoResultsFound showActions={true} />;
  }
};

const SearchTrafficAAGeniusTrackerMdot: FC = () => {
  // eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
  const store = useStore();

  useEffect(() => {
    if (!store.user.userInfo) {
      trackExperimentStage("flight_web_sr_traffic_aa_mdot", 4);
      flightsSessionStore.set("genius_banner_seen", true);
    }
  }, [store.user.userInfo]);

  return null;
};

export default React.memo(SearchResults);
