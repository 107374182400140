import { UIFlightData } from "@flights/types";
// eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
import { __deprecated__createTrackingUtils } from "../index";

// eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
const utils = __deprecated__createTrackingUtils({
  name: "flights_ace_web_flexibilty_sr_page",
  stages: {
    all_desktop_sr: 1,
    all_desktop_sr_with_cfar_or_flex: 2, //main
    all_desktop_sr_with_only_flex: 3, //
    all_desktop_sr_with_cfar_and_flex: 4,
    desktop_user_select_cfar_or_flex: 5,
    all_desktop_sr_with_cfar_or_flex_with_flights_apex_web_flex_selection_cta_variant: 6,
    all_desktop_sr_with_cfar_or_flex_dk_fi_nl: 7,
    all_desktop_sr_with_cfar_or_flex_be_es_pt: 8
  },
  goals: {
    click_view_flexible_options: 1,
    click_view_details_on_cfar_or_flex: 2,
    click_view_details_on_flex: 3,
    click_price_breakdown_on_fd: 4,
    switch_flexibility_on_ticket_type: 5
  },
  goalsWithValue: []
});

export default utils;

export function trackFlexibiltiyOnSearchResults(
  flights: UIFlightData[],
  bookingWindow?: number,
  pos?: string,
  isApexCtaExperimentVariant?: boolean
) {
  if (!(pos == "id" || pos == "qa")) {
    utils.stages.all_desktop_sr();
    flights.forEach((flight) => {
      if (!flight.requestableBrandedFares) {
        const extras = { hasCfar: false, hasFlex: false };
        flight.extraProducts?.forEach((extraProduct) => {
          if (extraProduct.type === "flexibleTicket") extras.hasFlex = true;
          if (extraProduct.type === "cancelForAnyReason" && bookingWindow && bookingWindow <= 90) extras.hasCfar = true;
        });
        if (extras.hasCfar || extras.hasFlex) {
          utils.stages.all_desktop_sr_with_cfar_or_flex();
          if (pos) {
            if (pos === "dk" || pos === "fi" || pos === "nl") {
              utils.stages.all_desktop_sr_with_cfar_or_flex_dk_fi_nl();
            } else if (pos === "be" || pos === "es" || pos === "pt") {
              utils.stages.all_desktop_sr_with_cfar_or_flex_be_es_pt();
            }
          }
          if (isApexCtaExperimentVariant) {
            utils.stages.all_desktop_sr_with_cfar_or_flex_with_flights_apex_web_flex_selection_cta_variant();
          }
        }
        if (extras.hasFlex && !extras.hasCfar) {
          utils.stages.all_desktop_sr_with_only_flex();
        }
        if (extras.hasFlex && extras.hasCfar) {
          utils.stages.all_desktop_sr_with_cfar_and_flex();
        }
      }
    });
  }
}
