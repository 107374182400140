import React, { useEffect } from "react";
import { Spinner, Text, Link } from "@bookingcom/bui-react";
import { t } from "@bookingcom/lingojs-core";
import List from "./List.js";
export default function Results(props) {
    const { add, addMulti, error, i18n, enableMultiSelect, loading, remove, result, selected, success, disableIndent, type, searchType, isFlyAnywhereExp, isMobile, trackFlyAnywhereWww, trackFlyAnywhereMdot } = props;
    const isToAndNotMultiStop = type === "to" && searchType !== "MULTISTOP";
    const showAnywhere = isFlyAnywhereExp && isToAndNotMultiStop;
    useEffect(() => {
        if (isToAndNotMultiStop) {
            if (isMobile) {
                trackFlyAnywhereMdot?.();
            }
            else {
                trackFlyAnywhereWww?.();
            }
        }
    }, [isToAndNotMultiStop, isMobile]);
    if (error)
        return (React.createElement(Text, { variant: "body_2" },
            i18n.trans(t("flights_error_unknown_header")),
            ". ",
            React.createElement(Link, { href: "/" }, i18n.trans(t("flights_error_timeout_subheader")))));
    if (loading)
        return React.createElement(Spinner, null);
    if (success && result.length)
        return (React.createElement(List, { add: add, addMulti: addMulti, enableMultiSelect: enableMultiSelect, i18n: i18n, list: result, remove: remove, selected: selected, disableIndent: disableIndent, showAnywhere: showAnywhere }));
    if (success && !result.length)
        return React.createElement(Text, { variant: "body_2" }, i18n.trans(t("flights_search_no_location_found")));
    return enableMultiSelect ? (React.createElement(React.Fragment, null,
        React.createElement(Text, { color: "neutral_alt", variant: "body_2" }, showAnywhere
            ? i18n.trans(t("flights_sb_usp_explore_subtext"))
            : i18n.trans(t("flights_sb_usp_multi_search_subtext"))))) : null;
}
