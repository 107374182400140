import React, { useState } from "react";
import { Card, Image, Stack, Text } from "@bookingcom/bui-react";
import GeniusTCModal from "./GeniusTCModal";
import { UICampaignBanner } from "@flights/types";
import styles from "./GeniusTCModal.module.css";

interface GeniusTCBannerProps {
  campaignBanner: UICampaignBanner;
}

function GeniusTCBanner(props: GeniusTCBannerProps) {
  const { campaignBanner } = props;

  const [showModal, setShowModal] = useState(false);

  const onCloseModal = () => {
    setShowModal(false);
  };

  const onShowModal = () => {
    setShowModal(true);
  };

  return (
    <>
      <Card>
        <Stack direction="row" justifyContent="start">
          <Stack.Item alignSelf="start">
            <Image
              asset={{
                setName: "illustrations-traveller",
                assetName: "GeniusFlightsBadge"
              }}
              className={styles.illustration}
              height="80px"
              width="120px"
              alt="Genius"
              attributes={{
                role: "presentation"
              }}
            />
          </Stack.Item>

          <Stack.Item grow alignSelf="start">
            <Stack direction="column" justifyContent="start" className={styles.wrapper}>
              <Text tagName="div" variant="strong_1" className={styles.title} data-testid="banner-header">
                {campaignBanner.title}
              </Text>
              <Text tagName="p" variant="small_1" className={styles.description} data-testid="banner-subheader">
                {campaignBanner.text}
              </Text>
              <Text
                tagName="div"
                variant="body_2"
                className={styles.link}
                data-testid="banner-link"
                attributes={{ tabIndex: 0, role: "button", onClick: onShowModal }}
              >
                {campaignBanner.action.ctaText}
              </Text>
            </Stack>
          </Stack.Item>
        </Stack>
      </Card>
      <GeniusTCModal isActive={showModal} onModalClose={onCloseModal} campaignBanner={campaignBanner} />
    </>
  );
}

export default GeniusTCBanner;
