import React, { useEffect, useMemo } from "react";
import isEmpty from "lodash/isEmpty";
import { useRemoteComponent } from "@bookingcom/remote-component";
import useEvents from "hooks/useEvents";

const GeniusLaunchSheet = () => {
  const startTime = useMemo(() => performance.now(), []);

  const { grumble } = useEvents();

  const { Component, isLoading, error } = useRemoteComponent({
    project: "web-shell",
    service: "header-mfe",
    path: "/LaunchSheetFlightsIndex",
    type: "capla"
  });

  useEffect(() => {
    if (!isLoading && error && !isEmpty(error)) {
      grumble("RC error: GeniusLaunchSheet", { error: error.toString() });
    }
  }, [startTime, isLoading, error, grumble]);

  return <Component />;
};

export default GeniusLaunchSheet;
