import { IRemoteComponentContext, RemoteComponentContext } from "@bookingcom/remote-component";
import React, { PropsWithChildren } from "react";

type RemoteComponentExperimentProps = {
  value?: IRemoteComponentContext;
};

const RemoteComponentExperiment = (props: PropsWithChildren<RemoteComponentExperimentProps>) => {
  const { value, children } = props;
  if (!value) return <>{children}</>;
  return <RemoteComponentContext.Provider value={value}>{children}</RemoteComponentContext.Provider>;
};

export default RemoteComponentExperiment;
