import { Actions, ActionTypes } from "./actions";
import { State, getInitialState } from "./state";
import { trackExperimentStage } from "../../utils/et";

const initialState = getInitialState();

const reducer = (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case ActionTypes.setSearchCriteria:
      /**
       * Track domestic and international stages for flights_us_supplier_switch
       */
      const { fromCountry, toCountry } = action.payload;
      const isDomestic = JSON.stringify(fromCountry) === JSON.stringify(toCountry);
      isDomestic
        ? trackExperimentStage("flights_us_supplier_switch", 3)
        : trackExperimentStage("flights_us_supplier_switch", 4);

      return {
        ...state,
        toCountry,
        fromCountry,
        toLocationName: action.payload.toLocationName,
        fromLocationName: action.payload.fromLocationName,
        adults: action.payload.adults,
        children: action.payload.children
      };

    default:
      return state;
  }
};

export { reducer, getInitialState };
