import { UIBrandedFare, UIFlightData } from "@flights/types";
import { Actions, ActionTypes } from "./actions";
import { getInitialState, State } from "./state";
import { isFeatureRunningClientSide } from "utils/features";
import merge from "lodash/merge";

const initialState = getInitialState();

const reducer = (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case ActionTypes.fetch:
      return {
        ...state,
        flight: undefined,
        fetchStatus: "loading",
        error: undefined
      };
    case ActionTypes.fetchReset:
      return {
        ...state,
        flight: undefined,
        fetchStatus: "initial",
        error: undefined
      };
    case ActionTypes.fetchSuccess:
      return {
        ...state,
        flight: action.payload.flight,
        fetchStatus: "success",
        error: undefined
      };

    case ActionTypes.fetchError:
      return {
        ...state,
        fetchStatus: "failed",
        error: action.payload.error,
        flight: undefined
      };

    case ActionTypes.selectBrandedFare:
      const { flight: baseOffer } = state;
      const { fareOffer } = action.payload;
      if (!baseOffer) {
        throw Error("ActionTypes.selectBrandedFare: base flight offer is missing");
      }

      return {
        ...state,
        flight: isFeatureRunningClientSide("FLIGHTS_PRICING_ENABLE_OVERRIDE_PRICE_FIELDS_WHEN_BRANDED_FARE_SELETED")
          ? getFlightDataWithSelectedBrandedFare(baseOffer, fareOffer)
          : merge({}, baseOffer, fareOffer),
        fetchStatus: "success",
        error: undefined
      };

    default:
      return state;
  }
};

export const getFlightDataWithSelectedBrandedFare = (
  flightData: UIFlightData,
  selectedBrandedFare?: UIBrandedFare
): UIFlightData => {
  if (!selectedBrandedFare) return flightData;

  // We need to keep `baseOffer` keys that don't differ between fares (like `segments` or `extraProducts`)
  // and are not presented in `fareOffer`,
  // while overriding the others that differ (like `token` or `priceBreakdown`).
  return {
    ...merge({}, flightData, selectedBrandedFare),
    // override price related fields - FPRICING-1330
    priceBreakdown: selectedBrandedFare.priceBreakdown,
    travellerPrices: selectedBrandedFare.travellerPrices
  };
};

export { reducer, getInitialState };
