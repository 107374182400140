import React, { useCallback, useRef, useState } from "react";
import Occupancy from "./Occupancy.js";
import { Popover, ActionBar } from "@bookingcom/bui-react";
import { t } from "@bookingcom/lingojs-core";
import useOccupancy from "./useOccupancy.js";
import { ShellButton } from "../Shell/index.js";
import { AccountUsersIcon, PersonHalfIcon } from "@bookingcom/bui-assets-react/streamline/index.js";
import styles from "./OccupancyDropDown.module.css";
const OccupancyButtonDropDown = () => {
    const { occupancy, i18n, trackV2, errorMessage, isOccupancySelected, occupancyText, count } = useOccupancy();
    const [active, setActive] = useState(false);
    const [state, setState] = useState(occupancy);
    const buttonRef = useRef(null);
    const trackEvent = useCallback(() => {
        if (!isOccupancySelected)
            return;
        if (occupancy.adults !== state.adults ||
            occupancy.children.length !== state.children.length ||
            occupancy.children.some((a, i) => state.children[i] !== a)) {
            setState(occupancy);
            trackV2("select_travelers", {
                number_adults: occupancy.adults,
                number_children: occupancy.children.length,
                children_ages: occupancy.children
            });
        }
    }, [occupancy, state, isOccupancySelected, trackV2]);
    const handleOnClose = useCallback(() => {
        setActive(false);
        trackEvent();
    }, [trackEvent]);
    const handleOnOpen = useCallback(() => {
        setActive(true);
    }, []);
    const handleOnButtonclick = useCallback(() => {
        trackV2("click_passenger_done");
        handleOnClose();
    }, [handleOnClose, trackV2]);
    return (React.createElement(React.Fragment, null,
        React.createElement(ShellButton, { icon: count > 1 ? AccountUsersIcon : PersonHalfIcon, tabIndex: 0, ref: buttonRef, className: styles.triggerCta, "data-ui-name": "button_occupancy", title: i18n.trans(t("a11y_flights_search_box_travellers_edit_hint")), errorMessage: errorMessage, errorVariant: "tooltip", errorPosition: "bottom", "data-ui-error": Boolean(errorMessage), onClick: () => {
                setActive(true);
                trackV2("click_passenger");
            }, onKeyDown: (e) => {
                if (e.key !== "ArrowDown")
                    return;
                setActive(true);
                trackV2("click_passenger");
            } }, occupancyText),
        React.createElement(Popover, { fill: true, hideClose: true, active: active, onClose: handleOnClose, onOpen: handleOnOpen, position: "bottom-end", triggerType: "click", triggerRef: buttonRef },
            React.createElement(Popover.Content, null,
                React.createElement("div", { className: styles.content },
                    React.createElement("div", { className: styles.contentScrollArea },
                        React.createElement(Occupancy, { hideTitle: true })),
                    React.createElement("div", { className: styles.contentFooter },
                        React.createElement(ActionBar, { button: {
                                wide: true,
                                text: i18n.trans(t("flights_action_done")),
                                onClick: handleOnButtonclick,
                                attributes: {
                                    "data-ui-name": "button_occupancy_action_bar_done"
                                }
                            } }, occupancyText)))))));
};
export default OccupancyButtonDropDown;
