import { useLocation } from "react-router-dom";

export default function useGetUrlParams() {
  const location = useLocation();
  const getUrlParam = (param: string = "") => {
    const urlParams = new URLSearchParams(location.search);
    return urlParams.get(param) || "";
  };
  return {
    getUrlParam
  };
}
