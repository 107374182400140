import { useCallback } from "react";
import useBookingWindowFromSearch from "hooks/useBookingWindow";
import useRouteName from "hooks/useRouteName";
import { RouteName } from "app/routes";
import { isOfMetaOrigin } from "utils/marketing-url-params";

import expWww from "utils/experiments/customer-aquisition/flights_web_cat_fly_anywhere_www";
import expMdot from "utils/experiments/customer-aquisition/flights_web_cat_fly_anywhere_mdot";

const EXTRA_PAGES: Array<RouteName> = ["landing", "not-found", "pb-change-flight"];

const useTrackFlyAnywhere = () => {
  const bookingWindow = useBookingWindowFromSearch();
  const routeName = useRouteName();

  const trackFlyAnywhereWww = useCallback(() => {
    expWww.trackWithDefaultStage();

    if (routeName === "home") {
      expWww.stages.home_page_2();
    } else if (routeName === "searchresults") {
      expWww.stages.search_page_3();
    } else if (EXTRA_PAGES.includes(routeName)) {
      expWww.stages.landing_rebook_notfound_4();
    }

    if (bookingWindow && bookingWindow > 30) {
      expWww.stages.booking_window_gt_30_5();
    } else if (bookingWindow && bookingWindow <= 30) {
      expWww.stages.booking_window_lt_30_6();
    }

    if (isOfMetaOrigin()) {
      expWww.stages.meta_users_7();
    } else {
      expWww.stages.direct_users_8();
    }
  }, [bookingWindow, routeName]);

  const trackFlyAnywhereMdot = useCallback(() => {
    expMdot.trackWithDefaultStage();

    if (routeName === "home") {
      expMdot.stages.home_page_2();
    } else if (routeName === "searchresults") {
      expMdot.stages.search_page_3();
    } else if (EXTRA_PAGES.includes(routeName)) {
      expMdot.stages.landing_rebook_notfound_4();
    }

    if (bookingWindow && bookingWindow > 30) {
      expMdot.stages.booking_window_gt_30_5();
    } else if (bookingWindow && bookingWindow <= 30) {
      expMdot.stages.booking_window_lt_30_6();
    }

    if (isOfMetaOrigin()) {
      expMdot.stages.meta_users_7();
    } else {
      expMdot.stages.direct_users_8();
    }
  }, [bookingWindow, routeName]);

  return { trackFlyAnywhereWww, trackFlyAnywhereMdot };
};

export { useTrackFlyAnywhere };
