import { StoreState } from "store/index";

export const getOrder = (state: StoreState) => state.order.order;
export const getOrderCountry = (state: StoreState) => state.order.order?.salesInfo?.country;
export const getOrderSupplierType = (state: StoreState) => state.order.order?.supplierType;
export const getOrderId = (state: StoreState) => state.order.order?.orderId;
export const getOrderFetchStatus = (state: StoreState) => state.order.fetchStatus;
export const getAddOnOrder = (state: StoreState) => state.order.addOnOrder;
export const getNoOfAdults = (state: StoreState) =>
  state.order.order?.passengers.filter((p) => p.type === "ADULT").length;
export const getNoOfChildren = (state: StoreState) =>
  state.order.order?.passengers.filter((p) => p.type === "KID").length;
export const getFlightSegments = (state: StoreState) => state.order.order?.airOrder.flightSegments;
export const getConfirmedAt = (state: StoreState) => state.order.order?.airOrder.confirmedAt;
