import React, { useCallback, useEffect, useMemo, useRef } from "react";
import useOccupancy from "./useOccupancy.js";
import { InputStepper } from "@bookingcom/bui-react";
import { t } from "@bookingcom/lingojs-core";
import styles from "./Adults.module.css";
const Adults = (_) => {
    const { i18n, maxCount, occupancy, setOccupancy } = useOccupancy();
    const refElm = useRef(null);
    const adults = useMemo(() => {
        return occupancy.adults;
    }, [occupancy.adults]);
    const childrenCount = useMemo(() => {
        return occupancy.children.length;
    }, [occupancy.children]);
    const onChange = useCallback((adults) => {
        setOccupancy({ adults });
    }, [setOccupancy]);
    useEffect(() => {
        if (!refElm.current)
            return;
        const [minusBtn, plusBtn] = Array.prototype.slice.call(refElm.current.querySelectorAll("button"));
        minusBtn.setAttribute("data-ui-name", "button_occupancy_adults_minus");
        plusBtn.setAttribute("data-ui-name", "button_occupancy_adults_plus");
    }, [refElm]);
    return (React.createElement("div", { ref: refElm, className: styles.wrapper },
        React.createElement(InputStepper, { attributes: { "data-ui-name": "occupancy_adults" }, helper: i18n.trans(t("flights_adult_age_range", {
                variables: { num_exception: 18, min_adult_age: 18 }
            })), label: i18n.trans(t("flights_traveller_adult_name", {
                variables: { num_exception: adults }
            })), max: maxCount - childrenCount, min: 1, name: "sr_occupancy_adults_stepper", onChange: ({ value }) => {
                onChange(value);
            }, value: adults })));
};
export default Adults;
