import useGlobalContext from "./useGlobalContext";

type UseCreateUrlHook = {
  createUrl: (url: string) => string;
  createRoutePageUrl: (url: string) => string;
  createUrlWithApiPrefix: (url: string) => string;
  enhanceURLSearchParams: (params: URLSearchParams) => void;
};

/**
 * Injects the tracking variables into URLSearchParams object.
 * It works with object URL that already contains parameters.
 * If a parameter already exists, it will be overwritten. If not, it will be set
 *
 * @param   searchParams  URLSearchParams object which can be already pre-populated
 * @param   variables     The tracking variables to inject in the urls
 * @returns void.         Will mutate original searchParams with injected tracking variables parameters
 */
const injectTrackingVariablesInURLSearchParams = (
  searchParams: URLSearchParams,
  variables: Record<string, string> | undefined
): void => {
  for (const key in variables) {
    if (variables.hasOwnProperty(key)) {
      searchParams.set(key, variables[key]);
    }
  }
};

/**
 * useCreateUrl hook returns functions to build URLs. They should be used to construct all
 * (internal and external) urls on the website. That ensures that url will contain
 * all needed parameters that should be propagated across silos.
 *
 * - createUrl this function accepts URL as a string and adds required parameters to it.
 * It will return modified URL
 * - createRoutePageUrl this function accepts URL as a string and adds required parameters to it.
 * It returns long form URL with www.booking.com hostname where route pages are hosted.
 *
 * - enhanceURLSearchParams accepts URLSearchParams object and mutates it, adding required parameters.
 *
 */
export default function useCreateUrl(): UseCreateUrlHook {
  const { marketingTrackingVariables, apiPrefix, userAgent, isKnownBot, emkToken, offerInstanceId } =
    useGlobalContext();

  const createUrl = (url: string): string => {
    const urlObj = new URL(url, "http://x");

    if (userAgent.isBot && !isKnownBot) {
      // Build canonical URLs for bots and drop any URL params.
      // Also, only canonicalise URLs for bots that are not friendly. Friendly bots may
      // visit flights pages to read prices.
      // See server/modules/useragent.ts for how isKnownBot is resolved.
      return urlObj.pathname;
    }

    enhanceURLSearchParams(urlObj.searchParams);

    return urlObj.toString().replace(/^http:\/\/x/, "");
  };

  // This is a helper for working around the hostname, because
  // main flight app is hosted at flights.booking.com and
  // route pages are hosted at www.booking.com.
  const createRoutePageUrl = (url: string): string => {
    return `https://www.booking.com${createUrl(url)}`;
  };

  const createUrlWithApiPrefix = (url: string): string => {
    return `${apiPrefix}${createUrl(url)}`;
  };

  const enhanceURLSearchParams = (params: URLSearchParams): void => {
    const featureAndExperimentParamsInCurrentUrl =
      process.env.BUILD_TARGET === "client" ? getFeatureAndExperimentParamsFromCurrentUrl() : {};

    const paramsToInject = {
      ...featureAndExperimentParamsInCurrentUrl
    };

    if (marketingTrackingVariables?.aid) {
      paramsToInject.aid = marketingTrackingVariables?.aid;
    }
    if (marketingTrackingVariables?.label) {
      paramsToInject.label = marketingTrackingVariables?.label;
    }
    if (marketingTrackingVariables?.adPlat) {
      paramsToInject.adplat = marketingTrackingVariables?.adPlat;
    }
    if (marketingTrackingVariables?.gclid) {
      paramsToInject.gclid = marketingTrackingVariables?.gclid;
    }
    if (marketingTrackingVariables?.gclsrc) {
      paramsToInject.gclsrc = marketingTrackingVariables?.gclsrc;
    }
    if (marketingTrackingVariables?.auid) {
      paramsToInject.auid = marketingTrackingVariables?.auid;
    }
    if (marketingTrackingVariables?.externalTracking) {
      paramsToInject["ext-tr"] = marketingTrackingVariables?.externalTracking;
    }
    if (marketingTrackingVariables?.externalSource) {
      paramsToInject["ext-src"] = marketingTrackingVariables?.externalSource;
    }
    if (emkToken) {
      paramsToInject.emk = emkToken;
    }
    if (offerInstanceId) {
      paramsToInject.offerInstanceId = offerInstanceId;
    }

    injectTrackingVariablesInURLSearchParams(params, paramsToInject);
  };

  const getFeatureAndExperimentParamsFromCurrentUrl = (): Record<string, string> => {
    const result = {};
    const currentUrlParams = new URLSearchParams(location.search);

    currentUrlParams.forEach((value, name) => {
      if (
        name.startsWith("b_feature_") ||
        name.startsWith("exp_") ||
        name === "my_ip" ||
        name === "i_am_from" ||
        name === "show_tags" ||
        name === "debug" ||
        name === "mock-scenario-name" ||
        name === "mock-scenario-enabled"
      ) {
        // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
        result[name] = value;
      }
    });

    return result;
  };

  return { createUrl, createRoutePageUrl, createUrlWithApiPrefix, enhanceURLSearchParams };
}
