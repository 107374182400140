import { useCallback, useMemo } from "react";
import useUserAgent from "./useUserAgent";
import { trackExperiment, trackExperimentStage } from "utils/et";
import useProfiler from "./useProfiler";
import { useSelector } from "react-redux";
import { getFlight } from "store/flightDetails/selectors";
import { UIFlightData } from "@flights/types";
import useVirutalInterlining from "./useVirutalInterlining";
import useBaggages from "components/elements/Baggages/useBaggages";

export default function usePaxWithBaggages() {
  const { isMobile } = useUserAgent();
  const profiler = useProfiler();
  const flight = useSelector(getFlight);
  const { getBaggageCopyForVI } = useVirutalInterlining();
  const { getBaggagesStatus } = useBaggages(flight);

  const isPaxWithBaggages = useMemo(
    () =>
      (!isMobile && !!trackExperiment("flights_web_ddot_pax_with_baggages")) ||
      (isMobile && !!trackExperiment("flights_web_mdot_pax_with_baggages")),
    [isMobile]
  );

  const expname = useMemo(
    () => (isMobile ? "flights_web_mdot_pax_with_baggages" : "flights_web_ddot_pax_with_baggages"),
    [isMobile]
  );

  const isAnyExtraBaggages = useCallback(() => {
    const { personalItem, cabin, checkedIn } = getBaggagesStatus();
    return [personalItem, cabin, checkedIn].some((bag) => bag?.inclusionType !== "not_available");
  }, [getBaggagesStatus]);

  const isPartiallyIncluded = useCallback(() => {
    const { personalItem, cabin, checkedIn } = getBaggagesStatus();
    return [personalItem, cabin, checkedIn].some((bag) => {
      return bag?.inclusionType === "partial";
    });
  }, [getBaggagesStatus]);

  const isVIBanner = useCallback(
    (f: UIFlightData) => {
      return !!getBaggageCopyForVI(f?.segments || []);
    },
    [getBaggageCopyForVI]
  );

  const trackPaxWithBaggagessStages = useCallback(() => {
    trackExperimentStage(expname, 1);
    if (isAnyExtraBaggages()) trackExperimentStage(expname, 2);
    if (profiler.isDirect()) trackExperimentStage(expname, 3);
    if (profiler.isMeta()) trackExperimentStage(expname, 4);
    if (profiler.isSolo()) trackExperimentStage(expname, 5);
    if (!profiler.isSolo()) trackExperimentStage(expname, 6);
    if (isPartiallyIncluded()) trackExperimentStage(expname, 7);
    if (profiler.isWithInfants()) trackExperimentStage(expname, 8);
    if (flight && isVIBanner(flight)) trackExperimentStage(expname, 9);
  }, [profiler, flight, expname, isAnyExtraBaggages, isPartiallyIncluded, isVIBanner]);

  return { isPaxWithBaggages, trackPaxWithBaggagessStages };
}
