import React, { useCallback } from "react";
import { Icon } from "@bookingcom/bui-react";
import { CloseIcon } from "@bookingcom/bui-assets-react/streamline/index.js";
import useSegment from "./useSegment.js";
import { ADD_DATA_UI_NAME } from "../../utils/constants.js";
import { t } from "@bookingcom/lingojs-core";
import styles from "./RemoveSegment.module.css";
const RemoveSegment = (props) => {
    const { segmentIndex, disabled, className } = props;
    const { removeSegmentByIndex, i18n } = useSegment();
    const handleOnClick = useCallback(() => {
        removeSegmentByIndex(segmentIndex);
        setTimeout(() => {
            const addBtn = document.querySelector(`[data-ui-name='${ADD_DATA_UI_NAME}']`);
            if (!addBtn)
                return;
            addBtn.focus();
        }, 1);
    }, [removeSegmentByIndex, segmentIndex]);
    return (React.createElement("button", { type: "button", onClick: handleOnClick, className: `${styles.button} ${className}`, disabled: disabled, tabIndex: 0, "data-ui-name": `button_remove_segment_${segmentIndex}`, "aria-label": i18n.trans(t("a11y_flights_searchbox_multi_x_button")) },
        React.createElement(Icon, { attributes: { "aria-hidden": true }, size: "smaller", color: "neutral_alt", svg: CloseIcon, className: styles.icon })));
};
export default RemoveSegment;
