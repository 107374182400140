import React, { ReactElement } from "react";
import useGlobalContext from "hooks/useGlobalContext";
import { MpContextProvider } from "@bookingcom/mp-flights";
import useUserAgent from "hooks/useUserAgent";
import { reportError } from "@bookingcom/flights-core";
import { trackCustomGoal, trackExperiment, trackExperimentStage } from "../../../utils/et";

type MultiProductWrapperProps = {
  children: ReactElement;
};

export const MultiProductWrapper: React.FC<MultiProductWrapperProps> = (props) => {
  const { children } = props;
  const globalContext = useGlobalContext();
  const { isMobile, isTablet } = useUserAgent();
  const { marketingTrackingVariables, features, etStateBlob = "" } = useGlobalContext();
  const isMPEnabled = !!features["FLIGHTS_BASKET_CART_ENABLED"];
  const includeCredentials = !!features["FLIGHTS_WEB_MP_SHOP_ALLOW_CREDENTIALS"];
  const affiliateId = marketingTrackingVariables?.aid;
  const platform = isMobile || isTablet ? "mdot" : "www";

  return isMPEnabled ? (
    <MpContextProvider
      value={{
        country: globalContext.ipCountry || "",
        currency: globalContext.currencyCode || "",
        language: globalContext.lang || "",
        locale: globalContext.locale || "",
        env: globalContext.env || "dqs",
        etStateBlob,
        affiliateId: Number(affiliateId || 0),
        platform: platform,
        includeCredentials,
        reportError: reportError,
        trackExperiment: trackExperiment,
        trackExperimentStage: trackExperimentStage,
        trackCustomGoal: trackCustomGoal
      }}
    >
      {children}
    </MpContextProvider>
  ) : (
    children
  );
};
