import { useEffect, useRef, useState } from "react";
const useScrollBaseMonthChange = ({ isActive, selector, monthsCallback, daysCallback }) => {
    const monthsIntersectionRef = useRef();
    const datesIntersectionRef = useRef();
    const mutationRef = useRef();
    const timerRef = useRef();
    const [inView, setInView] = useState();
    useEffect(() => {
        if (!isActive)
            return;
        const root = document.querySelector(`[${selector.key}="${selector.value}"]`);
        // For now, based on calendar's HTML structure, we assume the last element is the one being scrolled
        const scrollableElement = root?.lastChild;
        if (scrollableElement) {
            scrollableElement.addEventListener("scroll", () => {
                clearTimeout(timerRef.current);
                timerRef.current = setTimeout(() => {
                    inView && monthsCallback?.(inView, "scroll");
                }, 200);
            });
        }
        return () => {
            clearTimeout(timerRef.current);
        };
    }, [monthsCallback, inView, isActive, selector.key, selector.value]);
    useEffect(() => {
        if (!isActive)
            return;
        const root = document.querySelector(`[${selector.key}="${selector.value}"]`);
        // For now, based on calendar's HTML structure, we assume the last element is the one being scrolled
        const scrollableElement = root?.lastChild;
        if (scrollableElement) {
            // The calendar adds new elements (months) as a user scrolls,
            // hence we need to check for new added/removed elements.
            mutationRef.current = new MutationObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.addedNodes.length) {
                        entry.addedNodes.forEach((node) => {
                            // Observe newly added days
                            const dates = node.querySelectorAll("[data-date]");
                            if (dates.length) {
                                dates.forEach((node) => {
                                    if (node && datesIntersectionRef.current) {
                                        datesIntersectionRef.current.observe(node);
                                    }
                                });
                            }
                            // Observe newly added months
                            monthsIntersectionRef?.current?.observe(node);
                        });
                    }
                    if (entry.removedNodes.length) {
                        entry.removedNodes.forEach((node) => {
                            // Unobserve removed days
                            const dates = node.querySelectorAll("[data-date]");
                            if (dates.length) {
                                dates.forEach((node) => {
                                    if (node && datesIntersectionRef.current) {
                                        datesIntersectionRef.current.unobserve(node);
                                    }
                                });
                            }
                            // Unobserve removed months
                            monthsIntersectionRef?.current?.unobserve(node);
                        });
                    }
                });
            });
            // This will track whenever 75% of an element (month) is visible on the viewport.
            monthsIntersectionRef.current = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const el = entry.target.querySelector("[data-date]");
                        if (el) {
                            const date = el.getAttribute("data-date");
                            if (date) {
                                setInView(new Date(date));
                            }
                        }
                    }
                });
            }, {
                root,
                threshold: 0.75
            });
            // This will track whatever elements (days) are visible on the viewport.
            datesIntersectionRef.current = new IntersectionObserver((entries) => {
                const dates = {};
                entries.forEach((entry) => {
                    const date = entry.target.getAttribute("data-date");
                    if (entry.isIntersecting) {
                        if (date) {
                            dates[date] = 1;
                        }
                    }
                    else {
                        if (date) {
                            dates[date] = 0;
                        }
                    }
                });
                daysCallback?.((prev) => {
                    const newState = { ...prev };
                    Object.entries(dates).forEach(([key, value]) => {
                        if (value === 1) {
                            newState[key] = 1;
                        }
                        else {
                            delete newState[key];
                        }
                    });
                    return newState;
                });
            }, {
                root,
                threshold: 0
            });
            const dates = scrollableElement.querySelectorAll("[data-date]");
            if (dates.length) {
                dates.forEach((node) => {
                    if (node && datesIntersectionRef.current) {
                        datesIntersectionRef.current.observe(node);
                    }
                });
            }
            mutationRef.current.observe(scrollableElement, {
                childList: true
            });
            scrollableElement.childNodes.forEach((node) => {
                if (node && monthsIntersectionRef.current) {
                    monthsIntersectionRef.current.observe(node);
                }
            });
        }
        return () => {
            mutationRef.current?.disconnect();
            monthsIntersectionRef.current?.disconnect();
        };
    }, [daysCallback, isActive, selector.key, selector.value]);
};
export default useScrollBaseMonthChange;
