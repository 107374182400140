let shouldReload = false;

export function checkForAppVersionMismatch(newVersion: string | null) {
  if (newVersion && window.__GLOBAL_CONTEXT__ && newVersion !== window.__GLOBAL_CONTEXT__.appVersion) {
    // We need to prevent from multiple page reloads when we vanguard FE code during rollout
    // (caused by 10% of new client code pods is requesting 90% of old server code pods, and vice versa).
    // Throttle page reloads down to no more often than every 20 mins, scheduling a delayed reload. Chances are
    // most of the users will finish their activity by that time, and reload will silently happen in inactive tab.
    setTimeout(() => (shouldReload = true), 20 * 60 * 1000);
  }
}

export function reloadIfNeeded() {
  if (shouldReload) {
    window.location.reload();
  }
}
