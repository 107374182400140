import React from "react";

import useUserAgent from "hooks/useUserAgent";
import { __deprecated__createTrackingUtilsV2 } from "utils/experiments";
import { useIsPriceReviewAvailable } from "components/elements/RebookFlow/hooks/useIsPriceReviewAvailable";
import { useSelector } from "react-redux";
import { getRebookPolicy } from "store/rebookPolicy/selectors";

export const trackRebookPriceReviewM3 = __deprecated__createTrackingUtilsV2({
  name: "flights_web_pb_rebook_price_review_m3",
  stages: {
    PRICE_REVIEW_AVAILABLE_FLEX_OR_ANCILLARIES: 1,
    PRICE_REVIEW_AVAILABLE_FLEX_OR_ANCILLARIES_DESKTOP: 2,
    PRICE_REVIEW_AVAILABLE_FLEX_OR_ANCILLARIES_MDOT: 3,
    PRICE_REVIEW_AVAILABLE_ANCILLARIES: 4,
    PRICE_REVIEW_AVAILABLE_ANCILLARIES_DESKTOP: 5,
    PRICE_REVIEW_AVAILABLE_ANCILLARIES_MDOT: 6,
    PRICE_REVIEW_AVAILABLE_FLEX: 7,
    PRICE_REVIEW_AVAILABLE_FLEX_DESKTOP: 8,
    PRICE_REVIEW_AVAILABLE_FLEX_MDOT: 9
  },
  goals: {
    CLICK_FIND_FLIGHTS: 1,
    CLICK_SEARCH_FLIGHTS: 2,
    CLICK_VIEW_DETAILS: 3,
    CLICK_CS_CHANNEL: 4,
    CLICK_COST_BREAKDOWN: 5
  },
  goalsWithValue: []
});

export const useTrackRebookPriceReviewM3 = () => {
  const { isMobile } = useUserAgent();
  const rebookPolicy = useSelector(getRebookPolicy);
  const isPriceReviewAvailable = useIsPriceReviewAvailable();

  React.useEffect(() => {
    const m3Policies = ["CHANGE_TRIP_YOURSELF_ANCILLARIES", "CHANGE_TRIP_YOURSELF_FLEXIBLE_TICKET_TRIP"];

    if (
      isPriceReviewAvailable &&
      rebookPolicy?.rebookEligibilityInfo.rebookFlow &&
      m3Policies.includes(rebookPolicy.rebookEligibilityInfo.rebookFlow)
    ) {
      trackRebookPriceReviewM3.stages.PRICE_REVIEW_AVAILABLE_FLEX_OR_ANCILLARIES();
      if (isMobile) {
        trackRebookPriceReviewM3.stages.PRICE_REVIEW_AVAILABLE_FLEX_OR_ANCILLARIES_MDOT();
      } else {
        trackRebookPriceReviewM3.stages.PRICE_REVIEW_AVAILABLE_FLEX_OR_ANCILLARIES_DESKTOP();
      }

      if (rebookPolicy.rebookEligibilityInfo.rebookFlow === "CHANGE_TRIP_YOURSELF_ANCILLARIES") {
        trackRebookPriceReviewM3.stages.PRICE_REVIEW_AVAILABLE_ANCILLARIES();
        if (isMobile) {
          trackRebookPriceReviewM3.stages.PRICE_REVIEW_AVAILABLE_ANCILLARIES_MDOT();
        } else {
          trackRebookPriceReviewM3.stages.PRICE_REVIEW_AVAILABLE_ANCILLARIES_DESKTOP();
        }
      }

      if (rebookPolicy.rebookEligibilityInfo.rebookFlow === "CHANGE_TRIP_YOURSELF_FLEXIBLE_TICKET_TRIP") {
        trackRebookPriceReviewM3.stages.PRICE_REVIEW_AVAILABLE_FLEX();
        if (isMobile) {
          trackRebookPriceReviewM3.stages.PRICE_REVIEW_AVAILABLE_FLEX_MDOT();
        } else {
          trackRebookPriceReviewM3.stages.PRICE_REVIEW_AVAILABLE_FLEX_DESKTOP();
        }
      }
    }
  }, [isMobile, isPriceReviewAvailable, rebookPolicy]);
};
