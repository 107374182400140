import React, { useCallback, useEffect, useRef, useState } from "react";
import useOccupancy from "./useOccupancy.js";
import { InputStepper } from "@bookingcom/bui-react";
import { t } from "@bookingcom/lingojs-core";
const Children = (props) => {
    const { occupancy, onChange } = props;
    const { i18n, maxCount, trackV2 } = useOccupancy();
    const refElm = useRef(null);
    const children = occupancy.children;
    const adults = occupancy.adults;
    const childrenCount = occupancy.children.length;
    const [count, setCount] = useState(occupancy.children.length || 0);
    const handleOnChange = useCallback((count) => {
        const result = [];
        for (let i = 0; i < count; i++)
            result.push(children[i]);
        trackV2("click_passenger_children_count", result.length);
        onChange(result);
    }, [children, onChange, trackV2]);
    useEffect(() => {
        if (!refElm.current)
            return;
        const [minusBtn, plusBtn] = Array.prototype.slice.call(refElm.current.querySelectorAll("button"));
        minusBtn.setAttribute("data-ui-name", "button_occupancy_children_minus");
        plusBtn.setAttribute("data-ui-name", "button_occupancy_children_plus");
    }, [refElm]);
    useEffect(() => {
        // this is a hack till we fix a bug in input stepper
        if (count != children.length)
            handleOnChange(count);
    }, [count, children, handleOnChange]);
    return (React.createElement("div", { ref: refElm },
        React.createElement(InputStepper, { attributes: { "data-ui-name": "occupancy_children" }, label: i18n.trans(t("flights_traveller_child_name", {
                variables: { num_exception: childrenCount }
            })), helper: i18n.trans(t("flights_child_age_range", {
                variables: { min_child_age: "0", max_child_age: "17" }
            })), name: "sr_occupancy_children_stepper", min: 0, max: maxCount - adults, onChange: ({ value }) => {
                setCount(value);
            }, value: childrenCount })));
};
export default Children;
