import { formatISO9075, isBefore } from "date-fns";
import { parseDateISO8601 } from "@bookingcom/flights-core";
import { useContext, useMemo } from "react";
import { SearchBoxContext } from "../../components/SearchBoxContext/index.js";
export const parseLocation = (loc) => {
    const code = loc[0]?.code || "";
    const type = loc[0]?.type || "";
    if (!code || !type || code === "Anywhere" || type === "Anywhere") {
        return;
    }
    return `${code}.${type}`;
};
export const formatDate = (date) => formatISO9075(date, { representation: "date" });
const currencySymbolsMinimal = {
    EUR: "€",
    GBP: "£",
    USD: "$",
    UAH: "₴",
    THB: "฿",
    PHP: "₱"
};
const priceMaxLength = 5;
export const formatPriceForFareCalendar = (price, params) => {
    let roundedPrice = price.nanos > 0 ? price.units + 1 : price.units;
    const abbreviations = ["K", "M", "B"];
    let unitIndex = -1;
    while (roundedPrice >= 1000 && unitIndex < abbreviations.length - 1) {
        roundedPrice /= 1000;
        unitIndex++;
    }
    const decimalsCount = roundedPrice.toString().split(".")?.[1]?.length || 0;
    const truncatedDecimals = `${decimalsCount > 1 ? roundedPrice + 0.1 : roundedPrice}`.replace(/(\d+\.\d)\d*/, "$1");
    const priceString = `${Number(truncatedDecimals)}${abbreviations[unitIndex] || ""}`;
    const result = [];
    if (!params?.hideSymbol && priceString.length < priceMaxLength) {
        const symbol = currencySymbolsMinimal[price.currencyCode] || "";
        result.push(symbol);
    }
    result.push(priceString);
    return result.join("");
};
export const calcNumberOfNights = (from, to) => {
    return Math.floor((to.getTime() - from.getTime()) / 864e5) || 0;
};
export const preventDateFromBeingBefore = (date, referenceDate) => {
    return isBefore(date, referenceDate) ? referenceDate : date;
};
export const useMinMaxDates = (index) => {
    // This logic is copied from "useCalendar" hook.
    // We may use this hook there is this goes full on.
    const { segments } = useContext(SearchBoxContext);
    const { departureDate: prevDepartureDate } = segments[index - 1] || {};
    const prevFrom = prevDepartureDate ? parseDateISO8601(prevDepartureDate) : undefined;
    const minDate = useMemo(() => {
        return prevFrom || new Date();
    }, [prevFrom]);
    const maxDate = useMemo(() => {
        return new Date(new Date().getTime() + 310176e5); // 360 days
    }, []);
    return { minDate, maxDate };
};
