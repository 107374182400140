// Generally experiments run by MdotValue (https://teams.booking.com/team/60011316)
// will have most of the in common. This hook will help us keep the code simpler to implement.

import useGlobalContext from "hooks/useGlobalContext";
import { isDirect, isOfMetaOrigin, isOfPPCOrigin } from "utils/marketing-url-params";

export type MdotValueDefaultsStageName =
  | "signed_in"
  | "signed_out"
  | "us_traffic"
  | "key_markets"
  | "direct_traffic"
  | "paid_traffic"
  | "ios"
  | "android";
const KEY_MARKET_COUNTRY_CODES = ["us", "fr", "in", "it", "de", "gb", "es", "br"];

// Usage example:
// In this example, calling trackDefaultMdovalueStages() will track all the possible default stage.
// Stages not definited as default will be ignored by this hook.
//
// flights_mdotvalue_experiment_name.ts
// ---
// export defautlt createTrackingUtils({
//   name: "flights_mdotvalue_experiment_name"
//    stages: {
//     conditional_stage: 1,
//     clicked_close_flight_details_modal: 2,
//
//     paid_traffic: 3,
//     us_traffic: 4,
//     non_us_traffic: 5,
//     signed_in: 6,
//     signed_out: 7,
//     android: 8,
//     ios: 9
//   },
//   ...
// });
//
// component_triggering_stages.ts
// ---
// import flights_mdotvalue_experiment_name from "utils/experiments/mdotvalue/flights_mdotvalue_experiment_name";
// ...
// const trackDefaultMdovalueStages = useMdotValueTrackDefaultStages();
// if (preCondition) {
//   trackDefaultMdovalueStages(flights_mdotvalue_experiment_name.stages);
// }
export default function useMdotValueTrackDefaultStages() {
  const { auth, ipCountry } = useGlobalContext();

  return (stages: { [key: string]: Function }) => {
    const isSignedIn = auth.isAuthenticatedOnWeb;
    const isKeyMarket = Boolean(ipCountry && KEY_MARKET_COUNTRY_CODES.includes(ipCountry.toLowerCase()));
    const isUsTraffic = Boolean(ipCountry && ipCountry.toLowerCase() === "us");
    const isIOS = Boolean(
      navigator?.userAgent && /iphone|ipad|ipod|ios/.test(navigator?.userAgent?.toLocaleLowerCase())
    );

    const stageConditions: Record<MdotValueDefaultsStageName, boolean> = {
      signed_in: isSignedIn,
      signed_out: !isSignedIn,
      us_traffic: isUsTraffic,
      key_markets: isKeyMarket,
      direct_traffic: isDirect(),
      paid_traffic: isOfPPCOrigin() || isOfMetaOrigin(),
      ios: isIOS,
      android: !isIOS
    };

    Object.keys(stages).forEach((stageName) => {
      if (typeof stageName === "string" && stageConditions[stageName as MdotValueDefaultsStageName]) {
        stages[stageName]();
      }
    });
  };
}
