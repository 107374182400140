import { ActionsUnion, createAction } from "@bookingcom/flights-core";
import { State } from "./state";

import { SBState } from "@bookingcom/flights-searchbox";

export enum ActionTypes {
  setType = "search/setType",
  setFrom = "search/setFrom",
  setTo = "search/setTo",
  setDateRange = "search/setDateRange",
  setDepartureDate = "search/setDepartureDate",
  setReturnDate = "search/setReturnDate",
  setOccupancyAndCabinClass = "search/setOccupancyAndCabinClass",
  setCabinClass = "search/setCabinClass",
  setSortOrder = "search/setSortOrder",
  setPage = "search/setPage",
  setFiltersDraft = "search/setFiltersDraft",
  resetFiltersDraft = "search/resetFiltersDraft",
  applyFilters = "search/applyFilters",
  resetFilters = "search/resetFilters",
  swapFromTo = "search/swapFromTo",
  resetFiltersButKeep = "search/resetFiltersButKeep",
  setValueFromSB = "setValueFromSB",
  resetFilter = "search/resetFilter"
}

export const actions = {
  setSortOrder: createAction((sort: State["sort"]) => ({
    type: ActionTypes.setSortOrder,
    payload: { sort }
  })),

  setPage: createAction((page: State["page"]) => ({
    type: ActionTypes.setPage,
    payload: { page }
  })),

  setFiltersDraft: createAction((filtersDraft: State["filtersDraft"]) => ({
    type: ActionTypes.setFiltersDraft,
    payload: { filtersDraft }
  })),

  resetFiltersDraft: createAction(() => ({
    type: ActionTypes.resetFiltersDraft,
    payload: {}
  })),

  applyFilters: createAction(() => ({
    type: ActionTypes.applyFilters,
    payload: {}
  })),

  resetFilters: createAction(() => ({
    type: ActionTypes.resetFilters,
    payload: {}
  })),

  resetFiltersButKeep: createAction((filter) => ({
    type: ActionTypes.resetFiltersButKeep,
    payload: { filter }
  })),

  setValueFromSB: createAction((searchboxVal: SBState) => {
    return {
      type: ActionTypes.setValueFromSB,
      payload: { searchboxVal }
    };
  }),

  resetFilter: createAction((filter) => ({
    type: ActionTypes.resetFilter,
    payload: { filter }
  }))
};

export type Actions = ActionsUnion<typeof actions>;
