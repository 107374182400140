import React, { FC, useCallback } from "react";
import localstory from "localstory";

import { Text, Banner, Button, Icon } from "@bookingcom/bui-react";
import { InfoSignIcon } from "@bookingcom/bui-assets-react/streamline/index.js";
import { useI18n, t } from "@bookingcom/lingojs-react";

import useUserAgent from "../../../hooks/useUserAgent";
import useModalDialog from "hooks/useModalDialog";
import useToggle from "hooks/useToggle";
import styles from "./SirfInfoBanner.module.css";
import { mcn } from "utils/mergeClassnames";

type Props = {
  bannerName: "search-results" | "checkout-pax"; // used to store in client session storage whether banner was closed by user

  bannerContent: React.ReactNode;
  className?: string;
};
const getSessionStorageKeyName = (bannerName: string) => `spanish_islands_info_banner_is_closed_by_user__${bannerName}`;

const SirfInfoBanner: FC<Props> = ({ bannerName, bannerContent, className }) => {
  const localStore = localstory(window.sessionStorage, "flights");
  const { isMobile } = useUserAgent();
  const i18n = useI18n();
  const Modal = useModalDialog();

  const { visible: modalActive, show: showModal, hide: hideModal } = useToggle();

  const isBannerClosed = Boolean(localStore.get(getSessionStorageKeyName(bannerName)));

  const onBannerClose = useCallback(() => {
    localStore.set(getSessionStorageKeyName(bannerName), true);
  }, [localStore, bannerName]);

  return (
    <>
      {!isBannerClosed && (
        <Banner
          className={mcn(styles.bannerRoot, className)}
          startIcon={<Icon svg={InfoSignIcon} size="large" />}
          actions={[
            {
              text: i18n.trans(t("flights_action_learn_more")),
              onClick: showModal
            }
          ]}
          closeAriaLabel="Close banner"
          onClose={onBannerClose}
          attributes={{ "data-testid": "sirf_info_banner" }}
        >
          {bannerContent}
        </Banner>
      )}
      <Modal
        active={modalActive}
        onCloseTrigger={hideModal}
        fill={isMobile}
        attributes={{ "data-testid": "sirf_info_banner_modal" }}
      >
        <ModalContent onCtaButtonClick={hideModal} />
      </Modal>
    </>
  );
};

const ModalContent: FC<{ onCtaButtonClick: () => void }> = ({ onCtaButtonClick }) => {
  const { isMobile } = useUserAgent();
  const i18n = useI18n();

  return (
    <div className={styles.modalContent}>
      <Text variant="headline_3" className={styles.modalTitle}>
        {i18n.trans(t("flights_modal_spanish_discount_header"))}
      </Text>
      <Text className={styles.modalParagraph} variant="body_2">
        {i18n.trans(t("flights_modal_spanish_discount_1"))}
      </Text>
      <Text variant="strong_2">{i18n.trans(t("flights_modal_spanish_discount_subheader"))}</Text>
      <Text className={styles.modalParagraph} variant="body_2">
        {i18n.trans(t("flights_modal_spanish_discount_2"))}
      </Text>
      <Text className={styles.modalParagraph} variant="body_2">
        {i18n.trans(t("flights_modal_spanish_discount_3"))}
      </Text>
      <Button
        text={i18n.trans(t("flights_ok_got_action"))}
        onClick={onCtaButtonClick}
        className={styles.modalCtaButton}
        size={isMobile ? "large" : "medium"}
        attributes={{ "data-testid": "ok" }}
      />
    </div>
  );
};

export default SirfInfoBanner;
