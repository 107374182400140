import { useCallback, useEffect, useMemo, useState } from "react";
import * as dateFn from "date-fns";
import useCalendar from "./useCalendar.js";
import { formatDate, formatPriceForFareCalendar } from "../../hooks/useFareCalendar/utils.js";
export default function (props) {
    const { segmentIndex, fareCalendarData, onUpdateDate, onFareCalendarDateChange, onCalendarNavigate } = props;
    const { endDate, i18n, isRoundTrip, maxDate, minDate, monthHashFull, sortedDays, sortedMonth, startDate, trackV2, weekDaysHashFull } = useCalendar({ segmentIndex });
    const getDateYearMonthDay = useCallback((d) => {
        const day = String(d ? d.getDate() : "");
        const month = String(d ? d.getMonth() : "");
        const year = String(d ? d.getFullYear() : "");
        const yearMonth = year !== "" && month !== "" ? `${year}-${month}` : "";
        return { day, yearMonth };
    }, []);
    const _start = useMemo(() => getDateYearMonthDay(startDate), [getDateYearMonthDay, startDate]);
    const _end = useMemo(() => getDateYearMonthDay(endDate), [getDateYearMonthDay, endDate]);
    const [fromDay, setFromDay] = useState(_start.day);
    const [fromYearMonth, setFromYearMonth] = useState(_start.yearMonth);
    const [toDay, setToDay] = useState(_end.day);
    const [toYearMonth, setToYearMonth] = useState(_end.yearMonth);
    const fromYear = useMemo(() => fromYearMonth.split("-")[0] || "", [fromYearMonth]);
    const fromMonth = useMemo(() => fromYearMonth.split("-")[1] || "", [fromYearMonth]);
    const toYear = useMemo(() => toYearMonth.split("-")[0] || "", [toYearMonth]);
    const toMonth = useMemo(() => toYearMonth.split("-")[1] || "", [toYearMonth]);
    const getDateValues = useCallback(({ from: fromTuple, to: toTuple }) => {
        const d = new Date();
        d.setHours(0, 0, 0, 0);
        let from = undefined;
        let to = undefined;
        const [fromYear, fromMonth, fromDay] = fromTuple;
        const [toYear, toMonth, toDay] = toTuple;
        if (fromDay && fromMonth && fromYear) {
            from = dateFn.set(d, {
                date: Number(fromDay),
                month: Number(fromMonth),
                year: Number(fromYear)
            });
        }
        if (from && toDay && toMonth && toYear) {
            to = dateFn.set(d, {
                date: Number(toDay),
                month: Number(toMonth),
                year: Number(toYear)
            });
        }
        return { from, to };
    }, []);
    const onFromDayChange = useCallback((day) => {
        const { from, to } = getDateValues({ from: [fromYear, fromMonth, day], to: [] });
        onFareCalendarDateChange({ departureDate: from, returnDate: to });
        onUpdateDate({ from, to });
        setToDay("");
        setToYearMonth("");
        setFromDay(day);
    }, [fromMonth, fromYear, getDateValues, onFareCalendarDateChange, onUpdateDate]);
    const onFromYearMonthChange = useCallback((yearMonth) => {
        const [year, month] = yearMonth.split("-");
        onUpdateDate(getDateValues({ from: [year, month, ""], to: [] }));
        const date = new Date(Number(year), Number(month), 1);
        onCalendarNavigate({ departureYearMonth: date });
        setFromDay("");
        setToDay("");
        setToYearMonth("");
        setFromYearMonth(yearMonth);
    }, [getDateValues, onCalendarNavigate, onUpdateDate]);
    const onToDayChange = useCallback((day) => {
        const { from, to } = getDateValues({ from: [fromYear, fromMonth, fromDay], to: [toYear, toMonth, day] });
        onFareCalendarDateChange({ departureDate: from, returnDate: to });
        onUpdateDate({ from, to });
        setToDay(day);
    }, [fromDay, fromMonth, fromYear, getDateValues, onFareCalendarDateChange, onUpdateDate, toMonth, toYear]);
    const onToYearMonthChange = useCallback((yearMonth) => {
        const [year, month] = yearMonth.split("-");
        const { from, to } = getDateValues({ from: [fromYear, fromMonth, fromDay], to: [year, month, ""] });
        onUpdateDate({ from, to });
        const date = new Date(Number(year), Number(month), 1);
        onCalendarNavigate?.({ returnYearMonth: date });
        setToDay("");
        setToYearMonth(yearMonth);
    }, [fromDay, fromMonth, fromYear, getDateValues, onCalendarNavigate, onUpdateDate]);
    const getMonthYearList = useCallback((starting) => {
        const start = new Date(starting || minDate);
        start.setDate(1);
        const end = new Date(maxDate);
        let current = new Date(start);
        const list = [];
        do {
            list.push({
                // @ts-ignore: Element implicitly has an 'any' type. Fix the issue timely.
                text: `${monthHashFull[sortedMonth[current.getMonth()]]} - ${current.getFullYear()}`,
                value: `${current.getFullYear()}-${current.getMonth()}`
            });
            current = dateFn.addMonths(current, 1);
        } while (dateFn.isBefore(current, end));
        return list;
    }, [minDate, maxDate, monthHashFull, sortedMonth]);
    const fromYearMonthList = useMemo(() => {
        return getMonthYearList();
    }, [getMonthYearList]);
    const getDaysList = useCallback((monthYear, startingDate) => {
        const [_year, _month] = monthYear.split("-");
        if (!_year || !_month)
            return [];
        const year = Number(_year);
        const month = Number(_month);
        const start = new Date(startingDate || minDate);
        const end = new Date(maxDate);
        const currentMonth = new Date(year, month);
        let currentDay = new Date(year, month, 1);
        const list = [];
        do {
            const i = dateFn.getDay(currentDay);
            const disabled = (dateFn.isBefore(currentDay, start) && !dateFn.isSameDay(currentDay, start)) ||
                (dateFn.isAfter(currentDay, end) && !dateFn.isSameDay(currentDay, end));
            let farePrice = "";
            const bound = startingDate ? fareCalendarData?.inbound : fareCalendarData?.outbound;
            if (bound) {
                const dateFound = bound[formatDate(currentDay)];
                if (!disabled && dateFound) {
                    farePrice = ` ${formatPriceForFareCalendar(dateFound.fare, { hideSymbol: false })}`;
                }
            }
            list.push({
                // @ts-ignore: Element implicitly has an 'any' type. Fix the issue timely.
                text: `${dateFn.getDate(currentDay)} - ${weekDaysHashFull[sortedDays[i]]}${farePrice}`,
                value: String(list.length + 1),
                disabled
            });
            currentDay = dateFn.addDays(currentDay, 1);
        } while (dateFn.isSameMonth(currentDay, currentMonth));
        return list;
    }, [fareCalendarData, maxDate, minDate, sortedDays, weekDaysHashFull]);
    const dateValues = useMemo(() => {
        const d = new Date();
        d.setHours(0, 0, 0, 0);
        let from = undefined;
        let to = undefined;
        if (fromDay && fromMonth && fromYear) {
            from = dateFn.set(d, {
                date: Number(fromDay),
                month: Number(fromMonth),
                year: Number(fromYear)
            });
        }
        if (from && toDay && toMonth && toYear) {
            to = dateFn.set(d, {
                date: Number(toDay),
                month: Number(toMonth),
                year: Number(toYear)
            });
        }
        return { from, to };
    }, [fromDay, fromMonth, fromYear, toDay, toMonth, toYear]);
    const toYearMonthList = useMemo(() => {
        return getMonthYearList(dateValues.from || startDate);
    }, [getMonthYearList, startDate, dateValues]);
    useEffect(() => {
        onUpdateDate(dateValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return {
        endDate,
        fromDay,
        fromYearMonth,
        fromYearMonthList,
        getDaysList,
        i18n,
        isRoundTrip,
        onFromDayChange,
        onFromYearMonthChange,
        onToDayChange,
        onToYearMonthChange,
        dateValues,
        startDate,
        toDay,
        toYearMonth,
        toYearMonthList,
        trackV2
    };
}
