/**
 * Returns if it's the 'pb-flight-details' route or if we came from it.
 * It helps to handle cases with the order-details modal which is also
 * a separate route.
 * returns {Boolean}
 */
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { RouteName } from "app/routes";
import useRouteName from "hooks/useRouteName";

type LocationState = {
  from: RouteName;
};

const useIsPbFlightDetails = () => {
  const location = useLocation();
  const routeName = useRouteName();
  const { from } = (location.state as LocationState) || {};

  return useMemo(() => routeName === "pb-flight-details" || from === "pb-flight-details", [routeName, from]);
};

export default useIsPbFlightDetails;
