import { UICampaignBanner, UIGeniusCampaignBanner } from "@flights/types";

export type State = {
  discountBanner?: UICampaignBanner; // @TODO: move indexBannerCampaign here from global context
  geniusAirlines?: UIGeniusCampaignBanner;
  genius?: UICampaignBanner;
};

// eslint-disable-next-line no-restricted-syntax -- this line was auto generated, hence fix the issue timely
export const getInitialState = (): State => ({});
