import React, { useCallback, useState } from "react";
import { Calendar as _Calendar, Text } from "@bookingcom/bui-react";
import useCalendar from "./useCalendar.js";
import { formatDate, formatPriceForFareCalendar } from "../../hooks/useFareCalendar/utils.js";
import useScrollBaseMonthChange from "./useScrollBaseMonthChange.js";
const attributes = {
    key: "data-ui-name",
    value: "calendar_body"
};
export default function Calendar(props) {
    const { segmentIndex, onDateChange, fareCalendarData, fareCalendarLoading, onCalendarNavigate, ...restProps } = props;
    const { setDateValues, isRoundTrip, startDate, endDate, baseDate, formatPrice, ...calendarProps } = useCalendar({
        segmentIndex
    });
    // keeping this in state, as we dont need to change
    // baseDate on each interaction, only when component init
    const [_baseDate] = useState(baseDate);
    // We have a state to track visible days on the viewport do address
    // a performance problem when updating the state of all dates.
    const [visibleDays, setVisibleDays] = useState({});
    const handleOnDateChange = useCallback((data) => {
        const { endDate, startDate } = data;
        setDateValues({ from: startDate || undefined, to: endDate || undefined });
        onDateChange({ from: startDate || undefined, to: endDate || undefined });
    }, [setDateValues, onDateChange]);
    const renderDay = useCallback((date) => {
        const yyyy = date.getFullYear();
        const m = date.getMonth() + 1;
        const d = date.getDate();
        const mm = `0${m}`.slice(-2);
        const dd = `0${d}`.slice(-2);
        return React.createElement("span", { "data-ui-name": `calendar_cell_${yyyy}-${mm}-${dd}` }, d);
    }, []);
    const renderAttachment = useCallback(({ date }) => {
        if (!fareCalendarData) {
            return undefined;
        }
        const dateString = formatDate(date);
        if (restProps.mode === "vertical" && !visibleDays[dateString])
            return;
        const dateFound = fareCalendarData[dateString];
        if (dateFound) {
            if (dateFound.error) {
                return {
                    text: React.createElement(Text, { color: "disabled" }, "-")
                };
            }
            return {
                text: formatPriceForFareCalendar(dateFound.fare, { hideSymbol: false }),
                variant: dateFound.fareRange === "LOW" ? "good" : undefined,
                ariaLabel: formatPrice(dateFound.fare)
            };
        }
        if (fareCalendarLoading) {
            return { loading: true };
        }
        return {
            text: React.createElement(Text, { color: "disabled" }, "\u00A0")
        };
    }, [fareCalendarData, fareCalendarLoading, formatPrice, restProps.mode, visibleDays]);
    const _onCalendarNavigate = useCallback((date) => {
        if (onCalendarNavigate) {
            onCalendarNavigate(date, restProps.mode);
        }
    }, [onCalendarNavigate, restProps.mode]);
    useScrollBaseMonthChange({
        isActive: restProps.mode === "vertical" && !!onCalendarNavigate,
        selector: { key: attributes.key, value: attributes.value },
        monthsCallback: _onCalendarNavigate,
        daysCallback: setVisibleDays
    });
    return (React.createElement(_Calendar, { ...calendarProps, ...restProps, onDateChange: handleOnDateChange, startDate: startDate, endDate: isRoundTrip ? endDate : null, baseDate: _baseDate, renderDay: renderDay, attributes: { [attributes.key]: attributes.value }, renderAttachment: renderAttachment, onBaseMonthChange: _onCalendarNavigate }));
}
