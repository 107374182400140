import React, { useEffect } from "react";
import { SheetContainer } from "@bookingcom/bui-react";
import useShared from "./useShared.js";
import { t } from "@bookingcom/lingojs-core";
import styles from "./Modal.module.css";
const Modal = (props) => {
    const { children, className, active, ...rest } = props;
    const { i18n } = useShared();
    useEffect(() => {
        if (active) {
            document.body.classList.add(styles.bodyFreeze);
        }
        else {
            document.body.classList.remove(styles.bodyFreeze);
        }
        return () => {
            document.body.classList.remove(styles.bodyFreeze);
        };
    }, [active]);
    return (React.createElement(SheetContainer, { size: "large", closeAriaLabel: i18n.trans(t("close")), className: `${styles.modal} ${className}`, active: active, ...rest }, children));
};
export default Modal;
