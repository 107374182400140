import { SheetContainer, SheetContainerProps } from "@bookingcom/bui-react";
import React, { useCallback } from "react";
import useUserAgent from "./useUserAgent";
import { useI18n } from "@bookingcom/lingojs-react";
import { t } from "@bookingcom/lingojs-core";
import styles from "./useModalDialog.module.css";

type CustomModalProps = {
  showModalInMobile?: boolean;
  dataTestId?: string;
  closeAriaLabel?: string;
  fullHeight?: boolean;
};

type Props = Omit<SheetContainerProps, "closeAriaLabel"> & CustomModalProps;

/** Returns a Drawer on mobile or a Modal on desktop */
function useModalDialog() {
  const { isMobile } = useUserAgent();
  const i18n = useI18n();
  return useCallback(
    (props: React.PropsWithChildren<Props>) => {
      const {
        children,
        dataTestId,
        fill = false,
        fullHeight = false,
        lockCloseOnOutsideClick = false,
        onClose,
        onCloseTrigger,
        position,
        showModalInMobile = false,
        closeAriaLabel,
        ...rest
      } = props;

      const calculatedPosition =
        isMobile && showModalInMobile ? "center" : position || (isMobile ? "bottom" : "center");

      return (
        <SheetContainer
          attributes={{ "data-testid": dataTestId }}
          closeAriaLabel={closeAriaLabel || i18n.trans(t("close"))}
          fill={fill}
          lockCloseOnOutsideClick={lockCloseOnOutsideClick}
          onClose={onClose}
          onCloseTrigger={onCloseTrigger || onClose}
          position={calculatedPosition}
          className={isMobile && fullHeight ? styles.wrapper : ""}
          {...rest}
        >
          {children}
        </SheetContainer>
      );
    },
    [isMobile, i18n]
  );
}

export default useModalDialog;
