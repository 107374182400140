import React from "react";
import { Spinner } from "@bookingcom/bui-react";
import Frame from "./Frame";
import NoSSR from "../../app/NoSSR";

const LoadingStatus: React.FunctionComponent = function () {
  return (
    <NoSSR>
      <Frame m={4} alignItems="center" justifyContent="center">
        <Spinner />
      </Frame>
    </NoSSR>
  );
};

export default LoadingStatus;
