import localstory from "localstory";

// This singleton will return an instance of session storage to be used across the application
const flightsSessionStore = localstory(
  process.env.BUILD_TARGET == "client" ? window.sessionStorage : undefined,
  "flights",
  process.env.JEST_WORKER_ID !== undefined ? { vacuum: false } : undefined
);

export { flightsSessionStore };
