/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { useMemo } from "react";
import { Banner } from "@bookingcom/bui-react";
import { Trans } from "@bookingcom/lingojs-react";
// eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
import { useStore } from "../../../store";
import { QuestionMarkCircleIcon } from "@bookingcom/bui-assets-react/streamline/index.js";
import Frame from "../Frame";

const DestinationUkraineWarning = () => {
  // eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
  const { search } = useStore();

  const shouldShowAlert = useMemo(() => {
    return search.searchSegments.some((segment) => {
      return segment.to.some((to) => to.country === "UA") || segment.from.some((from) => from.country === "UA");
    });
  }, [search.searchSegments]);

  return shouldShowAlert ? (
    <Frame mb={4}>
      <Banner
        title={<Trans tag="flights_ukraine_armed_conflict_banner_header" />}
        text={<Trans tag="flights_ukraine_armed_conflict_banner_para_1" />}
        startIcon={QuestionMarkCircleIcon}
        variant="hint"
        dismissible={false}
      />
    </Frame>
  ) : null;
};

export default DestinationUkraineWarning;
