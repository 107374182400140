import { useCallback } from "react";
import routeMap from "app/routes";
import { isFeatureRunningClientSide } from "utils/features";
import { matchPath } from "react-router-dom";
import { isOfMetaOrigin } from "utils/marketing-url-params";
import { createTrackingUtils } from "..";

const utils = createTrackingUtils({
  name: "flights_web_price_format_user_locale",
  stages: {
    main: 1,
    desktop: 2,
    mdot: 3,
    direct: 4,
    meta: 5
  },
  defaultStage: 1,
  goals: {},
  goalsWithValue: []
});

const { stages } = utils;

const getRouteName = () => {
  if (!window || !window.location) return "";

  for (const route of routeMap({
    isMobile: window?.__GLOBAL_CONTEXT__?.userAgent?.isMobile,
    isPaymentCollectionPage: !!isFeatureRunningClientSide("FLIGHTS_WEB_PAYMENT_COLLECTION_NEW_PAGE")
  })) {
    if (matchPath(window.location.pathname, { path: route.path, exact: true })) {
      return route.name;
    }
  }

  return "";
};

export const isPriceFormatTrackingRouteCondition = () => {
  const routeName = getRouteName();

  if (routeName.includes("pb-") || routeName === "confirmation") {
    return false;
  }

  return true;
};

export const useTrackPriceFormatUserLocaleStages = () => {
  return useCallback(() => {
    if (!window || !window.__GLOBAL_CONTEXT__) return;

    const isMobile = window?.__GLOBAL_CONTEXT__?.userAgent?.isMobile;
    const isMeta = isOfMetaOrigin();

    isMobile ? stages.mdot() : stages.desktop();
    isMeta ? stages.meta() : stages.direct();
  }, []);
};

export default utils;
