import React from "react";
import { RemoteGeniusBenefitsCarousel, RemoteGeniusSignInBanner } from "components/remote/index";
import useGlobalContext from "hooks/useGlobalContext";

const GeniusLoyalty = () => {
  const { auth } = useGlobalContext();

  if (auth.isAuthenticatedOnWeb) {
    return <RemoteGeniusBenefitsCarousel />;
  } else {
    return <RemoteGeniusSignInBanner />;
  }
};

export default GeniusLoyalty;
