import React, { useCallback, useMemo } from "react";
import { InputRadioGroup, InputRadio, Stack } from "@bookingcom/bui-react";
import useCabinClass from "./useCabinClass.js";
export default function CabinClassRadio() {
    const { cabinClass, cabinClassOptions, setCabinClass, cabinClassTitle } = useCabinClass();
    const handleOnChange = useCallback((name) => {
        setCabinClass(name);
    }, [setCabinClass]);
    const options = useMemo(() => cabinClassOptions.map((o) => ({
        ...o,
        attributes: { "data-ui-name": `cabin_class_${o.value.toLowerCase()}` },
        inputAttributes: {
            "data-ui-name": `cabin_class_input_${o.value.toLowerCase()}`
        }
    })), [cabinClassOptions]);
    return (React.createElement(InputRadioGroup, { name: "sr_cabin_class", value: cabinClass, label: cabinClassTitle, onChange: ({ value }) => {
            handleOnChange(value);
        } },
        React.createElement(Stack, null, options.map((option) => (React.createElement(InputRadio, { ...option, key: option.value }))))));
}
