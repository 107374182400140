import React, { useContext } from "react";
import { SearchBoxContext, SearchboxController } from "../SearchBoxContext/index.js";
import { ChangeFlightSegmentVertical } from "../Segment/index.js";
import { Submit } from "../Submit/index.js";
import YellowBox from "./YellowBox.js";
import styles from "./SearchBoxVerticalDefault.module.css";
const SearchBoxChangeFlightVertical = () => {
    const { hideShadow } = useContext(SearchBoxContext);
    return (React.createElement("div", { className: styles.wrapper },
        React.createElement("div", { className: styles.line },
            React.createElement(YellowBox, { hideShadow: hideShadow },
                React.createElement("div", { className: styles.yellowInner },
                    React.createElement(ChangeFlightSegmentVertical, null),
                    React.createElement(Submit, { size: "large", wide: true })))),
        React.createElement("div", { className: styles.line })));
};
export default function SearchBoxChangeFlightVerticalDefault() {
    return (React.createElement(SearchboxController, null,
        React.createElement(SearchBoxChangeFlightVertical, null)));
}
