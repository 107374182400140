import { getImageAssetUrl } from "@bookingcom/bui-assets-react";
import { FlightCardHighlightStyle } from "components/elements/FlightCard/components/FlightCardHighlight";

import { FlightBadgeVariant, UICampaignBadge, UICampaignBestOfferHighlight, UICampaignStyle } from "@flights/types";

const getFlightCardHighlightStyle = (
  bestOfferHighlight?: UICampaignBestOfferHighlight
): FlightCardHighlightStyle | undefined =>
  bestOfferHighlight?.style === "PRIMARY"
    ? FlightCardHighlightStyle.PRIMARY
    : bestOfferHighlight?.style === "GENIUS"
    ? FlightCardHighlightStyle.GENIUS
    : undefined;

const getFlightCardBadgeStyle = (campaignBadge: UICampaignBadge) =>
  campaignBadge.style === "GENIUS"
    ? FlightBadgeVariant.GENIUS
    : campaignBadge.style === "DARK"
    ? FlightBadgeVariant.NEUTRAL
    : campaignBadge.style === "VIVID"
    ? FlightBadgeVariant.CONSTRUCTIVE
    : FlightBadgeVariant.PRIMARY;

export const getCampaignBestOfferHighlightConfig = (bestOfferHighlight?: UICampaignBestOfferHighlight) => {
  const bestOfferHighlightStyle = getFlightCardHighlightStyle(bestOfferHighlight);
  const bestOfferHighlightText = bestOfferHighlight?.headerText;

  return { bestOfferHighlightStyle, bestOfferHighlightText };
};

export const getCampaignBadgesConfig = (campaignBadges?: UICampaignBadge[]) => {
  return (
    campaignBadges?.map((campaignBadge) => ({
      style: getFlightCardBadgeStyle(campaignBadge),
      text: campaignBadge.text,
      hover: campaignBadge.hover
    })) || []
  );
};

export const getCampaignBannerAsset = (campaignStyle: UICampaignStyle): Parameters<typeof getImageAssetUrl>[0] => {
  switch (campaignStyle) {
    case "GENIUS":
      return {
        setName: "illustrations-traveller",
        assetName: "GeniusFlightsBadge"
      };
    case "DARK":
      return {
        setName: "illustrations-traveller",
        assetName: "DiscountPercent"
      };
    case "VIVID":
      return {
        setName: "illustrations-traveller",
        assetName: "PromotionPercent"
      };
    default:
      return { setName: "illustrations-traveller", assetName: "TripsUnlockSpot" };
  }
};
