import React from "react";
import { Text } from "@bookingcom/bui-react";
import { t } from "@bookingcom/lingojs-core";
import useFormatPrice from "hooks/useFormatPrice";
import { I18nChildContext, useI18n } from "@bookingcom/lingojs-react";
import Frame from "components/elements/Frame";
import styles from "./TaxBreakDownList.module.css";

const TaxBreakDownList = (props: any) => {
  const i18n = useI18n() as I18nChildContext;
  const { priceTaxBreakdown } = props;
  const { formatPrice } = useFormatPrice();

  return (
    <div>
      {Object.keys(priceTaxBreakdown).map((eachTaxItem, index) => {
        const taxSubItem = priceTaxBreakdown[eachTaxItem];
        const totalAggregatedSubTaxItem = taxSubItem.taxesAndFeesTotals;
        return (
          <Frame key={index} mt={1} mb={1}>
            <Text variant="small_1" color="neutral_alt" mixin={{ paddingBlockEnd: 1 }}>
              {getTaxesToTags(eachTaxItem, i18n)}
            </Text>
            <Frame>
              {Object.keys(totalAggregatedSubTaxItem).map((eachTaxSubItem, index) => {
                return (
                  <div key={index}>
                    <Frame direction="row" justifyContent="space-between" mt={1} mb={1}>
                      <Text variant="small_2" color="neutral_alt">
                        {getTaxesToTags(eachTaxSubItem, i18n)}
                      </Text>
                      <Frame ml={1}>
                        <Text
                          variant="small_2"
                          color="neutral_alt"
                          mixin={{ paddingBlockEnd: 1 }}
                          className={styles.listItemMoney}
                          attributes={{ "data-testid": "breakdown_list_row_two_column_two" }}
                        >
                          {formatPrice(totalAggregatedSubTaxItem[eachTaxSubItem])}
                        </Text>
                      </Frame>
                    </Frame>
                  </div>
                );
              })}
            </Frame>
          </Frame>
        );
      })}
    </div>
  );
};

const getTaxesToTags = (taxType: string, i18n: I18nChildContext) => {
  switch (taxType) {
    case "AirTransportationCharges":
      return i18n.trans(t("flights_canada_air_charges_header"));

    case "ThirdPartyCharges":
      return i18n.trans(t("flights_canada_third_party_header"));

    case "Harmonised sale tax":
      return i18n.trans(t("flights_canada_harmonised_sale_tax"));

    case "Immigration fee (XY2)":
      return i18n.trans(t("flights_canada_xy2_fee"));

    case "Transportation tax":
      return i18n.trans(t("flights_canada_transport_tax"));

    case "U.S Flight Segment Tax":
      return i18n.trans(t("flights_canada_segment_tax"));

    case "Air Travellers Security Charge":
      return i18n.trans(t("flights_canada_security_charge"));

    case "Goods and Services Tax (XG8)":
      return i18n.trans(t("flights_canada_xg8_fee"));

    case "U.S Agriculture Fee":
      return i18n.trans(t("flights_canada_agriculture_fee"));

    case "U.S Customs Fee":
      return i18n.trans(t("flights_canada_customs_fee"));

    case "Canada Airport Improvement Fee":
      return i18n.trans(t("flights_canada_airport_improvement"));

    default:
      return taxType;
  }
};
export default TaxBreakDownList;
