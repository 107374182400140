import { createTrackingUtils } from "..";

/**
 * We are using these utils so that we can test the default stage 1 tracking
 */
const utils = createTrackingUtils({
  name: "flights_apex_web_q3_2024_blockout",
  stages: {
    variant_usage_test: 1,
    main: 2,
    desktop: 3,
    mdot: 4,
    sr_or_fd_with_flex: 5,
    branded_fare_page: 6,
    ticket_type_page: 7
  },
  defaultStage: 1,
  goals: {},
  goalsWithValue: []
});

export default utils;
