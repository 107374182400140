import { ActionsUnion, createAction } from "@bookingcom/flights-core";

export enum ActionTypes {
  authenticate = "user/authenticate",
  logOut = "user/logOut"
}

export const actions = {
  authenticate: createAction(() => ({
    type: ActionTypes.authenticate,
    payload: {}
  })),

  logOut: createAction(() => ({
    type: ActionTypes.logOut,
    payload: {}
  }))
};

export type Actions = ActionsUnion<typeof actions>;
