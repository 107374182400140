import { useCallback, useMemo } from "react";
import { BYeahSearchResultsEventParams } from "./useEventTracking";
import useGlobalContext from "./useGlobalContext";
import useTrackerContext from "./useTrackerContext";
import { flightsSessionStore } from "../utils/session-store";

export function useFlightSearchResultsGTMTag() {
  const { trackExternal } = useTrackerContext();
  const { lang, ipCountry, payerId, customerIp = "" } = useGlobalContext();
  const store = flightsSessionStore;
  const label = store.get("label");
  const affiliateId = store.get("aid");
  let searchParams = "";
  if (window.location.search) {
    const query = new URLSearchParams(window.location.search);
    const from = query.get("from");
    const to = query.get("to");
    const tripType = query.get("type");
    const adults = query.get("adults");
    const children = query.get("children");
    searchParams = `from=${from}&to=${to}&type=${tripType}&adults=${adults}&children=${children}`;
    if (tripType === "MULTISTOP") {
      const dates = query.get("multiStopDates");
      searchParams = `${searchParams}&dates=${dates}`;
    } else {
      const fromDate = query.get("depart") || undefined;
      const toDate = query.get("return") || undefined;
      searchParams = `${searchParams}&fromDate=${fromDate}&toDate=${toDate}`;
    }
  }
  const params: BYeahSearchResultsEventParams = useMemo(() => {
    const additionalParams = { customerIp: "" };

    additionalParams.customerIp = customerIp;

    return {
      lang: lang || "",
      searchParams,
      userLocation: ipCountry || "",
      userAgent: navigator?.userAgent,
      affiliateId: affiliateId || "",
      label: label || "",
      cookieId: payerId || "",
      ...additionalParams
    };
  }, [lang, searchParams, ipCountry, affiliateId, label, payerId, customerIp]);
  return useCallback(() => {
    trackExternal({
      type: "gtm-byeah-search-results",
      args: [
        {
          event: "flights-byeah-search-results-load",
          ...params
        }
      ]
    });
  }, [trackExternal, params]);
}
