/* start - flights_web_flexible_date_search */
import localstory from "localstory";

const store = localstory(window.sessionStorage, "flights");

const key = "flexOriginPrice";

const setOriginPrice = (price: number) => {
  store.set(key, price, { ttl: "1d" });
};
const consumeOriginPrice = () => {
  const value: number | undefined = store.get(key);
  store.unset(key);
  return value;
};

export default function useFlexPriceDiffTracker() {
  return { setOriginPrice, consumeOriginPrice };
}
/* end - flights_web_flexible_date_search */
