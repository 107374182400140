/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { useEffect, useRef } from "react";
import { useRemoteComponent } from "@bookingcom/remote-component";
import useEvents from "hooks/useEvents";
import { useTheme } from "@bookingcom/bui-react";
import WorldwideFlightsMobile from "components/elements/WorldwideFlights/WorldwideFlights";
import WorldwideFlights from "components/elements/WorldwideFlights/WorldwideFlights.desktop";
import Frame from "components/elements/Frame/Frame";
import Container from "components/elements/Container";
import { useTrackLandingPageViewport } from "../../hooks/c360/useLandingPageEvents";

/**
 * SEO UI remote component containing links to popular routes, to be used on Home page
 */
export default function SEOInterlinkingComponent({ isMobile }: { isMobile: boolean }) {
  const { grumble } = useEvents();
  const theme = useTheme();
  const scrollRef = useRef<HTMLDivElement>(null);

  const remoteInterlinkingComponent = useRemoteComponent(
    {
      project: "web-shell",
      service: "header-mfe",
      path: "/FlightsIndex/Interlinking",
      type: "capla"
    },
    {
      fallbackComponent: isMobile ? WorldwideFlightsMobile : WorldwideFlights
    }
  );

  useEffect(() => {
    if (remoteInterlinkingComponent.error) {
      grumble("SEO interlinking component error", { error: remoteInterlinkingComponent.error.toString() });
    }
  }, [remoteInterlinkingComponent.error, grumble]);

  useTrackLandingPageViewport(scrollRef, "worldwide_flights");

  // eslint-disable-next-line @typescript-eslint/naming-convention -- this line was auto generated, hence fix the issue timely
  const InterlinkingComponent = remoteInterlinkingComponent.Component;

  // loading might still be true along with an error
  if (remoteInterlinkingComponent.isLoading && !remoteInterlinkingComponent.error) return null;

  if (remoteInterlinkingComponent.error) {
    return <InterlinkingComponent />; // fallback component, already includes styling and stage tracking
  }

  if (isMobile) {
    return (
      <Frame attributes={{ style: { width: "100%" } }} grow={1} p={4} elementRef={scrollRef}>
        <InterlinkingComponent />
      </Frame>
    );
  }

  return (
    <Container style={{ margin: `${theme.units.spacing_8x} auto`, alignItems: "start" }} innerRef={scrollRef}>
      <InterlinkingComponent />
    </Container>
  );
}
