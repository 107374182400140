import { Actions, ActionTypes } from "./actions";
import { State, getInitialState } from "./state";

const initialState = getInitialState();

const reducer = (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case ActionTypes.setMessage:
      return {
        ...state,
        message: action.payload.message,
        type: action.payload.type || "polite"
      };
    case ActionTypes.reset:
      return {
        ...state,
        message: "",
        type: "polite"
      };

    default:
      return state;
  }
};

export { reducer, getInitialState };
