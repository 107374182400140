import React from "react";
import { Text } from "@bookingcom/bui-react";
import useFormatPrice from "hooks/useFormatPrice";
import { UIPrice } from "@flights/types";
import { useI18n } from "@bookingcom/lingojs-react";
import { t } from "@bookingcom/lingojs-core";
import Frame from "components/elements/Frame";
import styles from "./BcomMargin.module.css";

interface Props {
  bcomMargin: UIPrice;
}

const BcomMargin = (props: Props) => {
  const { bcomMargin } = props;
  const i18n = useI18n();
  const { formatPrice } = useFormatPrice();
  return (
    <Frame direction="row" justifyContent="space-between" mt={1} mb={1}>
      <Text variant="emphasized_2" className={styles.textColor}>
        {bcomMargin.units > 0
          ? i18n.trans(t("flights_platform_service_fee"))
          : i18n.trans(t("flights_booking_com_pays"))}
      </Text>
      <Frame ml={1}>
        <Text
          variant="emphasized_2"
          className={styles.listItemMoney}
          attributes={{ "data-testid": "breakdown_bcom_margin" }}
        >
          {formatPrice(bcomMargin)}
        </Text>
      </Frame>
    </Frame>
  );
};
export default BcomMargin;
