import { useContext } from "react";
import { SearchBoxContext } from "../SearchBoxContext/index.js";
import { CABIN_CLASS_MAP, OCCUPANCY_INFANT_AGE } from "../../utils/constants.js";
import { t } from "@bookingcom/lingojs-core";
const useOccupancyCabinClass = () => {
    const { i18n, occupancy, cabinClass, trackV2, isSubmitted, setCabinClass, setOccupancy } = useContext(SearchBoxContext);
    const isChildrenAgesValid = occupancy.children.every((c) => c !== undefined);
    const isOccupancyCabinClassSelected = !!occupancy.adults && isChildrenAgesValid;
    const travellers = occupancy.adults + occupancy.children.length;
    const cabinClassName = i18n.trans(CABIN_CLASS_MAP[cabinClass]);
    const infantsCount = occupancy.children.filter((a) => a < OCCUPANCY_INFANT_AGE).length;
    const isValidInfants = infantsCount <= occupancy.adults;
    const errorMessage = isSubmitted && (!isOccupancyCabinClassSelected || !isValidInfants)
        ? i18n.trans(t("flights_search_error_travellers"))
        : undefined;
    const isOccupancySelected = !!occupancy.adults && !occupancy.children.some((c) => c === undefined || c === null);
    return {
        cabinClass,
        cabinClassName,
        errorMessage,
        i18n,
        isChildrenAgesValid,
        isOccupancyCabinClassSelected,
        isOccupancySelected,
        isSubmitted,
        occupancy,
        setCabinClass,
        setOccupancy,
        trackV2,
        travellers
    };
};
export default useOccupancyCabinClass;
