import { useCallback } from "react";
import { t } from "@bookingcom/lingojs-core";
import { formatDate as bookingcomFormatDate } from "@bookingcom/date-format/dist/format-date.js";
import { formatTime as bookingcomFormatTime } from "@bookingcom/date-format/dist/format-time.js";
import { monthNames } from "@bookingcom/date-format/dist/month-names.js";
import monthNameFormName from "@bookingcom/date-format/dist/month-name-forms/name/index.js";
import monthNameFormShortName from "@bookingcom/date-format/dist/month-name-forms/short_name/index.js";
import { weekdayNames } from "@bookingcom/date-format/dist/weekday-names.js";
import weekdayNameFormName from "@bookingcom/date-format/dist/weekday-name-forms/name/index.js";
import weekdayNameFormNameShort from "@bookingcom/date-format/dist/weekday-name-forms/short/index.js";
import shortDateWithoutYear from "@bookingcom/date-format/dist/date-format-forms/short_date_without_year/index.js";
import shortDateWithWeekdayWithoutYear from "@bookingcom/date-format/dist/date-format-forms/short_date_with_weekday_without_year/index.js";
import dateWithWeekday from "@bookingcom/date-format/dist/date-format-forms/date_with_weekday/index.js";
import dayShortMonthTime from "@bookingcom/date-format/dist/date-format-forms/day_short_month_time/index.js";
import shortDate from "@bookingcom/date-format/dist/date-format-forms/short_date/index.js";
import dateWithShortWeekdayWithYear from "@bookingcom/date-format/dist/date-format-forms/date_with_short_weekday_with_year/index.js";
import shortDateWithWeekdat from "@bookingcom/date-format/dist/date-format-forms/short_date_with_weekday/index.js";
import { parseDateISO8601 } from "./useDateFormat.js";
const monthsSortedList = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december"
];
const weekDaysSortedList = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
// this is the sorted list for https://office.booking.com/admintranslate/site/translate.html?l=weekday;lang=en;aid=1
const weekDaysListSortedForTranslation = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday"
];
export default function useFormatDatetime(i18n) {
    const formatDate = useCallback((input) => {
        if (typeof input.date === "string") {
            input.date = parseDateISO8601(input.date);
        }
        return bookingcomFormatDate(i18n, input);
    }, [i18n]);
    const formatTime = useCallback((input) => {
        return bookingcomFormatTime(i18n, input);
    }, [i18n]);
    const getMonthHash = useCallback((nameType) => {
        let form;
        switch (nameType) {
            case "short":
                form = monthNameFormShortName;
                break;
            case "full":
            default:
                form = monthNameFormName;
                break;
        }
        return monthNames(i18n, { form });
    }, [i18n]);
    const getWeekDaysHash = useCallback((nameType) => {
        let shortesHash;
        switch (nameType) {
            case "shortest":
                shortesHash = weekdayNames(i18n, { form: weekdayNameFormNameShort });
                weekDaysListSortedForTranslation.forEach((val, idx) => {
                    shortesHash[val] = i18n.trans(t("weekday", String(idx + 1), "shortest"));
                });
                return shortesHash;
            case "short":
                return weekdayNames(i18n, { form: weekdayNameFormNameShort });
            case "full":
            default:
                return weekdayNames(i18n, { form: weekdayNameFormName });
        }
    }, [i18n]);
    const getMonthList = useCallback((nameType) => {
        const names = getMonthHash(nameType);
        // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
        return monthsSortedList.map((m) => names[m]);
    }, [getMonthHash]);
    const getWeekDaysList = useCallback((nameType) => {
        const names = getWeekDaysHash(nameType);
        // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
        return weekDaysSortedList.map((d) => names[d]);
    }, [getWeekDaysHash]);
    const birthDate = useCallback((input) => {
        return formatDate({ date: input, form: shortDate });
    }, [formatDate]);
    const flightDate = useCallback((input) => {
        return formatDate({ date: input, form: shortDateWithoutYear });
    }, [formatDate]);
    const flightDateAndTime = useCallback((input) => {
        return formatDate({ date: input, form: dayShortMonthTime });
    }, [formatDate]);
    const flightDateWeekday = useCallback((input) => {
        return formatDate({ date: input, form: shortDateWithWeekdayWithoutYear });
    }, [formatDate]);
    const flightDateWeekdayFull = useCallback((input) => {
        return formatDate({ date: input, form: dateWithWeekday });
    }, [formatDate]);
    const flightShortDateWeekdayYear = useCallback((input) => {
        return formatDate({ date: input, form: shortDateWithWeekdat });
    }, [formatDate]);
    const flightDateWeekdayYear = useCallback((input) => {
        return formatDate({ date: input, form: dateWithShortWeekdayWithYear });
    }, [formatDate]);
    const flightTime = useCallback((input) => {
        const time = typeof input === "string" ? parseDateISO8601(input) : input;
        return formatTime({ time });
    }, [formatTime]);
    const flightDateAndTimeWithWeekday = useCallback((input) => {
        return `${flightDateWeekday(input)} · ${flightTime(input)}`;
    }, [flightDateWeekday, flightTime]);
    const flightDateWithWeekdayFull = useCallback((input) => {
        return flightDateWeekdayFull(input);
    }, [flightDateWeekdayFull]);
    return {
        getWeekDaysList,
        getMonthList,
        getWeekDaysHash,
        getMonthHash,
        formats: {
            birthDate,
            flightDate,
            flightDateAndTime,
            flightDateAndTimeWithWeekday,
            flightDateWithWeekdayFull,
            flightDateWeekday,
            flightDateWeekdayYear,
            flightTime,
            flightShortDateWeekdayYear
        }
    };
}
