const debug = require("debug")("cookies");

interface SetCookieOptions {
  maxAge?: string | number;
  secure?: boolean;
  domain?: string;
  path?: string;
}

export function parseMaxAge(value: string | number): number {
  switch (typeof value) {
    case "string":
      return value.split(/\s/).reduce((acc, str) => {
        let scale = 1;
        if (str.endsWith("y")) {
          scale = 60 * 60 * 24 * 365;
        } else if (str.endsWith("d")) {
          scale = 60 * 60 * 24;
        } else if (str.endsWith("h")) {
          scale = 60 * 60;
        } else if (str.endsWith("m")) {
          scale = 60;
        }
        acc += parseInt(str, 10) * scale;
        return acc;
      }, 0);

    case "number":
      return value;

    default:
      throw new Error("Invalid value for max-age");
  }
}

export function setCookie(name: string, value: string, options: SetCookieOptions = {}) {
  const attributes: string[] = [];

  attributes.push(name + "=" + value);

  if (options.maxAge !== undefined) {
    attributes.push("max-age=" + parseMaxAge(options.maxAge));
  }

  if (options.path) {
    attributes.push("path=" + options.path);
  }

  if (options.domain) {
    attributes.push("domain=" + options.domain);
  }

  if (options.secure || (location.protocol === "https:" && options.secure !== false)) {
    attributes.push("secure");
  }

  const cookieValue = attributes.join("; ");
  debug("Setting cookie: %s", cookieValue);
  document.cookie = cookieValue;
}

export function getCookie(name: string): string | undefined {
  const value = document.cookie.split(";").filter((value) => value.trim().startsWith(name + "="))[0];
  return value && value.split("=")[1];
}
