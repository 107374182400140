/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { useMemo, useState } from "react";
import { Button } from "@bookingcom/bui-react";
// eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
import { useStore } from "../../../store";
import { useXsellActionsCall } from "../../hooks/useXsellActionsCall";
import { UIXAccommodation } from "../../types";
import MobileAccommodationCard from "../common/MobileAccommodationCard";

import { t } from "@bookingcom/lingojs-core";
import { useI18n } from "@bookingcom/lingojs-react";
import { useEventsTracking } from "../../hooks/useEventsTracking";
import useIsInViewport from "../../../hooks/useIsInViewport";
import { useClickHandlers } from "../../hooks/useClickHandlers";
import useLoader from "../../hooks/useLoader";
import styles from "./MobileCardsList.module.css";

const MobileCardsList = () => {
  const [currentElement, setCurrentElement] = useState<any>(null);

  // eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
  const store = useStore();
  const i18n = useI18n();
  const { crossSell: carouselData, isLoading } = store.crossSell;
  const loader = useLoader("mobileCards");
  const isCardsListInViewport = useIsInViewport(currentElement, undefined, { threshold: 0.33 });
  const { handleButtonClick, handleCardClick } = useClickHandlers("mList");

  useXsellActionsCall("mList", 3);
  useEventsTracking(isCardsListInViewport, "mList");

  const setRefEl = (element: HTMLElement | null) => {
    setCurrentElement(element);
  };

  /* istanbul ignore next */
  const CTA_TEXT: string = useMemo(() => {
    return i18n.trans(t("flights_confirmation_genius_see_action"));
  }, [i18n]);

  if (isLoading) {
    return loader;
  }

  return carouselData?.accommodations.length ? (
    <div ref={setRefEl} className={styles.refDiv}>
      {carouselData?.accommodations.map((accommodation: UIXAccommodation, index: number) => (
        <div key={index} className={styles.card} data-testid={`card-${index}`}>
          <MobileAccommodationCard
            onCardClick={() => {
              handleCardClick(accommodation.id, index, accommodation.recResultsId, accommodation.propertyTarget.target);
            }}
            accommodation={accommodation}
            index={index}
            searchParams={carouselData.searchParams}
          />
        </div>
      ))}
      {carouselData?.searchResults.target ? (
        <Button
          attributes={{
            "data-testid": "carousel-redirect-btn"
          }}
          size="large"
          wide={{
            s: true,
            m: false
          }}
          onClick={() => {
            handleButtonClick(carouselData.searchResults.target, CTA_TEXT);
          }}
          variant="secondary"
        >
          {CTA_TEXT}
        </Button>
      ) : null}
    </div>
  ) : null;
};

export default MobileCardsList;
