import { createTrackingUtils } from "../index";

const utils = createTrackingUtils({
  name: "flights_apex_web_bf_on_mdot",
  stages: {
    users_on_bf_mdot: 1,
    airlines_with_sellable: 2,
    airlines_with_exclusions: 3,
    incremental_price_exp: 4
  },
  goals: {
    open_fare_details: 1,
    scrolled: 2,
    open_price_info: 3
  },
  goalsWithValue: [],
  defaultStage: 1
});

export default utils;
