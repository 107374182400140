import { BRANDED_FARE_FEATURES_CONFIG } from "./constants.js";
import memoize from "lodash/memoize";
export const getFeatureConfigsByName = memoize(() => {
    const featureConfigsByName = {};
    for (const config of BRANDED_FARE_FEATURES_CONFIG) {
        featureConfigsByName[config.name] = config;
    }
    return featureConfigsByName;
});
export const getLabelCopy = (featureName, availability, useAltCopy) => {
    const config = getFeatureConfigsByName()[featureName];
    if (!config)
        return undefined;
    switch (availability) {
        case "SELLABLE":
            return (useAltCopy && config.sellableFeatureCopyAlt) || config.sellableFeatureCopy;
        case "NOT_INCLUDED":
            return (useAltCopy && config.nonIncludedFeatureCopyAlt) || config.nonIncludedFeatureCopy;
        default:
            return (useAltCopy && config.includedFeatureCopyAlt) || config.includedFeatureCopy;
    }
};
