import React, { useEffect } from "react";
import { InputSelect } from "@bookingcom/bui-react";
import useCalendarSelect from "./useCalendarSelect.js";
import { t } from "@bookingcom/lingojs-core";
import styles from "./CalendarSelect.module.css";
export default function CalendarSelect(props) {
    const { segmentIndex, onDateChange, variant = "vertical" } = props;
    const { dateValues, fromDay, fromYearMonth, fromYearMonthList, getDaysList, i18n, isRoundTrip, onFromDayChange, onFromYearMonthChange, onToDayChange, onToYearMonthChange, toDay, toYearMonth, toYearMonthList } = useCalendarSelect({ segmentIndex });
    useEffect(() => {
        onDateChange(dateValues);
    }, [dateValues, onDateChange]);
    const defaultOption = { text: "-", value: "", disabled: true };
    return (React.createElement("div", { className: `${styles.wrapper} ${variant === "horizontal" ? styles.wrapperHorizontal : ""}` },
        React.createElement(InputSelect, { label: i18n.trans(t("flights_index_search_box_departure_month")), name: `searchbox-date-from-month-year-${segmentIndex}`, options: [defaultOption, ...fromYearMonthList], value: fromYearMonth, onChange: ({ value }) => {
                onFromYearMonthChange(value);
            } }),
        React.createElement(InputSelect, { label: i18n.trans(t("flights_index_search_box_departure_date")), name: `searchbox-date-from-day-${segmentIndex}`, options: [defaultOption, ...getDaysList(fromYearMonth)], disabled: !fromYearMonth, value: fromDay, onChange: ({ value }) => {
                onFromDayChange(value);
            } }),
        isRoundTrip ? (React.createElement(React.Fragment, null,
            React.createElement(InputSelect, { label: i18n.trans(t("flights_index_search_box_return_month")), name: `searchbox-date-to-month-year-${segmentIndex}`, options: [defaultOption, ...toYearMonthList], value: toYearMonth, disabled: !fromDay, onChange: ({ value }) => {
                    onToYearMonthChange(value);
                } }),
            React.createElement(InputSelect, { label: i18n.trans(t("flights_index_search_box_return_date")), name: `searchbox-date-to-day-${segmentIndex}`, options: [defaultOption, ...getDaysList(toYearMonth, dateValues.from)], disabled: !toYearMonth, value: toDay, onChange: ({ value }) => {
                    onToDayChange(value);
                } }))) : null));
}
