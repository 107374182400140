import { t } from "@bookingcom/lingojs-core";
export const CABIN_CLASS_MAP = {
    ECONOMY: t("flights_cabin_class_economy_option"),
    PREMIUM_ECONOMY: t("flights_cabin_class_premium_option"),
    BUSINESS: t("flights_cabin_class_business_option"),
    FIRST: t("flights_cabin_class_first_option"),
    PREMIUM_BUSINESS: t("flights_cabin_class_premium_business"),
    PREMIUM_FIRST: t("flights_cabin_class_premium_first")
};
export const CABIN_CLASS_FILTER_OPTIONS = ["ECONOMY", "PREMIUM_ECONOMY", "BUSINESS", "FIRST"];
export const SEARCH_TYPE_MAP = {
    ROUNDTRIP: t("flights_route_round_trip"),
    ONEWAY: t("flights_route_one_way"),
    MULTISTOP: t("flights_multicity_label")
};
export const SEARCH_TYPE_EXTENDED_MAP = {
    ROUNDTRIP: t("flights_search_on_sr_round_trip"),
    ONEWAY: t("flights_search_on_sr_one_way"),
    MULTISTOP: t("flights_search_on_sr_multi")
};
export const MULTISTOP_MAX_SEGMENT_LENGTH = 5;
export const OCCUPANCY_MAX_ALLOWED_PASSENGERS = 9;
export const OCCUPANCY_INFANT_AGE = 2;
export const LOCATION_DATA_UI_NAME = {
    from: "input_location_from_segment",
    to: "input_location_to_segment"
};
export const ADD_DATA_UI_NAME = "button_segment_add";
export const ANYWHERE = "Anywhere";
export const ANYWHERE_LOCATION = {
    type: ANYWHERE,
    code: ANYWHERE,
    name: "",
    country: ANYWHERE,
    countryName: ANYWHERE,
    city: ANYWHERE,
    cityName: "",
    regionName: ANYWHERE,
    countryNameShort: ANYWHERE
};
