import React from "react";
import { useRemoteComponent } from "@bookingcom/remote-component";
import Container from "components/elements/Container";
import Frame from "components/elements/Frame";
import useUserAgent from "hooks/useUserAgent";

// eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/naming-convention
const renderComponent = (Component: React.ComponentType, isMobile: boolean) => {
  return isMobile ? (
    <Frame attributes={{ style: { width: "100%" } }}>
      <Component />
    </Frame>
  ) : (
    <Container>
      <Component />
    </Container>
  );
};

const GeniusBenefitsCarousel = () => {
  const { isMobile } = useUserAgent();

  const { Component } = useRemoteComponent({
    project: "web-shell",
    service: "header-mfe",
    path: "/FlightsGeniusBenefitsCarousel",
    type: "capla"
  });

  return renderComponent(Component, isMobile);
};

export default GeniusBenefitsCarousel;
