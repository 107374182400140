import clientFetch from "./client-fetch";

export type UIFlogEvent = {
  message: string;
  payload?: any;
};

const report = (type: "grumble" | "squeak" | "warning", event: UIFlogEvent) => {
  void clientFetch(`/events/${type}`, {
    method: "post",
    body: JSON.stringify(event),
    headers: {
      "Content-Type": "application/json",
      "X-Booking-Referrer": location.href || ""
    }
  });
};

export const grumble = (message: string, payload?: any) => {
  report("grumble", { message, payload });
};

export const squeak = (message: string) => {
  report("squeak", { message });
};

export const warning = (message: string) => {
  report("warning", { message });
};
