const debounce = <F extends (...params: any[]) => void>(fn: F, delay: number) => {
  // eslint-disable-next-line no-restricted-syntax -- this line was auto generated, hence fix the issue timely
  let timeoutID = null as unknown as number;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutID);
    timeoutID = window.setTimeout(() => fn.apply(this, args), delay);
  } as F;
};

export default debounce;
