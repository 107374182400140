import React, { SVGProps, memo } from "react";
import ATOLLogoSvg from "assets/icons/atol-protected-logo.svg";

/**
 * ATOL logo svg
 */
const _ATOLLogo = (props: SVGProps<SVGElement>) => {
  return <ATOLLogoSvg alt="" aria-hidden="true" width={24} height={24} {...props} />;
};

export const ATOLLogo = memo(_ATOLLogo);
