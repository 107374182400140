/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { useCallback, useEffect, useState } from "react";
import { Chip, Button, Link, SheetContainer, Stack, SheetContainerProps } from "@bookingcom/bui-react";
import Airlines from "../Input/Airlines";
import useSearchFiltersController from "../useSearchFiltersController";
import { useI18n, t } from "@bookingcom/lingojs-react";
import Frame from "components/elements/Frame";

export type AirlinesModalProps = {
  position: SheetContainerProps["position"];
};

const AirlinesModalCom = (props: AirlinesModalProps) => {
  const i18n = useI18n();
  const [active, setActive] = useState(false);
  const { airlines } = useSearchFiltersController();
  const [shouldApply, setShouldApply] = useState(false);
  const [draftValue, setDraftValue] = useState(airlines.value);

  const handleOnClose = useCallback(() => {
    setActive(false);
    setDraftValue(airlines.value);
  }, [airlines.value]);

  const handleOnChange = useCallback((value: typeof draftValue) => {
    setDraftValue(value);
  }, []);

  const handleOnApply = useCallback(() => {
    airlines.set(draftValue);
    setActive(false);
    setShouldApply(true);
  }, [airlines, draftValue]);

  const handleOnReset = useCallback(() => {
    airlines.reset();
    setActive(false);
    setShouldApply(true);
  }, [airlines]);

  const onSelectAll = useCallback(() => {
    setDraftValue(airlines.options.map((a) => a.value));
  }, [airlines]);

  const onSelectNone = useCallback(() => {
    setDraftValue([]);
  }, []);

  useEffect(() => {
    if (shouldApply) {
      setShouldApply(false);
      setDraftValue(airlines.value);
    }
  }, [shouldApply, airlines]);

  return (
    <>
      <Chip
        variant="action"
        checked={airlines.touched && !!airlines.value.length}
        onClick={() => setActive(true)}
        bubble={
          airlines.touched && !!airlines.value.length
            ? {
                text: airlines.value.length,
                ariaLabel: i18n.trans(
                  t("flights_mdot_filters_airlines_applied", {
                    variables: { num_airlines: airlines.value.length, num_exception: airlines.value.length }
                  })
                )
              }
            : undefined
        }
        label={i18n.trans(t("flights_filter_airlines"))}
        attributes={{ "aria-label": i18n.trans(t("a11y_flights_filters_airlines")) }}
      />
      <SheetContainer
        active={active}
        onCloseTrigger={handleOnClose}
        closeAriaLabel={i18n.trans(t("close"))}
        title={i18n.trans(t("flights_filter_airlines"))}
        position={props.position}
        footer={
          <Frame mr={4} mb={4} ml={4}>
            <Stack direction="row">
              <Stack.Item grow>
                <Button wide onClick={handleOnReset} disabled={!airlines.touched} variant="secondary" size="large">
                  {i18n.trans(t("flights_filter_reset"))}
                </Button>
              </Stack.Item>
              <Stack.Item grow>
                <Button wide onClick={handleOnApply} size="large">
                  {i18n.trans(t("flights_mdot_filters_apply_cta"))}
                </Button>
              </Stack.Item>
            </Stack>
          </Frame>
        }
      >
        <Stack gap={4}>
          <Stack gap={4} direction="row">
            <Link
              onClick={onSelectAll}
              disabled={draftValue.length === airlines.options.length && airlines.options.length !== 0}
            >
              {i18n.trans(t("flights_select_all_toggle"))}
            </Link>
            <Link onClick={onSelectNone} disabled={draftValue.length === 0}>
              {i18n.trans(t("flights_deselect_all_toggle"))}
            </Link>
          </Stack>
          <Airlines options={airlines.options} value={draftValue} onChange={handleOnChange} />
        </Stack>
      </SheetContainer>
    </>
  );
};

export default function AirlinesModal({ position }: AirlinesModalProps) {
  const { airlines } = useSearchFiltersController();
  if (!airlines.options.length) return null;
  return <AirlinesModalCom position={position} />;
}
