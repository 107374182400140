/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React from "react";
import useSearchFiltersController from "../useSearchFiltersController";
import { Link, Stack, Text } from "@bookingcom/bui-react";
import WithSuffix from "../WithSuffix";
import { useI18n, t } from "@bookingcom/lingojs-react";
import Duration from "../Input/Duration";

export default function DurationFilter() {
  const i18n = useI18n();
  const { duration } = useSearchFiltersController();

  if (duration.options.max === duration.options.min) return null;

  return (
    <Stack attributes={{ "data-testid": "duration_filter" }}>
      <WithSuffix
        suffix={
          duration.touched ? (
            <Link attributes={{ "data-testid": "search_filter_duration_reset" }} onClick={duration.reset}>
              {i18n.trans(t("flights_filter_reset"))}
            </Link>
          ) : null
        }
      >
        <Text variant="emphasized_1">{i18n.trans(t("flights_filters_duration_title"))}</Text>
      </WithSuffix>
      <Duration min={duration.options.min} max={duration.options.max} value={duration.value} onChange={duration.set} />
    </Stack>
  );
}
