/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React from "react";
import { useParams } from "react-router-dom";

import { LiveChatLoader } from "../components/elements/LiveChatWidget/LiveChatWidget";
import ConfirmationInnerLoader from "../components/elements/ConfirmationInnerLoader";
import useGlobalContext from "../hooks/useGlobalContext";

const LiveChat: React.FC = () => {
  const params = useParams<{ token: string }>();
  const { features } = useGlobalContext();

  if (!features.FLIGHTS_WEB_POST_BOOKING_LIVE_CHAT) {
    return null;
  }

  return (
    <ConfirmationInnerLoader token={params.token} cache>
      <LiveChatLoader />
    </ConfirmationInnerLoader>
  );
};

export default LiveChat;
