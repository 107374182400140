import { UIFlightData, UIBrandedFare, UIClientFetchError } from "@flights/types";
import { ActionsUnion, createAction } from "@bookingcom/flights-core";

export enum ActionTypes {
  fetch = "flightDetails/fetch",
  fetchReset = "flightDetails/fetchReset",
  fetchSuccess = "flightDetails/fetchSuccess",
  fetchError = "flightDetails/fetchError",
  selectBrandedFare = "flightDetails/selectBrandedFare"
}

export type SelectBrandedFarePayload = { fareOffer: UIBrandedFare };
export type SelectBrandedFareFunction = (fareOffer: UIBrandedFare) => {
  type: ActionTypes.selectBrandedFare;
  payload: SelectBrandedFarePayload;
};

export const actions = {
  fetch: createAction(() => ({
    type: ActionTypes.fetch,
    payload: {}
  })),

  fetchReset: createAction(() => ({
    type: ActionTypes.fetchReset,
    payload: {}
  })),

  fetchSuccess: createAction((flight: UIFlightData) => ({
    type: ActionTypes.fetchSuccess,
    payload: { flight }
  })),

  fetchError: createAction((error: UIClientFetchError) => ({
    type: ActionTypes.fetchError,
    payload: { error }
  })),

  selectBrandedFare: createAction<SelectBrandedFareFunction, ActionTypes.selectBrandedFare, SelectBrandedFarePayload>(
    (fareOffer: UIBrandedFare) => ({
      type: ActionTypes.selectBrandedFare,
      payload: { fareOffer }
    })
  )
};

export type Actions = ActionsUnion<typeof actions>;
