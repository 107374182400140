/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Chip, SheetContainer, SheetContainerProps, Stack } from "@bookingcom/bui-react";
import Stops from "../Input/Stops";
import useSearchFiltersController from "../useSearchFiltersController";
import { useI18n, t } from "@bookingcom/lingojs-react";
import Frame from "components/elements/Frame";

export type StopsModalProps = {
  position: SheetContainerProps["position"];
};

const StopsModalComp = (props: StopsModalProps) => {
  const { stops } = useSearchFiltersController();
  const i18n = useI18n();
  const [active, setActive] = useState(false);
  const [shouldApply, setShouldApply] = useState(false);
  const [draftValue, setDraftValue] = useState(stops.value);

  const handleOnClose = useCallback(() => {
    setActive(false);
    setDraftValue(stops.value);
  }, [stops.value]);

  const handleOnChange = useCallback((value: typeof draftValue) => {
    setDraftValue(value);
  }, []);

  const handleOnApply = useCallback(() => {
    stops.set(draftValue);
    setActive(false);
    setShouldApply(true);
  }, [stops, draftValue]);

  const handleOnReset = useCallback(() => {
    stops.reset();
    setActive(false);
    setShouldApply(true);
  }, [stops]);

  useEffect(() => {
    if (shouldApply) {
      setShouldApply(false);
      setDraftValue(stops.value);
    }
  }, [shouldApply, stops]);

  useEffect(() => {
    setDraftValue(stops.value);
  }, [stops.value]);

  const label = useMemo(() => {
    if (stops.touched) {
      return stops.value === 0
        ? i18n.trans(t("flights_mdot_filters_direct_applied"))
        : i18n.trans(
            t("flights_mdot_filters_stops_applied", {
              variables: { num_stops: stops.value, num_exception: stops.value }
            })
          );
    }
    return i18n.trans(t("flights_filters_stops_title"));
  }, [i18n, stops]);

  return (
    <>
      <Chip
        variant="action"
        checked={stops.touched}
        onClick={() => setActive(true)}
        label={label}
        attributes={{ "aria-label": i18n.trans(t("a11y_flights_filters_stops")) }}
      />
      <SheetContainer
        keepMounted
        active={active}
        onCloseTrigger={handleOnClose}
        closeAriaLabel={i18n.trans(t("close"))}
        title={i18n.trans(t("flights_filters_stops_title"))}
        position={props.position}
        footer={
          <Frame mr={4} mb={4} ml={4}>
            <Stack direction="row">
              <Stack.Item grow>
                <Button wide onClick={handleOnReset} disabled={!stops.touched} variant="secondary" size="large">
                  {i18n.trans(t("flights_filter_reset"))}
                </Button>
              </Stack.Item>
              <Stack.Item grow>
                <Button wide onClick={handleOnApply} size="large">
                  {i18n.trans(t("flights_mdot_filters_apply_cta"))}
                </Button>
              </Stack.Item>
            </Stack>
          </Frame>
        }
      >
        <Stops options={stops.options} value={draftValue} onChange={handleOnChange} />
      </SheetContainer>
    </>
  );
};

export default function StopsModal({ position }: StopsModalProps) {
  const { stops } = useSearchFiltersController();
  if (!stops.options.length) return null;
  return <StopsModalComp position={position} />;
}
