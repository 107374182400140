import Frame from "../../../components/elements/Frame";
import React from "react";
import { Button, Card, Divider, Image, Stack, Text } from "@bookingcom/bui-react";
import { t, useI18n } from "@bookingcom/lingojs-react";
import { useSelector } from "react-redux";
import { getCaption, getDiscountLink, getHeader, getSubheader } from "store/esim/selectors";

interface EsimBannerProps {
  isPostBooking: boolean;
}

const EsimBanner = ({ isPostBooking }: EsimBannerProps) => {
  const i18n = useI18n();
  const header = useSelector(getHeader);
  const subheader = useSelector(getSubheader);
  const caption = useSelector(getCaption);
  const discountLink = useSelector(getDiscountLink);

  return (
    <Card>
      <Stack>
        <Frame mb={1} direction="row" alignItems="start">
          <Frame pr={3} pt={1}>
            <Image
              width="64px"
              height="64px"
              alt="Genius Esim Benefit"
              asset={{
                setName: "illustrations-traveller",
                assetName: "GeniusEsimBenefit"
              }}
              contentMode="fit"
            />
          </Frame>
          <Stack>
            <Text variant="strong_1">{header}</Text>
            <Text variant="body_1">{subheader}</Text>
            {isPostBooking && discountLink && (
              <>
                <Text variant="small_2">{caption}</Text>
                <Button
                  variant="secondary"
                  size="medium"
                  onClick={() => window.open(discountLink, "_blank", "noopener noreferrer")}
                >
                  {i18n.trans(t("flights_genius_esim_cta"))}
                </Button>
              </>
            )}
          </Stack>
        </Frame>
        <Divider />
        <Frame mt={1} direction="row" justifyContent="space-between" alignItems="center">
          <Text variant="small_1">{i18n.trans(t("flights_xsell_genius_banner_footer"))}</Text>
          <Image
            asset={{
              setName: "images-genius",
              assetName: "GeniusLogo"
            }}
            height="14px"
            width="54px"
            alt="Genius Logo"
            contentMode="fit"
          />
        </Frame>
      </Stack>
    </Card>
  );
};

export default EsimBanner;
