import { useEffect, useMemo } from "react";
import debounce from "utils/debounce";
import styles from "./useSideBarSticky.module.css";

type UseSideBarStickyProps = {
  conditions?: () => boolean;
  wrapper: HTMLDivElement | null;
  fixScrollPadding?: boolean;
};

const useSideBarSticky = (props: UseSideBarStickyProps) => {
  const { conditions = () => false, wrapper, fixScrollPadding } = props;
  const shouldBeSticky = useMemo(() => conditions(), [conditions]);

  const onScroll = useMemo(
    () =>
      debounce(() => {
        if (!wrapper) return;
        const { top } = wrapper.getBoundingClientRect();
        if (top === 0) return;
        const isStickyClass = wrapper?.classList.contains(styles.sticky);
        if (top <= 16 && !isStickyClass) wrapper?.classList.add(styles.sticky);
        const isScrollClass = wrapper.classList.contains(styles.innerScroll);
        if (top === 16) wrapper.classList.add(styles.innerScroll);
        if ((top < 0 || top > 16) && isScrollClass) wrapper.classList.remove(styles.innerScroll);
      }, 15),
    [wrapper]
  );

  useEffect(() => {
    if (!shouldBeSticky) return;
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [shouldBeSticky, onScroll, fixScrollPadding, wrapper]);

  useEffect(() => {
    if (!fixScrollPadding || !wrapper) return;
    wrapper.setAttribute("data-scroll-padding", "1");
  }, [fixScrollPadding, wrapper]);
};
export default useSideBarSticky;
