/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React from "react";
import { useRemoteComponent } from "@bookingcom/remote-component";
import useRouteName from "hooks/useRouteName";
import { RouteName } from "app/routes";
import { Header as WebShellHeader } from "@bookingcom/web-shell";
import { BookingComLogoDarkBackgroundsMono } from "@bookingcom/bui-assets-react/brand";
import useUserAgent from "hooks/useUserAgent";
import styles from "./Header.module.css";

const SMALL_HEADER_PAGE_LIST: RouteName[] = [
  "checkout-fare",
  "checkout-seat-selection",
  "checkout-sirf",
  "checkout-start",
  "checkout-ticket-type",
  "checkout",
  "checkout2",
  "checkout3",
  "checkout-collection", // FLIGHTS_WEB_PAYMENT_COLLECTION_NEW_PAGE
  "pb-checkout-payment"
];

function HeaderFallback() {
  return <WebShellHeader logo={<BookingComLogoDarkBackgroundsMono />} />;
}

export default function Header() {
  const routeName = useRouteName();
  const { isMobile } = useUserAgent();
  const { isWebview } = useUserAgent();
  const remoteHeader = useRemoteComponent(
    {
      project: "web-shell",
      service: "header-mfe",
      path: "/TravellerHeader/Flights",
      type: "capla"
    },
    {
      fallbackComponent: HeaderFallback
    }
  );

  if (
    routeName === "not-available" ||
    routeName === "live-chat" ||
    (isWebview && routeName.includes("checkout")) ||
    (isMobile && routeName === "flightdetails") ||
    (isWebview && routeName === "additional-flights-terms") ||
    (isWebview && routeName === "home") ||
    (isWebview && routeName === "landing") ||
    (isWebview && routeName === "confirmation") ||
    (isWebview && routeName === "pb-order-details") ||
    (isWebview && routeName === "pb-flight-details") ||
    (isWebview && routeName === "pb-add-baggage") ||
    (isWebview && routeName === "pb-cancel-order") ||
    (isWebview && routeName === "pb-cancel-order") ||
    (isWebview && routeName === "pb-checkout-payment") ||
    (isWebview && routeName === "pb-checkout-confirmation") ||
    (isWebview && routeName === "additional-flights-terms")
  ) {
    return <></>;
  }

  const hideProductsBar = SMALL_HEADER_PAGE_LIST.includes(routeName) || (isMobile && routeName === "searchresults");

  const { Component } = remoteHeader;
  return (
    <div className={hideProductsBar ? styles.root : undefined}>
      <Component />
    </div>
  );
}
