import React, { Fragment } from "react";
import { Switch, Route } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import NotFound from "../screens/NotFound";
import routeMap from "./routes";

import useUserAgent from "../hooks/useUserAgent";

import { reloadIfNeeded } from "../utils/check-client-version";
import LoadingStatus from "components/elements/LoadingStatus";
import { I18nChildContext, t, useI18n } from "@bookingcom/lingojs-react";
import useGlobalContext from "hooks/useGlobalContext";
import "./global.css";

type RendererProps = {
  component: JSX.Element;
};

const Renderer = ({ component }: RendererProps) => {
  reloadIfNeeded();
  return component;
};

const Layout = () => {
  const { isMobile, isWebview } = useUserAgent();
  const { isPaymentCollectionPage } = useGlobalContext();
  const routes = routeMap({ isMobile, isPaymentCollectionPage });
  const i18n = useI18n() as I18nChildContext;

  return (
    <Fragment>
      <Helmet>
        <title>{i18n.trans(t("flights_page_title_bp_generic"))}</title>
        <meta property="webview:header" content="regular" />
        <meta property="webview:title" content={""} />
        {isWebview && (
          <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1" />
        )}
      </Helmet>
      {/* <GlobalStyles /> */}
      <Switch>
        {routes
          .filter((route) => !route.exclude)
          .map((route) => {
            // eslint-disable-next-line @typescript-eslint/naming-convention -- this line was auto generated, hence fix the issue timely
            const { component: Component, ...otherProps } = route;
            return (
              <Route key={route.path} {...otherProps}>
                <Renderer component={<Component fallback={<LoadingStatus />} />} />
              </Route>
            );
          })}
        <Route>
          <Renderer component={<NotFound />} />
        </Route>
      </Switch>
    </Fragment>
  );
};

export default Layout;
