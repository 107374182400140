import useGlobalContext from "./useGlobalContext";

const useTrackFlightsWebUseOfferPos = () => {
  const { isExcludedFromPosMigrationPhaseOne } = useGlobalContext();
  const isVariant = !isExcludedFromPosMigrationPhaseOne;

  return {
    isVariant
  };
};

export default useTrackFlightsWebUseOfferPos;
