import { trackCustomGoal, trackExperiment, trackExperimentStage, trackGoalWithValue } from "utils/et";
import * as etV2 from "utils/etV2";
import { ClientSideFrontendExperiment } from "@flights/types";
import { trackMetaCustomGoal, trackMetaExperimentStage } from "utils/meta-et-tracking";
import { isOfMetaOrigin } from "utils/marketing-url-params";
import * as debugTrackingNames from "utils/experiments/debugTrackingNames";

type UtilMapForGoalsWithValue<T extends string> = Record<T, (value: number) => void>;
type TrackingFn<T extends string> = (name: T, value: number) => void;

export type ExperimentConfig<TStage extends string, TGoal extends string, TGoalValue extends string> = {
  name: ClientSideFrontendExperiment;
  stages: Record<TStage, number>;
  goals: Record<TGoal, number>;
  goalsWithValue?: TGoalValue[];
};

export type ExperimentConfigWithDefault<TStage extends string, TGoal extends string, TGoalValue extends string> = {
  name: ClientSideFrontendExperiment;
  defaultStage: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  stages: Record<TStage, number>;
  goals: Record<TGoal, number>;
  goalsWithValue?: TGoalValue[];
};

/**
 * Returns a complete, typesafe set of tracking functions for the provided experiment config.
 * @deprecated We are migrating form our own implementation of tracking library to tracking library provided by ET team
 * Please use createTrackingUtils instead.
 * More info in the wiki https://gitlab.booking.com/flights/main/-/wikis/Migrating-to-new-client-side-tracking-library
 * Example:
 * import { createTrackingUtils } from "utils/experiments";
 * const trackingUtils = createTrackingUtils();
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export function __deprecated__createTrackingUtils<
  TStage extends string,
  TGoal extends string,
  TGoalValue extends string
>(config: ExperimentConfig<TStage, TGoal, TGoalValue>) {
  debugTrackingNames.set(config);

  return {
    name: () => config.name,
    variant: () => trackExperiment(config.name),
    stages: createNumericTrackingUtils(trackExperimentStage, config.name, config.stages),
    goals: createNumericTrackingUtils(trackCustomGoal, config.name, config.goals),
    goalsWithValue: createCustomGoalWithValueTrackingUtils(config.goalsWithValue)
  };
}

/**
 * @deprecated We are fixing issues with tracking
 * Please use createTrackingUtils instead.
 * More info https://docs.google.com/document/d/10s5hMGIsHqDKLiiEvcPLsh4GnwpwlKNlC9lTK6q_ORo/edit
 * Example:
 * import { createTrackingUtils } from "utils/experiments";
 * const trackingUtils = createTrackingUtils();
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export function __deprecated__createTrackingUtilsV2<
  TStage extends string,
  TGoal extends string,
  TGoalValue extends string
>(config: ExperimentConfig<TStage, TGoal, TGoalValue>) {
  debugTrackingNames.set(config);

  return {
    name: () => config.name,
    // eslint-disable-next-line import/no-deprecated
    variant: () => etV2.track(config.name),
    stages: createNumericTrackingUtils(etV2.stage, config.name, config.stages),
    goals: createNumericTrackingUtils(etV2.customGoal, config.name, config.goals),
    goalsWithValue: createCustomGoalWithValueTrackingUtilsV2(config.goalsWithValue)
  };
}

export function createTrackingUtils<TStage extends string, TGoal extends string, TGoalValue extends string>(
  config: ExperimentConfigWithDefault<TStage, TGoal, TGoalValue>
) {
  debugTrackingNames.set(config);

  return {
    name: () => config.name,
    trackWithDefaultStage: () => etV2.trackWithDefaultStage(config.name, config.defaultStage),
    stages: createNumericTrackingUtils(etV2.stage, config.name, config.stages),
    goals: createNumericTrackingUtils(etV2.customGoal, config.name, config.goals),
    goalsWithValue: createCustomGoalWithValueTrackingUtilsV2(config.goalsWithValue)
  };
}

export function doNotUseThisEver<TStage extends string, TGoal extends string, TGoalValue extends string>(
  config: ExperimentConfig<TStage, TGoal, TGoalValue>
) {
  debugTrackingNames.set(config);

  return {
    name: () => config.name,
    variant: () => etV2.trackWithDefaultStage(config.name, 1),
    stages: createNumericTrackingUtils(etV2.stage, config.name, config.stages),
    goals: createNumericTrackingUtils(etV2.customGoal, config.name, config.goals),
    goalsWithValue: createCustomGoalWithValueTrackingUtilsV2(config.goalsWithValue)
  };
}

/**
 * @deprecated We are migrating form our own implementation of tracking library to tracking library provided by ET team
 * Please use createCustomGoalWithValueTrackingUtilsV2 instead.
 * More info in the wiki https://gitlab.booking.com/flights/main/-/wikis/Migrating-to-new-client-side-tracking-library
 */
function createCustomGoalWithValueTrackingUtils<T extends string>(goals: T[] = []): UtilMapForGoalsWithValue<T> {
  const utilMap = {} as UtilMapForGoalsWithValue<T>;

  goals.forEach((goal) => {
    utilMap[goal] = (value: number) => trackGoalWithValue(goal, value);
  });

  return utilMap;
}

function createCustomGoalWithValueTrackingUtilsV2<T extends string>(goals: T[] = []): UtilMapForGoalsWithValue<T> {
  const utilMap = {} as UtilMapForGoalsWithValue<T>;

  goals.forEach((goal) => {
    utilMap[goal] = (value: number) => etV2.goalWithValue(goal, value);
  });

  return utilMap;
}

/**
 * @deprecated We are migrating form our own implementation of tracking library to tracking library provided by ET team
 * Please use createMetaCustomGoalWithValueTrackingUtilsV2 instead.
 * More info in the wiki https://gitlab.booking.com/flights/main/-/wikis/Migrating-to-new-client-side-tracking-library
 */
function createMetaCustomGoalWithValueTrackingUtils<T extends string>(goals: T[] = []): UtilMapForGoalsWithValue<T> {
  const utilMap = {} as UtilMapForGoalsWithValue<T>;

  goals.forEach((goal) => {
    utilMap[goal] = (value: number) => isOfMetaOrigin() && trackGoalWithValue(goal, value);
  });

  return utilMap;
}

function createNumericTrackingUtils<TName extends string, TKey extends string>(
  trackingFn: TrackingFn<TName>,
  expName: TName,
  indexMap: Record<TKey, number>
) {
  const utilMap = {} as Record<TKey, VoidFunction>;
  type Index = typeof indexMap[keyof typeof indexMap];

  for (const [name, index] of Object.entries(indexMap)) {
    utilMap[name as TKey] = () => trackingFn(expName, index as Index);
  }

  return utilMap;
}

/**
 * Returns a typesafe set of tracking functions only for meta traffic
 * @deprecated We are migrating form our own implementation of tracking library to tracking library provided by ET team
 * Please use createTrackingUtils instead.
 * More info in the wiki https://gitlab.booking.com/flights/main/-/wikis/Migrating-to-new-client-side-tracking-library
 * Example:
 * import { createTrackingUtils } from "utils/experiments";
 * const metaTrackingUtils = createTrackingUtils();
 */
// eslint-disable-next-line @typescript-eslint/naming-convention  -- this line was auto generated, hence fix the issue timely
export function __deprecated__createMetaTrackingUtils<
  TStage extends string,
  TGoal extends string,
  TGoalValue extends string
>(config: ExperimentConfig<TStage, TGoal, TGoalValue>) {
  return {
    name: () => config.name,
    variant: () => isOfMetaOrigin() && trackExperiment(config.name),
    stages: createNumericTrackingUtils(trackMetaExperimentStage, config.name, config.stages),
    goals: createNumericTrackingUtils(trackMetaCustomGoal, config.name, config.goals),
    goalsWithValue: createMetaCustomGoalWithValueTrackingUtils(config.goalsWithValue)
  };
}
