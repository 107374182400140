// eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
import { __deprecated__createTrackingUtilsV2 } from "..";
import { useEffect, useState } from "react";
import useProfiler from "hooks/useProfiler";
import useIsInViewport from "hooks/useIsInViewport";
import useRouteName from "hooks/useRouteName";

// eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
const utils = __deprecated__createTrackingUtilsV2({
  name: "flights_web_ddot_last_available_seats_v4",
  stages: {
    last_available_seats_less_than_2_travellers: 1, // main
    last_available_seats_1_traveller: 2,
    scroll_to_last_available_seats_offer: 3
  },
  goals: {
    book_offer_with_last_available_seats: 1,
    open_fd_with_last_available_seats: 2
  },
  goalsWithValue: []
});

const { stages } = utils;

export const useTrackAvailableSeatsDesktopFD = ({
  canRenderLastAvailableSeats,
  travellersCount
}: {
  canRenderLastAvailableSeats: boolean;
  travellersCount: number;
}) => {
  const { isDirect, isMeta } = useProfiler();
  const isMessageVisible = canRenderLastAvailableSeats && travellersCount <= 2;

  useEffect(() => {
    if (isMeta() && isMessageVisible) {
      stages.last_available_seats_less_than_2_travellers();
      travellersCount === 1 && stages.last_available_seats_1_traveller();
    }
  }, [canRenderLastAvailableSeats, isDirect, isMessageVisible, isMeta, travellersCount]);
};

export const useTrackAvailableSeatsDesktopSR = ({
  canRenderLastAvailableSeats,
  travellersCount,
  availableSeatsFlightIndex
}: {
  canRenderLastAvailableSeats: boolean;
  travellersCount: number;
  availableSeatsFlightIndex: number;
}) => {
  const routeName = useRouteName();
  const { isMeta } = useProfiler();
  const isInViewPort = useIsInViewport(document.getElementById(`flight-card-${availableSeatsFlightIndex}`));
  const isMessageVisible = canRenderLastAvailableSeats && travellersCount <= 2;
  const [viewportTracked, setViewportTracked] = useState(false);

  useEffect(() => {
    if (isMeta() && routeName === "searchresults" && isInViewPort && isMessageVisible && !viewportTracked) {
      stages.scroll_to_last_available_seats_offer();
      setViewportTracked(true);
    }
  }, [isInViewPort, isMessageVisible, isMeta, routeName, viewportTracked]);
};

export default utils;
