import { useEffect, useRef } from "react";
import useGlobalContext from "./useGlobalContext";
import useRouteName from "./useRouteName";

import useGTMRouteChangeEvent from "./useGTMRouteChangeEvent";

const useRouteChangeTracking = () => {
  const { marketingTrackingVariables } = useGlobalContext();
  const routeName = useRouteName();

  const GTMRouteChangeEvent = useGTMRouteChangeEvent();

  const gtmRouteChangeTrackingRef = useRef({
    GTMRouteChangeEvent
  });

  useEffect(() => {
    const { GTMRouteChangeEvent } = gtmRouteChangeTrackingRef.current;
    GTMRouteChangeEvent();
  }, [routeName, marketingTrackingVariables]);
};

export default useRouteChangeTracking;
