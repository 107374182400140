import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useSearchFiltersController from "../useSearchFiltersController";
import { InputCheckbox, InputSlider, Stack, Tab, Text } from "@bookingcom/bui-react";
import { useI18n, t } from "@bookingcom/lingojs-react";
import WithSuffix from "../WithSuffix";
import useUserAgent from "hooks/useUserAgent";
import flights_web_flight_time_filter from "utils/experiments/funnel/flights_web_flight_time_filter";
import cloneDeep from "lodash/cloneDeep";
import { RangeChangeArgs } from "@bookingcom/bui-react/components/InputSlider/InputSlider.types";
import { useFormatDateTime } from "@bookingcom/flights-core";

type FlightTimesControllerProps = NonNullable<ReturnType<typeof useSearchFiltersController>["flightTimes"]>;

type FlightTimesProps = {
  options: FlightTimesControllerProps["options"];
  value: FlightTimesControllerProps["value"];
  onChange: FlightTimesControllerProps["set"];
  getLabel: FlightTimesControllerProps["getLabel"];
  getTitle: FlightTimesControllerProps["getTitle"];
};

const sliderMinDefaultValue = 0;
const sliderMaxDefaultValue = 23;

export default function FlightTimes(props: FlightTimesProps) {
  const { options, value, onChange, getLabel, getTitle } = props;
  const i18n = useI18n();
  const { isMobile } = useUserAgent();
  const isSliderExperiment = flights_web_flight_time_filter.variant();
  // Since "handleOnChangeSlider" is delayed it may use a stale "value" and "onChange"
  // from closure. This will result in the sliders internal state being out of sync with
  // the actual store state. This "ref" is being used to keep a fresh reference to the store state.
  const freshValueRef = useRef({ value, onChange });
  freshValueRef.current = { value, onChange };

  const handleOnChange = useCallback(
    (checked: boolean, timeIdx: number, segmentIdx: number, type: string) => {
      const result = cloneDeep(value);
      // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
      result[segmentIdx][type][timeIdx].selected = checked;
      onChange(result);
    },
    [value, onChange]
  );

  const handleOnChangeSlider: SegmentSliderProps["onChange"] = useCallback((index, name, data) => {
    const result = cloneDeep(freshValueRef.current.value);

    if (data.start === sliderMinDefaultValue && data.end === sliderMaxDefaultValue) {
      // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
      result[index][name] = [];
    } else {
      // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
      result[index][name] = [{ start: formatValue(data.start), end: formatValue(data.end), selected: true }];
    }

    freshValueRef.current.onChange(result);
  }, []);

  return (
    <Tab
      vertical={false}
      moreLabel={i18n.trans(t("flights_filters_more_flights_action"))}
      fillEqually={true}
      variant={isSliderExperiment ? "rounded" : undefined}
    >
      <Stack>
        <Tab.TriggerList>
          {options.map((_, segmentIdx) => {
            const title = getTitle(segmentIdx);
            return (
              <Tab.Trigger
                id={title}
                text={title}
                key={title + segmentIdx}
                linkAttributes={{ "data-testid": `flight_times_filter_v2_flight_times_button_${segmentIdx}` }}
              />
            );
          })}
        </Tab.TriggerList>
        {options.map((segment, segmentIdx) => {
          const title = getTitle(segmentIdx);
          const arrival = segment.arrival;
          const departure = segment.departure;
          return (
            <Tab.Panel key={title + segmentIdx} id={title}>
              <Stack gap={isMobile ? 8 : 4}>
                <Stack.Item>
                  {isSliderExperiment ? (
                    <SegmentSlider
                      name="departure"
                      label={getLabel(segmentIdx, "departure")}
                      value={value?.[segmentIdx]?.departure || []}
                      segmentIndex={segmentIdx}
                      onChange={handleOnChangeSlider}
                    />
                  ) : (
                    <Segment
                      title={title}
                      segmentType="departure"
                      semgentTimes={departure}
                      label={getLabel(segmentIdx, "departure")}
                      value={value?.[segmentIdx]?.departure || []}
                      onChange={(checked, idx) => handleOnChange(checked, idx, segmentIdx, "departure")}
                    />
                  )}
                </Stack.Item>
                <Stack.Item>
                  {isSliderExperiment ? (
                    <SegmentSlider
                      name="arrival"
                      label={getLabel(segmentIdx, "arrival")}
                      value={value?.[segmentIdx]?.arrival || []}
                      segmentIndex={segmentIdx}
                      onChange={handleOnChangeSlider}
                    />
                  ) : (
                    <Segment
                      title={title}
                      segmentType="arrival"
                      semgentTimes={arrival}
                      label={getLabel(segmentIdx, "arrival")}
                      value={value?.[segmentIdx]?.arrival || []}
                      onChange={(checked, idx) => handleOnChange(checked, idx, segmentIdx, "arrival")}
                    />
                  )}
                </Stack.Item>
              </Stack>
            </Tab.Panel>
          );
        })}
      </Stack>
    </Tab>
  );
}

type SegmentProps = {
  title: string;
  label: string;
  segmentType: "arrival" | "departure";
  semgentTimes: FlightTimesProps["options"][number]["departure" | "arrival"];
  value: FlightTimesProps["value"][number]["departure" | "arrival"];
  onChange: (checked: boolean, indx: number) => void;
};

function Segment(props: SegmentProps) {
  const { label, segmentType, semgentTimes, value, onChange } = props;
  const { isMobile } = useUserAgent();
  const i18n = useI18n();
  const { formats } = useFormatDateTime(i18n);

  const getFormattedTime = useCallback(
    (start?: string | null, end?: string | null) => {
      if (!start || !end) return;
      const startTime = `2000-01-01T${start}:00`;
      const endTime = `2000-01-01T${end}:00`;
      return i18n.trans(
        t("flights_filters_times_range", {
          variables: {
            start_time: formats.flightTime(startTime),
            end_time: formats.flightTime(endTime)
          }
        })
      );
    },
    [i18n, formats]
  );

  return (
    <Stack gap={isMobile ? 4 : 2}>
      <Text variant="emphasized_2" attributes={{ "data-testid": `flight_times_filter_v2_${segmentType}_label` }}>
        {label}
      </Text>
      {semgentTimes.map((timeSegment, idx) => {
        const formattedTimeLabel = getFormattedTime(timeSegment.start, timeSegment.end);
        return (
          <WithSuffix
            key={`${segmentType}-${timeSegment.start}-${timeSegment.end}`}
            suffix={
              <span data-testid={`flight_times_filter_v2_flight_time_${segmentType}_${idx}_count`}>
                {timeSegment.count}
              </span>
            }
          >
            <InputCheckbox
              disabled={timeSegment.count === 0}
              label={formattedTimeLabel || `${timeSegment.start} - ${timeSegment.end}`}
              checked={!!value?.[idx]?.selected}
              key={`${segmentType}-${timeSegment.start}-${timeSegment.end}`}
              name={`${segmentType}-${timeSegment.start}-${timeSegment.end}`}
              attributes={{ "data-testid": `flight_times_filter_v2_flight_time_${segmentType}_${idx}` }}
              onChange={({ checked }) => {
                onChange(checked, idx);
              }}
            />
          </WithSuffix>
        );
      })}
    </Stack>
  );
}

type SegmentData = { start: number; end: number };

type SegmentSliderProps = {
  name: string;
  label: string;
  onChange: (index: number, name: string, data: SegmentData) => void;
  value: FlightTimesProps["value"][number]["departure" | "arrival"];
  segmentIndex: number;
};

const addLeadingZero = (value: number) => {
  return value > 9 ? value : `0${value}`;
};

const formatValue = (value: number) => `${addLeadingZero(value)}${value === sliderMaxDefaultValue ? ":59" : ":00"}`;

const SegmentSlider = ({ name, label, value, segmentIndex, onChange }: SegmentSliderProps) => {
  const { minDefaultValue, maxDefaultValue } = useMemo(() => {
    const values = value.find((v) => v.selected);

    if (!values) {
      return {
        minDefaultValue: sliderMinDefaultValue,
        maxDefaultValue: sliderMaxDefaultValue
      };
    }

    const minValueNumber = Number(values?.start?.split(":")[0]);
    const maxValueNumber = Number(values?.end?.split(":")[0]);

    return {
      minDefaultValue: isNaN(minValueNumber) ? sliderMinDefaultValue : minValueNumber,
      maxDefaultValue: isNaN(maxValueNumber) ? sliderMaxDefaultValue : maxValueNumber
    };
  }, [value]);
  const [values, setValues] = useState({ minValue: minDefaultValue, maxValue: maxDefaultValue });
  const i18n = useI18n();

  useEffect(() => {
    setValues({ minValue: minDefaultValue, maxValue: maxDefaultValue });
  }, [minDefaultValue, maxDefaultValue]);

  const renderLabel = useCallback(
    (minValue: number, maxValue: number) => {
      return `${i18n.trans(t("flights_filters_times_hours"))} ${formatValue(minValue)} - ${formatValue(maxValue)}`;
    },
    [i18n]
  );

  const handleOnChange = useCallback(({ minValue, maxValue }: RangeChangeArgs) => {
    setValues((s) => {
      const newState = { ...s };

      if (minValue != undefined) {
        newState.minValue = minValue;
      }

      if (maxValue != undefined) {
        newState.maxValue = maxValue;
      }

      return newState;
    });
  }, []);

  const handleOnChangeCommit = useCallback(
    ({ minValue, maxValue }: RangeChangeArgs) => {
      const start = minValue != undefined ? minValue : values.minValue;
      const end = maxValue != undefined ? maxValue : values.maxValue;
      onChange(segmentIndex, name, { start, end });
    },
    [name, onChange, segmentIndex, values.maxValue, values.minValue]
  );

  return (
    <InputSlider
      name={name}
      label={label}
      minValue={values.minValue}
      maxValue={values.maxValue}
      min={sliderMinDefaultValue}
      max={sliderMaxDefaultValue}
      range={true}
      minAriaLabel={formatValue(values.minValue)}
      maxAriaLabel={formatValue(values.maxValue)}
      renderTooltipValue={formatValue}
      renderValue={renderLabel}
      onChange={handleOnChange}
      onChangeCommit={handleOnChangeCommit}
    />
  );
};
