import { UITravellerPrice } from "@flights/types";

// get the lowest price for adults
// ===============================
// sort travellerPrices by total price
// return the first one (lowest)

const getLowestAdultTravellerPrice = (travellerPrices: UITravellerPrice[]) => {
  return travellerPrices
    .filter((price) => price.travellerType === "ADULT")
    .sort((a, b) => {
      const { units: aUnits, nanos: aNanos } = a.travellerPriceBreakdown.total;
      const { units: bUnits, nanos: bNanos } = b.travellerPriceBreakdown.total;
      return aUnits === bUnits ? aNanos - bNanos : aUnits - bUnits;
    })
    .find((price) => Boolean(price.travellerType));
};

export default getLowestAdultTravellerPrice;
