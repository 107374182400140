/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { useCallback, useEffect, useState } from "react";
import useSearchFiltersController from "../useSearchFiltersController";
import { InputSlider } from "@bookingcom/bui-react";
import { useI18n, t } from "@bookingcom/lingojs-react";
import useFormatPrice from "hooks/useFormatPrice";

type BudgetPerAdultControllerProps = NonNullable<ReturnType<typeof useSearchFiltersController>["budgetPerAdult"]>;

type BusgetPerAdultProps = {
  min: BudgetPerAdultControllerProps["options"]["min"];
  max: BudgetPerAdultControllerProps["options"]["max"];
  value: BudgetPerAdultControllerProps["value"];
  onChange: BudgetPerAdultControllerProps["set"];
};

export default function BudgetPerAdult(props: BusgetPerAdultProps) {
  const { min, max, value, onChange } = props;
  const i18n = useI18n();
  const { formatPrice } = useFormatPrice();
  const [sliderValue, setSliderValue] = useState(value);
  const [stateValue, setStateValue] = useState(value);

  const handleOnChange = useCallback((val: number) => {
    setSliderValue(val);
  }, []);

  const handleOnChangeCommit = useCallback(
    (val: number) => {
      onChange(val);
      setStateValue(val);
    },
    [onChange]
  );

  useEffect(() => {
    setSliderValue(value);
    setStateValue(value);
  }, [value]);

  const price = (units: number) => formatPrice({ nanos: 0, units, currencyCode: max.currencyCode });

  return (
    // https://jira.booking.com/jira/browse/DESY-5093
    <div style={{ padding: "var(--bui_spacing_4x)", overflow: "hidden", margin: "calc(var(--bui_spacing_4x) * -1)" }}>
      <InputSlider
        min={min.units}
        max={max.units}
        value={sliderValue}
        interval={10}
        name="filters-budget-per-adult"
        attributes={{ "data-testid": "search_filter_stops_budget_per_adult_slider" }}
        ariaLabel={i18n.trans(t("a11y_flights_filter_price_announce"))}
        label={i18n.trans(
          t("flights_filter_price_subtitle", {
            variables: { num_max: price(stateValue) }
          })
        )}
        onChange={({ value }) => handleOnChange(value)}
        onChangeCommit={({ value }) => handleOnChangeCommit(value)}
        renderValue={() => null}
        renderTooltipValue={(val) => price(val)}
      />
    </div>
  );
}
