/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { useEffect, useMemo, useCallback, useRef } from "react";
import { DESKTOP_SIDEBAR_WIDTH } from "../../../../constants";
import { trackExperiment, trackExperimentStage } from "utils/et";
import { isOfMetaOrigin } from "utils/marketing-url-params";
import useRouteName from "hooks/useRouteName";
import useUserAgent from "hooks/useUserAgent";
import useSideBarSticky from "./useSideBarSticky";
import Frame, { PaddingDirection } from "components/elements/Frame";

type Props = {
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  fixScrollPadding?: boolean;
} & PaddingDirection;

const Sidebar = (props: Props) => {
  const { children, p, pt, pr, pb, pl, className, fixScrollPadding } = props;

  // flights_web_ddot_sticky_price_breakdown -- start
  // ================================================
  const routeName = useRouteName();
  const { isMobile } = useUserAgent();
  const divRef = useRef<HTMLDivElement>(null);
  const isCheckout = useMemo(() => !isMobile && routeName.startsWith("checkout"), [routeName, isMobile]);

  useEffect(() => {
    if (!isCheckout) return;
    trackExperimentStage("flights_web_ddot_sticky_price_breakdown", 1);
    trackExperimentStage("flights_web_ddot_sticky_price_breakdown", isOfMetaOrigin() ? 2 : 3);
  }, [isCheckout]);

  const shouldBeSticky = useCallback(
    () => isCheckout && !!trackExperiment("flights_web_ddot_sticky_price_breakdown"),
    [isCheckout]
  );

  useSideBarSticky({ wrapper: divRef?.current, conditions: shouldBeSticky, fixScrollPadding });
  // ================================================
  // flights_web_ddot_sticky_price_breakdown -- end

  return (
    <Frame
      className={className}
      direction="column"
      p={p}
      pt={pt}
      pr={pr}
      pb={pb}
      pl={pl}
      attributes={{ style: { ...props.style, flexShrink: 0, width: DESKTOP_SIDEBAR_WIDTH } }}
    >
      <div ref={divRef}>{children}</div>
    </Frame>
  );
};

export default Sidebar;
