import React from "react";
import { Text, AspectRatio, useTheme } from "@bookingcom/bui-react";
import { t, useI18n } from "@bookingcom/lingojs-react";
import { UIWorldwideFlightInfo } from "@flights/types";
import useFormatPrice from "hooks/useFormatPrice";
import { IMAGE_STATIC_ASSETS_URL } from "../../../constants";
import useCreateUrl from "hooks/useCreateUrl";
import useGlobalContext from "hooks/useGlobalContext";
import { useFormatDateTime } from "@bookingcom/flights-core";
import styles from "./WorldwideFlightsItem.module.css";
import { formattingUtils } from "@flights/web-api-utils-universal";

interface Props {
  flight: UIWorldwideFlightInfo;
}

const getImageUrl = (toCityImageUrl: string): string => {
  return `${IMAGE_STATIC_ASSETS_URL}${toCityImageUrl}`;
};

const WorldwideFlightsItem: React.FunctionComponent<Props> = ({ flight }) => {
  const { locale } = useGlobalContext();
  const { createRoutePageUrl } = useCreateUrl();
  const i18n = useI18n();
  const theme = useTheme();
  const { formats } = useFormatDateTime(i18n);
  const { formatPrice } = useFormatPrice();

  const routeName = i18n.trans(
    t("seo_flights_new_index_lp_cheap_flights_meta_flight_route", {
      variables: { city_name_origin: flight.fromCityNameLocalised, city_name_destination: flight.toCityNameLocalised }
    })
  );

  const flightParams = flight.tripStartDate
    ? i18n.trans(
        t("flights_seo_price_depart_date", { variables: { depart_date: formats.flightDate(flight.tripStartDate) } })
      )
    : "";
  const fromPriceFormated = flight.price
    ? formatPrice({ currencyCode: flight.price.currencyCode, units: flight.price.units, nanos: 0 })
    : "";
  const fromPrice = i18n.trans(t("flights_seo_price_one_way_pp", { variables: { price: fromPriceFormated } }));

  const flightDurationPrefix = i18n.trans(t("seo_lpc_flights_route_shortest_time"));
  const flightDuration = flight.flightDurationMinutes
    ? formattingUtils.flightDuration(flight.flightDurationMinutes, i18n)
    : "";

  return (
    <a href={createRoutePageUrl(`/flights/route/${flight.flightRouteSlug}.${locale}.html`)} className={styles.wrapper}>
      <AspectRatio width={theme.units.spacing_16x}>
        <img className={styles.image} src={getImageUrl(flight.toCityImageUrl)} alt={routeName} />
      </AspectRatio>
      <div className={styles.textContainer}>
        <Text className={styles.text} variant="strong_2">
          {routeName}
        </Text>
        {flightParams !== "" && (
          <Text className={styles.text} variant="body_2" color="neutral_alt">
            {flightParams}
          </Text>
        )}
        {fromPriceFormated !== "" && (
          <Text className={styles.text} variant="body_2" color="neutral_alt">
            {fromPrice}
          </Text>
        )}
        {flightDuration !== "" && (
          <Text
            className={styles.text}
            variant="body_2"
            color="neutral_alt"
          >{`${flightDurationPrefix} ${flightDuration}`}</Text>
        )}
      </div>
    </a>
  );
};
export default WorldwideFlightsItem;
