import { useFormatDateTime } from "@bookingcom/flights-core";
import { useI18n, t } from "@bookingcom/lingojs-react";
import useCabinClassName from "hooks/useCabinClassName";
import useCreateUrl from "hooks/useCreateUrl";
import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { StoreState } from "store";
import { UILocation } from "@flights/types";
import { useSelector } from "react-redux";

export default function useSearchOverview() {
  const history = useHistory();
  const { createUrl } = useCreateUrl();
  const search = useSelector((state: StoreState) => state.search);
  const i18n = useI18n();
  const cabinClassName = useCabinClassName();
  const { formats } = useFormatDateTime(i18n);
  const { searchSegments, cabinClass, adults, children, type } = search || {};

  const handleOnClick = useCallback(() => {
    history.push(createUrl("/"));
  }, [history, createUrl]);

  const airportName = useCallback(
    (loc: UILocation[]) =>
      loc
        .map((l) => {
          return type === "MULTISTOP"
            ? l.code
            : // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
              [l.type === "AIRPORT" && l.code, l["cityName"] || l.name].filter(Boolean).join(" ");
        })
        .join(", "),
    [type]
  );

  const airports = useMemo(() => {
    const isFlyAnywhere = window.location.pathname.startsWith("/fly-anywhere");
    return searchSegments.map((_) => [
      airportName(_.from),
      isFlyAnywhere ? i18n.trans(t("flights_search_destination_anywhere")) : airportName(_.to)
    ]);
  }, [airportName, i18n, searchSegments]);

  const airportList = useMemo(() => {
    return airports.reduce((result, current, idx) => {
      if (idx == 0) {
        result.push([current[0], current[1]]);
        return result;
      }
      const prev = result?.[result.length - 1];
      if (current?.[0] === prev?.[prev.length - 1]) {
        result.splice(result.length - 1, 1, [...prev, current[1]]);
      } else {
        result.push([current[0], current[1]]);
      }
      return result;
    }, [] as string[][]);
  }, [airports]);

  const travellers = useMemo(() => {
    const travellersCount = adults + children?.length || 0;
    return i18n.trans(
      t("flights_search_passenger_count_mix", {
        num_exception: travellersCount,
        variables: { num_travellers: travellersCount }
      })
    );
  }, [i18n, adults, children]);

  const cabin = useMemo(() => {
    return cabinClassName(cabinClass);
  }, [cabinClassName, cabinClass]);

  const dates = useMemo(() => {
    const firstSegmentDepartureDate = searchSegments[0].departureDate;
    const lastSegmentReturnDate =
      searchSegments[searchSegments.length - 1].returnDate ||
      (type === "MULTISTOP" && searchSegments[searchSegments.length - 1].departureDate);
    return [firstSegmentDepartureDate, lastSegmentReturnDate]
      .filter(Boolean)
      .map((_: string) => formats.flightDate(_))
      .join(" - ");
  }, [searchSegments, formats, type]);

  const ariaLabel = i18n.trans(t("a11y_flights_search_on_sr_edit"));

  return { handleOnClick, airportList, type, travellers, cabin, dates, ariaLabel };
}
