import { UIFlightData } from "@flights/types";
import { createTrackingUtils } from "../index";

const utils = createTrackingUtils({
  name: "flights_apex_web_expand_bf_on_sr",
  stages: {
    has_requestable_bf: 1,
    between_3_to_5_offers: 2,
    eight_or_more_offers: 3,
    selects_upsell_fare: 4,
    one_way_travelers: 5,
    round_or_multi_trip: 6,
    false_positive: 7
  },
  goals: {
    collapses_fares: 1,
    sees_fare_details: 2,
    clicks_parent_offer: 3,
    clicks_lowest_fare: 4
  },
  goalsWithValue: [],
  defaultStage: 1
});

const trackSearchResultsStages = (flights: UIFlightData[]) => {
  const countRequestableBF = flights.filter((flight) => flight.requestableBrandedFares).length;
  if (countRequestableBF > 0) utils.stages.has_requestable_bf();
  if (countRequestableBF > 2 && countRequestableBF < 6) utils.stages.between_3_to_5_offers();
  if (countRequestableBF >= 8) utils.stages.eight_or_more_offers();
};

export default { ...utils, trackSearchResultsStages };
