import Orchestrator from "@bookingcom/web-page-orchestrator";

/**
 * define queries and commands in OrchestratorContext
 * consume them from useOrchestrator
 * ==================================================
 */

let initialised = false;
export const getIsOrchestratorInitialised = (): boolean => initialised;
export const setIsOrchestratorInitialised = (val: boolean): void => {
  initialised = val;
};
const orchestratorInitialiser = () => {
  if (process.env.BUILD_TARGET !== "client") return;
  if (typeof window === "undefined") return;
  const orchestrator = window.orchestrator || new Orchestrator();
  window.orchestrator = orchestrator;
  setIsOrchestratorInitialised(true);
};
orchestratorInitialiser();
