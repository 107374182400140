import React, { ReactNode } from "react";

import Frame from "components/elements/Frame";
import styles from "./FlightCardBadges.module.css";
import { mcn } from "utils/mergeClassnames";

type Props = {
  className?: string;
  children?: ReactNode;
};

export const FlightCardBadges = ({ className, children }: Props) => {
  return <Frame className={mcn(styles.flightCardBadges, className || "")}>{children}</Frame>;
};
