import { Actions, ActionTypes } from "./actions";
import { State, getInitialState } from "./state";

const initialState = getInitialState();

const reducer = (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case ActionTypes.fetch:
      return {
        ...state,
        flexibleDateMinPrice: [],
        fetchStatus: "loading",
        error: undefined
      };
    case ActionTypes.fetchSuccess:
      return {
        ...state,
        flexibleDateMinPrice: action.payload,
        fetchStatus: "success",
        error: undefined
      };

    case ActionTypes.fetchError:
      return {
        ...state,
        flexibleDateMinPrice: [],
        fetchStatus: "failed",
        error: action.payload
      };

    case ActionTypes.reset:
      return getInitialState();

    default:
      return state;
  }
};

export { reducer, getInitialState };
