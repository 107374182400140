import { RouteName } from "app/routes";
import { useEffect, useRef } from "react";
import useRouteName from "./useRouteName";
import { trackGoal } from "utils/et";

const usePageviewIsNotABounce = () => {
  // eslint-disable-next-line no-restricted-syntax -- this line was auto generated, hence fix the issue timely
  const landingAction = useRef<string | null>(null);

  const route = useRouteName();

  useEffect(() => {
    const redirectRoutes: RouteName[] = ["checkout-start"];

    if (!landingAction.current && route && !redirectRoutes.includes(route)) {
      landingAction.current = route;
    }
    if (landingAction.current && route && route !== landingAction.current) {
      trackGoal("flights_pageview_is_not_a_bounce");
      if (landingAction.current === "flightdetails") {
        trackGoal("flights_fd_pageview_is_not_a_bounce");
      }
      if (landingAction.current === "searchresults") {
        trackGoal("flights_sr_pageview_is_not_a_bounce");
      }
    }
  }, [route]);
};

export default usePageviewIsNotABounce;
