import React from "react";

import { Card, Stack, Timeline } from "@bookingcom/bui-react";
import { PlaneTripIcon, TicketIcon, TravelCreditIcon } from "@bookingcom/bui-assets-react/streamline/index.js";
import { Divider } from "@bookingcom/bui-react";
import { ItemProps } from "@bookingcom/bui-react/components/Timeline/Timeline.types";

import { UICampaignBanner } from "@flights/types";

import styles from "./FlightCampaignTCBannerModal.module.css";

type FlightCampaignTCBannerModalContentProps = {
  campaignBanner: UICampaignBanner;
};

export const FlightCampaignTCBannerModalContent = ({ campaignBanner }: FlightCampaignTCBannerModalContentProps) => {
  const timeLineItems: ItemProps[] = campaignBanner.action.timeline
    ? [
        {
          childrenText: campaignBanner.action.timeline.firstStepText,
          marker: TicketIcon
        },
        {
          childrenText: campaignBanner.action.timeline.secondStepText,
          marker: TravelCreditIcon
        },
        {
          childrenText: campaignBanner.action.timeline.thirdStepText,
          marker: PlaneTripIcon
        }
      ].map(({ childrenText, marker }) => ({
        children: <div className={styles.timeLineItem}>{childrenText}</div>,
        marker,
        markerColor: "brand_primary",
        lineVariant: "dashed"
      }))
    : [];

  return (
    <Card className={styles.contentContainer}>
      <Stack direction="column" justifyContent="start" gap={0}>
        {timeLineItems.length > 0 && (
          <div className={styles.timeLineContainer}>
            <Timeline className={styles.timeline}>
              {timeLineItems.map((timeLineItem, index) => (
                <Timeline.Item key={`kTimeLineItem-${index}`} {...timeLineItem} />
              ))}
            </Timeline>
            <Divider className={styles.timeLineDivider} />
          </div>
        )}
        <div className={styles.contentTitle}>{campaignBanner.action.contentTitle}</div>
        <ul className={styles.contentItemList}>
          {campaignBanner.action.content.map((contentItem, index) =>
            contentItem.itemType === "BULLET" ? (
              <li key={`kContentItem-${index}`} className={styles.contentItemBullet}>
                {contentItem.text}
              </li>
            ) : (
              <p key={`kContentItem-${index}`} className={styles.contentItemParagraph} role="listitem">
                {contentItem.text}
              </p>
            )
          )}
        </ul>
      </Stack>
    </Card>
  );
};
