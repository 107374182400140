import React, { FC, useRef, useState } from "react";

import { Button, Icon, Popover, SheetContainer, Text } from "@bookingcom/bui-react";
import {
  CrossIcon,
  InfoSignIcon,
  CloseCircleFillIcon,
  DollarCircleFillIcon,
  CheckmarkSelectedFillIcon
} from "@bookingcom/bui-assets-react/streamline/index.js";
import { Color } from "@bookingcom/bui-react/components/Icon/Icon.types";
import Frame from "components/elements/Frame";
import styles from "./CheckoutFareInner.module.css";
import { t, useI18n } from "@bookingcom/lingojs-react";
import useUserAgent from "hooks/useUserAgent";
import { TriggerAttributes } from "@bookingcom/bui-react/components/_base/Flyout/Flyout.types";
import { UIBrandedFareFeatureAvailability } from "@flights/types";
import flights_apex_web_sellable_features_v3 from "utils/experiments/apex/flights_apex_web_sellable_features_v3";

const availabilityIcons: Record<UIBrandedFareFeatureAvailability, { color: Color; icon: FC }> = {
  INCLUDED: { color: "constructive", icon: CheckmarkSelectedFillIcon },
  SELLABLE: { color: "neutral", icon: DollarCircleFillIcon },
  NOT_INCLUDED: { color: "destructive", icon: CloseCircleFillIcon }
};

export const FareFeature: FC<{
  content: string;
  IconSVG?: FC;
  id?: string;
  description?: string;
  subtitle?: string;
  availability: UIBrandedFareFeatureAvailability;
  isCompact?: boolean;
  compactHeight?: boolean;
  // eslint-disable-next-line @typescript-eslint/naming-convention -- this line was auto generated, hence fix the issue timely
}> = ({ content, IconSVG, id, description, subtitle, availability, isCompact, compactHeight }) => {
  const [showDescription, setShowDescription] = useState(false);
  const i18n = useI18n();
  const { isMobile } = useUserAgent();
  const popoverRef = useRef(document.body);
  const showAvailabilityIcon = !!flights_apex_web_sellable_features_v3.trackWithDefaultStage();

  IconSVG = availability === "NOT_INCLUDED" && !showAvailabilityIcon ? CrossIcon : IconSVG;

  if (!IconSVG) return null;

  const availabilityIcon = availabilityIcons[availability];

  const renderDescriptionButton = (attributes?: TriggerAttributes) => (
    <Button
      variant="tertiary-neutral"
      onClick={() => isMobile && setShowDescription(true)}
      attributes={{
        ...attributes,
        "aria-label": i18n.trans(t("a11y_flights_apex_bf_greenfare_tooltip_open"))
      }}
    >
      <Icon size="medium" svg={InfoSignIcon} color="neutral_alt" />
    </Button>
  );

  const renderDescriptionContent = () => <Text>{description}</Text>;

  return (
    <Frame
      direction="row"
      attributes={{ "data-branded-fares-row": id }}
      mb={compactHeight ? 2 : isCompact ? 1 : 3}
      className={styles.featureWrapper}
      alignItems="center"
    >
      <div className={styles.featureIconWrapper}>
        <Icon
          className={isCompact ? styles.compactFeatureIcon : styles.featureIcon}
          size={isCompact ? "smaller" : "medium"}
          svg={IconSVG}
          color="neutral"
        />
        {showAvailabilityIcon && availabilityIcon && (
          <Icon
            size="smaller"
            svg={availabilityIcon.icon}
            color={availabilityIcon.color}
            className={styles.availabilityIcon}
          />
        )}
      </div>

      <div className={styles.featureContent}>
        <Text variant="body_2" attributes={{ "aria-label": content }}>
          {content}
        </Text>

        <Text variant="body_2" attributes={{ "aria-label": subtitle }}>
          {subtitle}
        </Text>
      </div>

      {description && (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <span onClick={(event) => event.stopPropagation()}>
          {isMobile ? (
            <>
              {renderDescriptionButton()}
              <SheetContainer
                active={showDescription}
                closeAriaLabel={i18n.trans(t("a11y_flights_apex_bf_greenfare_tooltip_close"))}
                title={i18n.trans(t("flights_apex_bf_greenfare_tooltip_hover_title"))}
                position="bottom"
                onCloseTrigger={() => setShowDescription(false)}
              >
                {renderDescriptionContent()}
              </SheetContainer>
            </>
          ) : (
            <Popover
              containerRef={popoverRef}
              closeAriaLabel={i18n.trans(t("a11y_flights_apex_bf_greenfare_tooltip_close"))}
            >
              <Popover.Trigger className={styles.infoIcon}>{renderDescriptionButton}</Popover.Trigger>
              <Popover.Content>{renderDescriptionContent()}</Popover.Content>
            </Popover>
          )}
        </span>
      )}
    </Frame>
  );
};
