import { ActionTypes, Actions } from "./actions";
import { State, getInitialState } from "./state";

const initialState = getInitialState();

const reducer = (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case ActionTypes.setSortOrder:
      const { sort } = action.payload;
      return { ...state, sort };

    case ActionTypes.setPage:
      const { page } = action.payload;
      return { ...state, page };

    case ActionTypes.setFiltersDraft:
      const { filtersDraft } = action.payload;
      return { ...state, filtersDraft };

    case ActionTypes.applyFilters:
      return { ...state, filters: state.filtersDraft };

    case ActionTypes.resetFiltersDraft:
      return { ...state, filtersDraft: getInitialState().filters };

    case ActionTypes.resetFilters:
      const { filters } = getInitialState();
      return { ...state, filters: filters, filtersDraft: filters };

    case ActionTypes.resetFiltersButKeep:
      const filtersInitState = getInitialState().filters;
      return {
        ...state,
        filters: { ...filtersInitState, ...action.payload.filter },
        filtersDraft: { ...filtersInitState, ...action.payload.filter }
      };

    case ActionTypes.setValueFromSB:
      const { searchboxVal } = action.payload;
      return { ...state, ...(searchboxVal as State) };

    case ActionTypes.resetFilter:
      const { filter } = action.payload;
      const initialState = {};
      // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
      initialState[filter] = getInitialState().filters[filter];
      return {
        ...state,
        filters: { ...state.filters, ...initialState },
        filtersDraft: { ...state.filtersDraft, ...initialState }
      };

    default:
      return state;
  }
};

export { reducer, getInitialState };
