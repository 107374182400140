/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React from "react";
import { Card, Divider, SkeletonLoader, Stack } from "@bookingcom/bui-react";
import useRouteName from "hooks/useRouteName";
import useUserAgent from "hooks/useUserAgent";
const GeniusRCSkeleton = () => {
  const routeName = useRouteName();
  const { isMobile } = useUserAgent();

  let margin = "16px 0";

  if (routeName === "confirmation") {
    if (isMobile) {
      margin = "16px 0 0";
    } else {
      margin = "0 0 16px";
    }
  }

  return (
    <div style={{ margin }}>
      <Card>
        <Stack direction="column" gap={3}>
          <Stack.Item>
            <div style={{ padding: "8px 0" }}>
              <SkeletonLoader variant="three-lines" />
              {routeName === "confirmation" ? (
                <div style={{ marginTop: 11 }}>
                  <SkeletonLoader variant="one-line" />
                </div>
              ) : null}
            </div>
          </Stack.Item>
          <Divider />
          <Stack.Item>
            <div style={{ padding: "4px 0" }}>
              <SkeletonLoader variant="one-line" />
            </div>
          </Stack.Item>
        </Stack>
      </Card>
    </div>
  );
};

export default GeniusRCSkeleton;
