import { useState, useCallback } from "react";

export type UseToggleResult = Readonly<{
  toggle: (show?: boolean) => void;
  show: () => void;
  hide: () => void;
  visible: boolean;
}>;

export default function useToggle(initialState: boolean = false): UseToggleResult {
  const [visible, setVisible] = useState(initialState);
  const toggle = useCallback(
    (show?: boolean): boolean => {
      const _visible = typeof show === "boolean" ? show : !visible;
      setVisible(_visible);
      return _visible;
    },
    [visible]
  );

  const show = useCallback(() => toggle(true), [toggle]);
  const hide = useCallback(() => toggle(false), [toggle]);

  return { toggle, show, hide, visible } as const;
}
