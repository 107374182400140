/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { useEffect, useCallback } from "react";
import { getCLS, getFCP, getFID, getLCP, getTTFB, Metric, ReportHandler } from "web-vitals";

import useGlobalContext from "../../../hooks/useGlobalContext";
import useTrackerContext from "../../../hooks/useTrackerContext";
import useRouteName from "../../../hooks/useRouteName";
import useClientMetrics, { initClientMetrics } from "../../../hooks/useClientMetrics";
import useEvents from "hooks/useEvents";

import {
  initializePerformanceObserver,
  Metric as ClassicMetric,
  ReportHandler as ClassicMetricReportHandler
} from "../../../utils/client-performance-metrics";

import { initETTracker, trackGoalWithValue } from "../../../utils/et";
import NoSSR from "../../../app/NoSSR";

const debug = require("debug")("trackers");

const getNavigationEntry = () =>
  window?.performance?.getEntriesByType?.("navigation")[0] as PerformanceNavigationTiming | undefined;

//
// Trackers
// --------
//
// proxy third-party client api methods through postMessage
//
// 1. Render iframe containing Riskify client script
// 2. The iframe needs to skip SSR so we can capture the onload event
// 3. The riskified loader inside is lazy-initialized on checkout page only
// 4. The last useEffect here is the only thing that re-runs, when the path in the router changes
//
// #overengineering
//
// Riskified: https://apiref.riskified.com/curl/#beacon
//

const Trackers: React.FunctionComponent = () => {
  const { payerId } = useGlobalContext();
  const { trackExternal } = useTrackerContext();
  const { trackClientMetric } = useClientMetrics();

  const currentRoute = useRouteName();
  const { jsError } = useEvents();
  const page = currentRoute || "unknown_page";

  const sendWebVitalsToClientMetrics: ReportHandler = useCallback(
    (props: Metric) => {
      const { name, value } = props;
      debug(name, value);
      trackClientMetric(name, value);
    },
    [trackClientMetric]
  );

  useEffect(() => {
    getCLS(sendWebVitalsToClientMetrics);
    getFCP(sendWebVitalsToClientMetrics);
    getFID(sendWebVitalsToClientMetrics);
    getLCP(sendWebVitalsToClientMetrics);
    getTTFB(sendWebVitalsToClientMetrics);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const sendClassicMetricsToClientMetrics: ClassicMetricReportHandler = useCallback(
    (props: ClassicMetric) => {
      const { name, value } = props;
      debug(name, value);
      trackClientMetric(name, value);
    },
    [trackClientMetric]
  );

  useEffect(() => {
    initializePerformanceObserver(sendClassicMetricsToClientMetrics);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    debug("Tracking Pageview: " + page);
    trackClientMetric("pageview");
  }, [page, trackClientMetric]);

  useEffect(() => {
    debug("Running Riskified page tracking...", page);

    // track riskified on all checkout routes
    switch (page) {
      case "checkout":
      case "checkout2":
      case "checkout3":
        if (payerId) {
          trackExternal({ type: "RISKX", action: "go", args: [page], data: { sessionId: payerId } });
        } else {
          jsError(new Error("Trackers: missing payerId"));
        }
        break;
    }
  }, [page, payerId, trackExternal, jsError]);

  useEffect(initClientMetrics, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(initETTracker, []); // eslint-disable-line react-hooks/exhaustive-deps

  // exp web_shell_flights_capla_defered
  useEffect(() => {
    const sinceTimeOrigin: undefined | number = window.performance?.now?.();
    const responseEnd = getNavigationEntry()?.responseEnd;

    sinceTimeOrigin &&
      responseEnd &&
      trackGoalWithValue("flights_react_app_mounted", Math.round(sinceTimeOrigin - responseEnd));
  }, []);

  /*eslint-disable-next-line @bookingcom/flights/no-unassigned-todo-comments*/
  return null; // TODO: this can now be turned into a hook...
};

const ClientOnlyTrackers: React.FunctionComponent = () => (
  <NoSSR>
    <Trackers />
  </NoSSR>
);

export default React.memo(ClientOnlyTrackers);
