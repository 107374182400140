import { createTrackingUtils } from "../index";
import { EU_COUNTRIES } from "./tracking-helpers";

const utils = createTrackingUtils({
  name: "flights_ace_web_esim_banner",
  defaultStage: 2,
  stages: {
    customers_on_sr_page: 1,
    eligible_customers_on_sr_page: 2,
    genius_1_customers: 3,
    genius_2_customers: 4,
    genius_3_customers: 5,
    eligible_customers_that_received_email_notifications: 6,
    eu_customers: 7,
    web_customers: 8,
    apps_customers: 9
  },
  goals: {
    viewed_esim_banner: 1,
    clicked_esim_banner: 2
  },
  goalsWithValue: []
});

export function trackEsimBannerStages(isEligible: boolean, pos: string, geniusLevel: number) {
  utils.stages.customers_on_sr_page();
  utils.stages.web_customers();

  if (isEligible) {
    utils.stages.eligible_customers_on_sr_page();
  }

  if (EU_COUNTRIES.includes(pos)) {
    utils.stages.eu_customers();
  }

  if (geniusLevel === 1) {
    utils.stages.genius_1_customers();
  } else if (geniusLevel === 2) {
    utils.stages.genius_2_customers();
  } else if (geniusLevel === 3) {
    utils.stages.genius_3_customers();
  }
}

export default utils;
