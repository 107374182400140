import { getInitialState, State } from "store/esim/state";
import { Actions, ActionTypes } from "./actions";

const initialState = getInitialState();

const reducer = (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case ActionTypes.fetch:
      return {
        ...state,
        esim: {},
        fetchStatus: "loading",
        error: undefined
      };
    case ActionTypes.fetchSuccess:
      return {
        ...state,
        esim: action.payload.esim,
        fetchStatus: "success",
        error: undefined
      };

    case ActionTypes.fetchError:
      return {
        ...state,
        fetchStatus: "failed",
        error: action.payload.error,
        esim: undefined
      };
    default:
      return {
        ...state
      };
  }
};

export { reducer, getInitialState };
