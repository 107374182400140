import React from "react";

interface LocaleContext {
  locale: any;
  isRTL: boolean;
}

const contextOptions: LocaleContext = {
  locale: undefined,
  isRTL: false
};

const context = React.createContext(contextOptions);

export default context;
