import { t } from "@bookingcom/lingojs-core";
import React from "react";
import { Icon, Text } from "@bookingcom/bui-react";
import { CrossIcon } from "@bookingcom/bui-assets-react/streamline/index.js";
import styles from "./Tags.module.css";
export default function Tags(props) {
    const { selectedLocations, i18n, onClick } = props;
    return (React.createElement(React.Fragment, null, selectedLocations.map((loc, idx) => (React.createElement(Text, { tagName: "span", variant: "body_2", key: loc.code + loc.type + idx, className: styles.item, attributes: {
            onClick: () => {
                onClick(idx);
            }
        } },
        React.createElement("span", { className: styles.text }, loc.type === "CITY" ? (React.createElement(React.Fragment, null,
            React.createElement("b", null, loc.name),
            ` ${i18n.trans(t("flights_multi_select_all_airports"))}`)) : (React.createElement(React.Fragment, null,
            React.createElement("b", null, loc.code),
            ` ${loc.cityName || loc.name}`))),
        React.createElement(Icon, { size: "small", className: styles.icon, svg: CrossIcon }))))));
}
