import deepEqual from "deep-equal";
/**
 * Checks if array is consistent (simpler speaking, all elements are the same).
 * It returns `true` if all elements of array returns `true` when compared to 1st element
 * (via `deepEqual()` by default).
 * Always returns `true` for 0- and 1-length arrays.
 */
export const isConsistent = (array, comparator = deepEqual) => {
    return array.every((elem) => comparator(elem, array[0]));
};
/**
 * Returns a new array without duplicates.
 * Elements are compared by strict equality by default (use `comparator` param to alter it).
 */
export const unique = (array, comparator = (a, b) => a === b) => {
    const uniques = [];
    array.forEach((elem) => {
        if (uniques.findIndex((unique) => comparator(elem, unique)) === -1) {
            uniques.push(elem);
        }
    });
    return uniques;
};
/**
 * Returns a boolean condition if an element is present or not; i.e not null and not undefined
 * Also enforces a non-nullable type on return
 */
export const isNotEmpty = (t) => {
    // eslint-disable-next-line no-restricted-syntax -- this line was auto generated, hence fix the issue timely
    return t !== undefined && t !== null;
};
