import React from "react";

import { List } from "@bookingcom/bui-react";
import { CheckmarkIcon, CloseIcon, MinusIcon } from "@bookingcom/bui-assets-react/streamline/index.js";
import FeatureListItem from "./FeatureListItem";

type Props = {
  included: string[];
  excluded?: string[];
  isSubtle?: boolean;
};

const FeatureList = ({ included = [], excluded = [], isSubtle }: Props) => (
  <List rowSpacing="medium">
    {[
      ...included
        .filter(Boolean)
        .map((content) => <FeatureListItem icon={CheckmarkIcon} content={content} isIncluded={true} key={content} />),
      ...excluded
        .filter(Boolean)
        .map((content) => <FeatureListItem icon={isSubtle ? MinusIcon : CloseIcon} content={content} key={content} />)
    ]}
  </List>
);

export default FeatureList;
