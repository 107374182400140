import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { t } from "@bookingcom/lingojs-core";
import YellowBox from "./YellowBox.js";
import { SearchBoxContext, SearchboxController } from "../SearchBoxContext/index.js";
import { ShellWrapper } from "../Shell/index.js";
import { AddSegment, SegmentVertical } from "../Segment/index.js";
import { OccupancyCabinClassModal } from "../OccupancyCabinClass/index.js";
import { DirectFlightSwitch } from "../DirectFlight/index.js";
import { Submit } from "../Submit/index.js";
import { SearchTypeSelect } from "../SearchType/index.js";
import { Button, Stack } from "@bookingcom/bui-react";
import { OverviewVertical } from "../Overview/index.js";
import styles from "./SearchBoxVerticalCollapsible.module.css";
const animate = (config) => {
    const { duration, onAnimate, onStart, onFinish } = config;
    let startTime = undefined;
    const animate = (timestamp) => {
        if (!startTime)
            startTime = timestamp;
        const elapsedTime = timestamp - startTime;
        const progress = Math.floor(Math.min(elapsedTime / duration, 1) * 100);
        onAnimate(progress);
        if (progress < 100)
            requestAnimationFrame(animate);
        else if (onFinish)
            setTimeout(onFinish, 5);
    };
    if (onStart)
        onStart();
    requestAnimationFrame(animate);
};
function SearchBoxVerticalCollapsible() {
    const [expand, setExpand] = useState(false);
    const [regionHeight, setRegionHeight] = useState(0);
    const { hideShadow, searchType, isValid, onTrackSBExperimentCustomGoal, i18n, onTrackSBExperimentStage } = useContext(SearchBoxContext);
    const searchTypeRef = useRef(null);
    const triggerRef = useRef(null);
    const regionWrapperRef = useRef(null);
    const regionRef = useRef(null);
    const mutationRef = useRef();
    const isMultiStop = searchType === "MULTISTOP";
    const onContentChange = useCallback(() => {
        if (regionRef.current)
            setRegionHeight(regionRef.current.clientHeight);
    }, [regionRef]);
    const doExpand = useCallback(() => {
        onTrackSBExperimentStage({ name: "flights_web_mdot_sr_full_sb", stage: 2 });
        onTrackSBExperimentCustomGoal({ name: "flights_web_mdot_sr_full_sb", customGoal: 1 });
        animate({
            duration: 240,
            onAnimate: (progress) => {
                if (!regionWrapperRef.current)
                    return;
                const currentHeight = regionWrapperRef.current.offsetHeight;
                const newHeight = currentHeight + (regionHeight - currentHeight) * (progress / 100);
                regionWrapperRef.current.style.height = `${newHeight}px`;
                regionWrapperRef.current.style.opacity = `${progress / 100}`;
            },
            onStart: () => {
                if (!regionWrapperRef.current || !triggerRef.current)
                    return;
                regionWrapperRef.current.style.display = "block";
                regionWrapperRef.current.style.height = `${triggerRef.current.clientHeight}px`;
            },
            onFinish: () => {
                if (!regionWrapperRef.current || !searchTypeRef.current)
                    return;
                regionWrapperRef.current.style.height = "auto";
                regionWrapperRef.current.style.overflow = "visible";
                if (triggerRef.current)
                    triggerRef.current.style.display = "none";
                searchTypeRef.current.focus();
            }
        });
        setExpand(true);
    }, [regionWrapperRef, regionHeight, searchTypeRef, triggerRef, onTrackSBExperimentCustomGoal]);
    const doCollapse = useCallback(() => {
        animate({
            duration: 240,
            onAnimate: (progress) => {
                if (!regionWrapperRef.current)
                    return;
                regionWrapperRef.current.style.height = `${regionHeight * ((100 - progress) / 100)}px`;
                regionWrapperRef.current.style.opacity = `${(100 - progress) / 100}`;
            },
            onStart: () => {
                if (triggerRef.current)
                    triggerRef.current.style.display = "block";
                if (regionWrapperRef.current)
                    regionWrapperRef.current.style.overflow = "hidden";
            },
            onFinish: () => {
                if (triggerRef.current)
                    triggerRef.current.focus();
                if (regionWrapperRef.current)
                    regionWrapperRef.current.style.display = "none";
            }
        });
        setTimeout(() => setExpand(false), 5);
    }, [regionWrapperRef, regionHeight, triggerRef]);
    useEffect(() => {
        mutationRef.current = new MutationObserver(() => onContentChange());
        if (regionWrapperRef.current) {
            mutationRef.current.observe(regionWrapperRef.current, { childList: true, subtree: true, characterData: true });
        }
        if (regionRef.current)
            setRegionHeight(regionRef.current.clientHeight);
        setTimeout(() => {
            if (regionWrapperRef.current)
                regionWrapperRef.current.style.display = "none";
        }, 5);
        return () => mutationRef.current?.disconnect();
    }, [onContentChange, regionWrapperRef, regionRef, mutationRef]);
    return (React.createElement(YellowBox, { hideShadow: hideShadow },
        React.createElement("div", { className: `${styles.yellowInner} ${expand && styles.yellowInnerFadeOut}` },
            React.createElement(OverviewVertical, { "aria-expanded": expand ? "true" : "false", onClick: doExpand, ref: triggerRef, className: styles.overview })),
        React.createElement("div", { style: { overflow: "hidden", height: 0, opacity: 0, zIndex: 2 }, ref: regionWrapperRef },
            React.createElement("div", { role: "region", className: styles.yellowInner, hidden: expand, ref: regionRef },
                React.createElement(SearchTypeSelect, { ref: searchTypeRef }),
                React.createElement(SegmentVertical, null),
                isMultiStop ? (React.createElement(ShellWrapper, { fill: true },
                    React.createElement(AddSegment, { wide: true, variant: "tertiary" }))) : null,
                React.createElement(OccupancyCabinClassModal, null),
                !isMultiStop ? (React.createElement(ShellWrapper, { size: "large" },
                    React.createElement(DirectFlightSwitch, null))) : null,
                React.createElement(Stack, { direction: "row", gap: 1 },
                    React.createElement(Stack.Item, { grow: true },
                        React.createElement(Button, { wide: true, size: "large", variant: "secondary", text: i18n.trans(t("flights_search_on_sr_cta_cancel")), onClick: () => {
                                onTrackSBExperimentCustomGoal({ name: "flights_web_mdot_sr_full_sb", customGoal: 2 });
                                doCollapse();
                            } })),
                    React.createElement(Stack.Item, { grow: true },
                        React.createElement(Submit, { size: "large", onClick: () => isValid && doCollapse(), wide: true })))))));
}
export default function SearchBoxHorizontalDefault() {
    return (React.createElement(SearchboxController, null,
        React.createElement(SearchBoxVerticalCollapsible, null)));
}
