import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { t } from "@bookingcom/lingojs-core";
import { I18nChildContext, useI18n } from "@bookingcom/lingojs-react";

import { Button, useTheme } from "@bookingcom/bui-react";

import NotAvailableIllustration from "../../../../assets/illustrations/not-available.svg";
import { useScreenNavigation } from "../../../../app/navigation";

import { trackGoal } from "../../../../utils/et";
import Frame from "components/elements/Frame";
import FloatingPanel from "components/atoms/FloatingPanel";
import { KAYAK_ADS } from "../../../../constants";
import useClientMetrics from "hooks/useClientMetrics";

export interface Props {
  showActions?: boolean;
}

export const NoResultsFound: React.FunctionComponent<Props> = (props) => {
  const theme = useTheme();
  const i18n = useI18n() as I18nChildContext;
  const location = useLocation();
  const { trackClientMetric } = useClientMetrics();

  const queryParams = new URLSearchParams(location.search);

  const caSource = queryParams.get("ca_source") || "";
  const { showActions } = props;

  const navigateHome = useScreenNavigation("home");

  useEffect(() => {
    trackGoal("flights_empty_search_results_page_view");
  }, []);

  useEffect(() => {
    if (caSource === KAYAK_ADS) {
      trackClientMetric("kayak_ads_empty_search_results_page_view");
    }
  }, [caSource, trackClientMetric]);

  return (
    <React.Fragment>
      <div>
        <Frame
          attributes={{
            style: {
              ...theme.fonts.small.font_emphasized_2,
              color: theme.colors.color_foreground_alt,
              textAlign: "center"
            }
          }}
          m={4}
        >
          {i18n.trans(t("flights_search_no_results"))}
        </Frame>
        <div style={{ margin: `0 -${theme.units.spacing_4x}` }}>
          <NotAvailableIllustration />
        </div>
      </div>
      {showActions && (
        <FloatingPanel align="bottom" fixed>
          <Frame m={4}>
            <Button onClick={navigateHome} variant="secondary">
              {i18n.trans(t("flights_search_change_search_button"))}
            </Button>
          </Frame>
        </FloatingPanel>
      )}
    </React.Fragment>
  );
};
