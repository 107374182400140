import parse from "date-fns/parse";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import { useMemo } from "react";
// eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
import { useStore } from "../store";

const useBookingWindow = () => {
  const {
    search: { searchSegments },
    flightDetails: { flight }
    // eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
  } = useStore();

  return useMemo(() => {
    if (searchSegments.length && searchSegments[0].departureDate) {
      const departureDate = parse(searchSegments[0].departureDate, "yyyy-MM-dd", new Date());
      return differenceInCalendarDays(departureDate, new Date());
    }

    if (flight?.segments.length && flight.segments[0].departureTime) {
      const departureDate = parse(flight.segments[0].departureTime, "yyyy-MM-dd'T'HH:mm:ss", new Date());
      return differenceInCalendarDays(departureDate, new Date());
    }

    return undefined;
  }, [flight?.segments, searchSegments]);
};

export default useBookingWindow;
