import { State } from "../store/search/state";

//When users navigate to a different flight page (without search params) and if they either reload the page or navigate to a different URL
//we loose the store search info (resets to initial values)
//Its possible that those users comes back to a search page through browser back (or forward) buttons or back button in checkout pages
//Its not straightforward to create the search store in client side.
//see src/server/getDerivedStateFromRequest.ts

//Reload the page in those cases to populate the store.search information from server
export function isSearchStoreInitial(searchState: State) {
  //check for from and to arrays since there are default values for other fields
  //from field has default value populated in production based on geoip
  if (searchState.searchSegments[0]) {
    return searchState.searchSegments[0].from.length == 0 && searchState.searchSegments[0].to.length == 0;
  }
  return false;
}

export default function useReloadIfSearchStoreMissing(searchState: State) {
  if (process.env.BUILD_TARGET !== "client") return;

  if (isSearchStoreInitial(searchState)) {
    window.location.reload();
    return true;
  }
  return false;
}
