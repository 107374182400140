import React, { useCallback } from "react";

import useGlobalContext from "../../hooks/useGlobalContext";
import useEvents from "../../hooks/useEvents";

import TrackerContext, { TrackerEvent } from "./TrackerContext";
import useC360Tracker from "hooks/useC360Tracker";

const debug = require("debug")("trackers");

interface Props {
  children: React.ReactChild;
}

interface Event extends TrackerEvent {
  retries: number;
}

function TrackerProvider(props: Props) {
  const { env } = useGlobalContext();
  const { grumble, jsError } = useEvents();
  const c360Tracker = useC360Tracker();

  const { children } = props;
  const skipTracking = (!env || env === "dev") && !debug.enabled;
  const maxRetries = 3;
  const retryDelay = 1000;

  const isC360Dev = useCallback((eventType: string) => eventType === "c360" && env === "dev", [env]);

  const retry = useCallback(
    (event: Event, cb: Function) => {
      if (event.retries >= maxRetries) {
        grumble("Tracker : failed to report event", event.type);
      } else {
        setTimeout(() => {
          cb({ ...event, retries: event.retries + 1 });
        }, retryDelay);
      }
    },
    [grumble]
  );

  const triggerTrackExternal = useCallback(
    (event: Event) => {
      switch (event.type) {
        case "gtm-price-accuracy":
        case "gtm-skyscanner-confirmation":
        case "gtm-byeah-confirmation":
        case "gtm-byeah-search-results":
        case "gtm-route-change":
          window.dataLayer.push({ ...event.args[0] });
          break;

        case "gtag":
          if (typeof window.gtag === "function") {
            window.gtag(...event.args);
          } else {
            retry(event, triggerTrackExternal);
          }
          break;
        case "RISKX":
          window.riskifiedBeaconLoad(event.data?.sessionId);
          break;
        case "c360":
          void c360Tracker.c360SendEvent(event.args[0]);
          break;
        default:
          jsError(new Error(`Tracker : unhandled event type - ${event.type}`));
          break;
      }
    },
    [c360Tracker, jsError, retry]
  );

  const trackExternal = useCallback(
    (event: TrackerEvent) => {
      if (!skipTracking || isC360Dev(event.type)) {
        try {
          debug("event sent: %o", event);
          triggerTrackExternal({ ...event, retries: 0 });
        } catch (err) {
          debug.error(err);
          jsError(new Error(`Caught exception in TrackerProvider: ${err}`));
        }
      }
    },
    [skipTracking, isC360Dev, triggerTrackExternal, jsError]
  );

  debug("Initialized TrackerProvider");

  return <TrackerContext.Provider value={{ trackExternal }}>{children}</TrackerContext.Provider>;
}

export { TrackerProvider };
