import {
  UISubsidizedFareType,
  UISubsidizedFaresInfo,
  UISubsidizedFaresSummary,
  UISubsidizedFareSirf,
  UISirfMunicipality,
  UISirfMunicipalityCode,
  UISubsidizedFareTravelDocument,
  UISirfTravelDocument,
  UIOrder
} from "@flights/types";

// SIRF = Spanish islands resident fare

export const isSirfAvailable = (subsidizedFares: UISubsidizedFaresInfo | UISubsidizedFaresSummary): boolean => {
  return subsidizedFares.findIndex(({ type }) => type === "SPANISH_RESIDENT_FARE") !== -1;
};

export const isSirfApplied = (subsidizedFareTypes: UISubsidizedFareType[]): boolean => {
  return subsidizedFareTypes.includes("SPANISH_RESIDENT_FARE");
};

export const getSirfInfo = (subsidizedFares: UISubsidizedFaresInfo): UISubsidizedFareSirf | undefined => {
  return subsidizedFares.find(({ type }) => type === "SPANISH_RESIDENT_FARE");
};

export const getPassengerSirfData = (
  subsidizedFareTravelDocuments: UISubsidizedFareTravelDocument[]
): UISirfTravelDocument | undefined => {
  return subsidizedFareTravelDocuments.find(({ subsidizedFareType }) => subsidizedFareType === "SPANISH_RESIDENT_FARE");
};

export const getMunicipalityData = (
  municipalityCode: UISirfMunicipalityCode,
  municipalities: UISirfMunicipality[]
): UISirfMunicipality | undefined => {
  return municipalities.find(({ code }) => code === municipalityCode);
};

export const areAllPassengerSirfDocsVerified = (order: UIOrder): boolean => {
  const { passengers } = order;

  const areAllVerified = passengers.every(({ subsidizedFareTravelDocuments }) => {
    const sirfDoc = subsidizedFareTravelDocuments?.find(
      ({ subsidizedFareType }) => subsidizedFareType === "SPANISH_RESIDENT_FARE"
    );

    return Boolean(sirfDoc && sirfDoc.status === "VERIFIED");
  });

  return areAllVerified;
};
