/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Chip, SheetContainer, SheetContainerProps, Stack } from "@bookingcom/bui-react";
import Sort from "../Input/Sort";
import useSearchFiltersController from "../useSearchFiltersController";
import { useI18n, t } from "@bookingcom/lingojs-react";
import Frame from "components/elements/Frame";

export type DurationModalProps = {
  position: SheetContainerProps["position"];
};

const SortModalComp = (props: DurationModalProps) => {
  const { sort } = useSearchFiltersController();
  const i18n = useI18n();
  const [active, setActive] = useState(false);
  const [shouldApply, setShouldApply] = useState(false);
  const [draftValue, setDraftValue] = useState(sort.value);

  const handleOnClose = useCallback(() => {
    setActive(false);
    setDraftValue(sort.value);
  }, [sort.value]);

  const handleOnChange = useCallback((value: typeof draftValue) => {
    setDraftValue(value);
  }, []);

  const handleOnApply = useCallback(() => {
    sort.set(draftValue);
    setActive(false);
    setShouldApply(true);
  }, [sort, draftValue]);

  const handleOnReset = useCallback(() => {
    sort.reset();
    setActive(false);
    setShouldApply(true);
  }, [sort]);

  useEffect(() => {
    if (shouldApply) {
      setShouldApply(false);
      setDraftValue(sort.value);
    }
  }, [shouldApply, sort]);

  const currentLabel = sort.options.filter((s) => s.value === sort.value)[0].label;

  const label = useMemo(() => {
    return i18n.trans(t("flights_sort_by_name", { variables: { sort_name: currentLabel } }));
  }, [i18n, currentLabel]);

  return (
    <>
      <Chip variant="action" checked={true} onClick={() => setActive(true)} label={label} />
      <SheetContainer
        active={active}
        onClose={handleOnClose}
        onAfterClose={handleOnClose}
        onCloseTrigger={handleOnClose}
        closeAriaLabel={i18n.trans(t("close"))}
        title={i18n.trans(t("flights_filter_sort"))}
        keepMounted={false}
        position={props.position}
        footer={
          <Frame mr={4} mb={4} ml={4}>
            <Stack gap={4} direction="row">
              <Stack.Item grow>
                <Button wide onClick={handleOnReset} disabled={!sort.touched} variant="secondary" size="large">
                  {i18n.trans(t("flights_filter_reset"))}
                </Button>
              </Stack.Item>
              <Stack.Item grow>
                <Button wide onClick={handleOnApply} size="large">
                  {i18n.trans(t("flights_mdot_filters_apply_cta"))}
                </Button>
              </Stack.Item>
            </Stack>
          </Frame>
        }
      >
        <Sort options={sort.options} value={draftValue} onChange={handleOnChange} />
      </SheetContainer>
    </>
  );
};

export default function DurationModal({ position }: DurationModalProps) {
  const { sort } = useSearchFiltersController();
  if (!sort.options.length) return null;
  return <SortModalComp position={position} />;
}
