/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { FC, ReactNode, useEffect, useMemo } from "react";

import { Alert, Text, Title, useTheme } from "@bookingcom/bui-react";
import usePrevious from "@bookingcom/bui-react/hooks/usePrevious";
import { t } from "@bookingcom/lingojs-core";
import { I18nChildContext, useI18n } from "@bookingcom/lingojs-react";

import {
  UIFlightData,
  UIOrderExtras,
  UIOrderFuckedUpProductType,
  UIOrderSeatMapSelection,
  UIPassenger,
  UIPriceBreakdown,
  UISubsidizedFareType,
  UITraveller,
  UITravellerPrice,
  UIPrice,
  UICarrierTaxPerTravellerBreakdown,
  UIAirOrder,
  UIBcomPricingItem
} from "@flights/types";
import { ancillaryNames } from "../../../../utils/ancillaryNames";
import useFormatPrice from "hooks/useFormatPrice";
import useGlobalContext from "hooks/useGlobalContext";
import { isSirfApplied } from "../../../../store/selectors/sirf";
import BcomMargin from "./BcomMargin";
import TaxBreakDownList from "./TaxBreakDownList";
import isHungaryDepartingRyanAirFlight from "utils/hungaryTaxes";
// eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
import { useActions } from "../../../../store";
import { actions as AriaLiveActions } from "store/ariaLive/actions";
import { MpProduct } from "@bookingcom/mp-flights";
import useInsuranceServerSide from "../../../../hooks/useInsuranceServerSide";
import styles from "./BreakdownList.module.css";
import { isFeatureRunningClientSide } from "utils/features";
import { useTicketPriceCopy } from "utils/useTicketPriceCopy";
import { pricingUtils } from "@flights/web-api-utils-universal";

export function getPriceBreakdowns(travellerPrices: UITravellerPrice[] | UITravellerPrice): UIPriceBreakdown[] {
  if (Array.isArray(travellerPrices))
    return travellerPrices.map((travellerPrice) => travellerPrice.travellerPriceBreakdown);
  else return [travellerPrices.travellerPriceBreakdown];
}

export type Props = {
  showFlightDetails: boolean;
  showAncillariesDetails: boolean;
  showTitle: boolean;
  totalPrice: UIPriceBreakdown;
  travellers?: UIPassenger[] | UITraveller[];
  travellerPrices?: UITravellerPrice[];
  ancillaries?: UIOrderExtras;
  appliedSubsidizedFares?: UISubsidizedFareType[];
  priceTaxBreakdown?: any;
  flightData?: UIFlightData;
  instalmentsFees?: UIPrice;
  totalTagName?: string;
  orderData?: UIAirOrder;
  multiProducts?: MpProduct[];
  isRynaAirHungaryFlight?: boolean;
  isBrandedFare?: boolean;
  isOrder?: boolean;
};

const BreakdownList: FC<Props> = ({
  showFlightDetails,
  showAncillariesDetails,
  showTitle,
  totalPrice,
  travellers,
  travellerPrices,
  ancillaries,
  appliedSubsidizedFares = [],
  priceTaxBreakdown,
  flightData,
  totalTagName = "h2",
  multiProducts = [],
  isRynaAirHungaryFlight,
  isBrandedFare,
  isOrder
}) => {
  const theme = useTheme();
  const i18n = useI18n() as I18nChildContext;
  const ticketPriceCopy = useTicketPriceCopy(i18n, travellers);
  // eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
  const ariaLiveActions = useActions(AriaLiveActions);
  const { formatPrice } = useFormatPrice();

  const currentTotalPrice = formatPrice(totalPrice.totalWithInstalments || totalPrice.total);
  const previousTotalPrice = usePrevious(currentTotalPrice);
  const { travelInsuranceContent } = useInsuranceServerSide(isOrder);

  const isPreventSummingBcomPricingItemsEnabled = isFeatureRunningClientSide(
    "FLIGHTS_PRICING_ENABLE_PREVENT_SUMMING_BCOMPRICINGITEM_IN_PRICE_BREAKDOWN"
  );
  const bcomMargin =
    isPreventSummingBcomPricingItemsEnabled && !isBrandedFare
      ? flightData?.priceBreakdown.bcomMargin
      : totalPrice.bcomMargin;
  const bcomPricingItems =
    isPreventSummingBcomPricingItemsEnabled && !isBrandedFare
      ? flightData?.priceBreakdown.bcomPricingItems
      : totalPrice.bcomPricingItems;

  // Announce total price changes to screen readers
  useEffect(() => {
    if (previousTotalPrice !== currentTotalPrice) {
      ariaLiveActions.setMessage({
        message: i18n.trans(
          t("a11y_flights_aria_live_checkout_new_total_price", {
            variables: { price: currentTotalPrice }
          })
        ),
        type: "polite"
      });
    }
  }, [ariaLiveActions, currentTotalPrice, i18n, previousTotalPrice]);

  return (
    <>
      {showTitle && (
        <div style={{ paddingBottom: theme.units.spacing_2x }}>
          <Text variant="headline_3" tagName="h2">
            {i18n.trans(t("flights_price_breakdown_payment"))}
          </Text>
        </div>
      )}
      <div
        className={styles.root}
        style={{
          color: theme.colors.color_foreground
        }}
        role="table"
      >
        <BreakDownListItem
          title={ticketPriceCopy}
          instalmentsFees={totalPrice.instalmentsFees}
          priceBreakdown={
            travellerPrices && travellerPrices.length > 0
              ? pricingUtils.sumPriceBreakdown(getPriceBreakdowns(travellerPrices))
              : totalPrice
          }
          showDetails={showFlightDetails}
          dataTestId="price_breakdown_details"
          flightData={flightData}
          bcomPricingItems={bcomPricingItems}
          type={"flight_fare"}
        />
        {priceTaxBreakdown && <TaxBreakDownList priceTaxBreakdown={priceTaxBreakdown} />}
        {!!bcomMargin && !bcomPricingItems && <BcomMargin bcomMargin={bcomMargin} />}
        {ancillaries &&
          Object.keys(ancillaries).map((productType: UIOrderFuckedUpProductType, index) => {
            const product: UIOrderExtras[typeof productType] = ancillaries[productType];

            if (product && "price" in product && product.price) {
              let productTranslation;

              switch (productType) {
                case "seatMapSelection":
                  const p = product as UIOrderSeatMapSelection;
                  productTranslation = ancillaryNames(productType, {
                    num_exception: p.seats.length ? p.seats.length : 1
                  });
                  break;
                default:
                  productTranslation = ancillaryNames(productType);
              }

              let productName = productTranslation ? i18n.trans(productTranslation) : productType;

              if (productType === "travelInsurance" && travelInsuranceContent) {
                productName = travelInsuranceContent?.header || "";
              }

              const ancillaryBcomPricingItems = isPreventSummingBcomPricingItemsEnabled
                ? product.price.bcomPricingItems
                : undefined;

              return (
                <BreakDownListItem
                  title={productName}
                  priceBreakdown={product.price}
                  key={index}
                  showDetails={showAncillariesDetails}
                  dataTestId={`price_breakdown_ancillaries_${productType}`}
                  flightData={flightData}
                  bcomPricingItems={ancillaryBcomPricingItems}
                />
              );
            }
            return null;
          })}
        {
          // #############################
          // ### START - MultiProducts ###
          // #############################
        }
        {multiProducts.map((item) => {
          switch (item.productType) {
            case "INSURANCE": {
              const productTranslation = t("flights_ancillary_travelinsurance_name");
              const productName = i18n.trans(productTranslation);
              const productTotalPrice = item.priceBreakdown;
              return (
                <BreakDownListItem
                  title={productName}
                  priceBreakdown={productTotalPrice}
                  key="INSURANCE"
                  showDetails={showAncillariesDetails}
                  dataTestId="mp_travel_insurance_stti"
                />
              );
            }
            default: {
              return null;
            }
          }
        })}
        {
          // #############################
          // ### END - MultiProducts ###
          // #############################
        }
        <div
          data-testid="breakdown_list_footer"
          className={styles.listItem}
          style={{
            paddingTop: theme.units.spacing_4x,
            paddingBottom: theme.units.spacing_2x
          }}
          role="row"
        >
          <div data-testid="breakdown_list_text" role="cell">
            <Title title={i18n.trans(t("flights_total_price"))} variant="headline_3" titleTagName={totalTagName} />
            {!isRynaAirHungaryFlight && (
              <div style={{ color: theme.colors.color_foreground_alt }}>
                <Text attributes={{ "data-testid": "breakdown_list_small" }} variant="body_2">
                  {i18n.trans(t("flights_price_breakdown_taxes_inc"))}
                </Text>
              </div>
            )}

            {isSirfApplied(appliedSubsidizedFares) && (
              <Alert
                text={i18n.trans(t("flights_spanish_residency_discount_applied"))}
                variant="success"
                inline
                className={styles.discountAlert}
              />
            )}
          </div>

          <Text
            variant="headline_3"
            className={styles.listItemMoney}
            attributes={{
              "data-testid": "breakdown_list_price",
              role: "cell"
            }}
          >
            {/* see usePaymentComponentIframeEvents */}
            {currentTotalPrice}
          </Text>
        </div>
      </div>
    </>
  );
};

type BreakDownListItemProps = {
  priceBreakdown: UIPriceBreakdown;
  title?: string;
  showDetails?: boolean;
  comment?: ReactNode;
  dataTestId?: string;
  instalmentsFees?: UIPrice;
  flightData?: UIFlightData;
  orderData?: UIAirOrder;
  carrierTax?: UICarrierTaxPerTravellerBreakdown[];
  bcomPricingItems?: UIBcomPricingItem[];
  type?: "insurance_addon" | "flight_fare";
};

export const BreakDownListItem: FC<BreakDownListItemProps> = ({
  priceBreakdown,
  title,
  showDetails,
  comment,
  dataTestId,
  instalmentsFees,
  flightData,
  carrierTax,
  orderData,
  bcomPricingItems,
  type
}) => {
  const i18n = useI18n() as I18nChildContext;
  const theme = useTheme();
  const { ipCountry } = useGlobalContext();
  const { formatPrice } = useFormatPrice();

  const offerPointOfSale = flightData && flightData.pointOfSale ? flightData.pointOfSale : ipCountry;

  const isCarrierTaxEqualTax = useMemo(() => {
    /**
     * ==========================================
     * if the carrier taxes is equal to taxes
     * we will only display carrier taxs.
     * to avoid repetition as both will have the same value
     * ==========================================
     */
    if (carrierTax?.length !== 1) return false;
    const { avgPerTraveller: avg } = carrierTax[0];
    const tax = priceBreakdown.tax;
    if (!avg || !tax) return false;
    if (avg.nanos !== tax.nanos || avg.units !== tax.units) return false;
    return true;
  }, [carrierTax, priceBreakdown.tax]);

  if (priceBreakdown.total.units <= 0) {
    return null;
  }

  const segments = flightData?.segments || orderData?.flightSegments;
  const getTaxesAndChargesCopy = () => {
    if (type === "insurance_addon") {
      return i18n.trans(t("flights_insurance_price_breakdown_tax"));
    } else if (segments && isHungaryDepartingRyanAirFlight(segments)) {
      return i18n.trans(t("flights_ryanair_hungary_price_breakdown_tax"));
    } else {
      return offerPointOfSale === "us"
        ? i18n.trans(t("flights_price_breakdown_airline_fees"))
        : i18n.trans(t("flights_price_breakdown_airport_taxes"));
    }
  };

  const getBasePriceCopy = () => {
    if (type === "insurance_addon") {
      return i18n.trans(t("flights_insurance_price_breakdown"));
    } else if (type === "flight_fare") {
      return i18n.trans(t("flights_price_breakdown_flight_fare"));
    } else {
      return i18n.trans(t("flights_price_breakdown_base"));
    }
  };

  return (
    <div data-testid={dataTestId}>
      {title && (
        <div
          className={styles.listItem}
          style={{
            paddingTop: theme.units.spacing_1x,
            paddingBottom: theme.units.spacing_1x
          }}
          role="row"
        >
          <Text variant="strong_2" attributes={{ "data-testid": "breakdown_list_row_one_column_one", role: "cell" }}>
            {title}
          </Text>
          <Text
            variant="strong_2"
            attributes={{ "data-testid": "breakdown_list_row_one_column_two", role: "cell" }}
            className={styles.listItemMoney}
          >
            {formatPrice(priceBreakdown.total)}
          </Text>
        </div>
      )}
      {showDetails && (
        <div className={styles.listItemDetails}>
          <div
            className={styles.listItem}
            style={{
              paddingBottom: theme.units.spacing_1x
            }}
            role="row"
          >
            <Text
              variant="emphasized_2"
              className={styles.textColorHeader}
              attributes={{ "data-testid": "breakdown_list_row_two_column_one", role: "cell" }}
            >
              {getBasePriceCopy()}
            </Text>
            <Text
              variant="emphasized_2"
              className={styles.listItemMoney}
              attributes={{ "data-testid": "breakdown_list_row_two_column_two", role: "cell" }}
            >
              {formatPrice(priceBreakdown.baseFare)}
            </Text>
          </div>

          {priceBreakdown.fee && (priceBreakdown.fee.units !== 0 || priceBreakdown.fee.nanos !== 0) && (
            <div className={styles.listItem} style={{ paddingBottom: theme.units.spacing_1x }} role="row">
              {priceBreakdown.fee.units > 0 || priceBreakdown.fee.nanos > 0 ? (
                <Text
                  className={styles.textColorHeader}
                  variant="emphasized_2"
                  attributes={{ role: "cell", "data-testid": "breakdown_list_third_party_fee" }}
                >
                  {i18n.trans(t("flights_third_party_fee"))}
                </Text>
              ) : (
                <Text
                  className={styles.textColorHeader}
                  variant="emphasized_2"
                  attributes={{ role: "cell", "data-testid": "breakdown_list_discount" }}
                >
                  {i18n.trans(t("flights_price_breakdown_discount"))}
                </Text>
              )}
              <Text variant="emphasized_2" className={styles.listItemMoney} attributes={{ role: "cell" }}>
                {formatPrice(priceBreakdown.fee)}
              </Text>
            </div>
          )}

          {isCarrierTaxEqualTax ? null : (
            <div className={styles.listItem} style={{ paddingBottom: theme.units.spacing_1x }} role="row">
              <Text
                variant="emphasized_2"
                className={styles.textColorHeader}
                attributes={{ "data-testid": "breakdown_list_row_three_column_one", role: "cell" }}
              >
                {getTaxesAndChargesCopy()}
              </Text>
              <Text
                variant="emphasized_2"
                className={styles.listItemMoney}
                attributes={{ "data-testid": "breakdown_list_row_three_column_two", role: "cell" }}
              >
                {formatPrice(priceBreakdown.tax)}
              </Text>
            </div>
          )}

          {carrierTax?.length ? (
            <div style={{ paddingBottom: theme.units.spacing_1x }}>
              {carrierTax.map(({ carrier, avgPerTraveller }) => {
                return carrier && avgPerTraveller ? (
                  <div key={carrier?.code} className={styles.listItem}>
                    <div className={styles.textColorHeader}>
                      <Text
                        variant={isCarrierTaxEqualTax ? "emphasized_2" : "small_1"}
                        attributes={{ role: "cell" }}
                        color={isCarrierTaxEqualTax ? undefined : "neutral_alt"}
                        tagName="span"
                      >
                        {i18n.trans(
                          t("flights_price_breakdown_airline_charges", {
                            variables: {
                              airline_name: carrier.name || carrier.code
                            }
                          })
                        )}
                      </Text>
                    </div>
                    <div className={styles.listItemMoney}>
                      <Text
                        variant={isCarrierTaxEqualTax ? "emphasized_2" : "small_1"}
                        attributes={{ role: "cell" }}
                        color={isCarrierTaxEqualTax ? undefined : "neutral_alt"}
                        tagName="span"
                      >
                        {formatPrice(avgPerTraveller)}
                      </Text>
                    </div>
                  </div>
                ) : null;
              })}
            </div>
          ) : null}

          {!!instalmentsFees ? (
            <div className={styles.listItem} style={{ paddingBottom: theme.units.spacing_1x }} role="row">
              <Text
                variant="emphasized_2"
                className={styles.textColorHeader}
                attributes={{ "data-testid": "breakdown_list_row_four_column_one", role: "cell" }}
              >
                {i18n.trans(t("flights_price_breakdown_instalment_label"))}
              </Text>
              <Text
                variant="emphasized_2"
                className={styles.listItemMoney}
                attributes={{ "data-testid": "breakdown_list_row_three_column_two", role: "cell" }}
              >
                {formatPrice(instalmentsFees)}
              </Text>
            </div>
          ) : null}

          {bcomPricingItems &&
            bcomPricingItems.length > 0 &&
            bcomPricingItems.map((bcomPricingItem, index) => (
              <div
                key={`kBcomPricingItems_${index}`}
                className={styles.listItem}
                style={{ paddingBottom: theme.units.spacing_1x }}
                role="row"
              >
                <Text
                  variant="emphasized_2"
                  className={styles.textColorHeader}
                  attributes={{
                    "data-testid": `breakdown_list_bcompricingitems_row_${index}_column_one`,
                    role: "cell"
                  }}
                >
                  {bcomPricingItem.name}
                </Text>
                <Text
                  variant="emphasized_2"
                  className={styles.listItemMoney}
                  attributes={{
                    "data-testid": `breakdown_list_bcompricingitems_row_${index}_column_two`,
                    role: "cell"
                  }}
                >
                  {formatPrice(bcomPricingItem.amount)}
                </Text>
              </div>
            ))}

          {!!priceBreakdown.discount && (priceBreakdown.discount.units > 0 || priceBreakdown.discount.nanos > 0) && (
            <div className={styles.listItem} style={{ paddingBottom: theme.units.spacing_1x }} role="row">
              <Text variant="emphasized_2" className={styles.textColorHeader}>
                {i18n.trans(t("flights_cug_price_breakdown"))}
              </Text>
              <Text
                variant="emphasized_2"
                className={styles.listItemMoney}
                attributes={{ "data-testid": "breakdown_bcom_margin" }}
              >
                {formatPrice(priceBreakdown.discount, {
                  isDiscount: true
                })}
              </Text>
            </div>
          )}
        </div>
      )}

      {comment && (
        <Text variant="small_1" className={styles.listItemComment}>
          {comment}
        </Text>
      )}
    </div>
  );
};

export default BreakdownList;
