import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { UIAriaLive } from "@flights/types";
import styles from "./AriaLive.module.css";
import { getAriaLive } from "store/ariaLive/selectors";

/**
 * This component adds a sr-only element in the DOM with the aria-live attribute. Initially empty.
 * More on aria-live {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-live#choosing_the_aria-live_value}
 *
 * When you dispatch an action with a message, the screen reader will read it with priority.
 *
 * Example - Set new message
 * ariaLiveActions.setMessage({
 *   message: i18n.trans(t("flights_search_results_loading")),
 *   type: "assertive"
 * });
 *
 * Example - Reset to initial state
 * ariaLiveActions.reset();
 */
const AriaLive = () => {
  const [ariaType, setAriaType] = useState<UIAriaLive["type"]>(undefined);
  const [ariaMessage, setAriaMessage] = useState<UIAriaLive["message"] | undefined>(undefined);
  const ariaLive = useSelector(getAriaLive);

  useEffect(() => {
    setAriaType(ariaLive.type);
    if (ariaLive.message) setAriaMessage(ariaLive.message);
  }, [ariaLive]);

  return (
    <p aria-live={ariaType} className={styles.message}>
      {ariaMessage}
    </p>
  );
};
export default AriaLive;
