import React from "react";
import Frame from "components/elements/Frame";
import { Text } from "@bookingcom/bui-react";

type Props = { children: React.ReactNode };

const FareFeatureHeading = ({ children }: Props) => (
  <Frame mb={2}>
    <Text variant="small_1" color="neutral_alt">
      {children}
    </Text>
  </Frame>
);

export default FareFeatureHeading;
