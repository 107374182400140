import React, { useCallback } from "react";
import useCabinClass from "./useCabinClass.js";
import { InputSelect } from "@bookingcom/bui-react";
export default function CabinClassSelect() {
    const { cabinClass, cabinClassOptions, setCabinClass, cabinClassTitle } = useCabinClass();
    const handleOnChange = useCallback((name) => {
        setCabinClass(name);
    }, [setCabinClass]);
    return (React.createElement(InputSelect, { value: cabinClass, name: "sr_cabin_class", "data-ui-name": "cabin_class_input", label: cabinClassTitle, options: cabinClassOptions, onChange: ({ event }) => {
            if (!event)
                return;
            handleOnChange(event.target.value);
        } }));
}
