import React, { useMemo } from "react";
import { SortIcon } from "@bookingcom/bui-assets-react/streamline/index.js";
import { DropdownMenu, Chip, Text, ChipProps } from "@bookingcom/bui-react";
import useSearchFiltersController from "../useSearchFiltersController";
import { useI18n, t } from "@bookingcom/lingojs-react";
import { DESKTOP_SIDEBAR_WIDTH } from "../../../../constants";

export default function SortDropDown() {
  const i18n = useI18n();
  const { sort } = useSearchFiltersController();
  const currentLabel = sort.options.filter((s) => s.value === sort.value)[0].label;

  const label = useMemo(() => {
    return i18n.trans(t("flights_sort_by_name", { variables: { sort_name: currentLabel } }));
  }, [i18n, currentLabel]);

  return (
    <DropdownMenu
      disableAnimation
      attributes={{ style: { minWidth: DESKTOP_SIDEBAR_WIDTH, maxWidth: DESKTOP_SIDEBAR_WIDTH } }}
      sections={sort.options.map((option) => ({
        items: [
          {
            text: (
              <>
                <Text variant="body_1">{option.label}</Text>
                <Text color="neutral_alt" variant="body_2">
                  {option.description}
                </Text>
              </>
            ),
            value: option.value,
            onChoose: () => sort.set(option.value)
          }
        ]
      }))}
    >
      {(attributes) => (
        <Chip
          icon={SortIcon}
          label={label}
          attributes={attributes}
          onClick={attributes.onClick as ChipProps["onClick"]}
          variant="action"
          checked={true}
        />
      )}
    </DropdownMenu>
  );
}
