import { useMemo } from "react";
import { I18nChildContext, t } from "@bookingcom/lingojs-react";
import { UIPassenger, UIPassengerType, UITraveller } from "@flights/types";

function getPassengerCount(type: UIPassengerType, travellers: UIPassenger[] | UITraveller[]): number {
  const travellersByType = travellers.filter((traveller) => {
    return traveller.type === type;
  });
  return travellersByType.length;
}

export const useTicketPriceCopy = (
  i18n: I18nChildContext,
  travellers?: UIPassenger[] | UITraveller[],
  isRebookCost?: boolean
) => {
  return useMemo(() => {
    if (!travellers) {
      return undefined;
    }

    const numberOfAdults = getPassengerCount("ADULT", travellers);
    const numberOfChildren = getPassengerCount("KID", travellers);

    const adultNumberCopy = i18n.trans(
      t("flights_adult_number", {
        variables: {
          num_adults: numberOfAdults
        },
        num_exception: numberOfAdults
      })
    );

    if (numberOfChildren > 0) {
      const childNumberCopy = i18n.trans(
        t("flights_children_number", {
          variables: {
            num_children: numberOfChildren
          },
          num_exception: numberOfChildren
        })
      );

      return isRebookCost
        ? i18n.trans(
            t("flights_pb_rebook_cost_breakdown_all_pax", {
              variables: {
                adults: adultNumberCopy,
                children: childNumberCopy
              },
              num_exception: numberOfChildren + numberOfAdults
            })
          )
        : i18n.trans(
            t("flights_price_breakdown_all_pax_number", {
              variables: {
                adults: adultNumberCopy,
                children: childNumberCopy
              },
              num_exception: numberOfChildren + numberOfAdults
            })
          );
    }
    return isRebookCost
      ? i18n.trans(
          t("flights_pb_rebook_cost_breakdown_adults_only", {
            variables: {
              adults: adultNumberCopy
            },
            num_exception: numberOfAdults
          })
        )
      : i18n.trans(
          t("flights_price_breakdown_adults_number", {
            variables: {
              adults: adultNumberCopy
            },
            num_exception: numberOfAdults
          })
        );
  }, [i18n, travellers, isRebookCost]);
};
