import React from "react";
import { Icon, Stack } from "@bookingcom/bui-react";

type Props = {
  icon: React.ComponentType;
  content: string;
  isIncluded?: boolean;
};

const FeatureListItem = ({ icon, content, isIncluded }: Props) => (
  <Stack direction="row" alignItems="center" wrap="nowrap" gap={4}>
    <Icon svg={icon} size="small" color={isIncluded ? "constructive" : undefined} />
    <Stack.Item grow>{content}</Stack.Item>
  </Stack>
);

export default FeatureListItem;
