import React, { useCallback, useEffect, useMemo, useState, forwardRef } from "react";
import { Icon } from "@bookingcom/bui-react";
import { ArrowNavDownIcon } from "@bookingcom/bui-assets-react/streamline/index.js";
import styles from "./Select.module.css";
const SelectwithRef = forwardRef(function Select(props, ref) {
    const { icon, wide, options, value, canFocus = true, isFocus = false, onFocus, onBlur, ...rest } = props;
    const [focus, setFocus] = useState(isFocus);
    const text = options.filter((c) => c.value === value)[0].text;
    const handleOnFocus = useCallback((e) => {
        if (canFocus)
            setFocus(true);
        if (onFocus)
            onFocus(e);
    }, [onFocus, canFocus]);
    const handleOnBlur = useCallback((e) => {
        if (canFocus)
            setFocus(false);
        if (onBlur)
            onBlur(e);
    }, [onBlur, canFocus]);
    useEffect(() => {
        setFocus(isFocus);
    }, [isFocus]);
    const wrapperClass = useMemo(() => {
        return [styles.wrapper, focus && styles.wrapperFocus, wide && styles.wrapperWide].filter(Boolean).join(" ");
    }, [focus, wide]);
    return (React.createElement("div", { className: wrapperClass },
        React.createElement("select", { ref: ref, onFocus: handleOnFocus, onBlur: handleOnBlur, className: styles.select, value: value, ...rest }, options.map((option) => (React.createElement("option", { key: String(option.value), ...option }, option.text)))),
        icon ? React.createElement(Icon, { color: "neutral_alt", className: styles.customIcon, size: "medium", svg: icon }) : null,
        React.createElement("div", { "aria-hidden": "true", className: styles.text }, text),
        React.createElement("div", { "aria-hidden": "true", className: styles.icon },
            React.createElement(Icon, { size: "medium", svg: ArrowNavDownIcon }))));
});
export default SelectwithRef;
