import parse from "date-fns/parse";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import { useStore } from "store";
import { useMemo } from "react";

const useBookingWindowFromSearch = () => {
  const {
    search: { searchSegments }
  } = useStore();

  return useMemo(() => {
    if (!searchSegments.length || !searchSegments[0].departureDate) {
      return undefined;
    }

    const departureDate = parse(searchSegments[0].departureDate, "yyyy-MM-dd", new Date());
    return differenceInCalendarDays(departureDate, new Date());
  }, [searchSegments]);
};

export default useBookingWindowFromSearch;
