const expiration = 1000 * 60 * 2; // 2 Minutes
const limit = 20;
const fareCalendarCache = () => {
    const cachedData = new Map();
    return {
        get: (key) => {
            const cacheItem = cachedData.get(key);
            if (cacheItem) {
                if (new Date().getTime() - cacheItem.createdAt.getTime() > expiration) {
                    cachedData.delete(key);
                    return undefined;
                }
                return cacheItem.data;
            }
            return undefined;
        },
        add: (key, data) => {
            if (cachedData.size >= limit) {
                cachedData.clear();
            }
            cachedData.set(key, { createdAt: new Date(), data });
        }
    };
};
export default fareCalendarCache;
