import { useSelector } from "react-redux";
import { getOrder } from "store/order/selectors";
import { getRebookPolicy } from "store/rebookPolicy/selectors";
import { isPosIncludedInCancellationOptions } from "utils/isPosIncludedInCancellationOptions";
import { getOrderUtils } from "utils/getOrderUtils";

export const useIsPriceReviewAvailable = () => {
  const order = useSelector(getOrder);
  const rebookPolicy = useSelector(getRebookPolicy);

  if (!order) {
    return false;
  }

  const { isOneWay, isRoundTrip } = getOrderUtils(order);

  // price review uses same POS restrictions as cancellation options
  const priceReviewAvailableAtPos = isPosIncludedInCancellationOptions();

  const rebookPriceReviewAvailableFlow =
    rebookPolicy?.rebookEligibilityInfo.rebookFlow === "CHANGE_TRIP_YOURSELF_ANCILLARIES" ||
    rebookPolicy?.rebookEligibilityInfo.rebookFlow === "CHANGE_TRIP_YOURSELF_FLEXIBLE_TICKET_TRIP" ||
    rebookPolicy?.rebookEligibilityInfo.rebookFlow === "CHANGE_TRIP_YOURSELF";

  return (isOneWay || isRoundTrip) && rebookPriceReviewAvailableFlow && priceReviewAvailableAtPos;
};
