import React, { useEffect } from "react";
import { RemoteFooter } from "components/remote";
import { STICKY_FOOTER_SPACER_ID } from "../../../constants";
import scrollToTop from "utils/scrollToTop";
import styles from "./AppShell.desktop.module.css";
import { mcn } from "utils/mergeClassnames";

type Props = {
  header?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  children?: React.ReactNode;
  noMainWrapper?: boolean;
};

const AppShell: React.FunctionComponent<Props> = (props) => {
  const { header, children, noMainWrapper } = props;

  useEffect(scrollToTop, []);

  const content = (
    <div className={mcn(styles.content, props.className)} style={{ ...props.style }}>
      {children}
    </div>
  );
  return (
    <div className={styles.root}>
      {!!header && <div id="app-header">{header}</div>}
      {noMainWrapper ? (
        content
      ) : (
        <main role="main" id="basiclayout" key="app-shell">
          {content}
        </main>
      )}

      {/* This spacer will create space for a sticky footer, it receives its height from the useStickyFooterSpacerHeight hook */}
      <div id={STICKY_FOOTER_SPACER_ID} aria-hidden />
      <RemoteFooter />
    </div>
  );
};
export default AppShell;
