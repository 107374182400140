/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React from "react";
// eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
import { useStore } from "../../../store";
import { Stack } from "@bookingcom/bui-react";
import DurationFilter from "./Filters/DurationFilter";
import StopsFilter from "./Filters/StopsFilter";
import AirlinesFilter from "./Filters/AirlinesFilter";
import FlightTimesFilter from "./Filters/FlightTimesFilter";
import BudgetPerAdultFilter from "./Filters/BudgetPerAdultFilter";
import styles from "./SearchFilters.module.css";
import { useTrackTimeFilterStages } from "utils/experiments/funnel/flights_web_flight_time_filter";
import { useSelectorAppliedFilters } from "store/selectors/appliedFilters";
import PopularFilters from "./Filters/PopularFilters";

export type FlightType = "departs" | "arrives";

const SearchFilters: React.FunctionComponent = () => {
  // eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
  const store = useStore();
  const appliedFilters = useSelectorAppliedFilters(store);
  const { availableFilters = {} } = store.searchResults;
  useTrackTimeFilterStages(appliedFilters.length);

  return (
    <div className={styles.contentWrapper}>
      {Object.keys(availableFilters).length > 0 ? (
        <Stack gap={8}>
          <PopularFilters />
          <StopsFilter />
          <BudgetPerAdultFilter />
          <AirlinesFilter />
          <FlightTimesFilter />
          <DurationFilter />
        </Stack>
      ) : null}
    </div>
  );
};
export default SearchFilters;
