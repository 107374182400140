import React from "react";
import { t, useI18n } from "@bookingcom/lingojs-react";
import useUserAgent from "../../../hooks/useUserAgent";
import Frame from "../Frame";
import styles from "./OrderDetailsCard.module.css";
import { mcn } from "utils/mergeClassnames";

interface Props {
  heading?: React.ReactElement;
  children: React.ReactChild | React.ReactChild[];
  tail?: React.ReactElement;
  direction?: "row" | "col";
}

function OrderDetailsCard(props: Props) {
  const i18n = useI18n();
  const { isMobile } = useUserAgent();
  const { direction = isMobile ? "col" : "row" } = props;

  return (
    <div className={mcn(styles.root, styles[direction])}>
      <div className={styles.content}>
        <Frame alignItems="center" justifyContent="center" mr={4} className={styles.iconWrapper}>
          {props.heading}
        </Frame>
        <div>{props.children}</div>
      </div>
      {props.tail && (
        <div
          aria-label={i18n.trans(t("a11y_flights_pb_view_details_action"))}
          className={mcn(direction === "col" && styles.tail, styles.noWrap)}
        >
          {props.tail}
        </div>
      )}
    </div>
  );
}

export default OrderDetailsCard;
