import { BrandedFareClientFeature } from "@flights/types";
import { createTrackingUtils } from "..";
import { RouteName } from "app/routes";

const utils = createTrackingUtils({
  name: "flights_apex_web_sellable_features_v3",
  stages: {
    affected_users: 1,
    main: 1,
    desktop: 3,
    mdot: 4,
    bf_page: 5,
    sellable_baggage: 6,
    sellable_seats: 7,
    sellable_baggage_or_seats: 8,
    sr_bf_hover: 9
  },
  defaultStage: 1,
  goals: {
    change_to_higher: 1,
    change_to_lower: 2,
    higher_on_sr: 3,
    next_visible_bf: 4
  }
});

type Context = {
  features: BrandedFareClientFeature[];
  isMobile: boolean;
  routeName: RouteName;
};

export const trackStages = (ctx: Context) => {
  utils.stages.main();

  if (ctx.isMobile) utils.stages.mdot();
  if (!ctx.isMobile) utils.stages.desktop();

  const hasSellableBaggage = ctx.features.some((f) => f.availability === "SELLABLE" && f.group === "BAGGAGE");
  const hasSellableSeats = ctx.features.some((f) => f.availability === "SELLABLE" && f.group === "ON_THE_FLIGHT");
  const hasSellableBaggageOrSeats = hasSellableBaggage || hasSellableSeats;

  if (ctx.routeName === "searchresults") {
    utils.stages.sr_bf_hover();
    if (hasSellableBaggageOrSeats) utils.stages.sellable_baggage_or_seats();
  }

  if (ctx.routeName === "checkout-fare") {
    utils.stages.bf_page();
    if (hasSellableBaggageOrSeats) utils.stages.sellable_baggage_or_seats();
    if (hasSellableBaggage) utils.stages.sellable_baggage();
    if (hasSellableSeats) utils.stages.sellable_seats();
  }
};

export default { trackStages, ...utils };
