import { Actions, ActionTypes } from "./actions";
import { State, getInitialState } from "./state";

const initialState = getInitialState();

const reducer = (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case ActionTypes.isLoading:
      return {
        ...state,
        crossSell: state.crossSell,
        trackedAd: state.trackedAd,
        trackedAdLoading: state.trackedAdLoading,
        isLoading: action.payload.loading
      };
    case ActionTypes.trackedAd:
      return {
        ...state,
        crossSell: state.crossSell,
        isLoading: state.isLoading,
        trackedAdLoading: state.trackedAdLoading,
        trackedAd: action.payload.trackedAd
      };
    case ActionTypes.trackedAdLoading:
      return {
        ...state,
        crossSell: state.crossSell,
        isLoading: state.isLoading,
        trackedAd: state.trackedAd,
        trackedAdLoading: action.payload.trackedAdLoading
      };
    case ActionTypes.fetchSuccess:
      return {
        ...state,
        isLoading: state.isLoading,
        trackedAd: state.trackedAd,
        trackedAdLoading: state.trackedAdLoading,
        crossSell: action.payload.crossSell
      };

    default:
      return state;
  }
};

export { reducer, getInitialState };
