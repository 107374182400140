import { createTrackingUtils } from "..";

const utils = createTrackingUtils({
  name: "flights_web_mdot_sb_multi_select_chip_v2",
  stages: {
    render_searchbox: 1,
    direct: 2,
    meta: 3,
    solo: 4,
    non_solo: 5
  },
  defaultStage: 1,
  goals: {
    airport_city_count_1: 1,
    airport_city_count_2: 2,
    airport_city_count_3: 3,
    remove_airport_city_click_x: 4,
    remove_airport_city_backspace: 5
  },
  goalsWithValue: []
});

export default utils;
