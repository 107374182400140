import React, { useMemo } from "react";
import styles from "./ErrorMessage.module.css";
const ErrorInline = (props) => {
    const { text, attributes, id } = props;
    const { className, ...restAttributes } = attributes || {};
    const rootStyles = useMemo(() => {
        return [styles.inline, className].filter(Boolean).join(" ");
    }, [className]);
    return (React.createElement("div", { "data-ui-name-message": true, role: "alert", id: id, ...restAttributes, className: rootStyles }, text));
};
const ErrorTooltip = (props) => {
    const { text, attributes, id, isHeaderUpdateExp } = props;
    const { className, ...restAttributes } = attributes || {};
    const position = isHeaderUpdateExp ? "right" : props.position || "bottom";
    const rootStyles = useMemo(() => {
        return [
            styles.root,
            className,
            position == "top" && styles.rootTop,
            position == "bottom" && styles.rootBottom,
            position == "right" && styles.rootRight
        ]
            .filter(Boolean)
            .join(" ");
    }, [position, className]);
    const wrapperStyles = useMemo(() => {
        return [
            !isHeaderUpdateExp && styles.wrapper,
            position == "top" && styles.wrapperTop,
            position == "bottom" && styles.wrapperBottom,
            position == "right" && styles.wrapperRight
        ]
            .filter(Boolean)
            .join(" ");
    }, [position]);
    return (React.createElement("div", { id: id, role: "alert", "data-ui-name-message": true, className: rootStyles },
        React.createElement("div", { className: wrapperStyles },
            React.createElement("div", { className: styles.content, ...restAttributes }, text))));
};
const ShellErrorMessage = (props) => {
    return props.variant === "tooltip" ? React.createElement(ErrorTooltip, { ...props }) : React.createElement(ErrorInline, { ...props });
};
export default ShellErrorMessage;
