import React, { useEffect } from "react";
import isEmpty from "lodash/isEmpty";
import { useRemoteComponent } from "@bookingcom/remote-component";
import useRouteName from "hooks/useRouteName";
import useEvents from "hooks/useEvents";
import GeniusRCSkeleton from "cross-sell/components/GeniusRCSkeleton/GeniusRCSkeleton";

const paths = {
  checkout3: "/GeniusWrapper/Flights/BookProcess",
  confirmation: "/GeniusWrapper/Flights/Confirmation"
};

const GeniusWrapper = () => {
  const routeName = useRouteName();
  const { grumble } = useEvents();

  const { Component, isLoading, error } = useRemoteComponent({
    project: "web-shell",
    service: "header-mfe",
    // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
    path: paths[routeName],
    type: "capla"
  });

  useEffect(() => {
    if (!isEmpty(error)) grumble("RC error: Genius wrapper", { routeName, error: error.toString() });
  }, [error, grumble, routeName]);

  return isLoading ? <GeniusRCSkeleton /> : <Component />;
};

export default GeniusWrapper;
