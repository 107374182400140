import React, { useMemo } from "react";
import { UIAvailableExtraProducts, UIBrandedFareOrFlexTicket, UIFetchStatus, UIFlightData } from "@flights/types";
import { Button, Card, SkeletonLoader, Stack, Text, useTheme } from "@bookingcom/bui-react";
import FareCardPrice from "./FareCardPrice";
import { t, useI18n } from "@bookingcom/lingojs-react";
import useGoToFlightDetails from "components/elements/FareSelector/hooks/useGoToFlightDetails";
import styles from "./TicketTypeSearchResultCard.module.css";
import { TicketType } from "components/elements/TicketTypeRadio/types";
import FeatureList from "components/elements/FeatureList";
import flights_ace_web_flexibilty_sr_page from "utils/experiments/ace/flights_ace_web_flexibilty_sr_page";

type FareCardProps = {
  baseOffer: UIFlightData;
  fareOffer: UIBrandedFareOrFlexTicket;
  loadingStatus?: UIFetchStatus;
  ancillaries?: UIAvailableExtraProducts;
  ticketType: TicketType;
};

const TicketTypeSearchResultCard = ({ baseOffer, loadingStatus, fareOffer, ticketType }: FareCardProps) => {
  const i18n = useI18n();
  const goToFlightDetails = useGoToFlightDetails();
  const theme = useTheme();

  const TICKET_TITLE = useMemo(() => {
    return {
      standard: i18n.trans(t("flights_ancillary_offer_flexticket_notflexible")),
      flexible: i18n.trans(t("flights_ancillary_offer_flexticket_flexible")),
      cfar: i18n.trans(t("flights_apex_cfar_heading"))
    };
  }, [i18n]);

  const TICKET_FEATURES = useMemo(() => {
    return {
      standard: {
        included: [i18n.trans(t("flights_ace_sr_standard_ticket_1"))],
        excluded: [i18n.trans(t("flights_ace_sr_standard_ticket_2"))]
      },
      flexible: {
        included: [i18n.trans(t("flights_ace_sr_flex_ticket_1")), i18n.trans(t("flights_ace_sr_flex_ticket_2"))],
        excluded: []
      },
      cfar: {
        included: [i18n.trans(t("flights_ace_sr_cfar_ticket_1")), i18n.trans(t("flights_ace_sr_cfar_ticket_2"))],
        excluded: []
      }
    };
  }, [i18n]);

  return (
    <Card className={styles.card}>
      <Stack justifyContent="space-between" direction={"column"} gap={0}>
        <Text className={styles.title} variant="strong_1" attributes={{ "data-fare-card-row": "title" }}>
          {loadingStatus === "loading" ? (
            <Stack gap={6} direction="row">
              <Stack.Item grow>
                <SkeletonLoader />
              </Stack.Item>
              <SkeletonLoader width={theme.units.spacing_12x} />
            </Stack>
          ) : (
            TICKET_TITLE[ticketType]
          )}
        </Text>

        <Stack className={styles.featureWrap}>
          <FeatureList
            included={TICKET_FEATURES[ticketType].included}
            excluded={TICKET_FEATURES[ticketType].excluded}
          />
        </Stack>

        <div className={styles.priceWrap}>
          <FareCardPrice
            fareOffer={fareOffer}
            baseOffer={baseOffer}
            isFlexTicket={ticketType === "flexible"}
            isCfarTicket={ticketType === "cfar"}
            showGroupPricing={true}
          />
        </div>
        <Button
          onClick={() => {
            if (ticketType === "flexible" || ticketType === "cfar") {
              flights_ace_web_flexibilty_sr_page.goals.click_view_details_on_cfar_or_flex();
              if (ticketType === "flexible") {
                flights_ace_web_flexibilty_sr_page.goals.click_view_details_on_flex();
              }
            }
            goToFlightDetails(fareOffer.token, baseOffer, ticketType === "flexible", ticketType === "cfar");
          }}
          className={styles.button}
          variant="secondary"
          attributes={{ "aria-label": `${i18n.trans(t("flights_ace_sr_ticket_type_cta"))}` }}
        >
          {i18n.trans(t("flights_ace_sr_ticket_type_cta"))}
        </Button>
      </Stack>
    </Card>
  );
};

export default TicketTypeSearchResultCard;
