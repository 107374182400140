import React from "react";
import { LocaleState, GlobalContext } from "@flights/types";
import { getI18nState } from "server/modules/i18nWithExp";

type I18NState = ReturnType<typeof getI18nState>;

declare global {
  interface Window {
    __GLOBAL_CONTEXT__: GlobalContext;
    __LOCALE_STATE__: LocaleState;
    __I18N_STATE__: I18NState;
    gtag: any;
    loadTagManager?: Function;
    ga?: Function;
    riskifiedBeaconLoad: Function;
    dataLayer: any[];
  }
}

export default React.createContext<GlobalContext>({
  requestId: "",
  userAgent: {},
  availableLocales: [],
  experiments: {},
  features: {},
  auth: { enabled: true, clientId: "", redirectUri: "", isAuthenticatedOnWeb: false },
  supplierFeatures: [],
  isFlightsDomain: true,
  shouldNotTrack: false,
  apiPrefix: "",
  myIp: "",
  searchHistory: [],
  voidExcludedCountries: [],
  liveChatIncludedCountries: false,
  isPaymentCollectionPage: false,
  isCustomerServiceEnabledForPOS: false
});
