/* start - flights_web_flexible_date_search */
import { useEffect, useMemo } from "react";
// eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
import { useStore } from "../store";
import { trackGoalWithValue } from "utils/et";
import useFlexPriceDiffTracker from "./useFlexPriceDiffTracker";

type Params = {
  diffHigherTag: string;
  diffLowerTag: string;
  diffPercentageTag: string;
  isReferencePriceRounded?: boolean; // Used in case Search Results prices are rounded
};

export default function useFlexTrackPriceDiff({
  diffHigherTag,
  diffLowerTag,
  diffPercentageTag,
  isReferencePriceRounded = false
}: Params) {
  // eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
  const store = useStore();
  const {
    searchResults: { flightDeals = [] }
  } = store;
  const { consumeOriginPrice } = useFlexPriceDiffTracker();

  const bestPriceForAdult = useMemo(
    () =>
      flightDeals
        .find(({ key }) => key === "BEST")
        ?.travellerPrices.find(({ travellerType }) => travellerType === "ADULT")?.travellerPriceBreakdown[
        isReferencePriceRounded ? "totalRounded" : "total"
      ],
    [flightDeals, isReferencePriceRounded]
  );

  useEffect(() => {
    const originBestPrice = consumeOriginPrice();
    const currentBestPrice = bestPriceForAdult?.units;

    if (originBestPrice && currentBestPrice) {
      if (currentBestPrice > originBestPrice) {
        trackGoalWithValue(diffHigherTag, 1);
      }

      if (currentBestPrice < originBestPrice) {
        trackGoalWithValue(diffLowerTag, 1);
      }

      const priceDiff = originBestPrice - currentBestPrice;
      const priceDiffPercentage = Math.floor((priceDiff / originBestPrice) * 100);
      trackGoalWithValue(diffPercentageTag, priceDiffPercentage);
    }
  }, [bestPriceForAdult, consumeOriginPrice, diffHigherTag, diffLowerTag, diffPercentageTag]);
}
/* end - flights_web_flexible_date_search */
