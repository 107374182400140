import React from "react";
import { Occupancy } from "../Occupancy/index.js";
import { CabinClassRadio, CabinClassInputSelect } from "../CabinClass/index.js";
import styles from "./OccupancyCabinClass.module.css";
const OccupancyCabinClass = (props) => {
    const { cabinClassVariant = "radio", occupancyHideTitle } = props;
    return (React.createElement("div", { className: styles.wrapper },
        React.createElement(Occupancy, { hideTitle: occupancyHideTitle }),
        cabinClassVariant === "radio" ? React.createElement(CabinClassRadio, null) : React.createElement(CabinClassInputSelect, null)));
};
export default OccupancyCabinClass;
