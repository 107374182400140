// eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
import { __deprecated__createTrackingUtils } from "../index";
import { UIAvailableExtraProducts, UIFlightData } from "@flights/types";

// eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
const utils = __deprecated__createTrackingUtils({
  name: "flights_apex_web_ancillaries_micro_conversion_aa",
  stages: {
    sr_with_requestable_bf: 1,
    bf_page: 2,
    sr_with_flex_ticket: 3,
    ticket_type_page: 4,
    ancillary_page_flex_ticket: 5,
    ancillary_page_insurance: 6
  },
  goals: {
    bf_page_next_visible: 1,
    ticket_type_next_visible: 2,
    show_fare_options_sr: 3
  },
  goalsWithValue: []
});

const trackSearchResultsStages = (flights: UIFlightData[]) => {
  if (flights.some((flight) => flight.requestableBrandedFares)) utils.stages.sr_with_requestable_bf();
  if (flights.some((flight) => flight.ancillaries?.flexibleTicket)) utils.stages.sr_with_flex_ticket();
};

const trackAncillariesStages = (extraProducts: UIAvailableExtraProducts, canOfferFlexibleTicket: boolean) => {
  if (extraProducts.flexibleTicket && canOfferFlexibleTicket) utils.stages.ancillary_page_flex_ticket();
  if (extraProducts.travelInsurance) utils.stages.ancillary_page_insurance();
};

export default { ...utils, trackSearchResultsStages, trackAncillariesStages };
