import { useCallback } from "react";
import { validateSegment, validateOccupancy } from "../../utils/validator.js";
const useValidation = () => {
    const isOccupancyValid = useCallback((occupancy) => {
        return validateOccupancy(occupancy);
    }, []);
    const isSegmentsValid = useCallback((segments, _type) => {
        let isValid = true;
        segments.forEach((segment, idx) => {
            isValid = isValid && validateSegment(segment, idx, _type);
        });
        return isValid;
    }, []);
    const validate = useCallback((_segments, _occupancy, _type) => {
        return isSegmentsValid(_segments, _type) && isOccupancyValid(_occupancy);
    }, [isSegmentsValid, isOccupancyValid]);
    const focusFirstInvalidInput = useCallback(() => {
        setTimeout(() => {
            // to make sure re-renders finished
            const elm = document.querySelector("[data-ui-error='true']");
            if (elm)
                elm.focus();
        }, 5);
    }, []);
    return { validate, focusFirstInvalidInput };
};
export default useValidation;
