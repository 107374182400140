import React, { useEffect } from "react";
import groupBy from "lodash/groupBy";
import debounce from "lodash/debounce";

export default function useEqualRowHeight(
  container: React.RefObject<HTMLElement>,
  attribute: string,
  dependencies: any[] = []
) {
  useEffect(() => {
    handleChange(container, attribute);
  }, [container.current, ...dependencies]); // eslint-disable-line

  useEffect(() => {
    const onChange = debounce(() => handleChange(container, attribute), 100);
    window.addEventListener("resize", onChange);

    return () => {
      window.removeEventListener("resize", onChange);
    };
  }, []); // eslint-disable-line
}

function handleChange(container: React.RefObject<HTMLElement>, attribute: string) {
  if (!container.current) {
    return;
  }

  const cells = [...container.current.querySelectorAll(`[${attribute}]`)] as HTMLElement[];
  const rows = groupBy(cells, (cell) => cell.getAttribute(attribute));

  Object.values(rows).forEach(equalizeRow);
}

function equalizeRow(cells: HTMLElement[]) {
  let maxHeight = 0;

  for (const cell of cells) {
    cell.style.height = "auto";
    const rect = cell.getBoundingClientRect();
    maxHeight = Math.max(rect.height, maxHeight);
  }

  for (const cell of cells) {
    cell.style.height = `${maxHeight}px`;
  }
}
