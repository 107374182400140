import { createTrackingUtils } from "utils/experiments";

const utils = createTrackingUtils({
  name: "flights_web_cat_fly_anywhere_www",
  defaultStage: 1,
  stages: {
    opened_where_to_1: 1,
    home_page_2: 2,
    search_page_3: 3,
    landing_rebook_notfound_4: 4,
    booking_window_gt_30_5: 5,
    booking_window_lt_30_6: 6,
    meta_users_7: 7,
    direct_users_8: 8,
    empty_page_9: 9
  },
  goals: {
    clicked_card_1: 1,
    changed_sorting_2: 2,
    landed_on_fly_anywhere_3: 3,
    changed_page_4: 4
  },
  goalsWithValue: ["flights_web_cat_fly_anywhere_www_clicked_from"]
});

export default utils;
