/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React from "react";
import { Text } from "@bookingcom/bui-react";
import useUserAgent from "hooks/useUserAgent";
import { useI18n, I18nChildContext } from "@bookingcom/lingojs-react";
import { t } from "@bookingcom/lingojs-core";
import useATOL from "hooks/useATOL";
import Frame from "../Frame";

const ATOLFLightCardFooter = () => {
  const { isMobile } = useUserAgent();
  const i18n = useI18n() as I18nChildContext;
  const { isATolProtectedCheckOut } = useATOL();
  const isAtol = isATolProtectedCheckOut();

  if (!isAtol) return null;

  return (
    <Frame mt={isMobile ? 4 : 8} mb={isMobile ? 4 : 0}>
      <Text variant="small_1">
        {i18n.trans(t("flights_atol_itinerary_footer_disclaimer", { variables: { b_companyname: "Booking.com" } }))}
      </Text>
    </Frame>
  );
};

export default ATOLFLightCardFooter;
