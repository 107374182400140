/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Chip, SheetContainer, SheetContainerProps, Stack } from "@bookingcom/bui-react";
import Duration from "../Input/Duration";
import useSearchFiltersController from "../useSearchFiltersController";
import { useI18n, t } from "@bookingcom/lingojs-react";
import Frame from "components/elements/Frame";

export type DurationModalProps = {
  position: SheetContainerProps["position"];
};

const DurationModalComp = (props: DurationModalProps) => {
  const { duration } = useSearchFiltersController();
  const i18n = useI18n();
  const [active, setActive] = useState(false);
  const [shouldApply, setShouldApply] = useState(false);
  const [draftValue, setDraftValue] = useState(duration.value);

  const handleOnClose = useCallback(() => {
    setActive(false);
    setDraftValue(duration.value);
  }, [duration.value]);

  const handleOnChange = useCallback((value: typeof draftValue) => {
    setDraftValue(value);
  }, []);

  const handleOnApply = useCallback(() => {
    duration.set(draftValue);
    setActive(false);
    setShouldApply(true);
  }, [duration, draftValue]);

  const handleOnReset = useCallback(() => {
    duration.reset();
    setActive(false);
    setShouldApply(true);
  }, [duration]);

  useEffect(() => {
    if (shouldApply) {
      setShouldApply(false);
      setDraftValue(duration.value);
    }
  }, [shouldApply, duration]);

  const label = useMemo(() => {
    if (duration.touched) {
      const numHours = i18n.trans(
        t("flights_filters_duration_hours", {
          variables: { num_exception: duration.value, num_hours: duration.value.toString() }
        })
      );
      return i18n.trans(
        t("flights_mdot_filters_duration_applied", { variables: { num_hours: numHours, num_minutes: "" } })
      );
    }
    return i18n.trans(t("flights_filters_duration_title"));
  }, [i18n, duration]);

  return (
    <>
      <Chip
        variant="action"
        checked={duration.touched}
        onClick={() => setActive(true)}
        label={label}
        attributes={{ "aria-label": i18n.trans(t("a11y_flights_filters_duration")) }}
      />
      <SheetContainer
        active={active}
        onClose={handleOnClose}
        onAfterClose={handleOnClose}
        onCloseTrigger={handleOnClose}
        closeAriaLabel={i18n.trans(t("close"))}
        title={i18n.trans(t("flights_filters_duration_title"))}
        keepMounted={false}
        position={props.position}
        footer={
          <Frame mr={4} mb={4} ml={4}>
            <Stack direction="row">
              <Stack.Item grow>
                <Button wide onClick={handleOnReset} disabled={!duration.touched} variant="secondary" size="large">
                  {i18n.trans(t("flights_filter_reset"))}
                </Button>
              </Stack.Item>
              <Stack.Item grow>
                <Button wide onClick={handleOnApply} size="large">
                  {i18n.trans(t("flights_mdot_filters_apply_cta"))}
                </Button>
              </Stack.Item>
            </Stack>
          </Frame>
        }
      >
        <Duration min={duration.options.min} max={duration.options.max} value={draftValue} onChange={handleOnChange} />
      </SheetContainer>
    </>
  );
};

export default function DurationModal({ position }: DurationModalProps) {
  const { duration } = useSearchFiltersController();
  if (duration.options.max === duration.options.min) return null;
  if (duration.options.max === 0) return null;
  return <DurationModalComp position={position} />;
}
