import deepEqual from "deep-equal";
export function compressIdenticalAllowances(segment) {
    const compressed = [];
    segment.forEach((product) => {
        // we need to add up quantities (`maxPiece`) for luggage of the same type and restrictions
        const allowance = {
            ...product,
            // eslint-disable-next-line no-restricted-syntax -- this line was auto generated, hence fix the issue timely
            maxPiece: null,
            // eslint-disable-next-line no-restricted-syntax -- this line was auto generated, hence fix the issue timely
            piecePerPax: null
        };
        const existingAllowance = compressed.find((existing) => {
            const existingAllowance = {
                ...existing,
                // eslint-disable-next-line no-restricted-syntax -- this line was auto generated, hence fix the issue timely
                maxPiece: null,
                // eslint-disable-next-line no-restricted-syntax -- this line was auto generated, hence fix the issue timely
                piecePerPax: null
            };
            return deepEqual(existingAllowance, allowance);
        });
        // let's check if we already saw this type of luggage
        if (existingAllowance) {
            existingAllowance.maxPiece += product.maxPiece;
            existingAllowance.piecePerPax = product.maxPiece;
        }
        else {
            // making a copy to avoid mutating `product` via changing `existingAllowance.maxPiece` above
            // as `existingAllowance` would point to the same `product` object
            compressed.push({ ...product, piecePerPax: product.maxPiece });
        }
    });
    return compressed;
}
