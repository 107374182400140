import React, { useCallback } from "react";
import Adults from "./Adults.js";
import Children from "./Children.js";
import Ages from "./Ages.js";
import useOccupancy from "./useOccupancy.js";
import { Text } from "@bookingcom/bui-react";
import { t } from "@bookingcom/lingojs-core";
import styles from "./Occupancy.module.css";
const Occupancy = (props) => {
    const { hideTitle = false } = props;
    const { occupancy, setOccupancy, i18n } = useOccupancy();
    const handleAdultsChange = useCallback((adults) => {
        setOccupancy({ adults });
    }, [setOccupancy]);
    const handleChildrenChange = useCallback((children) => {
        setOccupancy({ children });
    }, [setOccupancy]);
    const handleAgesChange = useCallback((children) => {
        setOccupancy({ children });
    }, [setOccupancy]);
    return (React.createElement("div", { className: styles.wrapper },
        !hideTitle ? (React.createElement(Text, { variant: "body_2" }, i18n.trans(t("flights_search_travellers_name", {
            variables: {
                num_exception: occupancy.adults + occupancy.children.length
            }
        })))) : null,
        React.createElement(Adults, { occupancy: occupancy, onChange: handleAdultsChange }),
        React.createElement(Children, { occupancy: occupancy, onChange: handleChildrenChange }),
        React.createElement(Ages, { occupancy: occupancy, onChange: handleAgesChange })));
};
export default Occupancy;
