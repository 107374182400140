import React, { Fragment } from "react";
import { DateModal } from "../Date/index.js";
import { Divider } from "@bookingcom/bui-react";
import { OriginDestinationVertical } from "../OriginDestination/index.js";
import { ShellWrapper } from "../Shell/index.js";
import RemoveSegment from "./RemoveSegment.js";
import useSegment from "./useSegment.js";
import styles from "./SegmentVertical.module.css";
const SegmentVertical = () => {
    const { isMultiStop, segments } = useSegment();
    return (React.createElement(React.Fragment, null, segments.map((_, idx) => (React.createElement(Fragment, { key: idx }, isMultiStop ? (React.createElement(ShellWrapper, { fill: true, "data-ui-name": `segment_${idx}` },
        React.createElement("div", { className: styles.wrapper },
            React.createElement(OriginDestinationVertical, { segmentIndex: idx }),
            React.createElement(Divider, null),
            React.createElement(DateModal, { segmentIndex: idx }),
            React.createElement("div", { className: styles.removeWrapper },
                React.createElement(RemoveSegment, { className: styles.remove, disabled: segments.length === 1, segmentIndex: idx }))))) : (React.createElement(React.Fragment, null,
        React.createElement(ShellWrapper, { fill: true, "data-ui-name": `segment_${idx}` },
            React.createElement(OriginDestinationVertical, { segmentIndex: idx })),
        React.createElement(DateModal, { segmentIndex: idx }))))))));
};
export default SegmentVertical;
