import React from "react";
import LocaleContext from "./LocaleContext";

interface Props {
  locale?: any;
  children?: React.ReactNode;
  isRTL?: boolean;
}

const LocaleProvider: React.FunctionComponent<Props> = (props) => {
  const { locale, isRTL } = props;
  return (
    <LocaleContext.Provider value={{ isRTL: isRTL || false, locale: locale }}>{props.children}</LocaleContext.Provider>
  );
};

export default LocaleProvider;
