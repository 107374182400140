import { useLocation, matchPath } from "react-router-dom";
import useUserAgent from "./useUserAgent";
import { useMemo } from "react";
import routeMap from "../app/routes";
import useGlobalContext from "./useGlobalContext";

export default function useRouteName() {
  const location = useLocation();
  const { isMobile } = useUserAgent();
  const { isPaymentCollectionPage } = useGlobalContext();

  return useMemo(() => {
    for (const route of routeMap({ isMobile, isPaymentCollectionPage })) {
      if (matchPath(location.pathname, { path: route.path, exact: true })) {
        return route.name;
      }
    }

    throw new Error(`Current path <${location.pathname}> doesnt match a known route`);
  }, [isMobile, location, isPaymentCollectionPage]);
}
