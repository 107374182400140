import { Actions, ActionTypes } from "./actions";
import { State, getInitialState } from "./state";

const initialState = getInitialState();

const reducer = (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case ActionTypes.show:
      return {
        ...state,
        visible: true
      };
    case ActionTypes.hide:
      return {
        ...state,
        visible: false
      };

    default:
      return state;
  }
};

export { reducer, getInitialState };
