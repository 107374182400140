import { UIWorldwideFlightInfo } from "@flights/types";

/**
 * Worldwide flights data currently populated and used on Flights Index page.
 * See getDerivedStateFromRequest.ts for how it is computed.
 */
export type State = UIWorldwideFlightInfo[] | null;

// eslint-disable-next-line no-restricted-syntax -- this line was auto generated, hence fix the issue timely
export const getInitialState = (): State => null;
