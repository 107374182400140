export function getSegmentStatuses(statuses, segmentIndex) {
    if (!statuses.length)
        return [];
    const segmentStatus = statuses.filter((status) => {
        return status.legs.some((leg) => leg.legIdentifier.segmentIndex === segmentIndex);
    });
    return segmentStatus;
}
export function getAllLegsStatuses(statuses) {
    return statuses.map((status) => status.legs).flat(2);
}
export function getLegStatus(statuses, segmentIndex, legIndex) {
    const segmentStatuses = getSegmentStatuses(statuses, segmentIndex);
    const legStatus = segmentStatuses
        .map((segmentStatus) => {
        return segmentStatus.legs.filter(({ legIdentifier }) => {
            return legIdentifier.legIndex === legIndex && legIdentifier.segmentIndex === segmentIndex;
        });
    })
        .flat(2);
    return legStatus[0];
}
