import * as React from "react";
import { Card, SkeletonLoader, Stack, useTheme } from "@bookingcom/bui-react";

interface SkeletonFlightCardProps {
  shorterCard?: boolean;
}

export const SkeletonFlightCard: React.FC<SkeletonFlightCardProps> = ({ shorterCard }) => {
  const theme = useTheme();

  return (
    <Card>
      <Stack gap={6}>
        <Stack>
          <Stack gap={6} direction="row">
            <Stack.Item grow>
              <SkeletonLoader />
            </Stack.Item>
            <SkeletonLoader width={theme.units.spacing_12x} />
          </Stack>
          <Stack gap={6} direction="row">
            <Stack.Item grow>
              <SkeletonLoader />
            </Stack.Item>
            <SkeletonLoader width={theme.units.spacing_12x} />
          </Stack>
          {shorterCard && (
            <Stack gap={6} direction="row">
              <Stack.Item grow>
                <SkeletonLoader />
              </Stack.Item>
              <SkeletonLoader width={theme.units.spacing_12x} />
            </Stack>
          )}
        </Stack>
        {!shorterCard && (
          <Stack gap={6} direction="row">
            <Stack.Item grow>
              <SkeletonLoader />
            </Stack.Item>
            <SkeletonLoader width={theme.units.spacing_12x} />
          </Stack>
        )}
        <Stack gap={6} direction="row" justifyContent="space-between" alignItems="end">
          <SkeletonLoader variant="box" width={theme.units.spacing_8x} />
          <SkeletonLoader width="30%" />
        </Stack>
      </Stack>
    </Card>
  );
};
