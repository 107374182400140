/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React from "react";
import { Text } from "@bookingcom/bui-react";
import { useI18n, I18nChildContext } from "@bookingcom/lingojs-react";
import { t } from "@bookingcom/lingojs-core";
import useATOL from "hooks/useATOL";
import Frame from "../Frame";

const ATOLPriceBreakdownFooter = ({ isAtolProtected = false }) => {
  const i18n = useI18n() as I18nChildContext;
  const { isATolProtectedCheckOut } = useATOL();
  const isAtol = isAtolProtected || isATolProtectedCheckOut();

  if (!isAtol) return null;

  return (
    <Frame mt={4} mb={4}>
      <Text tagName="span" variant="small_1" attributes={{ "data-testid": "ATOL_price_breakdown_footer_text" }}>
        {i18n.trans(t("flights_atol_price_breakdown"))}
      </Text>
    </Frame>
  );
};

export default ATOLPriceBreakdownFooter;
