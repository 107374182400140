import React from "react";
import useSegment from "./useSegment.js";
import { OriginDestinationHorizontal } from "../OriginDestination/index.js";
import { DateDropDown } from "../Date/index.js";
import RemoveSegment from "./RemoveSegment.js";
import { OccupancyButtonDropDown } from "../Occupancy/index.js";
import styles from "./SegmentHorizontal.module.css";
const SegmentHorizontal = () => {
    const { isMultiStop, segments, fixInputSpacing } = useSegment();
    return (React.createElement("div", { className: styles.wrapper }, segments.map((_, idx) => (React.createElement("div", { key: idx, className: styles.line, "data-ui-name": "segments_list_item", style: {
            gridTemplateColumns: !isMultiStop ? (fixInputSpacing ? "60fr 22fr 18fr" : "6fr 2fr 2fr") : "5fr 2fr"
        } },
        React.createElement("div", { className: styles.destination },
            React.createElement(OriginDestinationHorizontal, { segmentIndex: idx })),
        React.createElement("div", { className: styles.date },
            React.createElement(DateDropDown, { segmentIndex: idx })),
        !isMultiStop ? React.createElement(OccupancyButtonDropDown, null) : undefined,
        isMultiStop ? (React.createElement("div", { className: styles.remove },
            React.createElement(RemoveSegment, { disabled: segments.length === 1, segmentIndex: idx }))) : null)))));
};
export default SegmentHorizontal;
