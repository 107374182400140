import { UIFlightSegment, UILeg, UITimelineLeg } from "@flights/types";

const fromLeg = (leg: UILeg | UITimelineLeg) => {
  return leg.flightStops.filter((stop) => stop && !stop.changeOfGauge);
};

const fromSegment = (segment: UIFlightSegment) => {
  return segment.legs.map((leg) => fromLeg(leg)).flat();
};

const getTechnicalStops = { fromLeg, fromSegment };

export default getTechnicalStops;
