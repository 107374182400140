import clientFetch from "utils/client-fetch";

const fetchApi = (url: string, method: string, headers: {}, params: {}, data: any) => {
  const options: RequestInit = {
    method: method,
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json"
    }
  };
  if (headers) {
    options.headers = { ...options.headers, ...headers };
  }
  if (method === "POST") {
    options.body = JSON.stringify(data);
  }
  if (params && Object.keys(params).length) {
    url += "?" + new URLSearchParams(params);
  }
  return clientFetch(url, options);
};

// eslint-disable-next-line no-restricted-syntax -- this line was auto generated, hence fix the issue timely
const get = (url: string, headers: {}, params: {}) => fetchApi(url, "GET", headers, params, null);
const post = (url: string, headers: {}, params: {}, data: any) => fetchApi(url, "POST", headers, params, data);

export { get, post };
