import { UILocation } from "@flights/types";
import { PARAM_LIST_SEPARATOR, URL_IATA_LIST_SEPARATOR } from "@flights/constants";

export function getCityIataCode(location?: UILocation) {
  return location && location.type === "AIRPORT" && location.city;
}

export function getCityName(location?: UILocation) {
  return location && location.type === "AIRPORT" && location.cityName;
}

export function tryReplaceAirportWithCity(location?: UILocation) {
  return location && (getCityIataCode(location) || location.code);
}

function tryReplaceAirportNameWithCityName(location?: UILocation) {
  return location && (getCityName(location) || location.name);
}

const getAirportSearchParams = (suggestions: UILocation[]) => {
  return suggestions.length > 1
    ? suggestions.map((suggestion) => suggestion.code).join(PARAM_LIST_SEPARATOR)
    : suggestions[0].code;
};

export function getExpandedSearchAirports(from?: UILocation[], to?: UILocation[]) {
  if (!from?.length || !to?.length) return undefined;

  const fromAirport = getAirportSearchParams(from);
  const toAirport = getAirportSearchParams(to);

  const currentIataCodes = `${fromAirport}-${toAirport}`;

  const fromCities = from.map((airport) => tryReplaceAirportWithCity(airport)).join(URL_IATA_LIST_SEPARATOR);
  const toCities = to.map((airport) => tryReplaceAirportWithCity(airport)).join(URL_IATA_LIST_SEPARATOR);

  const fromLocationName = from.map((airport) => tryReplaceAirportNameWithCityName(airport)).join(PARAM_LIST_SEPARATOR);
  const toLocationName = to.map((airport) => tryReplaceAirportNameWithCityName(airport)).join(PARAM_LIST_SEPARATOR);

  const newIataCodes = `${fromCities}-${toCities}`;

  if (newIataCodes !== currentIataCodes) {
    return { fromCities, toCities, fromLocationName, toLocationName };
  } else {
    return undefined;
  }
}
