/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { PropsWithChildren, ReactNode } from "react";
import { Stack, StackProps } from "@bookingcom/bui-react";

type WithSuffixProps = StackProps & {
  suffix?: number | string | ReactNode;
};

const WithSuffix = (props: PropsWithChildren<WithSuffixProps>) => {
  const { suffix, children, ...rest } = props;
  return (
    <Stack gap={3} direction="row" {...rest}>
      <Stack.Item grow>{children}</Stack.Item>
      {suffix !== undefined ? <Stack.Item>{suffix}</Stack.Item> : null}
    </Stack>
  );
};

export default WithSuffix;
