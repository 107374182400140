import { format as formatDate } from "date-fns";
import { ActionTypes } from "./actions.js";
import { getInitialState, initialSearchSegment, SEARCH_DATE_FORMAT } from "./state.js";
import { ANYWHERE } from "../utils/constants.js";
const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.setType: {
            const { type } = action.payload;
            const newSearchSegments = [...state.searchSegments];
            if (type === "MULTISTOP") {
                newSearchSegments.forEach((segment) => {
                    /**
                     * Remove multiselected inputs in case airports are selected first and the trip type is changed to multi stop
                     * Remove the if block to enable multiselect from multistop
                     */
                    if (type === "MULTISTOP") {
                        segment.from.splice(1);
                        segment.to.splice(1);
                    }
                    segment.returnDate = undefined;
                });
                return { ...state, type, searchSegments: newSearchSegments };
            }
            else {
                newSearchSegments.splice(1);
                return { ...state, type, searchSegments: newSearchSegments };
            }
        }
        case ActionTypes.removeSegment: {
            const { searchSegmentIndex } = action.payload;
            const newSearchSegments = [...state.searchSegments];
            newSearchSegments.splice(searchSegmentIndex, 1);
            return { ...state, searchSegments: newSearchSegments };
        }
        case ActionTypes.addSegment: {
            const newSearchSegments = [...state.searchSegments, initialSearchSegment];
            return { ...state, searchSegments: newSearchSegments };
        }
        case ActionTypes.clearSegments: {
            const firstSegment = state.searchSegments[0] || initialSearchSegment;
            const newSearchSegments = [firstSegment];
            return { ...state, searchSegments: newSearchSegments };
        }
        case ActionTypes.setFrom: {
            const { from, searchSegmentIndex = 0 } = action.payload;
            const newSearchSegments = [...state.searchSegments];
            const currentSearchSegment = { ...newSearchSegments[searchSegmentIndex] };
            currentSearchSegment.from = from;
            newSearchSegments[searchSegmentIndex] = currentSearchSegment;
            return { ...state, searchSegments: newSearchSegments };
        }
        case ActionTypes.setTo: {
            const { to, searchSegmentIndex = 0 } = action.payload;
            const newSearchSegments = [...state.searchSegments];
            const currentSearchSegment = { ...newSearchSegments[searchSegmentIndex] };
            currentSearchSegment.to = to;
            newSearchSegments[searchSegmentIndex] = currentSearchSegment;
            return { ...state, searchSegments: newSearchSegments };
        }
        case ActionTypes.setDateRange: {
            let nfrom, nto;
            const { range, searchSegmentIndex = 0, fareRange } = action.payload;
            const { from, to } = range;
            if (typeof from === "object") {
                nfrom = formatDate(from, SEARCH_DATE_FORMAT);
            }
            if (typeof to === "object") {
                nto = formatDate(to, SEARCH_DATE_FORMAT);
            }
            const newSearchSegments = [...state.searchSegments];
            const currentSearchSegment = { ...newSearchSegments[searchSegmentIndex] };
            currentSearchSegment.departureDate = nfrom;
            currentSearchSegment.returnDate = nto;
            if (state.type === "MULTISTOP") {
                currentSearchSegment.returnDate = undefined;
            }
            /* This is purely to help tracking, remove if fullon */
            if (fareRange) {
                currentSearchSegment.fareRange = { oneWay: fareRange?.oneWay, roundTrip: fareRange?.roundTrip };
            }
            /* ------------------------------------------------- */
            newSearchSegments[searchSegmentIndex] = currentSearchSegment;
            return { ...state, searchSegments: newSearchSegments };
        }
        case ActionTypes.setOccupancyAndCabinClass:
            return { ...state, ...action.payload };
        case ActionTypes.setOccupancy:
            return { ...state, ...action.payload };
        case ActionTypes.setCabinClass:
            return { ...state, ...action.payload };
        case ActionTypes.setIsDirect:
            return { ...state, isDirect: action.payload.isDirect };
        case ActionTypes.swapFromTo: {
            const { searchSegmentIndex = 0 } = action.payload;
            const newSearchSegments = [...state.searchSegments];
            const currentSearchSegment = { ...newSearchSegments[searchSegmentIndex] };
            const { from, to } = currentSearchSegment;
            if (to.some((item) => item.type === ANYWHERE)) {
                currentSearchSegment["from"] = [];
            }
            else {
                currentSearchSegment["from"] = to;
            }
            currentSearchSegment["to"] = from;
            newSearchSegments[searchSegmentIndex] = currentSearchSegment;
            return { ...state, searchSegments: newSearchSegments };
        }
        case ActionTypes.resetSegment: {
            const { searchSegmentIndex = 0 } = action.payload;
            const newSearchSegments = [...state.searchSegments];
            newSearchSegments[searchSegmentIndex] = initialSearchSegment;
            return { ...state, searchSegments: newSearchSegments };
        }
        case ActionTypes.fetchFareCalendar: {
            return {
                ...state,
                fareCalendar: { ...state.fareCalendar, fetchStatus: "loading" }
            };
        }
        case ActionTypes.fetchFareCalendarSuccess: {
            const { from, to, fares } = action.payload;
            const dateFares = state?.fareCalendar?.dateFares;
            let newDateFares = [{ from, to, fares }];
            if (dateFares) {
                newDateFares = [...dateFares, { from, to, fares }];
            }
            return {
                ...state,
                fareCalendar: {
                    ...state.fareCalendar,
                    fetchStatus: "success",
                    dateFares: newDateFares
                }
            };
        }
        case ActionTypes.fetchFareCalendarError: {
            return {
                ...state,
                fareCalendar: {
                    ...state.fareCalendar,
                    fetchStatus: "failed",
                    error: action.payload.error
                }
            };
        }
        case ActionTypes.setInitial:
            return action.payload.initialValues;
        default:
            return state;
    }
};
export { reducer, getInitialState };
